import React from 'react';
import { ReadingComprehensionLayout } from './reading-comprehension-layout';
import vlamParty from './vlam-party.png';
import './reading-comprehension-finished.scss';

type TReadingComprehensionFinishedProps = {
  onContinue: () => void;
}

export function ReadingComprehensionFinished({ onContinue }: TReadingComprehensionFinishedProps): JSX.Element {
  return <ReadingComprehensionLayout
    className="reading-comprehension-finished"
    onContinue={() => onContinue()}
  >
    <h2>
      Goed gedaan!<br/>
      Je hebt de les afgemaakt
    </h2>
    <img src={vlamParty} alt="" />
  </ReadingComprehensionLayout>;
}
