
/* eslint-disable no-case-declarations */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ILesson, IPupilProgress } from 'shared';
import { DynamicTable, DynamicTableColumn, ESortDirection } from '../../../common/utils/dynamic-table.component';
import { DateTime } from '../../../common/utils/date-time.component';
import { ProgressSummary } from './progress-summary';
import { ProgressOverviewModal } from './progress-overview-modal';

type TProgressDetailTableData = {
  lesson: string;
  date: ReactElement<any, any>;
  lessonType: string;
  result: ReactElement<any, any>;
  buttons: ReactElement<any, any>;
}

export const ProgressDetailTable = ({ progress }: { progress: IPupilProgress[] }): JSX.Element => {
  const { t } = useTranslation();

  const getLessonDisplayName = (lesson: ILesson): string => {
    if (lesson.book) {
      const book = t(`lesson-book:${lesson.book}`);
      return `${lesson.number} (${book})`;
    } else {
      return String(lesson.number);
    }
  };

  const sort = (indexA: number, indexB: number, direction: ESortDirection, objectKey: keyof TProgressDetailTableData): number => {
    const a = progress[indexA];
    const b = progress[indexB];
    if (!a || !b) {
      console.warn(`Sort in ProgressDetailTable error, sort item a: ${a ? 'defined' : 'undefined'} b: ${b ? 'defined' : 'undefined'}`);
      return 0;
    }

    const sortDesc = direction === ESortDirection.DESCENDING;
    switch (objectKey) {
      case 'lesson':
        return sortDesc ? b.lesson.number - a.lesson.number : a.lesson.number - b.lesson.number;
      case 'date':
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return sortDesc ? dateB.valueOf() - dateA.valueOf() : dateA.valueOf() - dateB.valueOf();
      default:
        return 0;
    }
  };

  const columns: DynamicTableColumn<TProgressDetailTableData>[] = [
    { title: 'Les', objectKey: 'lesson', customSortFunction: sort },
    { title: 'Datum', objectKey: 'date', customSortFunction: sort },
    { title: 'Type les', objectKey: 'lessonType' },
    { title: 'Resultaat', objectKey: 'result', disableSort: true },
    { title: '', objectKey: 'buttons', disableSort: true },
  ];

  const data: TProgressDetailTableData[] = [];
  for (const item of progress) {
    const { lesson, updatedAt } = item;

    data.push({
      lesson: getLessonDisplayName(lesson),
      date: <DateTime date={updatedAt} />,
      lessonType: lesson.type ? t(`lesson-type:${lesson.type}`) : '-',
      result: <ProgressSummary progress={item} />,
      buttons: <ProgressOverviewModal progress={item} title={`${t('progress-summary:results')} ${getLessonDisplayName(lesson)}`} />,
    });
  }

  return <DynamicTable<TProgressDetailTableData> columns={columns} data={data} sortByDefault={columns[0]} sortDirectionDefault={ESortDirection.DESCENDING} rowHeight="4.7rem" />;
};
