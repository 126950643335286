import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { Api } from '../../common/api/api';
import { Lesson } from '../../common/api/types';
import { PageTitle } from '../../common/utils/page-title.component';
import { Status } from '../../common/utils/status.component';
import { buildLessonTitle } from '../../common/utils/strings.helper';
import { EAdminRouterPath } from '../../router-path';
import { EditLesson } from './edit-lesson.component';

export enum EShowLessonState {
  NEW = 'new',
}

export function ShowLesson(): JSX.Element {
  const { id } = useParams();
  const { data: lesson, error, isLoading } = useQuery(['lesson', id], () => {
    if (!id) {
      return Promise.reject(new Error('Missing lesson id'));
    }
    return Api.getLesson(+id);
  }, { refetchOnWindowFocus: false });
  const [lessonOverride, setLessonOverride] = useState<Lesson|null>(null);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isNew = params.get('state') === EShowLessonState.NEW;
  const initialSaveMessage = isNew ? 'We hebben de nieuwe les opgeslagen' : undefined;

  let backLink;
  if (lesson) {
    backLink = EAdminRouterPath.LESSON_LIST.fullPath(
      { category: lesson.category, level: lesson.level, book: lesson.book || undefined }
    );
  }

  return <>
    <PageTitle backLink={backLink}>{buildLessonTitle(lesson?.category || 'Les', ' bewerken')}</PageTitle>
    <Status loading={isLoading} error={error} />
    { lesson && <EditLesson lesson={lessonOverride ?? lesson} onSubmit={setLessonOverride} initialSaveMessage={initialSaveMessage} /> }
  </>;
}
