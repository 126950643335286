import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { InstructionLabelsProvider } from '../common/instruction-labels-provider/instruction-labels.provider';
import { PupilLayout } from '../common/layouts/pupil.layout';
import { SchoolProvider } from '../common/school-provider/school.provider';
import { EMainRouterPath, EPupilRouterPath } from '../router-path';
import { PupilAuth } from './auth';
import { ListSchoolGroups } from './group/list';
import { ListGroupPupils } from './pupil/list';

export function PupilModule(): JSX.Element {
  return (
    <>
      <SchoolProvider unknownSchoolPath={EMainRouterPath.NOT_FOUND.path}>
        <InstructionLabelsProvider>
          <PupilLayout switchButtons={true}>
            <Routes>
              <Route path={EPupilRouterPath.HOME.path} element={<ListSchoolGroups />} />
              <Route path={EPupilRouterPath.AUTH.path} element={<PupilAuth />} />
              <Route path={EPupilRouterPath.GROUP_LIST.path} element={<ListSchoolGroups />} />
              <Route path={EPupilRouterPath.PUPIL_LIST.path} element={<ListGroupPupils />} />
            </Routes>
          </PupilLayout>
        </InstructionLabelsProvider>
      </SchoolProvider>
    </>
  );
}
