import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AuthService } from '../auth/auth.service';
import { EMainRouterPath } from '../../router-path';

export function AuthCallback(): JSX.Element|null {
  const navigate = useNavigate();
  const [error, setError] = useState<string|null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state'); // State contains the return path set by the auth service
    if (!code) {
      setError('Missing code parameter');
      return;
    }

    AuthService.saveTokensFromAuthCode(code)
      .then(() => {
        const returnPath = state && atob(state);
        navigate(returnPath || EMainRouterPath.AUTH.path);
      })
      .catch(err => {
        console.error('Auth failed:', err);
        setError('Authorization failed');
      });
  }, [navigate, searchParams]);

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }
  return null;
}
