import React from 'react';
import { DEFAULT_RECORDING_LANGUAGE, EInstructionLabel, Recording } from 'shared';
import { useInstructionLabel } from '../instruction-labels-provider/instruction-labels.context';
import { RecordingPlayer } from './recording-player.component';

interface IInstructionLabelProps {
  className?: string;
  instructionKey: EInstructionLabel;
  hideIfNotDefined?: boolean;
}

export function InstructionLabel({ className, instructionKey, hideIfNotDefined }: IInstructionLabelProps): JSX.Element {
  const instructionLabel = useInstructionLabel(instructionKey);
  const text = instructionLabel?.text || instructionKey;
  const recording: Recording|undefined = instructionLabel?.recordingFilename ? {
    [DEFAULT_RECORDING_LANGUAGE]: {
      filename: instructionLabel.recordingFilename,
      sourceFilename: instructionLabel.recordingFilename,
      name: instructionKey,
    }
  } : undefined;

  if (!recording && hideIfNotDefined) {
    return <></>;
  }

  return <RecordingPlayer className={className} recording={recording} language={DEFAULT_RECORDING_LANGUAGE}>{text}</RecordingPlayer>;
}
