import React, { PropsWithChildren, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { DEFAULT_RECORDING_LANGUAGE, Recording, TDifficultWord } from 'shared';
import { playAudio, stopAudio } from './audio.helper';
import htmlParser, { DOMNode, HTMLReactParserOptions, domToReact } from 'html-react-parser';
import { ElementType } from 'domelementtype';
import Modal from 'react-bootstrap/esm/Modal';
import { getStorageUrl } from './storage.helper';
import './text-display.component.scss';
import { resolveRecordingItem } from './recording-resolver';

type DifficultWordProps = {
  exampleUrl?: string;
} & PropsWithChildren;

export function DifficultWord({ children, exampleUrl }: DifficultWordProps): JSX.Element {
  const [showDifficultExample, setShowDifficultExample] = useState(false);

  useEffect(() => {
    if (!showDifficultExample) {
      return;
    }

    setTimeout(() => setShowDifficultExample(false), 3000);
  }, [showDifficultExample]);

  const handleShowDifficultExample = () => {
    setShowDifficultExample(true);
  };

  const handleHideDifficultExample = () => {
    setShowDifficultExample(false);
  };

  return <>
    <button className="difficult-word" onClick={handleShowDifficultExample} aria-label="Wat is dit?">{children}</button>
    <Modal show={showDifficultExample} className="difficult-word__modal" centered onHide={handleHideDifficultExample}>
      <Modal.Body><img src={exampleUrl} alt="" /></Modal.Body>
    </Modal>
  </>;
}

type TTextDisplayProps = {
  text: string;
  difficultWords: TDifficultWord[];
  recording?: Recording;
}

export interface ITextDisplayFunctions {
  play: (onFinish?: () => void) => void;
  stop: () => void;
  isPlaying: () => boolean;
}

export const TextDisplay = forwardRef<ITextDisplayFunctions, TTextDisplayProps>(({ text, difficultWords, recording }, ref): JSX.Element => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playingAudioId, setPlayingAudioId] = useState<number | null>(null);
  const recordingItem = resolveRecordingItem(recording, DEFAULT_RECORDING_LANGUAGE); // Only supports one language
  const recordingFilename = recordingItem?.filename;
  const difficultWordsToDisplay = [...difficultWords];

  useImperativeHandle(ref, () => ({
    play(callback) {
      // eslint-disable-next-line no-use-before-define
      handlePlay(callback);
    },
    stop() {
      // eslint-disable-next-line no-use-before-define
      handleStop();
    },
    isPlaying() {
      return isPlaying;
    }
  }));

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    return () => handleStop();
  }, [isPlaying]);


  const handleStop = () => {
    if (!isPlaying) {
      return;
    }

    setIsPlaying(false);

    if (playingAudioId !== null) {
      stopAudio(playingAudioId);
    }
    setPlayingAudioId(null);
  };

  const handlePlay = (onFinish?: () => void) => {
    if (!recordingFilename) {
      console.error('Tried to play recording, but no recording was defined');
      return;
    }

    if (isPlaying) {
      handleStop();
    }

    setIsPlaying(true);
    playAudio(recordingFilename, setPlayingAudioId)
      .catch(err => console.error('Could not play audio', recordingFilename, err))
      .finally(() => {
        if (onFinish) {
          onFinish();
        }
        setIsPlaying(false);
        setPlayingAudioId(null);
      });
  };

  const renderedText = useMemo(() => {
    let enrichedText = text;
    difficultWordsToDisplay.forEach(difficultWord => {
      const regex = new RegExp(difficultWord.word, 'i');
      const result = regex.exec(enrichedText);
      if (!result) {
        return;
      }

      const startChar = result.index;
      const endChar = startChar + result[0].length;

      enrichedText = enrichedText.slice(0, endChar) + '</difficult-word>' + enrichedText.slice(endChar); // eslint-disable-line prefer-template
      enrichedText = enrichedText.slice(0, startChar) + `<difficult-word data-img="${difficultWord.image?.mediumFilename || ''}">` + enrichedText.slice(startChar); // eslint-disable-line prefer-template
    });

    const difficultWordReplacer: HTMLReactParserOptions['replace'] = (domNode) => {
      if (domNode.type !== ElementType.Tag || domNode.name !== 'difficult-word') {
        return;
      }

      const image = domNode.attributes.find((value) => value.name === 'data-img')?.value;
      if (!image) {
        return;
      }

      // eslint-disable-next-line consistent-return
      return <DifficultWord exampleUrl={getStorageUrl(image)}>{domToReact(domNode.children as DOMNode[])}</DifficultWord>;
    };

    return <div className="text-display__content">{htmlParser(enrichedText, { replace: difficultWordReplacer })}</div>;
  }, [text, difficultWordsToDisplay]);

  return <div className="text-display">{renderedText}</div>;
});
