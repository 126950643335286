import React from 'react';
import Form from 'react-bootstrap/esm/Form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

export interface ICreateAdminFields {
  firstName: string;
  onChangeFirstName: (value: string) => void;
  lastName: string;
  onChangeLastName: (value: string) => void;
  email: string;
  onChangeEmail: (value: string) => void;
}

export function CreateAdminFields({ firstName, onChangeFirstName, lastName, onChangeLastName, email, onChangeEmail }: ICreateAdminFields): JSX.Element {
  const { t } = useTranslation();

  return <>
    <Row>
      <Form.Group as={Col} className="md-6">
        <Form.Label>{t('school:admin.user.first-name')}</Form.Label>
        <Form.Control
          type="text"
          onChange={e => onChangeFirstName(e.target.value)}
          value={firstName}
          placeholder={t('school:admin.user.first-name')} />
      </Form.Group>

      <Form.Group as={Col} className="md-6">
        <Form.Label>{t('school:admin.user.last-name')}</Form.Label>
        <Form.Control
          type="text"
          onChange={e => onChangeLastName(e.target.value)}
          value={lastName}
          placeholder={t('school:admin.user.last-name')} />
      </Form.Group>
    </Row>
    <Form.Group>
      <Form.Label>{t('school:admin.user.email')}</Form.Label>
      <Form.Control
        type="email"
        onChange={e => onChangeEmail(e.target.value)}
        value={email}
        placeholder={t('school:admin.user.email')} />
    </Form.Group>
  </>;
}
