/* eslint-disable no-case-declarations */
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { Api } from '../../common/api/api';
import { DateTime } from '../../common/utils/date-time.component';
import { LessonCategory } from '../../common/utils/lesson-category.component';
import { PageTitle } from '../../common/utils/page-title.component';
import { Status } from '../../common/utils/status.component';
import { EAdminRouterPath } from '../../router-path';
import { EIcon, Icon } from '../../common/utils/icon.component';
import { DynamicTable, DynamicTableColumn, ESortDirection } from '../../common/utils/dynamic-table.component';
import { useLessonParameters } from './use-lesson-parameters.hook';
import { buildLessonTitle, lessonTypeToString } from '../../common/utils/strings.helper';
import { Goal } from '../../common/utils/goal.component';
import { ELessonBook, ELessonCategory, ELessonLevel, LESSON_BOOKS, LESSON_VARIANTS } from 'shared';
import { t } from 'i18next';
import Form from 'react-bootstrap/esm/Form';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import { getBookImage } from './lesson-book-images/lesson-book-images';
import './list.scss';

import hiddenObjectsGameImage from './lesson-images/vocabulary.png';
import StrongReaderImage from './lesson-images/strong-reader.png';
import unknownImage from './lesson-images/unknown.png';

const lessonImages = new Map<`${ELessonLevel}_${ELessonCategory}`, string>([
  [`${ELessonLevel.LEVEL_3}_${ELessonCategory.DECODING}`, getBookImage(ELessonLevel.LEVEL_3, ELessonBook.DECODING_ONE)],
  [`${ELessonLevel.LEVEL_3}_${ELessonCategory.VOCABULARY}`, hiddenObjectsGameImage],
  [`${ELessonLevel.LEVEL_3}_${ELessonCategory.STRONG_READER}`, StrongReaderImage],

  [`${ELessonLevel.LEVEL_4}_${ELessonCategory.DECODING}`, getBookImage(ELessonLevel.LEVEL_4, ELessonBook.DECODING_ONE)],
  [`${ELessonLevel.LEVEL_4}_${ELessonCategory.READING_COMPREHENSION}`, getBookImage(ELessonLevel.LEVEL_4, ELessonBook.READING_COMPREHENSION_ONE)],
  [`${ELessonLevel.LEVEL_4}_${ELessonCategory.STRONG_READER}`, StrongReaderImage],

  [`${ELessonLevel.LEVEL_5}_${ELessonCategory.DECODING}`, getBookImage(ELessonLevel.LEVEL_5, ELessonBook.DECODING_ONE)],
  [`${ELessonLevel.LEVEL_5}_${ELessonCategory.READING_COMPREHENSION}`, getBookImage(ELessonLevel.LEVEL_5, ELessonBook.READING_COMPREHENSION_ONE)],
  [`${ELessonLevel.LEVEL_5}_${ELessonCategory.STRONG_READER}`, StrongReaderImage],

  [`${ELessonLevel.LEVEL_6}_${ELessonCategory.DECODING}`, getBookImage(ELessonLevel.LEVEL_6, ELessonBook.DECODING_ONE)],
  [`${ELessonLevel.LEVEL_6}_${ELessonCategory.READING_COMPREHENSION}`, getBookImage(ELessonLevel.LEVEL_6, ELessonBook.READING_COMPREHENSION_ONE)],
  [`${ELessonLevel.LEVEL_6}_${ELessonCategory.STRONG_READER}`, StrongReaderImage],

  [`${ELessonLevel.LEVEL_7}_${ELessonCategory.DECODING}`, getBookImage(ELessonLevel.LEVEL_7, ELessonBook.DECODING_ONE)],
  [`${ELessonLevel.LEVEL_7}_${ELessonCategory.READING_COMPREHENSION}`, getBookImage(ELessonLevel.LEVEL_7, ELessonBook.READING_COMPREHENSION_ONE)],
  [`${ELessonLevel.LEVEL_7}_${ELessonCategory.STRONG_READER}`, StrongReaderImage],

  [`${ELessonLevel.LEVEL_8}_${ELessonCategory.DECODING}`, getBookImage(ELessonLevel.LEVEL_8, ELessonBook.DECODING_ONE)],
  [`${ELessonLevel.LEVEL_8}_${ELessonCategory.READING_COMPREHENSION}`, getBookImage(ELessonLevel.LEVEL_8, ELessonBook.READING_COMPREHENSION_ONE)],
  [`${ELessonLevel.LEVEL_8}_${ELessonCategory.STRONG_READER}`, StrongReaderImage],
]);

function getLessonImage(level: ELessonLevel, category: ELessonCategory): string {
  return lessonImages.get(`${level}_${category}`) ?? unknownImage;
}

function LessonVariantSelector(): JSX.Element {
  const possibleVariants = LESSON_VARIANTS.filter(item => item.category !== ELessonCategory.BOOKSHELF);

  return <>
    <PageTitle>{t('category-selection:page-title')}</PageTitle>
    <ul className="lesson-variant-selector">
      { possibleVariants.map(({ category, level }) => <li key={`${category}_${level}`}>
        <Link to={EAdminRouterPath.LESSON_LIST.fullPath({ category, level })} className="lesson-variant-selector__item">
          <img className="lesson-variant-selector__image" src={getLessonImage(level, category)} alt=""/>
          <div className="lesson-variant-selector__info">
            <div className="lesson-variant-selector__level">{t(`lesson-level:${level}`)}</div>
            <div className="lesson-variant-selector__category"><LessonCategory value={category} level={level} upper /></div>
          </div>
        </Link>
      </li>)}
    </ul>
  </>;
}

interface ILessonTableData {
  id: number;
  number: number;
  name: string;
  type: JSX.Element|string;
  goal: JSX.Element;
  updatedAt: JSX.Element|string;
  published: string;
}

function LessonTable({ category, level, book }: { category: ELessonCategory, level: ELessonLevel, book?: ELessonBook }): JSX.Element|null {
  const navigate = useNavigate();
  const { data: lessons, error, isLoading } = useQuery(['lessons', category, level, book], () => Api.listLessons({ category, level, book }));
  const backLink = EAdminRouterPath.LESSON_LIST.fullPath(category === ELessonCategory.DECODING ? { category, level } : {});
  const [searchQuery, setSearchQuery] = React.useState<string>('');

  if (!lessons || isLoading || error) {
    return <>
      <PageTitle backLink={backLink} subTitle={<LessonCategory value={category} upper={true} level={level} />}>{t(`lesson-level:${ELessonLevel.LEVEL_3}`)}</PageTitle>
      <Status loading={isLoading} error={error} />
    </>;
  }

  const sort = (indexA: number, indexB: number, direction: ESortDirection, objectKey: keyof ILessonTableData): number => {
    const a = lessons[indexA];
    const b = lessons[indexB];
    if (!a || !b) {
      console.warn(`Sort in LessonTable error, sort item a: ${a ? 'defined' : 'undefined'} b: ${b ? 'defined' : 'undefined'}`);
      return 0;
    }

    const sortDesc = direction === ESortDirection.DESCENDING;
    switch (objectKey) {
      case 'updatedAt':
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return sortDesc ? dateB.valueOf() - dateA.valueOf() : dateA.valueOf() - dateB.valueOf();
      default:
        return 0;
    }
  };

  const columns: DynamicTableColumn<ILessonTableData>[] = [
    { title: 'Les', objectKey: 'number' },
    { title: 'Naam', objectKey: 'name' },
  ];

  if (category === ELessonCategory.DECODING) {
    columns.push(
      { title: 'Type les', objectKey: 'type' },
      { title: 'Doel van de les', objectKey: 'goal' },
    );
  }

  columns.push(
    { title: 'Laatst gewijzigd', objectKey: 'updatedAt', customSortFunction: sort },
    { title: 'Status', objectKey: 'published' },
  );

  const data: ILessonTableData[] = lessons.filter((lesson) => ((lesson.name || '').toLowerCase().includes(searchQuery.toLowerCase()))).map(({ id, number, name, meta, type, updatedAt, published }) => ({
    id,
    number,
    name: name || '',
    type: type ? lessonTypeToString(type, { upper: true }) : '',
    goal: <Goal meta={meta ?? null} />,
    updatedAt: <DateTime date={updatedAt} /> || '',
    published: published ? 'Gepubliceerd' : 'Concept',
  }));

  return <>
    <div className="lesson-table__header">
      <PageTitle backLink={backLink} subTitle={`${t(`lesson-category:${category}`)}${book ? ` - ${t(`lesson-book:${book}`)}` : ''} `}>{t(`lesson-level:${level}`)}</PageTitle>
      <div className="lesson-table__header-actions">
        <InputGroup className="input__search--user">
          <InputGroup.Text id="zoekIcoon">
            <Icon>{EIcon.SEARCH}</Icon>
          </InputGroup.Text>
          <Form.Control
            type="text"
            id="searchField"
            aria-describedby="zoekIcoon"
            placeholder={t('lessons:search')}
            autoComplete="off"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {
            searchQuery && (
              <InputGroup.Text id="clear-icon" onClick={() => setSearchQuery('')}>
                <Icon>{EIcon.X}</Icon>
              </InputGroup.Text>
            )
          }
        </InputGroup>
        <Button className="outer-shadow bigger-radius" onClick={() => navigate(EAdminRouterPath.LESSON_CREATE.fullPath({ category, level, book }))}><Icon>{EIcon.PLUS}</Icon>{buildLessonTitle('Nieuwe ', category, ' aanmaken')}</Button>
      </div>
    </div>
    <DynamicTable<ILessonTableData> columns={columns} data={data} sortByDefault={columns[0]} sortDirectionDefault={ESortDirection.ASCENDING} onClickRow={lesson => navigate(EAdminRouterPath.LESSON_SHOW.fullPath(lesson.id))} />
  </>;
}

export function BookSelection({ data: { category, level, books } }: { data: typeof LESSON_BOOKS[0] }): JSX.Element {
  return <>
    <PageTitle backLink={EAdminRouterPath.LESSON_LIST.fullPath()}>
      {t('book-selection:page-title')}
    </PageTitle>
    <ul className="lesson-variant-selector">
      { books.map(book => <li key={`${category}_${level}_${book}`}>
        <Link to={EAdminRouterPath.LESSON_LIST.fullPath({ category, level, book })} className="lesson-variant-selector__item">
          <img className="lesson-variant-selector__image" src={getBookImage(level, book)} alt=""/>
          <div className="lesson-variant-selector__info">
            <div className="lesson-variant-selector__level">{t(`lesson-book:${book}`)}</div>
          </div>
        </Link>
      </li>)}
    </ul>
  </>;
}

export function ListLessons(): JSX.Element {
  const { category, level, book } = useLessonParameters();
  const booksToSelect = LESSON_BOOKS.find((item) => item.level === level && item.category === category);

  if (category && level && !book && booksToSelect && booksToSelect.books) {
    return <BookSelection data={booksToSelect} />;
  }
  if (category && level) {
    return <LessonTable category={category} level={level} book={book}/>;
  }

  return <LessonVariantSelector />;
}
