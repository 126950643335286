import { useTranslation } from 'react-i18next';
import './select-school.scss';
import allLogo from '../layouts/all_logo_color.png';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { EIcon, Icon } from '../utils/icon.component';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getSchoolInStorage } from '../utils/storage.helper';
import { EMainRouterPath } from '../../router-path';


const SelectSchool = (): JSX.Element => {
  const { t } = useTranslation();
  const school = getSchoolInStorage();
  const [selectedSchool, setSelectedSchool] = useState<string>(school || '');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const noAutoRedirect = searchParams.get('noAutoRedirect') === 'true';

  useEffect(() => {
    if (noAutoRedirect) {
      return;
    }

    navigate(EMainRouterPath.PUPIL_SCHOOL_SHORT_LINK.fullPath(school));
  }, [noAutoRedirect]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate(EMainRouterPath.PUPIL_SCHOOL_SHORT_LINK.fullPath(selectedSchool));
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim().toLowerCase().replaceAll(/[^a-z0-9-]/g, '');
    setSelectedSchool(value);
  };

  return (
    <div className="school-selector__page">
      <img className="school-selector__logo" src={allLogo} alt="Actief Leren Lezen" />
      <div className="school-selector__wrapper">
        <h1 className="school-selector__title">{t('select-school:title')}</h1>
        <span className="school-selector__help-text">{t('select-school:help-text')}</span>
        <Form className="school-selector__form" onSubmit={handleSubmit}>
          <Form.Group className="school-selector__input-row">
            <InputGroup>
              <Form.Control name="school" type="text" placeholder={t('select-school:placeholder')} value={selectedSchool} onChange={handleInput}/>
            </InputGroup>
            <Button className="school-selector__button" variant="secondary" type="submit" disabled={!selectedSchool }><Icon>{EIcon.CHEVRON_RIGHT}</Icon></Button>
          </Form.Group>
        </Form>
        { school && <div className="school-selector__last-school">{t('select-school:last-visited-school')} <Link to={EMainRouterPath.SELECT_SCHOOL.fullPath()}>{school}</Link></div> }
      </div>
      <p className="school-selector__login-info">{t('common:teacher-or-schooladmin')} <Link to={EMainRouterPath.AUTH.path}>{t('common:sign-in')}</Link></p>
    </div>
  );
};

export default SelectSchool;
