import { Dispatch, SetStateAction } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { useTranslation } from 'react-i18next';
import { ELessonCategory, ELessonLevel, IGroup } from 'shared';
import { EIcon, Icon } from '../../common/utils/icon.component';
import { LessonCategory } from '../../common/utils/lesson-category.component';

const PupilProgressGroupDropdown = ({ groups, selectedGroup, setSelectedGroup }: {groups: IGroup[], selectedGroup: IGroup|undefined, setSelectedGroup: Dispatch<SetStateAction<IGroup|undefined>>}): JSX.Element => {
  const { t } = useTranslation();

  if (groups.length <= 1 && selectedGroup) {
    return <div className="pupil-progress__group-dropdown">{t('pupil-progress:progress-of')}: {selectedGroup?.name}</div>;
  }

  return (
    <Dropdown align="end">
      <Dropdown.Toggle variant="success" className="pupil-progress__group-dropdown">
        <>
          {Object.values(selectedGroup || {}).length ? `${t('pupil-progress:progress-of')}: ${selectedGroup?.name ?? ''}` : t('pupil-progress:select-a-group')} <Icon>{EIcon.CHEVRON_DOWN}</Icon>
        </>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {groups.map((group) => (
          <Dropdown.Item key={group.id} onClick={() => setSelectedGroup(group)} active={group === selectedGroup}>
            {group.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const PupilProgressLessonTypeDropdown = ({ level, selectedCategory, setSelectedCategory }: { level?: ELessonLevel, selectedCategory: ELessonCategory, setSelectedCategory: Dispatch<SetStateAction<ELessonCategory>>}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dropdown align="end">
      <Dropdown.Toggle variant="success" className="pupil-progress__group-dropdown">
        {selectedCategory && t('pupil-progress:lesson-type')} {selectedCategory ? <LessonCategory value={selectedCategory} level={level} upper /> : t('pupil-progress:select-a-lesson-type')} <Icon>{EIcon.CHEVRON_DOWN}</Icon>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.values(ELessonCategory).filter(item => item !== ELessonCategory.BOOKSHELF).map(category => (
          <Dropdown.Item key={category} onClick={() => setSelectedCategory(category)} active={category === selectedCategory}>
            <LessonCategory value={category} level={level} upper />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const PupilProgressSearchField = ({ searchQuery, setSearchQuery }: {searchQuery: string, setSearchQuery: Dispatch<SetStateAction<string>>}) => {
  const { t } = useTranslation();

  return (

    <InputGroup className="input__search--user">
      <InputGroup.Text id="zoekIcoon">
        <Icon>{EIcon.SEARCH}</Icon>
      </InputGroup.Text>
      <Form.Control
        type="text"
        id="searchField"
        aria-describedby="zoekIcoon"
        placeholder={t('school:search-user')}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </InputGroup>
  );
};

interface IPupilProgressFilters {
    selectedGroup: IGroup|undefined;
    selectedCategory: ELessonCategory;
    setSelectedCategory: Dispatch<SetStateAction<ELessonCategory>>;
    setSelectedGroup: Dispatch<SetStateAction<IGroup|undefined>>;
    groups: IGroup[] | undefined;
    searchQuery: string;
    setSearchQuery: Dispatch<SetStateAction<string>>;
}

export const PupilProgressFilters = ({ groups, selectedGroup, selectedCategory, setSelectedCategory, setSelectedGroup, searchQuery, setSearchQuery }: IPupilProgressFilters): JSX.Element => (
  <div className="pupil-progress__filters">
    <div className="pupil-progress__filters--dropdowns">
      <PupilProgressGroupDropdown selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} groups={groups || []}/>
      <span className="separator">|</span>
      <PupilProgressLessonTypeDropdown level={selectedGroup?.level} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>
    </div>
    <PupilProgressSearchField searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
  </div>
);
