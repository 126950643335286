import React, { PropsWithChildren, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ERecordingLanguage, Recording } from 'shared';
import { EIcon, Icon } from './icon.component';
import './recording-player.component.scss';
import { playAudio, stopAudio } from './audio.helper';
import { resolveRecordingItem } from './recording-resolver';

interface IRecordingPlayerProps {
  className?: string;
  recording?: Recording;
  language: ERecordingLanguage;
}

export function RecordingPlayer({ className, recording, language, children }: PropsWithChildren<IRecordingPlayerProps>): JSX.Element {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingAudioId, setPlayingAudioId] = useState<number|null>(null);

  const recordingItem = resolveRecordingItem(recording, language);
  const recordingFilename: string|undefined = recordingItem?.filename;

  useEffect(() => () => { // On unload
    if (playingAudioId === null) {
      return;
    }
    stopAudio(playingAudioId);
  }, [playingAudioId]);

  const handleClickPlay = () => {
    if (!recordingFilename) {
      console.error('Tried to play recording, but no recording was defined');
      return;
    }

    if (isPlaying) {
      return;
    }

    setIsPlaying(true);
    playAudio(recordingFilename, setPlayingAudioId)
      .catch(err => {
        console.error('Could not play audio', recordingFilename, err);
      })
      .finally(() => {
        setIsPlaying(false);
        setPlayingAudioId(null);
      });
  };

  return <button className="recording-player__play-button" title="Afspelen" onClick={handleClickPlay}><span className={classNames('recording-player', className, { 'recording-player--playing': isPlaying })}>
    <span className="recording-player__content">{children}</span>
    { recordingFilename &&
      <Icon className="recording-player__play-button-icon">{EIcon.SPEAKER}</Icon> }
  </span>
  </button>;
}
