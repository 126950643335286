import React, { CSSProperties, LegacyRef, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { EIcon, Icon } from './icon.component';
import './round-button.component.scss';

interface IRoundButtonProps {
  className?: string;
  variant?: 'danger'|'success'|'info'
  large?: boolean;
  showLabel?: boolean;
  disabled?: boolean;
  icon: EIcon;
  ref?: LegacyRef<HTMLButtonElement> | undefined;
  style?: CSSProperties;
  onClick: () => void;
}

export function RoundButton({ className, variant, icon, onClick, disabled, showLabel, large, children, ref, style }: PropsWithChildren<IRoundButtonProps>): JSX.Element {
  return <button
    onClick={onClick}
    ref={ref}
    style={style}
    className={classNames('round-button', {
      'round-button--show-label': showLabel,
      'round-button--large': large,
      [`round-button--${variant || ''}`]: variant,
    }, className)}
    type="button"
    disabled={disabled}
  >
    <span className="round-button__label">{children}</span>
    { icon && <Icon className="round-button__icon" >{icon}</Icon> }
  </button>;
}
