import { useMemo } from 'react';
import { ILesson } from 'shared';
import { EMPTY_LESSON_META, LessonMeta, LessonMetaManager } from '../../admin-module/lesson/lesson-meta-editor/lesson-meta';

export function useLessonMeta(lesson: ILesson): LessonMeta {
  return useMemo(() => {
    if (!lesson.meta) {
      return EMPTY_LESSON_META;
    }

    try {
      return LessonMetaManager.parseJsonToLessonMeta(lesson.meta);
    } catch (e) {
      console.error('Failed to read lesson meta', e);
      return EMPTY_LESSON_META;
    }
  }, [lesson]);
}
