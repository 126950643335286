import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Table from 'react-bootstrap/esm/Table';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IUserWithEmail } from 'shared';
import { Api } from '../../common/api/api';
import { User } from '../../common/api/types';
import { BoxDivider } from '../../common/utils/box.component';
import { Status } from '../../common/utils/status.component';
import { AddSchoolAdmin } from './add-school-admin.component';

interface IAdminTableProps {
  schoolId: number;
  admins?: IUserWithEmail[];
  onAddAdmin: (user: User) => void;
  onRemoveAdmin: (user: User) => void;
}

function AdminTable({ schoolId, admins, onAddAdmin, onRemoveAdmin }: IAdminTableProps): JSX.Element|null {
  if (!admins) {
    return null;
  }

  return <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>First name</th>
          <th>Last name</th>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        { admins.map(item => <tr key={item.user.id}>
          <td>{item.user.firstName}</td>
          <td>{item.user.lastName}</td>
          <td>{item.email}</td>
          <td><Button onClick={() => onRemoveAdmin(item.user)}>Remove</Button></td>
        </tr>)}
      </tbody>
    </Table>
    <BoxDivider title="Admin toevoegen" />
    <AddSchoolAdmin schoolId={schoolId} onAddAdmin={onAddAdmin} />
  </>;
}

export function SchoolAdminList({ schoolId }: { schoolId: number }): JSX.Element {
  const queryClient = useQueryClient();
  const { data: admins, error, isLoading } = useQuery(['schooladmins', schoolId], () => Api.listSchoolAdmins(schoolId));
  const mutation = useMutation((user: User) => Api.removeSchoolAdmin(schoolId, user.id), {
    onSuccess: () => queryClient.invalidateQueries('schooladmins'),
  });

  const handleAddAdmin = () => {
    queryClient.invalidateQueries('schooladmins')
      .catch(e => {
        console.error('Failed to invalidate query', e);
      });
  };

  const handleRemoveAdmin = (user: User) => {
    mutation.mutate(user);
  };

  return <>
    <Status loading={isLoading} error={error} />
    <AdminTable schoolId={schoolId} admins={admins} onAddAdmin={handleAddAdmin} onRemoveAdmin={handleRemoveAdmin} />
  </>;
}
