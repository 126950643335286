import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ELessonCategory, ILesson, EExerciseType, Exercise, HiddenObjectsGameExercise, WordTowerExercise, LessonDocumentManager, EPupilProgressStatus, VideoExercise, ELessonType, ReadingComprehensionTranscriptExercise, WordBlockExercise, ReadingComprehensionTextExercise } from 'shared';
import { Api } from '../../common/api/api';
import { CannotSetProgressBackwardsException } from '../../common/api/types';
import { Status } from '../../common/utils/status.component';
import { useLessonDocument } from '../../common/utils/use-lesson-document';
import { ELessonRouterPath } from '../../router-path';
import { DecodingOverview } from './decoding/decoding-overview';
import { ShowWordTower } from './decoding/show-word-tower';
import ReadingComprehensionOverview from './reading-comprehension/reading-comprehension-overview';
import ShowReadingComprehension from './reading-comprehension/show-reading-comprehension-transcript';
import { ShowVideo } from './video/show-video';
import { ShowHiddenObjectsGame } from './vocabulary/show-hidden-objects-game';
import { ShowWordBlock } from './decoding/show-word-block';
import { ShowReadingComprehensionText } from './reading-comprehension/show-reading-comprehension-text';

export function ShowExercises({ lesson }: { lesson: ILesson }): JSX.Element {
  const navigate = useNavigate();
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [showLessonOverview, setShowLessonOverview] = useState(LessonDocumentManager.shouldShowInitialLessonOverview(lesson));
  const [stepsMade, setStepsMade] = useState(0);
  const [error, setError] = useState<string|null>(null);
  const lessonDocument = useLessonDocument(lesson);
  const currentExercise: Exercise|null = lessonDocument.exercises[currentExerciseIndex] || null;
  const totalLessonStepsCount = LessonDocumentManager.getLessonStepsCount(lesson, lessonDocument);
  const progress = (100 / totalLessonStepsCount) * (stepsMade);

  useEffect(() => {
    setCurrentExerciseIndex(0);
    setShowLessonOverview(LessonDocumentManager.shouldShowInitialLessonOverview(lesson));
    setStepsMade(0);
  }, [lesson]);

  const handleStepForward = (): void => {
    setStepsMade(stepsMade + 1);
  };

  const handleFinishLesson = (result?: string): void => {
    if (typeof result !== 'string' && typeof result !== 'undefined') {
      // Story time!
      //   I once got a headache because I accidentally passed an event object into `result`.
      //   Causing the 'fast-safe-stringify' package to enter an infinite loop since it was
      //   stringifing an object with recursive references. I did not realize this until much
      //   later. May this error serve you well.
      throw new Error('[handleFinishExercise]: Result must be a string or undefined');
    }

    Api.updateOwnPupilProgress({ lessonId: lesson.id, status: EPupilProgressStatus.FINISHED, result: result ?? '{}' })
      .then(() => {
        navigate(ELessonRouterPath.LIST_LESSONS.fullPath(lesson.category, lesson.book));
      })
      .catch(err => {
        console.error('Failed update progress to finished', err);
        setError('Opslaan voortgang mislukt');
      });
  };

  const handleFinishExercise = (result?: string): void => {
    const nextExerciseIndex = currentExerciseIndex + 1;
    if (nextExerciseIndex >= lessonDocument.exercises.length) {
      // Is at last lessons
      handleFinishLesson(result);
    } else {
      setCurrentExerciseIndex(nextExerciseIndex);
      handleStepForward();
    }
  };

  const handleCloseLesson = (): void => {
    navigate(ELessonRouterPath.LIST_LESSONS.fullPath(lesson.category, lesson.book));
  };

  const handleStartLesson = (): void => {
    Api.updateOwnPupilProgress({ lessonId: lesson.id, status: EPupilProgressStatus.STARTED, result: '{}' })
      .catch(err => {
        if (err instanceof CannotSetProgressBackwardsException) {
          return; // The pupil already started this lesson. We cannot set it back to started.
        }
        console.error('Failed update progress to started', err);
      });
    setShowLessonOverview(false);
    handleStepForward();
  };

  if (error) {
    return <Status error={error} />;
  }

  if (!currentExercise) {
    return <Status error="Geen oefening beschikbaar voor les" />;
  }

  if (showLessonOverview) {
    switch (lesson.category) {
      case ELessonCategory.DECODING:
        if (lesson.type === ELessonType.READING_COMPREHENSION) return <ReadingComprehensionOverview lesson={lesson} progress={progress} onClose={handleCloseLesson} onStart={handleStartLesson} />;
        return <DecodingOverview lesson={lesson} progress={progress} onClose={handleCloseLesson} onStart={handleStartLesson} />;
      default:
        return <Status error="Overzicht weergeven voor les wordt niet ondersteund" />;
    }
  }

  switch (currentExercise.type) {
    case EExerciseType.HIDDEN_OBJECTS_GAME:
      return <ShowHiddenObjectsGame lesson={lesson} exercise={currentExercise as HiddenObjectsGameExercise} onFinishExercise={handleFinishExercise} onClose={handleCloseLesson} onStepForward={handleStepForward} />;
    case EExerciseType.WORD_TOWER:
      return <ShowWordTower lesson={lesson} exerciseIndex={currentExerciseIndex} exercise={currentExercise as WordTowerExercise} progress={progress} onFinishExercise={handleFinishExercise} onClose={handleCloseLesson} onStepForward={handleStepForward} />;
    case EExerciseType.WORD_BLOCK:
      return <ShowWordBlock lesson={lesson} exerciseIndex={currentExerciseIndex} exercise={currentExercise as WordBlockExercise} progress={progress} onFinishExercise={handleFinishExercise} onClose={handleCloseLesson} onStepForward={handleStepForward} />;
    case EExerciseType.VIDEO:
      return <ShowVideo lesson={lesson} progress={progress} exercise={currentExercise as VideoExercise} onClose={handleCloseLesson} onFinishExercise={handleFinishExercise} onStepForward={handleStepForward}/>;
    case EExerciseType.READING_COMPREHENSION_TRANSCRIPT:
      return <ShowReadingComprehension lesson={lesson} progress={progress} exercise={currentExercise as ReadingComprehensionTranscriptExercise} onClose={handleCloseLesson} onStepForward={handleStepForward} onFinishExercise={handleFinishExercise} />;
    case EExerciseType.READING_COMPREHENSION_TEXT:
      return <ShowReadingComprehensionText lesson={lesson} progress={progress} exercise={currentExercise as ReadingComprehensionTextExercise} onClose={handleCloseLesson} onStepForward={handleStepForward} onFinishExercise={handleFinishExercise} />;
    default:
      return <Status error="Onbekend type oefening" />;
  }
}

export function ShowLesson(): JSX.Element {
  const params = useParams();
  const lessonId = +(params.lessonId || '');
  const { data: pupilLesson, error, isLoading } = useQuery(
    ['lesson', lessonId], () => Api.getPupilLesson(lessonId),
    { refetchOnWindowFocus: false, refetchOnMount: false }); // Refetch causes un expect behavior since we do not expect the lesson to "change".

  if (isLoading || error) {
    return <Status error={error} loading={isLoading} />;
  }

  if (!pupilLesson) {
    return <Status error="Les kon niet geladen worden" />;
  }

  return <ShowExercises lesson={pupilLesson.lesson} />;
}
