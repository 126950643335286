"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionSchema = exports.difficultWordSchema = exports.imageSchema = exports.recordingSchema = exports.recordingItemSchema = exports.DEFAULT_RECORDING_LANGUAGE = exports.ERecordingLanguage = exports.REGEX_STORAGE_FILENAME = void 0;
var Joi = __importStar(require("joi"));
exports.REGEX_STORAGE_FILENAME = /^[0-9a-z_\-.]*$/;
var ERecordingLanguage;
(function (ERecordingLanguage) {
    ERecordingLanguage["NL_NL"] = "nl_nl";
    ERecordingLanguage["NL_BE"] = "nl_be";
})(ERecordingLanguage = exports.ERecordingLanguage || (exports.ERecordingLanguage = {}));
// If you change this for some reason you MUST migrate all existing exercises data since
// some (ReadingComprehensionText and ReadingComprehensionTranscript) use this value when
// creating recording.
exports.DEFAULT_RECORDING_LANGUAGE = ERecordingLanguage.NL_NL;
exports.recordingItemSchema = Joi.object({
    filename: Joi.string().regex(exports.REGEX_STORAGE_FILENAME).required(),
    sourceFilename: Joi.string().regex(exports.REGEX_STORAGE_FILENAME).required(),
    name: Joi.string().required(),
    transcript: Joi.object({
        transcript: Joi.string().required(),
        words: Joi.array().items(Joi.object({
            startTime: Joi.number().required(),
            endTime: Joi.number().required(),
            word: Joi.string().required(),
            isDifficult: Joi.bool().optional(),
            lineBreak: Joi.bool().optional(),
        })).required(),
    }).optional(),
});
exports.recordingSchema = Joi.object({
    nl_nl: exports.recordingItemSchema.optional().allow(null),
    nl_be: exports.recordingItemSchema.optional().allow(null),
});
exports.imageSchema = Joi.object({
    smallFilename: Joi.string().regex(exports.REGEX_STORAGE_FILENAME).required(),
    mediumFilename: Joi.string().regex(exports.REGEX_STORAGE_FILENAME).required(),
    largeFilename: Joi.string().regex(exports.REGEX_STORAGE_FILENAME).required(),
    xlargeFilename: Joi.string().regex(exports.REGEX_STORAGE_FILENAME).required(),
    sourceFilename: Joi.string().regex(exports.REGEX_STORAGE_FILENAME).required(),
});
exports.difficultWordSchema = Joi.object({
    word: Joi.string().allow(""),
    image: exports.imageSchema.optional(),
});
exports.questionSchema = Joi.object({
    question: Joi.object({
        text: Joi.string().allow(""),
        recording: exports.recordingSchema.optional().allow(null)
    }),
    answers: Joi.object({
        correct: Joi.string().allow(""),
        incorrect: Joi.array().items(Joi.string().allow(""))
    })
});
