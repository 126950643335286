import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import './modal-lesson.component.scss';
import { ModalArrow } from './modal-arrow.component';
import pfImage from '../lesson-modals-images/pf.png';

interface ModalLesson extends PropsWithChildren {
  className?: string;
  withArrows?: boolean;
}

export const ModalLesson: React.FC<ModalLesson> = ({ className, withArrows }) => (
  <div className={classNames('modal-lesson ', withArrows ? 'modal-lesson--with-arrows' : '', className)}>
    <div className="modal-lesson__text">
      <img className="modal-lesson__text-img" src={pfImage} alt="pf image" />
      <span>Les 8</span>
      <span className="modal-lesson__text-dot"></span>
      <span>het wrattenzwijn</span>
    </div>
    {withArrows && <>
      <ModalArrow className="modal-lesson__arrow-1" type="left-bottom">
       Het nummer van de les
      </ModalArrow>
      <ModalArrow className="modal-lesson__arrow-2" type="left">
       De naam van de les
      </ModalArrow>
    </>}
  </div>
);
