/* eslint-disable no-void */
import React, { useState } from 'react';
import { Api } from '../api/api';
import { Image } from 'shared';
import { FileInput } from './file-input.component';
import classNames from 'classnames';
import './image-input.component.scss';
import { getStorageUrl } from './storage.helper';

export const ALLOWED_IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
];

export const ALLOWED_IMAGE_FILE_EXTENSIONS = ['.jpeg', '.jpg', '.png', 'webp'];

interface IImageInputProps {
  className?: string;
  value?: Image|null;
  filename?: string;
  displayDelete?: boolean;
  onChange: (value: Image|null) => void;
  onBulkChange?: (set: { image: Image, source: File }[]) => void;
}

export function ImageInput({ className, filename, value, displayDelete, onChange, onBulkChange }: IImageInputProps): JSX.Element {
  const [isProcessingImage, setIsProcessingImage] = useState<boolean>(false);

  const uploadImage = async({ filename, file }: { filename: string, file: File }): Promise<{ image: Image, source: File }> => {
    const imageSet = await Api.uploadImage(file, filename);

    return {
      image: { ...imageSet },
      source: file,
    };
  };

  const processImage = (file: File) => uploadImage({
    filename: file.name.substring(0, file.name.lastIndexOf('.')) || file.name,
    file,
  });

  const handleFileChange = (file: File|null) => {
    if (!file) {
      onChange(null);
      return;
    }

    setIsProcessingImage(true);
    processImage(file)
      .then(({ image }) => onChange(image))
      .catch(err => {
        console.error('Failed to upload image', err);
      })
      .finally(() => {
        setIsProcessingImage(false);
      });
  };

  const handleBulkUpload = (files: File[]) => {
    setIsProcessingImage(true);
    Promise.all(files.map(file => processImage(file)))
      .then(onBulkChange)
      .catch(err => {
        console.error('Failed to bulk upload images', err);
      })
      .finally(() => {
        setIsProcessingImage(false);
      });
  };

  return <FileInput
    className={classNames('image-input', className)}
    filename={filename}
    processing={isProcessingImage}
    allowedMimeTypes={ALLOWED_IMAGE_MIME_TYPES}
    allowedFileExtension={ALLOWED_IMAGE_FILE_EXTENSIONS}
    displayDelete={displayDelete ?? !!value}
    onChange={handleFileChange}
    onBulkUpload={onBulkChange ? handleBulkUpload : undefined}
  >
    { (!filename && value) && <img className="image-input__preview" src={getStorageUrl(value.largeFilename)} alt=""/> }
  </FileInput>;
}
