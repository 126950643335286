import { ELessonCategory, ELessonLevel } from 'shared';
import React from 'react';

function convert(value: ELessonCategory, level?: ELessonLevel): string {
  const decodingName = level === ELessonLevel.LEVEL_3 ? 'lesboek' : 'technisch lezen';
  const strongReaderName = level === ELessonLevel.LEVEL_3 ? 'verrijking' : 'extra';

  switch (value) {
    case ELessonCategory.DECODING: return decodingName;
    case ELessonCategory.READING_COMPREHENSION: return 'begrijpend lezen';
    case ELessonCategory.VOCABULARY: return 'woordenschat';
    case ELessonCategory.STRONG_READER: return strongReaderName;
    case ELessonCategory.BOOKSHELF: return 'boekenplank';
    default: return value;
  }
}

export function LessonCategory({ value, upper, level }: { value: ELessonCategory, upper?: boolean, level?: ELessonLevel }): JSX.Element {
  if (upper) {
    const converted = convert(value, level);
    return <>{converted.charAt(0).toUpperCase() + converted.slice(1)}</>;
  }
  return <>{convert(value, level)}</>;
}
