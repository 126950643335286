import React, { FormEvent, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Alert from 'react-bootstrap/esm/Alert';
import Form from 'react-bootstrap/esm/Form';
import { Api } from '../../common/api/api';
import { ApiException, User } from '../../common/api/types';
import { useTranslation } from 'react-i18next';
import { CreateAdminFields } from './create-admin-fields.component';

export interface AddSchoolAdminProps {
  schoolId: number;
  onAddAdmin: (user: User) => void;
}

export function AddSchoolAdmin({ schoolId, onAddAdmin }: AddSchoolAdminProps): JSX.Element {
  const [error, setError] = useState<string|null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const { t } = useTranslation();

  const handleError = (err: any, logMessage: string) => {
    if (err instanceof ApiException) {
      setError(err.message);
      return;
    }

    console.error(logMessage, err);
    setError('An unknown error occurred');
  };

  const createAdmin = async(): Promise<User> => Api.addSchoolAdmin(schoolId, { firstName: firstName.trim(), lastName: lastName.trim(), email: email.trim() });

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();

    setSubmitting(true);
    createAdmin()
      .then(user => {
        onAddAdmin(user);
        setFirstName('');
        setLastName('');
        setEmail('');
      })
      .catch(e => handleError(e, 'Failed to create admin'))
      .finally(() => setSubmitting(false));
  };

  return <>
    <Form onSubmit={handleSubmit}>
      <CreateAdminFields
        firstName={firstName}
        onChangeFirstName={setFirstName}
        lastName={lastName}
        onChangeLastName={setLastName}
        email={email}
        onChangeEmail={setEmail}
      />

      { error && <Alert variant="danger">{error}</Alert>}
      <Button variant="primary" type="submit" disabled={submitting}>{t('common:add')}</Button>
    </Form>
  </>;
}
