import { createContext, useContext } from 'react';
import { ISchool, IUser, IUserDetails } from 'shared';

export interface ISessionContext {
  isAuthorized: boolean;
  isLoading: boolean;
  user?: IUser;
  isAdmin: boolean;
  userOfSchools: ISchool[];
  adminOfSchools: IUserDetails['adminOfSchools'];
  teacherOnSchools: IUserDetails['teacherOnSchools'];
  teacherOfGroups: IUserDetails['teacherOfGroups'];
  invalidateUserInfo: () => void;
}

export const getDefaultUserContext = (defaults: { isAuthorized: boolean, isLoading: boolean, invalidateUserInfo: () => void }): ISessionContext => ({
  ...defaults,
  isAdmin: false,
  userOfSchools: [],
  adminOfSchools: [],
  teacherOnSchools: [],
  teacherOfGroups: [],
});

export const userDetailsToContext = (userDetails: IUserDetails): Omit<ISessionContext, 'invalidateUserInfo'> => {
  const { user, adminOfSchools, teacherOnSchools, teacherOfGroups } = userDetails;
  const userOfSchools = [
    ...adminOfSchools,
    ...teacherOnSchools.filter(tSchool => adminOfSchools.some(aSchool => aSchool.id === tSchool.id) === false),
  ].sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
  const isAdmin = user.capabilities.length > 0;

  return {
    isAuthorized: true,
    isLoading: false,
    user,
    isAdmin,
    userOfSchools,
    adminOfSchools,
    teacherOnSchools,
    teacherOfGroups,
  };
};

export const SessionContext = createContext<ISessionContext>(getDefaultUserContext({
  isAuthorized: false,
  isLoading: false,
  invalidateUserInfo: () => console.warn('Called invalidateUserInfo when context was not initialized'),
}));

export function useSession(): ISessionContext {
  return useContext(SessionContext);
}
