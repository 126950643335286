
import React, { useState, useCallback, useEffect, KeyboardEvent } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Form from 'react-bootstrap/esm/Form';
import { Box, BoxDivider } from '../../../../common/utils/box.component';
import { WordTowerExercise, Recording, WordPart, WordTowerExerciseData, WordBlockExercise, WordBlockExerciseData } from 'shared';
import { IExerciseEditorProps } from './exercise-editor.component';
import Col from 'react-bootstrap/esm/Col';
import { AudioInput } from '../../../../common/utils/audio-input.component';
import { DangerButton } from '../../../../common/utils/danger-button.component';
import { EIcon } from '../../../../common/utils/icon.component';
import { ImageInput } from '../../../../common/utils/image-input.component';
import { capitalizeFirstLetter } from '../../../../common/utils/strings.helper';

interface WordPartsInputProps {
  allowUpperCase: boolean;
  wordParts: WordPart[];
  onChange: (parts: WordPart[]) => void;
}

interface WordPartsInput {
  wordParts: string;
  recording: Recording|null;
}

const WORD_PARTS_INPUT_CLASS = 'word-tower-editor__word-parts-input';

function WordPartsInput({ allowUpperCase, wordParts, onChange }: WordPartsInputProps): JSX.Element {
  const [inputs, setInputs] = useState<WordPartsInput[]>(wordParts.map(wordPart => ({
    wordParts: wordPart.letterBlocks.join(' '),
    recording: wordPart.recording || null,
  })));

  const handleInputWordPart = useCallback((index: number, value: string): void => {
    if (!inputs[index]) {
      inputs[index] = {
        wordParts: '',
        recording: null,
      };
    }

    inputs[index].wordParts = value.trimStart();
    if (!allowUpperCase) {
      inputs[index].wordParts.toLowerCase();
    }
    inputs[index].wordParts.replace(/ +/g, ' ');
    setInputs([...inputs]);
  }, [inputs]);

  const handleRecordingWordPart = useCallback((index: number, value: Recording|null): void => {
    if (!inputs[index]) {
      inputs[index] = {
        wordParts: '',
        recording: null,
      };
    }

    inputs[index].recording = value;
    setInputs([...inputs]);
  }, [inputs]);

  const filterOutEmptyRows = (inputs: WordPartsInput[]): WordPartsInput[] => inputs.filter(part => part.wordParts.trim() || part.recording);

  const handleBlur = useCallback(() => {
    const filteredInputs = filterOutEmptyRows(inputs);
    setInputs(filteredInputs);
  }, [inputs]);

  const getLetterBlocks = (wordParts: string): string[] => {
    // Check if wordParts is '' cause ''.split(' ') will result in [''] which is not allowed nor wanted.
    const trimmed = wordParts.trim();
    return trimmed.length > 0 ? trimmed.split(' ') : [];
  };

  useEffect(() => {
    const filteredInputs = filterOutEmptyRows(inputs);

    const newWordParts: WordPart[] = filteredInputs.map(part => ({
      letterBlocks: getLetterBlocks(part.wordParts),
      recording: part.recording || undefined,
    }));
    onChange(newWordParts);
  }, [inputs]);

  const handleKeyDownOnWordInput = (event: KeyboardEvent): void => {
    if (event.key !== 'Enter') {
      return;
    }

    const focusedElement = document.activeElement;
    if (!focusedElement || !focusedElement.classList.contains(WORD_PARTS_INPUT_CLASS)) {
      return;
    }

    const parentRow = focusedElement.closest<HTMLElement>('.row');
    if (!parentRow) {
      return;
    }

    const getNeighboringElement = (element: HTMLElement) => ((event.shiftKey ? element.previousElementSibling : element.nextElementSibling) as HTMLElement | null);

    let neighboringElement = getNeighboringElement(parentRow);
    while (neighboringElement) {
      const nextInput = neighboringElement.querySelector<HTMLElement>(`.${WORD_PARTS_INPUT_CLASS}`);
      if (nextInput) {
        nextInput.focus();
        break;
      }

      neighboringElement = getNeighboringElement(neighboringElement);
    }
  };

  const renderRow = (index: number, part: WordPartsInput) => <Row key={index}>
    <Form.Group as={Col}>
      <Form.Label>Woorddeel {index + 1}</Form.Label>
      <Form.Control
        className={WORD_PARTS_INPUT_CLASS}
        type="text"
        required
        value={part.wordParts}
        onBlur={handleBlur}
        onChange={e => handleInputWordPart(index, e.target.value)}
        onKeyDown={handleKeyDownOnWordInput} />
    </Form.Group>

    <Form.Group as={Col}>
      <Form.Label>Audio opname van woorddeel {index + 1}</Form.Label>
      <AudioInput value={part.recording} onChange={recording => handleRecordingWordPart(index, recording)} />
    </Form.Group>
  </Row>;

  return <div className="word-tower-editor__word-parts-input-container">
    { [...inputs, { wordParts: '', recording: null }].map((part, index) => renderRow(index, part)) }
  </div>;
}

export function GenericWordTowerEditor({ exerciseName, allowUpperCase, value, titleElements, onChange, onDelete }: IExerciseEditorProps<WordTowerExercise|WordBlockExercise> & { exerciseName: string, allowUpperCase: boolean, titleElements?: JSX.Element }): JSX.Element {
  const data: WordTowerExerciseData|WordBlockExerciseData = value.data ?? {};
  const [pre, setPre] = useState(data.pre ?? '');
  const [word, setWord] = useState(data.word ?? '');
  const [recording, setRecording] = useState(data.recording ?? null);
  const [fullRecording, setFullRecording] = useState(data.fullRecording ?? null);
  const [image, setImage] = useState(data.image ?? null);
  const [repetitions] = useState(data.repetitions ?? 1); // setRepetitions
  const [wordParts, setWordParts] = useState(data.wordParts ?? []);

  const handleInputPre = (value: string) => {
    if (allowUpperCase) {
      setPre(value.trimStart());
    } else {
      setPre(value.toLowerCase().trimStart());
    }
  };
  const handleInputWord = (value: string) => {
    if (allowUpperCase) {
      setWord(value.trimStart());
    } else {
      setWord(value.toLowerCase().trimStart());
    }
  };
  const handleChangeWordParts = (value: WordPart[]) => setWordParts(value);
  const handleChangeRecording = (value: Recording|null) => setRecording(value ?? null);
  const handleChangeFullRecording = (value: Recording|null) => setFullRecording(value ?? null);

  const nameLower = exerciseName.toLowerCase();
  const nameUpper = capitalizeFirstLetter(nameLower);

  useEffect(() => {
    onChange({ ...value, data: {
      image: image || undefined,
      pre: pre.trim() || undefined,
      word: word.trim() || undefined,
      wordParts,
      recording: recording || undefined,
      fullRecording: fullRecording || undefined,
    } });
  }, [pre, word, recording, fullRecording, image, repetitions, wordParts]);

  const handleDelete = (): void => {
    onDelete({ ...value });
  };

  const renderBoxHeader = (): JSX.Element => <>
    <DangerButton
      icon={EIcon.MINUS_CIRCLE}
      onClick={handleDelete}
      modalText={`Weet je het zeker dat je het ${nameLower} wilt verwijderen?`}
      modalCancelLabel="Behouden"
      modalConfirmLabel="Verwijderen"
    >Verwijder {nameLower}</DangerButton>
  </>;

  return <Box className="word-tower-editor" title={nameUpper} afterTitle={titleElements} headerContent={renderBoxHeader()} >
    <Row>
      <Form.Group as={Col}>
        <Form.Label>Woord</Form.Label>
        <Form.Control
          type="text"
          required
          value={word}
          onChange={e => handleInputWord(e.target.value)} />
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label>Audio opname van woord</Form.Label>
        <AudioInput value={fullRecording} onChange={handleChangeFullRecording} />
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Col}>
        <Form.Label>Eindwoord</Form.Label>
        <Form.Control
          type="text"
          required
          value={pre}
          onChange={e => handleInputPre(e.target.value)} />
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label>Audio opname van eindwoord</Form.Label>
        <AudioInput value={recording} onChange={handleChangeRecording} />
      </Form.Group>
    </Row>

    <Row>
      <Form.Group as={Col}>
        <Form.Label>Afbeelding van woord</Form.Label>
        <ImageInput value={image} onChange={setImage} />
      </Form.Group>

      <Col></Col>
    </Row>

    <BoxDivider title="Woorddelen" />
    <WordPartsInput allowUpperCase={allowUpperCase} wordParts={wordParts} onChange={handleChangeWordParts} />
  </Box>;
}

export function WordTowerEditor(props: IExerciseEditorProps<WordTowerExercise>): JSX.Element {
  return <GenericWordTowerEditor exerciseName="Woordtorentje" allowUpperCase={false} { ...props } />;
}
