import React, { FormEvent, useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import { Api } from '../../common/api/api';
import { Lesson } from '../../common/api/types';
import { isEnumValue, parseNumber, parseText } from '../../common/utils/input.helper';
import { useMutation, useQueryClient } from 'react-query';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import './edit-lesson.component.scss';
import { Status } from '../../common/utils/status.component';
import { DateTime } from '../../common/utils/date-time.component';
import { Box, BoxDivider } from '../../common/utils/box.component';
import { buildLessonString, lessonTypeToString } from '../../common/utils/strings.helper';
import { LessonDocumentEditor } from './lesson-document-editor/lesson-document-editor.component';
import { Prompt } from '../../common/utils/prompt.component';
import { DangerButton } from '../../common/utils/danger-button.component';
import { EIcon } from '../../common/utils/icon.component';
import { EAdminRouterPath } from '../../router-path';
import { useNavigate, useParams } from 'react-router-dom';
import { EMPTY_LESSON_META_STRING } from './lesson-meta-editor/lesson-meta';
import { LessonMetaEditor } from './lesson-meta-editor/lesson-meta-editor.component';
import { ELessonBook, ELessonCategory, ELessonLevel, ELessonType, LESSON_BOOKS, LESSON_TYPES_PER_CATEGORY, LessonDocumentManager } from 'shared';
import { useTranslation } from 'react-i18next';
import { useLessonParameters } from './use-lesson-parameters.hook';
import { EditorTitle } from '../../common/utils/editor-title';
import { ModalInfo } from './lesson-modals/modal-info.component';

export interface ICreateLesson {
  category: ELessonCategory;
  level: ELessonLevel;
}

interface IEditLessonProps {
  lesson?: Lesson;
  createLesson?: ICreateLesson;
  initialSaveMessage?: string;
  onSubmit: (lesson: Lesson) => void;
}

function getLessonTypes(category: ELessonCategory): ELessonType[] {
  return LESSON_TYPES_PER_CATEGORY.get(category) ?? [];
}

function getDefaultLessonType(category: ELessonCategory): ELessonType|null {
  return getLessonTypes(category)[0] ?? null;
}

function isAllowedLessonType(category: ELessonCategory, type: ELessonType|null): boolean {
  const lessonTypes = getLessonTypes(category);
  if (type === null) {
    return lessonTypes.length === 0;
  }
  return getLessonTypes(category).includes(type);
}

export function EditLesson({ lesson, createLesson, initialSaveMessage, onSubmit }: IEditLessonProps): JSX.Element {
  const navigate = useNavigate();
  const [error, setError] = useState<string|null>(null);
  const lessonParameters = useLessonParameters();
  const { category, level } = lesson ?? {
    category: lessonParameters.category ?? ELessonCategory.DECODING,
    level: lessonParameters.level ?? ELessonLevel.LEVEL_3,
    ...createLesson
  };
  const lessonBooks = LESSON_BOOKS.find(item => item.category === category && item.level === level)?.books || [];
  const hasBooks = lessonBooks.length !== 0;
  const defaultBook = lessonParameters.book || (lessonBooks.length >= 1 ? lessonBooks[0] : ELessonBook.DECODING_ONE);

  // Lesson properties, filled by use effect
  const [number, setNumber] = useState<number|null>(null);
  const [name, setName] = useState<string|null>(null);
  const [type, setType] = useState<ELessonType|null>(getDefaultLessonType(category));
  const [published, setPublished] = useState<boolean>(false);
  const [document, setDocument] = useState<string>(LessonDocumentManager.getEmptyLessonDocumentString(category));
  const [meta, setMeta] = useState<string>(EMPTY_LESSON_META_STRING);
  const [book, setBook] = useState<ELessonBook | null>(null);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean|null>(null);
  const [suppressUnsavedChangesPrompt, setSuppressUnsavedChangesPrompt] = useState(false);
  const [saveMessage, setSaveMessage] = useState<string|null>(initialSaveMessage || null);
  const { t } = useTranslation();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const mutation = useMutation((lessonData: Omit<Lesson, 'id' | 'createdAt' | 'updatedAt'>) => {
    if (lesson) { // If updating lesson
      return Api.updateLesson(lesson.id, lessonData);
    }
    return Api.createLesson(lessonData);
  }, { onSuccess: async() => {
    setSaveMessage('We hebben de wijzigingen opgeslagen');
    setHasUnsavedChanges(false);
    await queryClient.invalidateQueries(['lesson', id]);
  } });
  const availableLessonTypes = getLessonTypes(category);

  useEffect(() => {
    setNumber(lesson?.number ?? null);
    setName(lesson?.name || null);
    setType(lesson?.type ?? getDefaultLessonType(category));
    setPublished(lesson?.published ?? false);
    setDocument(lesson?.document ?? LessonDocumentManager.getEmptyLessonDocumentString(category));
    setMeta(lesson?.meta ?? EMPTY_LESSON_META_STRING);
    setBook(lesson?.book ?? null);
  }, [lesson]);

  useEffect(() => {
    // When the mutation is in a success state mutation.data will contain the updated lesson
    if (!mutation.data) {
      return;
    }
    onSubmit(mutation.data);
  }, [mutation.data]);

  useEffect(() => {
    if (!saveMessage) {
      return;
    }

    const timeout = setTimeout(() => {
      setSaveMessage(null);
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
    };
  }, [saveMessage]);

  useEffect(() => {
    const hasChanges =
      !lesson ||
      lesson.number !== number ||
      lesson.name !== name ||
      lesson.type !== type ||
      lesson.published !== published ||
      lesson.document !== document ||
      lesson.meta !== meta ||
      lesson.book !== book;

    if (hasChanges) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }

    // Force book when no book is set when required
    if (!book && hasBooks) {
      setBook(lesson?.book || defaultBook);
    }
  }, [lesson, number, name, type, published, document, meta, book]);

  useEffect(() => {
    // When the category changes the type can be left in a value that is not allowed.
    // Here we fix the type on category changes.
    if (!isAllowedLessonType(category, type)) {
      setType(getDefaultLessonType(category));
    }
  }, [category]);

  const handleDeleteLesson = () => {
    if (!lesson) {
      return;
    }

    setSuppressUnsavedChangesPrompt(true); // To prevent 'Are you sure' dialog from popping up
    Api.deleteLesson(lesson.id)
      .then(() => {
        navigate(EAdminRouterPath.LESSON_LIST.fullPath({ category: lesson.category, level: lesson.level }));
      })
      .catch(err => {
        console.error('Failed to delete lesson', err);
        setError('Verwijderen les mislukt');
        setSuppressUnsavedChangesPrompt(false); // We did not delete, restore the state
      });
  };

  const handleInputNumber = (value: string) => {
    setNumber(parseNumber(value));
  };

  const handleChangeBook = (value: string) => {
    if (lessonBooks.includes(value as ELessonBook)) {
      setBook(value as ELessonBook);
    } else if (value === null) {
      setBook(null);
    } else {
      console.error('Received unknown value that is not part of ELessonBook or valid for lesson:', value);
      setBook(null);
    }
  };

  const handleInputName = (value: string) => {
    setName(parseText(value) || null);
  };

  const handleChangeType = (value: string) => {
    if (isEnumValue(ELessonType, value)) {
      setType(value as ELessonType);
    } else if (value === null) {
      setType(null);
    } else {
      console.error('Received unknown value that is not part of ELessonType:', value);
      setType(getDefaultLessonType(category));
    }
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();

    if (!number) {
      setError('Please set an number');
      return;
    }


    mutation.mutate({ number, name, category, type, level, published, document, meta, book });
  };

  return <div className="edit-lesson">
    <Prompt
      when={suppressUnsavedChangesPrompt ? false : hasUnsavedChanges}
      message="Er zijn niet opgeslagen aanpassingen, weet je zeker dat je de pagina wilt verlaten?"
    />
    <div className="edit-lesson__container">
      <Form onSubmit={handleSubmit} className="edit-lesson__form">
        <div className="edit-lesson__basic-info">
          <EditorTitle>Kop van de les <ModalInfo/></EditorTitle>
          <Row>
            {hasBooks && <Form.Group as={Col}>
              <Form.Label>
                {buildLessonString('Bij welk boek hoort de ', category, '?')}
              </Form.Label>
              <Form.Select onChange={e => handleChangeBook(e.target.value)} value={book || undefined}>
                {lessonBooks.map((book) => (
                  <option key={book} value={book}>
                    {t(`lesson-book:${book}`)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>}
            <Form.Group as={Col}>
              <Form.Label>{buildLessonString('Wat is het nummer van de ', category, '?')}</Form.Label>
              <Form.Control
                type="text"
                required
                pattern="[0-9]*"
                value={number || ''}
                onChange={e => handleInputNumber(e.target.value)} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col}>
              <Form.Label>{buildLessonString('Wat is de naam van de ', category, '?')}</Form.Label>
              <Form.Control
                type="text"
                value={name || ''}
                maxLength={255}
                onChange={e => handleInputName(e.target.value)} />
            </Form.Group>
            { availableLessonTypes.length > 0 && <Form.Group as={Col}>
              <Form.Label>{buildLessonString('Wat is het type van de ', category, '?')}</Form.Label>
              <Form.Select value={type || -1} onChange={e => handleChangeType(e.target.value)}>
                { getLessonTypes(category).map((value, index) => <option key={index} value={value}>
                  { lessonTypeToString(value, { upper: true }) }
                </option>) }
              </Form.Select>
            </Form.Group>}
          </Row>
        </div>

        <div className="edit-lesson__submit-box-container">
          <Box className="edit-lesson__submit-box" title="Publiceren">
            <Status error={error || mutation.error} success={saveMessage} />

            <Form.Group>
              { lesson && <Form.Label>Status: {lesson.published ? 'Gepubliceerd' : 'Concept' }</Form.Label> }
              { !lesson && <Form.Label>Status:</Form.Label> }
              <Form.Select className="edit-lesson__select-published-state" value={published ? '1' : '0'} onChange={e => setPublished(e.target.value === '1')}>
                <option value={'0'}>Concept</option>
                <option value={'1'}>Gepubliceerd</option>
              </Form.Select>
            </Form.Group>

            <Form.Group>
              <Button className="edit-lesson__save-button" variant="primary" type="submit" disabled={mutation.isLoading || !hasUnsavedChanges}>Opslaan</Button>
            </Form.Group>

            { lesson && <div className="edit-lesson__last-edited">
              <span className="edit-lesson__last-edited-label">Laatst gewijzigd op:</span>
              <DateTime className="edit-lesson__last-edited-time" date={lesson.updatedAt} />
            </div> }

            { lesson && <>
              <BoxDivider />
              <DangerButton
                className="edit-lesson__delete-lesson-button"
                icon={EIcon.MINUS_CIRCLE}
                onClick={handleDeleteLesson}
                modalText="Weet je zeker dat je de les wilt verwijderen? Dit kan niet ongedaan gemaakt worden."
                modalCancelLabel="Behouden"
                modalConfirmLabel="Verwijderen"
              >Verwijder les</DangerButton>
            </> }
          </Box>
        </div>
      </Form>
      <LessonMetaEditor className="edit-lesson__edit-meta" lessonType={type} category={category} value={meta} onChange={setMeta} />
      <LessonDocumentEditor className="edit-lesson__edit-document" category={category} type={type} value={document} onChange={setDocument} />
    </div>
  </div>;
}
