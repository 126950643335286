/* eslint-disable newline-per-chained-call */
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Recording, TDifficultWord } from 'shared';
import { AudioInput } from '../../../../../common/utils/audio-input.component';
import { EditorEvents, EditorProvider, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import './rich-text-editor.scss';

const extensions = [
  StarterKit.configure({
    bold: false,
    code: false,
    italic: false,
    strike: false,
    horizontalRule: false,
    hardBreak: false,
    codeBlock: false,
  })
];

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <ButtonGroup aria-label="Toolbar with button groups">
      <Button size="sm"
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
      >Undo</Button>
      <Button size="sm"
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
      >Redo</Button>
      <Button size="sm"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >H1</Button>
      <Button size="sm"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >H2</Button>
      <Button size="sm"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >H3</Button>
      <Button size="sm"
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
      >H4</Button>
      <Button size="sm"
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
      >H5</Button>
      <Button size="sm"
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
      >H6</Button>
      <Button size="sm"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >Bullet list</Button>
      <Button size="sm"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >Ordered list</Button>
      <Button size="sm"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >Blockquote</Button>
    </ButtonGroup>
  );
};

interface IReadingComprehensionRichTextEditorProps {
  text: string;
  onChangeText: (value: string) => void;
  difficultWords?: TDifficultWord[] | undefined;
  recording: Recording | null;
  onRecordingChanged: (recording: Recording | null) => void;
}

export const ReadingComprehensionRichTextEditor = ({
  text,
  onChangeText,
  recording,
  onRecordingChanged,
}: IReadingComprehensionRichTextEditorProps): JSX.Element => {
  const { t } = useTranslation();

  const handleChange = ({ editor }: EditorEvents['update']) => {
    const text = editor.getHTML();
    onChangeText(text.normalize()); // Normalize to get rid of accent characters that cannot be saved in the database
  };

  return (
    <>
      <Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>{t('reading-comprehension:admin.audio-input-label')}</Form.Label>
          <AudioInput value={recording} onChange={(recording) => onRecordingChanged(recording)} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={12}>
          <Form.Label>{t('reading-comprehension:admin.text-label')}</Form.Label>
          <div className="rich-text-editor">
            <EditorProvider slotBefore={<MenuBar />} extensions={extensions} content={text} onUpdate={handleChange}> </EditorProvider>
          </div>
        </Form.Group>
      </Row>
    </>
  );
};
