import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useMutation, useQuery } from 'react-query';
import { EInstructionLabel, IInstructionLabel, RecordingItem } from 'shared';
import { Api } from '../../common/api/api';
import { Box } from '../../common/utils/box.component';
import { PageTitle } from '../../common/utils/page-title.component';
import { Status } from '../../common/utils/status.component';
import Form from 'react-bootstrap/esm/Form';
import { AudioItemInput } from '../../common/utils/audio-input.component';
import './list.scss';
import Button from 'react-bootstrap/esm/Button';

function InstructionLabel({ labelKey, label }: { labelKey: EInstructionLabel, label: IInstructionLabel|undefined }): JSX.Element {
  const [text, setText] = useState(label?.text || '');
  const [recordingFilename, setRecordingFilename] = useState(label?.recordingFilename);
  const [hasChanged, setHasChanged] = useState(false);
  const isValid = !!(text.trim() && recordingFilename);
  const mutation = useMutation(Api.setInstructionLabel, {
    onSuccess: () => setHasChanged(false),
  });

  useEffect(() => {
    setText(label?.text || '');
    setRecordingFilename(label?.recordingFilename);
    setHasChanged(false);
  }, [labelKey, label]);

  const handleChangeTekst = (value: string) => {
    setText(value.trimStart());
    setHasChanged(true);
  };

  const handleChangeRecording = (value: RecordingItem|null) => {
    setRecordingFilename(value?.filename);
    setHasChanged(true);
  };

  const handleSave = (): void => {
    if (!isValid || mutation.isLoading) {
      return;
    }

    const newLabel: Omit<IInstructionLabel, 'id'> = {
      key: labelKey,
      text: text.trim(),
      recordingFilename,
    };
    mutation.mutate(newLabel);
  };

  const recording: RecordingItem|null = recordingFilename ? {
    filename: recordingFilename,
    sourceFilename: recordingFilename,
    name: 'Opname',
  } : null;

  return <Row>
    <Col className="list-instruction-label__item-key" sm="2">{labelKey}</Col>

    <Form.Group as={Col} fluid="true">
      <Form.Label>Tekst</Form.Label>
      <Form.Control
        type="text"
        value={text}
        onChange={e => handleChangeTekst(e.target.value)} />
    </Form.Group>

    <Form.Group as={Col} sm="3">
      <Form.Label>Audio opname</Form.Label>
      <AudioItemInput value={recording} onChange={value => handleChangeRecording(value)} />
    </Form.Group>

    <Form.Group as={Col} sm="1" className="list-instruction-label__submit-container">
      { hasChanged && <>
        { !mutation.isLoading && <Button disabled={!isValid || mutation.isLoading} onClick={handleSave}>Opslaan</Button> }
        <Status loading={mutation.isLoading} error={mutation.error} />
      </> }
    </Form.Group>
  </Row>;
}

export function ListInstructionLabels(): JSX.Element {
  const { data: labels, error, isLoading } = useQuery(['instruction-labels'], Api.listInstructionLabels);

  return <>
    <PageTitle>Algemene taallabels</PageTitle>
    <Status loading={isLoading} error={error} />
    <Box title="Alles">
      { labels && Object.values(EInstructionLabel).map(key => <InstructionLabel key={key} labelKey={key} label={labels[key]} />) }
    </Box>
  </>;
}
