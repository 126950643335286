import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TranscriptWord, TDifficultWord, Recording, ERecordingLanguage } from 'shared';
import { AudioInput } from '../../../../../common/utils/audio-input.component';
import { TranscriptDisplay } from '../../../../../common/utils/transcript-display.component';

interface IReadingComprehensionTranscriptTextEditorProps {
  words: TranscriptWord[];
  setWords: (value: TranscriptWord[]) => void;
  difficultWords?: TDifficultWord[] | undefined;
  recording: Recording | null;
  handleRecordingChanged: (recording: Recording | null) => void;
}

export const ReadingComprehensionTranscriptTextEditor = ({
  words,
  setWords,
  difficultWords,
  recording,
  handleRecordingChanged,
}: IReadingComprehensionTranscriptTextEditorProps): JSX.Element => {
  const { t } = useTranslation();

  const [speechRecognitionContext, setSpeechRecognitionContext] = useState<string[]>([]);
  const cleanSpeechRecognitionContext = speechRecognitionContext
    .map((item) => item.trim())
    .filter((item) => !!item); // Remove empty lines

  const handleChangeSpeechContext = (value: string) => {
    setSpeechRecognitionContext(value.split('\n'));
  };

  return (
    <>
      <Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>{t('reading-comprehension:admin.audio-input-label')}</Form.Label>
          <AudioInput allowedLanguages={[ERecordingLanguage.NL_NL]} transcribe speechContext={cleanSpeechRecognitionContext} value={recording} onChange={(recording) => handleRecordingChanged(recording)} />
          <Form.Text className="text-muted">{t('reading-comprehension:admin.audio-input-speech-context-hint')}</Form.Text>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>{t('reading-comprehension:admin.speech-context-title')}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={t('reading-comprehension:admin.speech-context-placeholder')}
            value={speechRecognitionContext.join('\n')}
            onChange={(event) => handleChangeSpeechContext(event.target.value)} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={12}>
          { recording && <>
            <Form.Label>{t('reading-comprehension:admin.transcript-label')}</Form.Label>
            <TranscriptDisplay words={words} difficultWords={difficultWords || []} recording={recording} onChange={setWords} />
          </> }
          <Form.Control.Feedback type="invalid">
            {t('reading-comprehension:admin.audio-input-no-recording')}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </>
  );
};
