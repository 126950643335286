import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { EInstructionLabel, EPupilPasswordPart } from 'shared';
import { SchoolSubscriptionExpiredException, TooManyRequestsException } from '../common/api/types';
import { SelectLayout } from '../common/layouts/select.layout';
import { useSchool } from '../common/school-provider/school.context';
import { InstructionLabel } from '../common/utils/instruction-label.component';
import { getPasswordImagePath } from '../common/utils/password-image-path';
import { authorizePupil } from '../common/utils/pupil-auth.helper';
import { Status } from '../common/utils/status.component';
import { ELessonRouterPath, EPupilRouterPath } from '../router-path';
import './auth.scss';

const passwordLength = 4;

function PasswordDisplay({ value, invalid, authorizing }: { value: EPupilPasswordPart[], invalid: boolean, authorizing: boolean }): JSX.Element {
  const entries: (EPupilPasswordPart|null)[] = [null, null, null, null];
  entries.splice(0, value.length, ...value);

  return <div className={classNames('password-display', { 'password-display--invalid': invalid, 'password-display--authorizing': authorizing })}>
    <div className="password-display__label"><InstructionLabel instructionKey={EInstructionLabel.VOER_JOUW_CODE_IN} /></div>
    <div className="password-display__value">
      { entries.map((entry, index) => <div key={`${entry || ''}_${index}`} className="password-display__value-part">
        <img className="password-display__image" src={entry ? getPasswordImagePath(entry) : ''} />
      </div>)}
    </div>
  </div>;
}

function PasswordInput({ value, disabled, onChange }: { value: EPupilPasswordPart[], disabled: boolean, onChange: (value: EPupilPasswordPart[]) => void }): JSX.Element {
  const options: EPupilPasswordPart[] = Object.values(EPupilPasswordPart);

  const handleClick = (option: EPupilPasswordPart): void => {
    const newValue = [...value, option];
    if (newValue.length > passwordLength) {
      newValue.splice(0, newValue.length - (passwordLength));
    }
    onChange(newValue);
  };

  return <div className="password-input">
    { options.map((option) => <button key={option} className="password-input__item-button" disabled={disabled} onClick={() => handleClick(option)}>
      <img className="password-input__image" src={getPasswordImagePath(option)} />
    </button>)}
  </div>;
}

export function PupilAuth(): JSX.Element {
  const { t } = useTranslation();
  const { school } = useSchool();
  const params = useParams();
  const pupilId = +(params.pupilId || '');
  const [searchParams] = useSearchParams();
  const groupId = +(searchParams.get('group') || '');
  const [password, setPassword] = useState<EPupilPasswordPart[]>([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [authorizing, setAuthorizing] = useState(false);
  const [badPassword, setBadPassword] = useState(false);
  const [authError, setAuthError] = useState<string|null>(null);
  const passwordTooShort = password.length < 4;

  if (!school) {
    return <Status loading={true} />;
  }

  if (!pupilId) {
    return <Status error="Leerling kon niet worden geladen zonder ID" />;
  }

  if (Number.isNaN(pupilId)) {
    return <Status error="Leerling ID is ongeldig" />;
  }

  useEffect(() => {
    if (!badPassword) {
      return;
    }

    const timeout = setTimeout(() => {
      setPassword([]);
      setBadPassword(false);
    }, 1000); // All animation values from the css added together

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
    };
  }, [badPassword]);

  const auth = () => {
    setAuthorizing(true);
    setBadPassword(false);
    setAuthError(null);
    authorizePupil({ pupilId, password })
      .then(token => {
        if (!token) {
          setBadPassword(true);
          return;
        }
        setIsAuthorized(true);
      })
      .catch((err) => {
        let message = t('pupil:unknown-auth-error');
        if (err instanceof SchoolSubscriptionExpiredException) {
          message = t('pupil:expired-school-license');
          setBadPassword(true);
        } else if (err instanceof TooManyRequestsException) {
          message = t('pupil:too-many-password-attempts');
          setBadPassword(true);
        } else {
          console.error('Failed to authorize:', err);
        }
        setAuthError(message);
      })
      .finally(() => {
        setAuthorizing(false);
      });
  };

  useEffect(() => {
    if (!passwordTooShort) {
      auth();
    }
  }, [password]);

  const handleChangePassword = (value: EPupilPasswordPart[]) => {
    setPassword(value);
    setBadPassword(false);
  };

  return <SelectLayout
    backPath={Number.isNaN(groupId) ? undefined : EPupilRouterPath.PUPIL_LIST.fullPath(school.id, groupId)}
    actionInstructionLabel={EInstructionLabel.WAT_IS_JOUW_WACHTWOORD}>
    <PasswordDisplay value={password} invalid={badPassword} authorizing={authorizing} />
    <Status className="auth-error" error={authError} />
    <PasswordInput value={password} disabled={authorizing || badPassword} onChange={handleChangePassword} />
    { isAuthorized && <Navigate to={ELessonRouterPath.HOME.fullPath} />}
  </SelectLayout>;
}
