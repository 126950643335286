import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SchoolLayout } from '../common/layouts/school.layout';
import { SchoolProvider } from '../common/school-provider/school.provider';
import { EMainRouterPath, ESchoolRouterPath } from '../router-path';
import { ListSchoolUsers } from './user/list';
import { PupilProgress } from './pupil-progress/list';
import { SchoolHome } from './home';
import { ProgressDetail } from './pupil-progress/progress-detail/progress-detail';

export function SchoolModule(): JSX.Element {
  return (
    <SchoolProvider unknownSchoolPath={EMainRouterPath.AUTH.path}>
      <SchoolLayout>
        <Routes>
          <Route path={ESchoolRouterPath.HOME.path} element={<SchoolHome />} />
          <Route path={ESchoolRouterPath.USER_LIST.path} element={<ListSchoolUsers />} />
          <Route path={ESchoolRouterPath.PROGRESS.path} element={<PupilProgress />} />
          <Route path={ESchoolRouterPath.PROGRESS_DETAIL.path} element={<ProgressDetail />} />
        </Routes>
      </SchoolLayout>
    </SchoolProvider>
  );
}
