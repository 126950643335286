import React, { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

// Prompt is removed from react-router v6 so we reintroduce it manually here.
// See: https://ui.dev/react-router-preventing-transitions

/* eslint-disable */
export function useBlocker(blocker: (tx: any) => void, when?: boolean|null): void {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    // @ts-ignore
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}
/* eslint-enable */

interface IPromptProps {
  when?: boolean|null;
  message: string;
}

export function Prompt({ when, message }: IPromptProps): JSX.Element {
  // For catching react-router changes
  const blocker = React.useCallback(
    (tx: any) => {
      // eslint-disable-next-line no-alert, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(blocker, when);

  // For catching browser router changes
  const beforeUnloadHandler = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = message;
    return event;
  }, []);

  useEffect(() => {
    if (when) {
      addEventListener('beforeunload', beforeUnloadHandler, { capture: true });
    }

    return () => removeEventListener('beforeunload', beforeUnloadHandler, { capture: true });
  }, [when]);

  return <></>;
}
