import { EInstructionLabel, ILesson, VideoExercise } from 'shared';
import { IExerciseProps } from '..';
import { InstructionLabel } from '../../../common/utils/instruction-label.component';
import { RoundButton } from '../../../common/utils/round-button.component';
import { VideoLayout } from './video-layout';
import './show-video.scss';
import { EIcon } from '../../../common/utils/icon.component';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import { useDeveloperByPassMode } from '../../../common/utils/developer-bypass';
import clsx from 'clsx';


interface IShowVideoProps extends IExerciseProps<VideoExercise> {
  progress: number;
  lesson: ILesson;
}

export const ShowVideo = ({ exercise, lesson, onClose, onFinishExercise }: IShowVideoProps): JSX.Element => {
  const [ended, setVideoEnded] = useState<boolean>(false);
  const developerBypassMode = useDeveloperByPassMode();
  const handleVideoPlaying = () => setVideoEnded(false);
  const [videoProgress, setVideoProgress] = useState<number>(0);

  return (
    <>
      <VideoLayout lesson={lesson} progress={videoProgress} onClose={onClose}>
        <div className="show-video">
          <div className="show-video__instruction"><InstructionLabel instructionKey={EInstructionLabel.MAAK_OPDRACHT_UIT_WERKBOEK} /></div>
          <div className="video-display">
            <ReactPlayer
              onProgress={(progress) => setVideoProgress(progress.played * 100)}
              loop={false}
              onEnded={() => setVideoEnded(true)}
              onPlay={handleVideoPlaying}
              config={{
                vimeo: {
                  playerOptions: {
                    pip: false,
                    portrait: false,
                    title: false,
                    byline: false,
                    color: '#850e2c'
                  }
                }
              }}
              url={exercise?.data?.url as string}
              controls />
          </div>
          <RoundButton
            className={clsx('show-video__next-button', (ended || developerBypassMode) && 'show-video__next-button--show')}
            showLabel
            icon={EIcon.CHEVRON_RIGHT}
            variant="success"
            onClick={() => onFinishExercise()}
            disabled={!ended && !developerBypassMode}>
              Klaar met de les
          </RoundButton>
        </div>
      </VideoLayout>
    </>
  );
};
