import React, { PropsWithChildren, useState } from 'react';
import classNames from 'classnames';
import { EIcon, Icon } from './icon.component';
import './danger-button.component.scss';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';

interface IDangerButtonProps {
  className?: string;
  icon?: EIcon;
  skipModal?: boolean;
  modalText?: string;
  modalCancelLabel?: string;
  modalConfirmLabel?: string;
  onClick: () => void;
}

export function DangerButton({ className, icon, modalText, skipModal, modalCancelLabel, modalConfirmLabel, onClick, children }: PropsWithChildren<IDangerButtonProps>): JSX.Element {
  const [showModal, setShowModal] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (skipModal) {
      onClick();
    } else {
      setShowModal(true);
    }
  };

  const handleCancel = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) e.stopPropagation();
    setShowModal(false);
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setShowModal(false);
    onClick();
  };

  return <>
    <Modal show={showModal} onHide={handleCancel}>
      <Modal.Body>{modalText || 'Weet je het zeker?'}</Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={(e) => handleCancel(e)}>{ modalCancelLabel || 'Annuleren'}</Button>
        <Button variant="danger" onClick={(e) => handleConfirm(e)}>{ modalConfirmLabel || 'Bevestigen'}</Button>
      </Modal.Footer>
    </Modal>
    <button className={classNames('danger-button', className)} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClick(e)} type="button">
      { icon && <Icon>{icon}</Icon> }
      <span className="danger-button__label">{children}</span>
    </button>
  </>;
}
