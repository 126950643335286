import React, { createContext, Dispatch, SetStateAction, useContext } from 'react';

export interface IHintContext {
  hintOpen: boolean;
  setHintOpen: Dispatch<SetStateAction<boolean>>;
  hintMessage?: string | React.ReactNode;
  setHintMessage: Dispatch<SetStateAction<string | React.ReactNode>>;
}

export const HintContext = createContext<IHintContext>({ hintOpen: false, setHintOpen: () => false, setHintMessage: () => false });

export function useHint(): IHintContext {
  return useContext(HintContext);
}
