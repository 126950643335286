import { useMemo } from 'react';
import { ILesson, LessonDocument, LessonDocumentManager, HiddenObjectsGameData, EExerciseType, WordTowerExerciseData, VideoExerciseData } from 'shared';

export function useLessonDocument(lesson: ILesson): LessonDocument {
  return useMemo(() => {
    if (!lesson.document) {
      return LessonDocumentManager.getEmptyLessonDocument(lesson.category);
    }

    try {
      return LessonDocumentManager.parseJsonToLessonDocument(lesson.document);
    } catch (e) {
      console.error('Failed to read lesson document', e);
      return LessonDocumentManager.getEmptyLessonDocument(lesson.category);
    }
  }, [lesson]);
}

export function useHiddenObjectsGameData(lesson: ILesson): HiddenObjectsGameData|null {
  const lessonDocument = useLessonDocument(lesson);

  for (const exercise of lessonDocument.exercises) {
    if (exercise.type !== EExerciseType.HIDDEN_OBJECTS_GAME) {
      continue;
    }

    if (!exercise.data) {
      return null;
    }

    return exercise.data as HiddenObjectsGameData;
  }

  return null;
}

export function useWordTowerData(lesson: ILesson): WordTowerExerciseData|null {
  const lessonDocument = useLessonDocument(lesson);

  for (const exercise of lessonDocument.exercises) {
    if (exercise.type !== EExerciseType.WORD_TOWER) {
      continue;
    }

    if (!exercise.data) {
      return null;
    }

    return exercise.data as WordTowerExerciseData;
  }

  return null;
}

export function useVideoData(lesson: ILesson): VideoExerciseData|null {
  const lessonDocument = useLessonDocument(lesson);

  for (const exercise of lessonDocument.exercises) {
    if (exercise.type !== EExerciseType.VIDEO) continue;

    if (!exercise.data) return null;

    return exercise.data as VideoExerciseData;
  }

  return null;
}
