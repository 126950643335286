import React, { PropsWithChildren, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { useTranslation } from 'react-i18next';
import { ISchool } from 'shared';
import { ESchoolRouterPath } from '../../router-path';
import { useSchool } from '../school-provider/school.context';
import { EIcon } from '../utils/icon.component';
import { IMainLayoutMenuItem, MainLayout } from './main.layout';

const FEEDBACK_URL = process.env.REACT_APP_FEEDBACK_FORM_URL || '';

if (!FEEDBACK_URL) {
  console.warn('Missing REACT_APP_FEEDBACK_FORM_URL');
}

const getDaysBetweenDates = (a: Date, b: Date): number => {
  const difference = a.getTime() - b.getTime();
  return Math.floor(difference / (1000 * 60 * 60 * 24));
};

const getNowOver100Days = (): Date => {
  const now = new Date();
  now.setDate(now.getDate() + 100);
  return now;
};

function ExpiredSubscriptionModal({ school }: { school: ISchool }): JSX.Element {
  const { t } = useTranslation();
  const [closedModal, setClosedModal] = useState(false);
  const daysLeftOnSubscription = getDaysBetweenDates(school.subscriptionEndAt ?? getNowOver100Days(), new Date());
  const hasInvalidSubscription = school.invalidSubscription;
  const displayModal = !closedModal && (hasInvalidSubscription || daysLeftOnSubscription < 7);

  const handleCloseModal = () => setClosedModal(true);

  return <Modal show={displayModal} onHide={handleCloseModal}>
    <Modal.Header closeButton>
      <Modal.Title>{
        hasInvalidSubscription ?
          t('school:expired-subscription:title') :
          t('school:expired-subscription:almost-title')
      }</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>{
        hasInvalidSubscription ?
          t('school:expired-subscription:info') :
          t(`school:expired-subscription:almost-info:${daysLeftOnSubscription === 1 ? 'one' : 'other'}`, { days: daysLeftOnSubscription })
      }</p>

      <p>{t('school:expired-subscription:contact-sales')}:</p>
      <address>
        <a href={`mailto:${t('common:contact-email')}`}>{t('common:contact-email')}</a><br/>
        <div>{t('common:contact-phone')}</div>
      </address>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="primary" onClick={handleCloseModal}>{t('common:continue')}</Button>
    </Modal.Footer>
  </Modal>;
}

export function SchoolLayout({ children }: PropsWithChildren<any>): JSX.Element {
  const { school } = useSchool();
  const { t } = useTranslation();

  const schoolMenuItems: IMainLayoutMenuItem[] = [];
  if (school) {
    schoolMenuItems.push(
      { name: 'Home', icon: EIcon.HOME, link: ESchoolRouterPath.HOME.fullPath(school.id) },
      { name: 'Voortgang', icon: EIcon.CHART, link: ESchoolRouterPath.PROGRESS.fullPath(school.id) },
      { name: 'Gebruikers', icon: EIcon.USERS, link: ESchoolRouterPath.USER_LIST.fullPath(school.id) },
      { name: t('school:navigation:feedback'), icon: EIcon.SMILE, link: FEEDBACK_URL, openExtern: true },
    );
  }

  return <>
    <MainLayout school={school} logoLink={school ? ESchoolRouterPath.HOME.fullPath(school.id) : '#'} menuItems={schoolMenuItems}>{children}</MainLayout>
    { school && <ExpiredSubscriptionModal school={school} /> }
  </>;
}
