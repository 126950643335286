import React from 'react';
import { EExerciseType } from 'shared';

function convert(value: EExerciseType): string {
  switch (value) {
    case EExerciseType.HIDDEN_OBJECTS_GAME: return 'woordplaat';
    case EExerciseType.WORD_TOWER: return 'woordtoren';
    case EExerciseType.WORD_BLOCK: return 'woordblok';
    case EExerciseType.READING_COMPREHENSION_TRANSCRIPT: return 'karaoke verhaal';
    case EExerciseType.READING_COMPREHENSION_TEXT: return 'verhaal';
    default: return value;
  }
}

export function getExerciseTypeTitle({ value, upper }: { value: EExerciseType, upper?: boolean }): string {
  if (upper) {
    const converted = convert(value);
    return converted.charAt(0).toUpperCase() + converted.slice(1);
  }
  return convert(value);
}

export function ExerciseType(props: { value: EExerciseType, upper?: boolean }): JSX.Element {
  return <>{getExerciseTypeTitle(props)}</>;
}
