export const parseNumber = (value: string|number): number|null => {
  const cleaned = `${value}`.replaceAll(/[^0-9]*/g, '');
  const parsed = +cleaned;
  return Number.isNaN(parsed) ? null : parsed;
};

export const parseText = (value: string): string => value.trimStart();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/ban-types
export const isEnumValue = (EnumType: Object, value: any): boolean => Object.values(EnumType).includes(value);
