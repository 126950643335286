/* eslint-disable no-case-declarations */
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../common/api/api';
import { PageTitle } from '../../common/utils/page-title.component';
import { Status } from '../../common/utils/status.component';
import { EAdminRouterPath } from '../../router-path';
import { IUsageRun } from 'shared';
import { DynamicTable, DynamicTableColumn, ESortDirection } from '../../common/utils/dynamic-table.component';
import { DateTime } from '../../common/utils/date-time.component';
import { useTranslation } from 'react-i18next';

interface IUsageRunsTableData {
  date: JSX.Element;
  runId: string;
  previousRunId?: string;
  schools: number;
  pupils: number;
  teachers: number;
}

function UsageTable({ usageRuns }: { usageRuns?: IUsageRun[] }): JSX.Element|null {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!usageRuns) {
    return null;
  }

  const sort = (indexA: number, indexB: number, direction: ESortDirection, objectKey: keyof IUsageRunsTableData): number => {
    const a = usageRuns[indexA];
    const b = usageRuns[indexB];
    if (!a || !b) {
      console.warn(`Sort in UsageTable error, sort item a: ${a ? 'defined' : 'undefined'} b: ${b ? 'defined' : 'undefined'}`);
      return 0;
    }

    const sortDesc = direction === ESortDirection.DESCENDING;
    switch (objectKey) {
      case 'date':
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortDesc ? dateB.valueOf() - dateA.valueOf() : dateA.valueOf() - dateB.valueOf();
      default:
        return 0;
    }
  };

  const columns: DynamicTableColumn<IUsageRunsTableData>[] = [
    { title: t('usage:reference-date'), objectKey: 'date', customSortFunction: sort },
    { title: t('usage:total-schools'), objectKey: 'schools' },
    { title: t('usage:total-teachers'), objectKey: 'teachers' },
    { title: t('usage:total-pupils'), objectKey: 'pupils' },
  ];

  const data: IUsageRunsTableData[] = usageRuns.map((run, index, list) => {
    const previousRun = list[index + 1]; // It's assumed that the runs are ordered descending
    return {
      date: <DateTime date={run.createdAt} />,
      runId: run.runId,
      previousRunId: previousRun?.runId,
      schools: run.schoolCount,
      pupils: run.pupilCount,
      teachers: run.teacherCount,
    };
  });

  const handleClickRow = (run: IUsageRunsTableData) => {
    navigate(EAdminRouterPath.USAGE_SHOW.fullPath(run.runId, { compareToRunId: run.previousRunId }));
  };

  return <DynamicTable<IUsageRunsTableData>
    columns={columns}
    data={data}
    sortByDefault={columns[0]}
    sortDirectionDefault={ESortDirection.DESCENDING}
    onClickRow={handleClickRow}
    showDownloadButton={true}
    downloadFileName={t('usage:title')}
  />;
}

export function ListUsage(): JSX.Element {
  const { t } = useTranslation();
  const { data: usageRuns, error, isLoading } = useQuery('usage', Api.listUsageRuns);

  return <>
    <PageTitle>{t('usage:title')}</PageTitle>
    <Status loading={isLoading} error={error} />
    <UsageTable usageRuns={usageRuns} />
  </>;
}
