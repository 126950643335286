import React from 'react';
import { useHint } from '../hint-provider/hint.context';
import './hint-balloon.component.scss';

const HintBalloon = (): JSX.Element => {
  const { hintMessage, hintOpen } = useHint();

  return (
    <div className={`hint-balloon ${hintOpen ? 'visible' : 'hidden'}`}>
      <div className="hint-balloon__content">
        <div className="hint-balloon__content-text">
          {hintMessage}
        </div>
      </div>
      <img src="/assets/vlam.png" alt="Vlam de draak" className="hint-balloon__image" />
    </div>
  );
};

export default HintBalloon;
