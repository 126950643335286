import { DEFAULT_RECORDING_LANGUAGE, ERecordingLanguage, Recording, RecordingItem } from 'shared';

/**
 * @param recording Recording object
 * @param language Language to find the recording for
 * @param fallbackLanguage Language to try when `language` is not found. Defaults to `DEFAULT_RECORDING_LANGUAGE` if not set
 * @returns The recording item matching the language or fallbackLanguage. Undefined if not found.
 */
export function resolveRecordingItem(recording: Recording|undefined|null, language: ERecordingLanguage, fallbackLanguage: ERecordingLanguage = DEFAULT_RECORDING_LANGUAGE): RecordingItem|undefined {
  if (!language) {
    console.error('[DEBUG] Missing language');
  }
  if (!recording) {
    return undefined;
  }

  if (recording[language]) {
    return recording[language];
  }

  return recording[fallbackLanguage];
}
