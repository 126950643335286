import { ELessonBook, ELessonLevel } from 'shared';

import level3decoding1 from './level-3-decoding-one.jpg';
import level3decoding2 from './level-3-decoding-two.jpg';
import level3decoding3 from './level-3-decoding-three.jpg';
import level3extra from './level-3-extra-one.jpg';
import level4decoding1 from './level-4-decoding-one.png';
import level4decoding2 from './level-4-decoding-two.png';
import level4readingcomprehension1 from './level-4-reading-comprehension-one.png';
import level4readingcomprehension2 from './level-4-reading-comprehension-two.png';
import level5decoding1 from './level-5-decoding.png';
import level5readingcomprehension1 from './level-5-reading-comprehension.png';
import level6readingcomprehension1 from './level-6-reading-comprehension-one.png';
import level6readingcomprehension2 from './level-6-reading-comprehension-two.png';
import level6decoding1 from './level-6-decoding-one.png';
import unknown from './unknown.png';

const lessonImages = new Map<`${ELessonLevel}_${ELessonBook}`, string>([
  [`${ELessonLevel.LEVEL_3}_${ELessonBook.DECODING_ONE}`, level3decoding1],
  [`${ELessonLevel.LEVEL_3}_${ELessonBook.DECODING_TWO}`, level3decoding2],
  [`${ELessonLevel.LEVEL_3}_${ELessonBook.DECODING_THREE}`, level3decoding3],
  [`${ELessonLevel.LEVEL_3}_${ELessonBook.EXTRA_ONE}`, level3extra],
  [`${ELessonLevel.LEVEL_4}_${ELessonBook.DECODING_ONE}`, level4decoding1],
  [`${ELessonLevel.LEVEL_4}_${ELessonBook.DECODING_TWO}`, level4decoding2],
  [`${ELessonLevel.LEVEL_4}_${ELessonBook.READING_COMPREHENSION_ONE}`, level4readingcomprehension1],
  [`${ELessonLevel.LEVEL_4}_${ELessonBook.READING_COMPREHENSION_TWO}`, level4readingcomprehension2],
  [`${ELessonLevel.LEVEL_5}_${ELessonBook.DECODING_ONE}`, level5decoding1],
  [`${ELessonLevel.LEVEL_5}_${ELessonBook.READING_COMPREHENSION_ONE}`, level5readingcomprehension1],
  [`${ELessonLevel.LEVEL_6}_${ELessonBook.READING_COMPREHENSION_ONE}`, level6readingcomprehension1],
  [`${ELessonLevel.LEVEL_6}_${ELessonBook.READING_COMPREHENSION_TWO}`, level6readingcomprehension2],
  [`${ELessonLevel.LEVEL_6}_${ELessonBook.DECODING_ONE}`, level6decoding1],
]);

export function getBookImage(level: ELessonLevel, book: ELessonBook): string {
  return lessonImages.get(`${level}_${book}`) ?? unknown;
}
