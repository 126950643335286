import { useTranslation } from 'react-i18next';
import { ELessonType, EPupilProgressStatus, IPupilDecodingResult, IPupilProgress, PupilDecodingResultSchema } from 'shared';
import { EIcon, Icon } from '../../../common/utils/icon.component';
import { PropsWithChildren } from 'react';
import './progress-summary.scss';

export function ProgressResult({ children }: PropsWithChildren): JSX.Element {
  return <div className="progress-result">
    {children}
  </div>;
}

export function ProgressResultItem({ icon, children, ...props }: PropsWithChildren<{ icon: EIcon }>): JSX.Element {
  return <span className="progress-result-item" { ...props }>
    <Icon>{icon}</Icon> {children}
  </span>;
}

function ReadingComprehensionResult({ result }: { result: IPupilDecodingResult }): JSX.Element {
  let correctAnswerCount = 0;
  let wrongAnswerCount = 0;

  result.questions?.forEach(question => {
    if (question.attempts.includes(question.correctOption)) {
      correctAnswerCount++;
    } else {
      wrongAnswerCount++;
    }
  });

  return <ProgressResult>
    <ProgressResultItem icon={EIcon.CHECK_CIRCLE}>{correctAnswerCount}</ProgressResultItem>
    <ProgressResultItem icon={EIcon.X_CIRCLE}>{wrongAnswerCount}</ProgressResultItem>
  </ProgressResult>;
}

export function ProgressSummary({ progress }: { progress: IPupilProgress }): JSX.Element {
  const { t } = useTranslation();

  const renderFinishedResult = () => {
    let data: any = {};
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data = JSON.parse(progress.result);
    } catch (err) {
      console.error('Failed to parse lesson progress result', progress, err);
    }

    if (progress.lesson.type === ELessonType.READING_COMPREHENSION && PupilDecodingResultSchema.validate(data)) {
      return <ReadingComprehensionResult result={data as IPupilDecodingResult}/>;
    }

    return <ProgressResult>
      <ProgressResultItem icon={EIcon.CHECK_CIRCLE}>{t('progress-summary:finished')}</ProgressResultItem>
    </ProgressResult>;
  };

  switch (progress.status) {
    case EPupilProgressStatus.STARTED:
      return <ProgressResult>
        <ProgressResultItem icon={EIcon.PROGRESS}>{t('progress-summary:in-progress')}</ProgressResultItem>
      </ProgressResult>;
    case EPupilProgressStatus.FINISHED:
      return renderFinishedResult();
    default:
      return <>-</>;
  }
}
