"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.runMigrations = exports.migrateLessonDocument = exports.LATEST_LESSON_DOCUMENT_VERSION = exports.migrations = void 0;
var lesson_document_manager_helper_1 = require("../lesson-document-manager.helper");
var v2_migration_1 = require("./v2.migration");
var v3_migration_1 = require("./v3.migration");
var v4_migration_1 = require("./v4.migration");
exports.migrations = [
    v2_migration_1.migrationToV2,
    v3_migration_1.migrationToV3,
    v4_migration_1.migrationToV4,
];
exports.LATEST_LESSON_DOCUMENT_VERSION = exports.migrations[exports.migrations.length - 1].migratingToVersion;
function migrateLessonDocument(lessonDocument, migration) {
    if (lessonDocument.version !== migration.migratingFromVersion) {
        throw new Error("Cannot migrate from version ".concat(lessonDocument.version, " to ").concat(migration.migratingToVersion, ". Required version: ").concat(migration.migratingFromVersion));
    }
    var migratedDocument = migration.migration(lessonDocument);
    migratedDocument.version = migration.migratingToVersion;
    return migratedDocument;
}
exports.migrateLessonDocument = migrateLessonDocument;
function runMigrations(lessonDocument) {
    var migrationsRun = [];
    var lessonDocumentCopy = lesson_document_manager_helper_1.LessonDocumentManager.copyDocument(lessonDocument);
    for (var _i = 0, migrations_1 = exports.migrations; _i < migrations_1.length; _i++) {
        var migration = migrations_1[_i];
        if (lessonDocumentCopy.version !== migration.migratingFromVersion) {
            continue;
        }
        lessonDocumentCopy = migrateLessonDocument(lessonDocumentCopy, migration);
        migrationsRun.push(migration);
    }
    return {
        migrated: migrationsRun.length !== 0,
        lessonDocument: lessonDocumentCopy,
    };
}
exports.runMigrations = runMigrations;
