import { RecordingItem } from 'shared';
import { getStorageUrl } from './storage.helper';

const playingAudio: (HTMLAudioElement|null)[] = [];

function cleanupAudio(id: number): void {
  playingAudio[id] = null;
}

export function stopAudio(audioId: number): void {
  const audio = playingAudio[audioId];
  if (!audio) {
    return;
  }

  audio.pause();
  audio.currentTime = 0;
}

export function pauseAudio(audioId: number): void {
  const audio = playingAudio[audioId];
  if (!audio) {
    return;
  }

  audio.pause();
}

export function resumeAudio(audioId: number): void {
  const audio = playingAudio[audioId];
  if (!audio) {
    return;
  }

  audio.play().catch(err => console.error('Failed to resume audio', err));
}

function playAudioFile(audio: HTMLAudioElement, audioId: number, reject: (reason?: any) => void) {
  audio.play()
    .catch(e => {
      cleanupAudio(audioId);
      reject(e);
    });
}

export function playAudio(recording: RecordingItem|string, audioIdCallback?: (id: number) => void, progressCallback?: (time: number) => void): Promise<void> {
  const filename = typeof recording === 'string' ? recording : recording.filename;
  const url = getStorageUrl(filename);
  const audio = new Audio(url);
  const audioId = playingAudio.push(audio) - 1;
  const stoppedEvents: (keyof HTMLMediaElementEventMap)[] = ['abort', 'compositionend', 'close', 'ended']; // We had 'pause' in there, but in conflicted with ALL-381. So if any issues come up, look here.

  return new Promise((resolve, reject) => {
    if (audioIdCallback) {
      audioIdCallback(audioId);
    }

    playAudioFile(audio, audioId, reject);

    if (progressCallback) {
      audio.addEventListener('timeupdate', (event) => progressCallback((event.currentTarget as HTMLAudioElement).currentTime));
    }

    const onDone = () => {
      stoppedEvents.forEach(key => audio.removeEventListener(key, onDone));
      cleanupAudio(audioId);
      resolve();
    };
    stoppedEvents.forEach(key => audio.addEventListener(key, onDone));
  });
}

export function playSingleAudio(recording: RecordingItem|string, audioIdCallback?: (id: number) => void): Promise<void> {
  playingAudio.forEach((audio, audioId) => {
    if (!audio) {
      return;
    }

    stopAudio(audioId);
  });

  return playAudio(recording, audioIdCallback);
}
