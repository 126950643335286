import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Api } from '../api/api';
import { SessionContext, ISessionContext, getDefaultUserContext, userDetailsToContext } from './session.context';
import { AuthService } from './auth.service';

export function SessionProvider({ children }: PropsWithChildren<any>): JSX.Element {
  const invalidateUserInfo = useCallback(() => {
    // eslint-disable-next-line no-use-before-define
    updateSession();
  }, []);

  const [session, setSession] = useState<ISessionContext>(getDefaultUserContext({ isAuthorized: AuthService.isAuthorized(), isLoading: true, invalidateUserInfo }));

  const updateSession = (isAuthorized?: boolean) => {
    if (isAuthorized === undefined) {
      isAuthorized = AuthService.isAuthorized();
    }

    if (isAuthorized) {
      // Immediately report a valid session so we don't have to wait for the user request.
      setSession(getDefaultUserContext({ isAuthorized, isLoading: true, invalidateUserInfo }));

      // Add the user data to the session
      Api.userMe()
        .then(userDetails => setSession({ ...userDetailsToContext(userDetails), invalidateUserInfo }))
        .catch(error => {
          console.error('Failed to get user for session', error);
          setSession(getDefaultUserContext({ isAuthorized: false, isLoading: false, invalidateUserInfo }));
        });
    } else {
      setSession(getDefaultUserContext({ isAuthorized, isLoading: false, invalidateUserInfo }));
    }
  };

  useEffect(() => {
    updateSession();
    AuthService.registerAuthorizationChangedCallback(updateSession);

    return () => {
      AuthService.removeAuthorizationChangedCallback(updateSession);
    };
  }, []);

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
}
