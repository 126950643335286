
import { ELessonCategory, ELessonType } from 'shared';
import classNames from 'classnames';
import React, { useEffect, useState, useCallback } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import { Info } from '../../../common/utils/info.component';
import { Status } from '../../../common/utils/status.component';
import { EMPTY_LESSON_META, LessonGoal, LessonMeta, LessonMetaManager } from './lesson-meta';
import { EditorTitle } from '../../../common/utils/editor-title';
import { ModalGoal } from '../lesson-modals/modal-goal.component';

interface ILessonMetaEditorProps {
  className?: string;
  category: ELessonCategory;
  lessonType?: ELessonType | null;
  value: string;
  onChange: (meta: string) => void;
}

const DEFAULT_LESSON_GOAL: LessonGoal = {
  letters: [],
  punctuations: [],
  words: [],
};

function GoalEditor({ value, onChange }: { value: LessonGoal|undefined, onChange: (value: LessonGoal) => void }): JSX.Element {
  // Tail states are removed on input blur and focus
  const [lettersTail, setLettersTail] = useState('');
  const [punctuationsTail, setPunctuationsTail] = useState('');
  const [wordsTail, setWordsTail] = useState('');

  const letters = (value?.letters || []).join(' ') + lettersTail;
  const punctuations = (value?.punctuations || []).join(' ') + punctuationsTail;
  const words = (value?.words || []).join(' ') + wordsTail;

  const reportChange = useCallback((change: Partial<LessonGoal>) => {
    onChange({
      ...(value || DEFAULT_LESSON_GOAL),
      ...change,
    });
  }, [value, onChange]);

  const splitStringIntoParts = (value: string): { parts: string[], tail: string } => {
    const cleanValue = value.trimStart().replaceAll(/\s{2,}/g, ' ');
    const parts = cleanValue.trim().split(' ').filter(part => !!part);
    const tailingChars = cleanValue.replace(parts.join(' '), '');
    return { parts, tail: tailingChars };
  };

  const handleFocusBlurLetters = () => setLettersTail('');
  const handleFocusBlurPunctuations = () => setPunctuationsTail('');
  const handleFocusBlurWords = () => setWordsTail('');

  const handleInputLetters = (value: string) => {
    const { parts, tail } = splitStringIntoParts(value);
    setLettersTail(tail);
    reportChange({ letters: parts });
  };

  const handleInputPunctuations = (value: string) => {
    const { parts, tail } = splitStringIntoParts(value);
    setPunctuationsTail(tail);
    reportChange({ punctuations: parts });
  };

  const handleInputWords = (value: string) => {
    const { parts, tail } = splitStringIntoParts(value);
    setWordsTail(tail);
    reportChange({ words: parts });
  };

  return <div className="goal-editor">
    <EditorTitle>Doel van de les <ModalGoal/></EditorTitle>
    <Row>
      <Form.Group as={Col}>
        <Form.Label>Letter(s) of lettersamenstellingen <Info>Gebruik een spatie om de letter(s) of -samenstellingen van elkaar te onderscheiden</Info></Form.Label>
        <Form.Control
          type="text"
          required
          value={letters}
          onChange={e => handleInputLetters(e.target.value)}
          onFocus={handleFocusBlurLetters}
          onBlur={handleFocusBlurLetters}
        />
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label>Leesteken(s) <Info>Gebruik een spatie om de leesteken(s) van elkaar te onderscheiden</Info></Form.Label>
        <Form.Control
          type="text"
          required
          value={punctuations}
          onChange={e => handleInputPunctuations(e.target.value)}
          onFocus={handleFocusBlurPunctuations}
          onBlur={handleFocusBlurPunctuations}
        />
      </Form.Group>
    </Row>

    <Row>
      <Form.Group>
        <Form.Label>Woord(en) <Info>Gebruik een spatie om de woord(en) van elkaar te onderscheiden</Info></Form.Label>
        <Form.Control
          type="text"
          required
          value={words}
          onChange={e => handleInputWords(e.target.value)}
          onFocus={handleFocusBlurWords}
          onBlur={handleFocusBlurWords}
        />
      </Form.Group>
    </Row>
  </div>;
}

export function LessonMetaEditor({ className, category, value, onChange, lessonType }: ILessonMetaEditorProps): JSX.Element {
  const [meta, setMeta] = useState<LessonMeta>(EMPTY_LESSON_META);
  const [invalidMetaError, setInvalidMetaError] = useState<string|null>(null);
  const displayGoalEditor = (category === ELessonCategory.DECODING && lessonType !== ELessonType.READING_COMPREHENSION) || meta.goal; // Always display the editor if goal exits on this object, just in case

  useEffect(() => {
    try {
      const parsedMeta = LessonMetaManager.parseJsonToLessonMeta(value);
      setMeta(parsedMeta);
      setInvalidMetaError(null);
    } catch (error) {
      console.error('Failed to parse meta', error);
      if (error instanceof Error) {
        setInvalidMetaError(error.message);
      } else {
        setInvalidMetaError('Unknown error');
      }
    }
  }, [value]);

  const saveChange = (meta: LessonMeta): void => {
    const newMetaSource = JSON.stringify(meta, null, 2);
    onChange(newMetaSource);
  };

  const handleChangeGoal = useCallback((goal: LessonGoal) => {
    saveChange({
      ...meta,
      goal,
    });
  }, [meta]);

  if (invalidMetaError) {
    return <Status error={invalidMetaError} />;
  }

  return <section className={classNames('lesson-meta-editor', className)}>
    { displayGoalEditor && <GoalEditor value={meta.goal} onChange={handleChangeGoal} /> }
  </section>;
}
