import React, { PropsWithChildren } from 'react';
import { ILesson } from 'shared';
import './decoding-layout.scss';
import icon from '../../icon-pjf.png';
import { RoundButton } from '../../../common/utils/round-button.component';
import { EIcon } from '../../../common/utils/icon.component';

function ProgressBar({ progress }: { progress: number }): JSX.Element {
  return <div className="decoding-layout__progress-bar">
    <div className="decoding-layout__progress" style={{ width: `${progress}%` }} />
  </div>;
}

export function DecodingLayout({ lesson, progress, onClose, children }: PropsWithChildren<{ lesson: ILesson, progress: number, onClose: () => void }>): JSX.Element {
  return <div className="decoding-layout">
    <div className="decoding-layout__header">
      <ProgressBar progress={progress} />
      <RoundButton icon={EIcon.X} onClick={onClose} variant="danger">Sluiten</RoundButton>
    </div>
    <div className="decoding-layout__title">
      <img className="decoding-layout__title-image" src={icon} alt="" />
      <h1 className="decoding-layout__title-text">Les {lesson.number} • {lesson.name}</h1>
    </div>
    { children }
  </div>;
}
