import React, { PropsWithChildren } from 'react';
import { EAdminRouterPath } from '../../router-path';
import { EIcon } from '../utils/icon.component';
import { IMainLayoutMenuItem, MainLayout } from './main.layout';
import { useSession } from '../auth/session.context';
import { EUserCapability } from 'shared';
import { useTranslation } from 'react-i18next';

export function AdminLayout({ children }: PropsWithChildren<any>): JSX.Element {
  const { user } = useSession();
  const { t } = useTranslation();

  const adminMenuItems: IMainLayoutMenuItem[] = [
    { name: 'Scholen', icon: EIcon.BOOK, link: EAdminRouterPath.SCHOOL_LIST.fullPath },
    { name: 'Content', icon: EIcon.LIST, link: EAdminRouterPath.LESSON_LIST.fullPath() },
    { name: 'Algemeen', icon: EIcon.GLOBE, link: EAdminRouterPath.INSTRUCTION_LABEL_LIST.fullPath },
  ];

  if (user?.capabilities.includes(EUserCapability.VIEW_USAGE)) {
    adminMenuItems.push({ name: t('usage:title'), icon: EIcon.TRENDING_UP, link: EAdminRouterPath.USAGE_LIST.fullPath });
  }

  return <MainLayout logoLink={EAdminRouterPath.HOME.path} menuItems={adminMenuItems}>{children}</MainLayout>;
}
