import React, { PropsWithChildren } from 'react';
import { useQuery } from 'react-query';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  BrowserRouter,
  useParams,
} from 'react-router-dom';
import { AdminModule } from './admin-module';
import { Api } from './common/api/api';
import { NotFoundException } from './common/api/types';
import { useSession } from './common/auth/session.context';
import { AuthCallback } from './common/pages/auth-callback';
import { SelectModule } from './common/pages/select-module';
import SelectSchool from './common/pages/select-school';
import NotFound from './common/utils/not-found.component';
import { Status } from './common/utils/status.component';
import { setSchoolInStorage } from './common/utils/storage.helper';
import { LessonModule } from './lesson-module';
import { PupilModule } from './pupil-module';
import { EMainRouterPath } from './router-path';
import { SchoolModule } from './school-module';

function RequireAuthorization({ requireAdmin, children }: { requireAdmin?: boolean, children: JSX.Element }) {
  const location = useLocation();
  const { isAuthorized, isAdmin, isLoading } = useSession();

  if (requireAdmin && isLoading) {
    return <Status loading />;
  }

  if (!isAuthorized || (requireAdmin && !isAdmin)) {
    return <Navigate to={EMainRouterPath.AUTH.path} state={{ from: location }} replace />;
  }

  return children;
}

function PupilSchoolShortLinkRedirect(): JSX.Element {
  const params = useParams();
  const { schoolId } = params;

  if (!schoolId) {
    throw new NotFoundException();
  }

  const { data, isLoading, error } = useQuery(['school-exists', schoolId], () => Api.getSchool(schoolId), { retry: false });
  if (error) return <Navigate to={EMainRouterPath.NOT_FOUND.path} replace={true} />;
  if (isLoading) return <Status loading />;
  if (data && schoolId && !isLoading) {
    setSchoolInStorage(data.friendlyId);
    return <Navigate to={EMainRouterPath.PUPIL_MODULE.fullPath(schoolId)} replace={true} />;
  } else {
    return <Navigate to={EMainRouterPath.NOT_FOUND.path} replace={true} />;
  }
}

export function AppRouter({ children }: PropsWithChildren<any>): JSX.Element {
  return (
    <BrowserRouter>
      {children}
      <Routes>
        <Route path="/" element={<SelectSchool />} />
        <Route path={EMainRouterPath.AUTH.path} element={<SelectModule />} />
        <Route path={EMainRouterPath.AUTH_CALLBACK.path} element={<AuthCallback />} />
        <Route path={`${EMainRouterPath.ADMIN_MODULE.path}/*`} element={<RequireAuthorization requireAdmin><AdminModule /></RequireAuthorization>} />
        <Route path={`${EMainRouterPath.SCHOOL_MODULE.path}/*`} element={<RequireAuthorization><SchoolModule /></RequireAuthorization>} />
        <Route path={`${EMainRouterPath.PUPIL_MODULE.path}/*`} element={<PupilModule />} />
        <Route path={`${EMainRouterPath.LESSON_MODULE.path}/*`} element={<LessonModule />} />
        <Route path={`${EMainRouterPath.SELECT_SCHOOL.path}/*`} element={<SelectSchool />} />
        <Route path={EMainRouterPath.PUPIL_SCHOOL_SHORT_LINK.path} element={<PupilSchoolShortLinkRedirect />} />
        <Route path={EMainRouterPath.NOT_FOUND.path} element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
