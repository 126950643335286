import React from 'react';
import Alert from 'react-bootstrap/esm/Alert';
import Spinner from 'react-bootstrap/esm/Spinner';

interface IStatusProps {
  loading?: boolean;
  error?: unknown|string|null;
  success?: string|null;
  info?: string|null;
  className?: string;
}

export function Status({ loading, error, success, info, className }: IStatusProps): JSX.Element|null {
  if (error) {
    let errorMessage = 'An unexpected error occurred';
    if (typeof error === 'string') {
      errorMessage = error;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    return <Alert className={`status status--message status--error ${className || ''}`} variant="danger">{errorMessage}</Alert>;
  }

  if (loading) {
    return <Spinner className={`status status--spinner status--loading ${className || ''}`} animation="border" variant="primary" />;
  }

  if (success) {
    return <Alert className={`status status--message status--success ${className || ''}`} variant="success">{success}</Alert>;
  }

  if (info) {
    return <Alert className={`status status--message status--info ${className || ''}`} variant="info">{info}</Alert>;
  }

  return null;
}
