import React, { PropsWithChildren } from 'react';
import './select.layout.scss';
import logo from './all_logo_color.png';
import classNames from 'classnames';
import { EInstructionLabel } from 'shared';
import { InstructionLabel } from '../utils/instruction-label.component';
import { EIcon, Icon } from '../utils/icon.component';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../auth/auth.service';
import { EMainRouterPath } from '../../router-path';

export interface SelectLayoutProps {
  actionInstructionLabel: EInstructionLabel;
  actionAlign?: 'start'|'center';
  actionRecording?: string;
  backPath?: string;
}

export function SelectLayout({ actionInstructionLabel, actionAlign, children, backPath }: PropsWithChildren<SelectLayoutProps>): JSX.Element {
  const navigate = useNavigate();


  const handleSignOut = () => {
    AuthService.deletePupilAccessToken();
    navigate(EMainRouterPath.SELECT_SCHOOL.fullPath());
  };

  const renderBackButton = () => {
    if (!backPath) {
      return <span className="select-layout__header-back-button-placeholder" />;
    }

    return <button className="select-layout__header-back-button" onClick={() => navigate(backPath)}>
      <Icon>
        {EIcon.ARROW_LEFT}
      </Icon>
    </button>;
  };

  return <div className="select-layout">
    <div className="select-layout__header">
      {renderBackButton()}
      <img className="select-layout__logo" src={logo} alt="Actief Leren Lezen" />
      {AuthService.pupilIsAuthorized() &&
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <button className="select-layout__signout-button" onClick={() => handleSignOut()}>
          <Icon>{EIcon.LOG_OUT}</Icon>
        </button>
      }
    </div>
    <div className={classNames('select-layout__action', { [`select-layout__action--${actionAlign || ''}`]: actionAlign })}>
      <h2 className="select-layout__action-text"><InstructionLabel instructionKey={actionInstructionLabel} /></h2>
    </div>
    <div className="select-layout__content">{children}</div>
  </div>;
}
