import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { EExerciseType, VideoExercise, VideoExerciseData } from 'shared';
import { IExerciseEditorProps } from './exercise-editor.component';
import { VideoDisplay } from './video-display.component';
import { Box } from '../../../../common/utils/box.component';
import { getExerciseTypeTitle } from '../../../../common/utils/exercise-type.component';
import { DangerButton } from '../../../../common/utils/danger-button.component';
import { EIcon } from '../../../../common/utils/icon.component';
import { useTranslation } from 'react-i18next';

export const VideoEditor = ({ value, onChange, onDelete }: IExerciseEditorProps<VideoExercise>): JSX.Element => {
  const data: VideoExerciseData = value.data ?? {};
  const [url, setUrl] = useState(data.url);
  const { t } = useTranslation();

  const handleInputUrl = (value: string) => setUrl(value.toLowerCase().trimStart());

  const handleDelete = () => {
    onDelete({ ...value });
  };

  useEffect(() => {
    onChange({
      ...value,
      data: {
        url,
      }
    });
  }, [url]);

  const renderBoxHeader = (handleDelete: (index?: number) => void, subject: string): JSX.Element => <>
    <DangerButton
      icon={EIcon.MINUS_CIRCLE}
      onClick={handleDelete}
      modalText={t('common:confirm-delete', { subject: t('common:this-item') })}
      modalCancelLabel={t('common:keep')}
      modalConfirmLabel={t('common:delete')}
    >{subject}</DangerButton>
  </>;

  return (
    <Box title={getExerciseTypeTitle({ value: EExerciseType.VIDEO, upper: true })} headerContent={renderBoxHeader(handleDelete, t('video:delete'))}>
      <Row>
        <Col>
          <Form.Label>{t('video:url')}</Form.Label>
          <Form.Control
            type="text"
            placeholder="https://vimeo.com/123456789"
            required
            value={url}
            onChange={e => handleInputUrl(e.target.value)} />
        </Col>
        <Col>
          { url && <VideoDisplay url={url} /> }
        </Col>
      </Row>
    </Box>
  );
};
