"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EInstructionLabel = void 0;
var EInstructionLabel;
(function (EInstructionLabel) {
    // Pupil login
    EInstructionLabel["KIES_JOUW_KLAS"] = "kies-jouw-klas";
    EInstructionLabel["KIES_EEN_LES"] = "kies-een-les";
    EInstructionLabel["WIE_BEN_JIJ"] = "wie-ben-jij";
    EInstructionLabel["WAT_IS_JOUW_WACHTWOORD"] = "wat-is-jouw-wachtwoord";
    EInstructionLabel["VOER_JOUW_CODE_IN"] = "voer-jouw-code-in";
    // Select lesson
    EInstructionLabel["MET_WELKE_LES_GA_JIJ_VERDER"] = "met-welke-les-ga-jij-verder";
    EInstructionLabel["BIJ_WELK_BOEK_BEN_JIJ"] = "bij-welk-boek-ben-jij";
    EInstructionLabel["KIES_EEN_WOORDENSCHAT"] = "kies-een-woordenschat";
    EInstructionLabel["PAK_JE_BOEK"] = "pak-je-boek";
    // Word towers / blocks
    EInstructionLabel["DOEL_VAN_DE_LES"] = "doel-van-de-les";
    EInstructionLabel["LETTERS"] = "letters";
    EInstructionLabel["WOORDEN"] = "woorden";
    EInstructionLabel["LEESTEKENS"] = "leestekens";
    EInstructionLabel["HERHAAL_DEZE_LETTER"] = "herhaal-deze-letter";
    EInstructionLabel["HERHAAL_DIT_WOORD"] = "herhaal-dit-woord";
    // Reading Comprehension
    EInstructionLabel["UITLEG_VAN_DE_LES"] = "uitleg-van-de-les";
    EInstructionLabel["KLIK_OP_ONDERSTREEPT_WOORD_UITLEG"] = "klik-op-onderstreept-woord-uitleg";
    // Videos (Sterke lezer)
    EInstructionLabel["MAAK_OPDRACHT_UIT_WERKBOEK"] = "maak-opdracht-uit-werkboek";
    // Global
    EInstructionLabel["GOED_GEDAAN"] = "goed-gedaan";
})(EInstructionLabel = exports.EInstructionLabel || (exports.EInstructionLabel = {}));
