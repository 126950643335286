import Modal from 'react-bootstrap/Modal';
import { PropsWithChildren, useState } from 'react';

interface Modal extends PropsWithChildren{
    title: string;
}

export function ModalComponent({ children, title }: Modal): JSX.Element {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="modal-link" onClick={handleShow}>
        Meer info
      </button>
      <Modal className="big-modal modal-info" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
}
