"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExerciseStepsCount = exports.exerciseSchema = exports.exerciseLimitReached = exports.EXERCISE_LIMITS = exports.EExerciseType = exports.WORD_BLOCK_REPEAT_COUNT = exports.WORD_TOWER_REPEAT_COUNT = void 0;
var Joi = __importStar(require("joi"));
var exercise_hidden_objects_game_1 = require("./exercise-hidden-objects-game");
var exercise_video_1 = require("./exercise-video");
var exercise_reading_comprehension_transcript_1 = require("./exercise-reading-comprehension-transcript");
var exercise_word_towers_1 = require("./exercise-word-towers");
var exercise_reading_comprehension_text_1 = require("./exercise-reading-comprehension-text");
exports.WORD_TOWER_REPEAT_COUNT = 1;
exports.WORD_BLOCK_REPEAT_COUNT = 1;
var EExerciseType;
(function (EExerciseType) {
    EExerciseType["WORD_TOWER"] = "word-tower";
    EExerciseType["WORD_BLOCK"] = "word-block";
    EExerciseType["HIDDEN_OBJECTS_GAME"] = "hidden-objects-game";
    EExerciseType["VIDEO"] = "video";
    EExerciseType["READING_COMPREHENSION_TRANSCRIPT"] = "reading-comprehension";
    EExerciseType["READING_COMPREHENSION_TEXT"] = "reading-comprehension-text";
})(EExerciseType = exports.EExerciseType || (exports.EExerciseType = {}));
exports.EXERCISE_LIMITS = (_a = {},
    _a[EExerciseType.VIDEO] = 1,
    _a[EExerciseType.READING_COMPREHENSION_TRANSCRIPT] = 1,
    _a[EExerciseType.READING_COMPREHENSION_TEXT] = 1,
    _a);
function exerciseLimitReached(items, type) {
    return items.filter(function (item) { return item.type === type; }).length >= exports.EXERCISE_LIMITS[type];
}
exports.exerciseLimitReached = exerciseLimitReached;
exports.exerciseSchema = Joi.object({
    id: Joi.string()
        .uuid()
        .required(),
    type: Joi.string()
        .allow(EExerciseType.HIDDEN_OBJECTS_GAME, EExerciseType.WORD_TOWER, EExerciseType.WORD_BLOCK, EExerciseType.VIDEO, EExerciseType.READING_COMPREHENSION_TRANSCRIPT, EExerciseType.READING_COMPREHENSION_TEXT)
        .only()
        .required(),
    data: Joi.when('type', {
        switch: [
            { is: EExerciseType.HIDDEN_OBJECTS_GAME, then: exercise_hidden_objects_game_1.hiddenObjectsGameExerciseDataSchema },
            { is: EExerciseType.WORD_TOWER, then: exercise_word_towers_1.wordTowerExerciseDataSchema },
            { is: EExerciseType.WORD_BLOCK, then: exercise_word_towers_1.wordTowerExerciseDataSchema },
            { is: EExerciseType.VIDEO, then: exercise_video_1.videoExerciseDataSchema },
            { is: EExerciseType.READING_COMPREHENSION_TRANSCRIPT, then: exercise_reading_comprehension_transcript_1.readingComprehensionTranscriptExerciseDataSchema },
            { is: EExerciseType.READING_COMPREHENSION_TEXT, then: exercise_reading_comprehension_text_1.readingComprehensionTextExerciseDataSchema },
        ],
    }).optional(),
});
function getWordTowerExerciseSteps(exercise) {
    var _a, _b;
    return (((_b = (_a = exercise.data) === null || _a === void 0 ? void 0 : _a.wordParts) === null || _b === void 0 ? void 0 : _b.length) || 0) * exports.WORD_TOWER_REPEAT_COUNT; // Every word tower is repeated WORD_TOWER_REPEAT_COUNT times
}
function getWordBlockExerciseStep(exercise) {
    var _a, _b;
    return (((_b = (_a = exercise.data) === null || _a === void 0 ? void 0 : _a.wordParts) === null || _b === void 0 ? void 0 : _b.length) || 0) * exports.WORD_BLOCK_REPEAT_COUNT; // Every word tower is repeated WORD_BLOCK_REPEAT_COUNT times
}
function getReadingComprehensionTranscriptSteps(exercise) {
    var _a, _b;
    return (1 + (((_b = (_a = exercise.data) === null || _a === void 0 ? void 0 : _a.questions) === null || _b === void 0 ? void 0 : _b.length) || 1));
}
function getExerciseStepsCount(exercise) {
    switch (exercise.type) {
        case EExerciseType.READING_COMPREHENSION_TEXT:
        case EExerciseType.HIDDEN_OBJECTS_GAME:
        case EExerciseType.VIDEO:
            return 1;
        case EExerciseType.WORD_TOWER:
            return getWordTowerExerciseSteps(exercise);
        case EExerciseType.WORD_BLOCK:
            return getWordBlockExerciseStep(exercise);
        case EExerciseType.READING_COMPREHENSION_TRANSCRIPT:
            return getReadingComprehensionTranscriptSteps(exercise);
        default:
            throw new Error('getExerciseStepsCount got an unexpected exercise type');
    }
}
exports.getExerciseStepsCount = getExerciseStepsCount;
