import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import './box.component.scss';

interface IBoxProps {
  className?: string;
  title?: string;
  afterTitle?: JSX.Element;
  headerContent?: JSX.Element;
}

export function Box({ className, title, afterTitle, headerContent, children }: PropsWithChildren<IBoxProps>): JSX.Element {
  return <div className={classNames('box', className)}>
    <div className="box__header">
      { title && <h3 className="box__title">{title} {afterTitle}</h3> }
      <div className="box__header-content">{headerContent}</div>
    </div>
    <div className="box__body">{children}</div>
  </div>;
}

interface IBoxDividerProps {
  className?: string;
  title?: string;
  content?: JSX.Element;
}

export function BoxDivider({ className, title, content }: IBoxDividerProps): JSX.Element {
  return <div className={classNames('box-divider', { 'box-divider--no-title': !title }, className)}>
    { title && <h4 className="box-divider__title">{title}</h4> }
    { content && <div className="box-divider__content">{content}</div> }
  </div>;
}
