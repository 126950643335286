// file deepcode ignore RegExpBadCharRange: Regex pattern seems to be used in many places & thus should be very carefully replaced at another time.
import React, { FormEvent, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Alert from 'react-bootstrap/esm/Alert';
import Form from 'react-bootstrap/esm/Form';
import { Api } from '../../common/api/api';
import { useParams } from 'react-router-dom';
import { ApiException, School } from '../../common/api/types';
import { useTranslation } from 'react-i18next';
import { ERecordingLanguage, ESchoolSubscription } from 'shared';
import { Box } from '../../common/utils/box.component';
import { EditSchoolInputs } from './create';
import { Status } from '../../common/utils/status.component';
import { useQueryClient, useQuery } from 'react-query';
import { SchoolAdminList } from './school-admin-list.component';
import { toast } from 'react-toastify';

function EditSchoolForm({ school, onChange }: { school: School, onChange: (school: School) => void }): JSX.Element {
  const [error, setError] = useState<string|null>(null);

  const [schoolName, setSchoolName] = useState<string>(school.name);
  const [friendlyId, setFriendlyId] = useState<string>(school.friendlyId);
  const [manualFriendlyId, setManualFriendlyId] = useState<boolean>(true);
  const [contactName, setContactName] = useState<string>(school.contactName || '');
  const [contactEmail, setContactEmail] = useState<string>(school.contactEmail || '');
  const [subscriptionType, setSubscriptionType] = useState<ESchoolSubscription>(school.subscriptionType || ESchoolSubscription.TRIAL);
  const [subscriptionEndAt, setSubscriptionEndAt] = useState<string>(school.subscriptionEndAt?.toISOString().substring(0, 10) || '');
  const [customSubscription, setCustomSubscription] = useState<boolean>(!!school.subscriptionEndAt);
  const [language, setLanguage] = useState<ERecordingLanguage>(school.language);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleError = (err: any): void => {
    if (err instanceof ApiException) {
      setError(err.message);
      return;
    }

    console.error('Unexpected CreateSchool error', err);
    setError('An unknown error occurred');
  };

  const handleSubmit = async(event: FormEvent): Promise<void> => {
    event.preventDefault();

    setSubmitting(true);
    try {
      const subscriptionEndAtValue = customSubscription && subscriptionEndAt ? new Date(subscriptionEndAt) : undefined;
      const newSchool = await Api.updateSchool(school.id, { name: schoolName.trim(), friendlyId, contactName: contactName.trim(), contactEmail, subscriptionType, subscriptionEndAt: subscriptionEndAtValue, language });
      onChange(newSchool);
      toast.success('School geüpdatet');
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return <>
    <Form onSubmit={(e) => {
      handleSubmit(e).catch(console.error);
    }}>
      <EditSchoolInputs
        schoolName={schoolName}
        setSchoolName={setSchoolName}
        friendlyId={friendlyId}
        setFriendlyId={setFriendlyId}
        contactName={contactName}
        setContactName={setContactName}
        contactEmail={contactEmail}
        setContactEmail={setContactEmail}
        manualFriendlyId={manualFriendlyId}
        setManualFriendlyId={setManualFriendlyId}
        subscriptionType={subscriptionType}
        setSubscriptionType={setSubscriptionType}
        subscriptionEndAt={subscriptionEndAt}
        setSubscriptionEndAt={setSubscriptionEndAt}
        customSubscription={customSubscription}
        setCustomSubscription={setCustomSubscription}
        language={language}
        setLanguage={setLanguage}
      />

      { error && <Alert variant="danger">{error}</Alert>}
      <Button variant="primary" type="submit" disabled={submitting}>{t('common:save')}</Button>
    </Form>
  </>;
}

export function ShowSchool(): JSX.Element {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { data: school, error, isLoading } = useQuery(['school', id], () => {
    if (!id) {
      return Promise.reject(new Error('Missing school id'));
    }
    return Api.getSchool(+id);
  });

  const handleChange = () => {
    queryClient.invalidateQueries(['school', id])
      .catch(console.error);
  };

  if (error || isLoading || !school) {
    return <Status loading={isLoading} error={error} />;
  }

  return <>
    <EditSchoolForm school={school} onChange={handleChange} />
    <br/>
    <Box title="Admins">
      <SchoolAdminList schoolId={school.id} />
    </Box>
  </>;
}
