import { ELessonType, ELessonLevel, ELessonCategory } from 'shared';

function convertLessonType(value: ELessonType): string {
  switch (value) {
    case ELessonType.WORD_TOWER: return 'woordtorentje les';
    case ELessonType.REPETITION: return 'herhalingsles';
    case ELessonType.READING_COMPREHENSION: return 'begrijpend lezen';
    case ELessonType.TEST: return 'toets';
    case ELessonType.LIBRARY: return 'bibliotheek';
    case ELessonType.WORD_ROWS: return 'woordrijen';
    default: return value;
  }
}

export function capitalizeFirstLetter(value: string): string {
  if (value.length === 0) {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function lessonTypeToString(value: ELessonType, { upper }: { upper?: boolean } = {}): string {
  if (upper) {
    const converted = convertLessonType(value);
    return capitalizeFirstLetter(converted);
  }
  return convertLessonType(value);
}

function convertLessonLevel(value: ELessonLevel): string {
  switch (value) {
    case ELessonLevel.LEVEL_3: return 'groep 3';
    default: return value;
  }
}

export function lessonLevelToString(value: ELessonLevel, { upper }: { upper?: boolean } = {}): string {
  if (upper) {
    const converted = convertLessonLevel(value);
    return capitalizeFirstLetter(converted);
  }
  return convertLessonLevel(value);
}

export function buildLessonTitle(...parts: (string|ELessonCategory)[]): string {
  let convertedParts = '';

  parts.forEach(part => {
    switch (part) {
      case ELessonCategory.STRONG_READER:
      case ELessonCategory.DECODING:
      case ELessonCategory.READING_COMPREHENSION:
        convertedParts += 'les';
        break;
      case ELessonCategory.VOCABULARY:
        convertedParts += 'woordplaat';
        break;
      default:
        convertedParts += part;
        break;
    }
  });

  return capitalizeFirstLetter(convertedParts);
}

export function buildLessonString(...parts: (string|ELessonCategory)[]): string {
  return buildLessonTitle(...parts);
}
