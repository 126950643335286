/* eslint-disable no-console */
/* eslint-disable camelcase */
import superagent from 'superagent';
import { EMainRouterPath } from '../../router-path';

// https://aws.amazon.com/blogs/mobile/understanding-amazon-cognito-user-pool-oauth-2-0-grants/

const authDomain = process.env.REACT_APP_AWS_AUTH_DOMAIN || '';
const clientId = process.env.REACT_APP_AWS_CLIENT_ID || '';
const redirectUri = `${process.env.REACT_APP_BASE_PATH || ''}${EMainRouterPath.AUTH_CALLBACK.path}`;

interface ITokenResponse {
  access_token: string;
  refresh_token: string;
  id_token: string;
  token_type: 'Bearer';
  expires_in: number;
}

interface IRenewTokenResponse {
  access_token: string;
  id_token: string;
  token_type: 'Bearer';
  expires_in: number;
}

export class UnknownAuthError extends Error {
  constructor() {
    super('UnknownAuthError');
  }
}

export class TokenRevokedError extends Error {
  constructor() {
    super('Refresh token has been revoked. Authorization code has been consumed already or does not exist.');
  }
}

export class NoTokenError extends Error {
  constructor() {
    super('NoTokenError');
  }
}

export class AccessTokenExpiredError extends Error {
  constructor() {
    super('AccessTokenExpiredError');
  }
}

export class RefreshTokenExpiredError extends Error {
  constructor() {
    super('RefreshTokenExpiredError');
  }
}

const SESSION_STORAGE_ACCESS_TOKEN = 'accessToken';
const SESSION_STORAGE_ACCESS_TOKEN_EXPIRE_DATE = 'accessTokenExpireDate';
const LOCAL_STORAGE_REFRESH_TOKEN = 'refreshToken';
export const SESSION_STORAGE_PUPIL_TOKEN = 'pupilAccessToken';

export type AuthorizationChangedCallback = (isAuthorized: boolean) => void;

export abstract class AuthService {
  private static authorizationChangedCallback: AuthorizationChangedCallback[] = [];

  static registerAuthorizationChangedCallback(callback: AuthorizationChangedCallback): void {
    AuthService.authorizationChangedCallback.push(callback);
  }

  static removeAuthorizationChangedCallback(callback: AuthorizationChangedCallback): void {
    const index = AuthService.authorizationChangedCallback.indexOf(callback);
    if (index > -1) {
      AuthService.authorizationChangedCallback.splice(index, 1);
    }
  }

  private static handleAuthorizationChanged(isAuthorized: boolean): void {
    AuthService.authorizationChangedCallback.forEach(callback => {
      callback(isAuthorized);
    });
  }

  static isAuthorized(): boolean {
    try {
      return !!AuthService.retrieveRefreshToken();
    } catch {
      return false;
    }
  }

  static logout(): void {
    AuthService.deleteAccessToken();
    AuthService.deleteRefreshToken();
  }

  static pupilLogout(): void {
    AuthService.deletePupilAccessToken();
  }

  static getAuthInterfaceUrl({ returnPath }: { returnPath?: string }): string {
    // state is used to pass the returnPath to auth callback page
    return `${authDomain}/login?client_id=${clientId}&response_type=code&scope=email+openid&redirect_uri=${encodeURIComponent(redirectUri)}&state=${btoa(returnPath || '')}`;
  }

  static async saveTokensFromAuthCode(code: string): Promise<void> {
    try {
      const tokens = await AuthService.getTokensFromCode(code);

      AuthService.storeTokens(tokens.access_token, tokens.expires_in, tokens.refresh_token);
    } catch (error) {
      console.error('getCredentials error', error);
    }
  }

  static async getAccessToken(): Promise<string> {
    try {
      return Promise.resolve(AuthService.retrieveAccessToken());
    } catch (err) {
      if (!(err instanceof AccessTokenExpiredError)) {
        throw err;
      }
    }

    // Token expired

    try {
      const refreshToken = AuthService.retrieveRefreshToken();
      const tokens = await AuthService.getTokensFromRefreshToken(refreshToken);
      AuthService.storeTokens(tokens.access_token, tokens.expires_in, refreshToken);
      return tokens.access_token;
    } catch (err) {
      AuthService.deleteRefreshToken();
      throw new NoTokenError();
    }
  }

  private static storeTokens(accessToken: string, expiresIn: number, refreshToken: string): void {
    const expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + expiresIn);

    sessionStorage.setItem(SESSION_STORAGE_ACCESS_TOKEN, accessToken);
    sessionStorage.setItem(SESSION_STORAGE_ACCESS_TOKEN_EXPIRE_DATE, expiresAt.toISOString());
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);

    AuthService.handleAuthorizationChanged(true);
  }

  private static deleteAccessToken(): void {
    sessionStorage.removeItem(SESSION_STORAGE_ACCESS_TOKEN);
    sessionStorage.removeItem(SESSION_STORAGE_ACCESS_TOKEN_EXPIRE_DATE);
  }

  private static deleteRefreshToken(): void {
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);

    AuthService.handleAuthorizationChanged(false);
  }

  static deletePupilAccessToken(): void {
    sessionStorage.removeItem(SESSION_STORAGE_PUPIL_TOKEN);
  }

  public static pupilIsAuthorized(): boolean {
    return !!sessionStorage.getItem(SESSION_STORAGE_PUPIL_TOKEN);
  }

  private static retrieveAccessToken(): string {
    const accessToken = sessionStorage.getItem(SESSION_STORAGE_ACCESS_TOKEN);
    const accessTokenExpireDate = sessionStorage.getItem(SESSION_STORAGE_ACCESS_TOKEN_EXPIRE_DATE);

    const accessTokenExpired = !accessToken || !accessTokenExpireDate || accessTokenExpireDate < new Date().toISOString();
    if (!accessToken || accessTokenExpired) {
      AuthService.deleteAccessToken();
      throw new AccessTokenExpiredError();
    }

    return accessToken;
  }

  private static retrieveRefreshToken(): string {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);

    if (!refreshToken) {
      throw new RefreshTokenExpiredError();
    }
    return refreshToken;
  }

  private static getTokensFromCode(code: string): Promise<ITokenResponse> {
    return superagent.post(`${authDomain}/oauth2/token`)
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .send('grant_type=authorization_code')
      .send(`code=${code}`)
      .send(`client_id=${clientId}`)
      .send(`redirect_uri=${redirectUri}`)
      .then(result => JSON.parse(result.text) as ITokenResponse)
      .catch(err => {
        throw AuthService.mapError(err);
      });
  }

  private static getTokensFromRefreshToken(refreshToken: string): Promise<IRenewTokenResponse> {
    return superagent.post(`${authDomain}/oauth2/token`)
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .send('grant_type=refresh_token')
      .send(`client_id=${clientId}`)
      .send(`refresh_token=${refreshToken}`)
      .then(result => JSON.parse(result.text) as IRenewTokenResponse)
      .catch(err => {
        throw AuthService.mapError(err);
      });
  }

  private static mapError(err: any): Error|any {
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    const errorBody = err.response?.text as string;
    try {
      const errorObject = JSON.parse(errorBody) as { error: string };
      if (errorObject.error) {
        switch (errorObject.error) {
          case 'invalid_grant':
            return new TokenRevokedError();
          case 'invalid_client':
            return new Error('App auth environment misconfigured, client invalid');
          case 'unauthorized_client':
            return new Error('App auth environment misconfigured, client unauthorized');
          case 'unsupported_grant_type':
          case 'invalid_request':
            return new Error(`Auth request was invalid ${errorObject.error}`);
          default:
            return new UnknownAuthError();
        }
      }
    } catch (parseError) {
      // Unknown error
    }
    return err; // eslint-disable-line @typescript-eslint/no-unsafe-return
  }
}
