"use strict";
// Migrate database if you change the enums
Object.defineProperty(exports, "__esModule", { value: true });
exports.EUserCapability = void 0;
var EUserCapability;
(function (EUserCapability) {
    EUserCapability["MANAGE_ADMIN_USERS"] = "manage_admin_users";
    EUserCapability["MANAGE_SCHOOL_USERS"] = "manage_school_users";
    EUserCapability["MANAGE_SCHOOLS"] = "manage_schools";
    EUserCapability["MANAGE_LESSONS"] = "manage_lessons";
    EUserCapability["MANAGE_INSTRUCTION_LABELS"] = "manage_instruction_labels";
    EUserCapability["UPLOAD_FILES"] = "upload_files";
    EUserCapability["VIEW_USAGE"] = "view_usage";
    EUserCapability["UPDATE_USAGE"] = "update_usage";
})(EUserCapability = exports.EUserCapability || (exports.EUserCapability = {}));
