import { EPupilAvatar } from 'shared';
import lamp from '../assets/lamp.jpg'; // fallback

import bell from '../assets/avatars/bell.png';
import boot from '../assets/avatars/boot.png';
import bunch from '../assets/avatars/bunch.png';
import choir from '../assets/avatars/choir.png';
import cup from '../assets/avatars/cup.png';
import deer from '../assets/avatars/deer.png';
import dent from '../assets/avatars/dent.png';
import door from '../assets/avatars/door.png';
import fence from '../assets/avatars/fence.png';
import fin from '../assets/avatars/fin.png';
import fire from '../assets/avatars/fire.png';
import flame from '../assets/avatars/flame.png';
import flute from '../assets/avatars/flute.png';
import foot from '../assets/avatars/foot.png';
import fox from '../assets/avatars/fox.png';
import fruit from '../assets/avatars/fruit.png';
import ghost from '../assets/avatars/ghost.png';
import glass from '../assets/avatars/glass.png';
import goat from '../assets/avatars/goat.png';
import hop from '../assets/avatars/hop.png';
import hour from '../assets/avatars/hour.png';
import house from '../assets/avatars/house.png';
import hut from '../assets/avatars/hut.png';
import nest from '../assets/avatars/nest.png';
import pants from '../assets/avatars/pants.png';
import paw from '../assets/avatars/paw.png';
import peacock from '../assets/avatars/peacock.png';
import pig from '../assets/avatars/pig.png';
import road from '../assets/avatars/road.png';
import sea from '../assets/avatars/sea.png';
import seesaw from '../assets/avatars/seesaw.png';
import stool from '../assets/avatars/stool.png';
import sun from '../assets/avatars/sun.png';
import sweep from '../assets/avatars/sweep.png';
import uncle from '../assets/avatars/uncle.png';
import wall from '../assets/avatars/wall.png';
import wasp from '../assets/avatars/wasp.png';
import well from '../assets/avatars/well.png';
import wig from '../assets/avatars/wig.png';
import witch from '../assets/avatars/witch.png';

const avatarFallbackPath = lamp;
const avatarPathMap: { [avatar: string]: string } = {
  [EPupilAvatar.BELL]: bell,
  [EPupilAvatar.BOOT]: boot,
  [EPupilAvatar.BUNCH]: bunch,
  [EPupilAvatar.CHOIR]: choir,
  [EPupilAvatar.CUP]: cup,
  [EPupilAvatar.DEER]: deer,
  [EPupilAvatar.DENT]: dent,
  [EPupilAvatar.DOOR]: door,
  [EPupilAvatar.FENCE]: fence,
  [EPupilAvatar.FIN]: fin,
  [EPupilAvatar.FIRE]: fire,
  [EPupilAvatar.FLAME]: flame,
  [EPupilAvatar.FLUTE]: flute,
  [EPupilAvatar.FOOT]: foot,
  [EPupilAvatar.FOX]: fox,
  [EPupilAvatar.FRUIT]: fruit,
  [EPupilAvatar.GHOST]: ghost,
  [EPupilAvatar.GLASS]: glass,
  [EPupilAvatar.GOAT]: goat,
  [EPupilAvatar.HOP]: hop,
  [EPupilAvatar.HOUR]: hour,
  [EPupilAvatar.HOUSE]: house,
  [EPupilAvatar.HUT]: hut,
  [EPupilAvatar.NEST]: nest,
  [EPupilAvatar.PANTS]: pants,
  [EPupilAvatar.PAW]: paw,
  [EPupilAvatar.PEACOCK]: peacock,
  [EPupilAvatar.PIG]: pig,
  [EPupilAvatar.ROAD]: road,
  [EPupilAvatar.SEA]: sea,
  [EPupilAvatar.SEESAW]: seesaw,
  [EPupilAvatar.STOOL]: stool,
  [EPupilAvatar.SUN]: sun,
  [EPupilAvatar.SWEEP]: sweep,
  [EPupilAvatar.UNCLE]: uncle,
  [EPupilAvatar.WALL]: wall,
  [EPupilAvatar.WASP]: wasp,
  [EPupilAvatar.WELL]: well,
  [EPupilAvatar.WIG]: wig,
  [EPupilAvatar.WITCH]: witch,
};

export function getAvatarPath(avatar: EPupilAvatar): string {
  return avatarPathMap[avatar] || avatarFallbackPath;
}
