import React, { PropsWithChildren } from 'react';
import { Api } from '../api/api';
import { useQuery } from 'react-query';
import { Status } from '../utils/status.component';
import { InstructionLabelsContext } from './instruction-labels.context';

export function InstructionLabelsProvider({ children }: PropsWithChildren<any>): JSX.Element {
  const { data: instructionLabels, error } = useQuery(['instruction-labels'], Api.listInstructionLabels);

  if (!instructionLabels || error) {
    return <Status loading={true} error={error} />;
  }

  return (
    <InstructionLabelsContext.Provider value={instructionLabels}>
      {children}
    </InstructionLabelsContext.Provider>
  );
}
