/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useSchool } from '../school-provider/school.context';
import { AuthService } from '../auth/auth.service';
import { useSession } from '../auth/session.context';
import { EIcon, Icon } from './icon.component';
import { Status } from './status.component';
import './user-menu.component.scss';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EMainRouterPath } from '../../router-path';

export function UserMenu(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthorized, user, userOfSchools, isAdmin } = useSession();
  const { school } = useSchool();
  const hasMultipleSchools = userOfSchools.length > 1;

  const companyName = school ? school.name : t('common:company');
  const switchButtonText = isAdmin ? t('common:switch-module') : t('common:select-school');

  const renderUser = (): JSX.Element|null => {
    if (isAuthorized && user) {
      return <div className="user-menu__user">
        <Icon className="user-menu__icon">{EIcon.USER}</Icon>
        <Dropdown align="end">
          <Dropdown.Toggle
            variant="link"
            title={t('common:user-menu')}
            className="mt-2"
          >
            <div className="user-menu__user-info">
              <span className="user-menu__user-name">{user.firstName} {user.lastName}</span>
              <span className="user-menu__user-company">{companyName}<Icon className="user-menu__toggle-icon">{EIcon.CHEVRON_DOWN}</Icon></span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            { (hasMultipleSchools || isAdmin) && <Dropdown.Item onClick={() => navigate(EMainRouterPath.AUTH.path)}>
              {switchButtonText}
            </Dropdown.Item> }
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => AuthService.logout()}>
              <Icon>{ EIcon.LOGOUT }</Icon> {t('common:sign-out')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>;
    }
    return null;
  };

  return <div className="user-menu">
    <Status loading={isAuthorized && !user} />
    { renderUser() }
  </div>;
}
