import React, { useEffect, useMemo, useState } from 'react';
import { ILesson, HiddenObjectsGameExercise, Recording, HiddenObjectsGameData, HiddenObjectsGameTile, HiddenObjectsGameScoreBoard, EInstructionLabel } from 'shared';
import { IExerciseProps } from '..';
import { HiddenObjectsGameDisplay } from '../../../admin-module/lesson/lesson-document-editor/exercises/hidden-objects-game-display.component';
import { Status } from '../../../common/utils/status.component';
import { VocabularyLayout } from './vocabulary-layout';
import './show-hidden-objects-game.scss';
import { playSingleAudio } from '../../../common/utils/audio.helper';
import { RecordingPlayer } from '../../../common/utils/recording-player.component';
import { EIcon } from '../../../common/utils/icon.component';
import { RoundButton } from '../../../common/utils/round-button.component';
import { getStorageUrl } from '../../../common/utils/storage.helper';
import { useDeveloperByPassMode } from '../../../common/utils/developer-bypass';
import finished01 from './finished_01.png';
import finished02 from './finished_02.png';
import { InstructionLabel } from '../../../common/utils/instruction-label.component';
import { usePupil } from '../../../common/pupil-provider/pupil.context';
import { resolveRecordingItem } from '../../../common/utils/recording-resolver';

const FINISHED_IMAGES = [finished01, finished02];

function SuccessOverlay({ tile, onClose }: { tile: HiddenObjectsGameTile, onClose: () => void }): JSX.Element {
  const { language } = usePupil();
  return <section className="hidden-objects-game-overlay">
    <div className="hidden-objects-game-overlay__box">
      <h1 className="hidden-objects-game-overlay__title">{tile.correctFeedback}</h1>
      <div className="hidden-objects-game-overlay__image-container">
        <img className="hidden-objects-game-overlay__image" src={tile.realisticImage && getStorageUrl(tile.realisticImage.mediumFilename)} alt={tile.word} />
        <RecordingPlayer className="hidden-objects-game-overlay__word" recording={tile.recordingWord} language={language}>{tile.word}</RecordingPlayer>
      </div>
      <RoundButton icon={EIcon.CHEVRON_RIGHT} className="hidden-objects-game-overlay__close-button" variant="success" showLabel={true} onClick={onClose}>Ga door</RoundButton>
    </div>
  </section>;
}

const scoreBoard = new HiddenObjectsGameScoreBoard();

function InteractiveDisplay({ lessonId, data, onFinished }: { lessonId: number, data: HiddenObjectsGameData, onFinished: (result: string) => void }): JSX.Element {
  const { language } = usePupil();
  const [selectedTileIndex, setSelectedTileIndex] = useState<number|null>(null);
  const [guessedTileIndex, setGuessedTileIndex] = useState<number|null>(null);
  const [completedTileIndexes, setCompletedTileIndexes] = useState<number[]>([]);
  const developerBypassMode = useDeveloperByPassMode();
  const { introRecording, image, tiles } = data;
  const selectedTile = tiles && selectedTileIndex !== null ? tiles[selectedTileIndex] : null;
  const guessedTile = tiles && guessedTileIndex !== null ? tiles[guessedTileIndex] : null;

  const playRecording = (recording?: Recording): void => {
    const recordingItem = resolveRecordingItem(recording, language);
    if (!recordingItem) {
      return;
    }

    playSingleAudio(recordingItem)
      .catch(err => console.error('Failed to play recording', err));
  };

  useEffect(() => { // handleLoad
    playRecording(introRecording);
    scoreBoard.resetGameResult();
  }, [lessonId]);

  useEffect(() => { // handleSelectTile
    if (!selectedTile) {
      return;
    }
    playRecording(selectedTile.questionRecording);

    if (developerBypassMode) {
      // eslint-disable-next-line no-use-before-define
      handleClickImage(true);
    }
  }, [selectedTileIndex]);

  useEffect(() => { // handleFinish
    if (guessedTileIndex) {
      // Don't finished while the overlay is still visible
      return;
    }

    if (!tiles) {
      return;
    }

    if (completedTileIndexes.length >= tiles.length) {
      onFinished(scoreBoard.getResult());
    }
  }, [completedTileIndexes, guessedTileIndex]);

  const handleClickImage = (clickedHitbox: boolean): void => {
    if (!selectedTile || selectedTileIndex === null) {
      return;
    }

    if (clickedHitbox) {
      scoreBoard.registerCorrectClick(selectedTile.word || 'unknown');
      if (selectedTile.correctFeedbackRecording) {
        playRecording(selectedTile.correctFeedbackRecording);
      }
      if (!developerBypassMode) {
        setGuessedTileIndex(selectedTileIndex);
      }
      setCompletedTileIndexes([...completedTileIndexes, selectedTileIndex]);
    } else {
      scoreBoard.registerFailedClick(selectedTile.word || 'unknown');
      if (!selectedTile.wrongFeedbackRecording || selectedTile.wrongFeedbackRecording.length === 0) {
        return;
      }
      const randomIndex = Math.floor(Math.random() * selectedTile.wrongFeedbackRecording.length);
      playRecording(selectedTile.wrongFeedbackRecording[randomIndex]);
    }
  };

  const handleCloseOverlay = (): void => {
    setGuessedTileIndex(null);
    setSelectedTileIndex(null);
  };

  const handleClickTile = (index: number): void => {
    if (completedTileIndexes.includes(index)) {
      // Don't allow the user to select completed tiles
      return;
    }

    if (selectedTileIndex === index) {
      return;
    }

    setSelectedTileIndex(index);
  };

  return <>
    <HiddenObjectsGameDisplay
      image={image || null}
      tiles={tiles || []}
      randomizeTiles={true}
      selectedIndex={selectedTileIndex}
      validTileIndexes={completedTileIndexes}
      onClick={handleClickTile}
      hitboxes={selectedTile?.hitbox}
      onClickImage={handleClickImage}
    />
    { guessedTile && <SuccessOverlay tile={guessedTile} onClose={handleCloseOverlay} /> }
  </>;
}

interface IShowHiddenObjectsGameProps extends IExerciseProps<HiddenObjectsGameExercise> {
  lesson: ILesson;
}

function FinishedHiddenObjectsGameDisplay(): JSX.Element {
  const image = useMemo(() => {
    const index = Math.floor(Math.random() * FINISHED_IMAGES.length);
    return FINISHED_IMAGES[index];
  }, []);

  return <div className="finished-display">
    <h2 className="finished-display__title">
      <InstructionLabel instructionKey={EInstructionLabel.GOED_GEDAAN} hideIfNotDefined={true} />
    </h2>
    <img className="finished-display__image" src={image} alt="" />
  </div>;
}

export function ShowHiddenObjectsGame({ exercise, lesson, onClose, onFinishExercise }: IShowHiddenObjectsGameProps): JSX.Element {
  const [isFinished, setIsFinished] = useState(false);
  const [finishedResult, setFinishedResult] = useState<string|undefined>();
  const [timeoutId, setTimeoutId] = useState<number|undefined>();

  const handleClose = () => {
    if (!isFinished) {
      onClose();
    } else {
      clearTimeout(timeoutId);
      onFinishExercise(finishedResult);
    }
  };

  const handleFinished = (result: string|undefined) => {
    setIsFinished(true);
    setFinishedResult(result);

    const id = setTimeout(() => {
      onFinishExercise(result);
    }, 3000);
    setTimeoutId(id as unknown as number);
  };

  if (!exercise.data) {
    return <Status error={`Laden van oefening ${exercise.id} mislukt door missende data`} />;
  }

  return <VocabularyLayout className="show-hidden-objects-game" lesson={lesson} onClose={handleClose}>
    { isFinished ?
      <FinishedHiddenObjectsGameDisplay /> :
      <InteractiveDisplay lessonId={lesson.id} data={exercise.data} onFinished={handleFinished} />
    }
  </VocabularyLayout>;
}
