import React, { useMemo } from 'react';
import { EMPTY_LESSON_META, LessonMeta, LessonMetaManager } from '../../admin-module/lesson/lesson-meta-editor/lesson-meta';

interface IGoalProps {
  meta: string|null;
}

export function Goal({ meta }: IGoalProps): JSX.Element {
  const { goal }: LessonMeta = useMemo(() => {
    if (!meta) {
      return EMPTY_LESSON_META;
    }

    try {
      return LessonMetaManager.parseJsonToLessonMeta(meta);
    } catch (error) {
      console.error('Failed to parse meta', error);
      return EMPTY_LESSON_META;
    }
  }, [meta]);

  const goalSummary: string[] = [];
  if (goal?.letters.length) goalSummary.push(goal.letters.join(' '));
  if (goal?.words.length) goalSummary.push(`woord${goal.words.length > 1 ? 'en' : ''}: ${goal.words.join(' ')}`);
  if (goal?.punctuations.length) goalSummary.push(`leesteken${goal.punctuations.length > 1 ? 's' : ''}: ${goal.punctuations.join(' ')}`);

  return <span className="goal">
    {goalSummary.join(' - ')}
  </span>;
}
