import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DEFAULT_RECORDING_LANGUAGE, IQuestion, Recording } from 'shared';
import { AudioInput } from '../../../../../common/utils/audio-input.component';
import { DangerButton } from '../../../../../common/utils/danger-button.component';
import { EIcon } from '../../../../../common/utils/icon.component';

interface IReadingComprehensionQuestionEditorProps {
  question: IQuestion;
  index: number;
  handleQuestionChange: (index: number, value: string) => void;
  handleQuestionRecordingChanged: (recording: Recording | null, index: number) => void;
  handleCorrectAnswerChanged: (value: string, index: number) => void;
  handleIncorrectAnswerChanged: (value: string, questionIndex: number, answerIndex: number) => void;
  handleAddIncorrectAnswer: (questionIndex: number) => void;
  handleDeleteIncorrectAnswer: (questionIndex: number, answerIndex: number) => void;
}

const ReadingComprehensionQuestionEditor = ({ question, index, handleQuestionChange, handleQuestionRecordingChanged, handleCorrectAnswerChanged, handleIncorrectAnswerChanged, handleAddIncorrectAnswer, handleDeleteIncorrectAnswer }: IReadingComprehensionQuestionEditorProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            {t('reading-comprehension:admin.questions.question')}
          </Form.Label>
          <Form.Control value={question.question?.text} onChange={(e) => handleQuestionChange(index, e.target.value)} name={`question-${index}}`}
            type="text"
          />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            {t('reading-comprehension:admin.questions.recording')}
          </Form.Label>
          <AudioInput value={question.question?.recording} allowedLanguages={[DEFAULT_RECORDING_LANGUAGE]} onChange={(recording) => handleQuestionRecordingChanged(recording, index)} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            {t('reading-comprehension:admin.answers.correct-answer')}
          </Form.Label>
          <Form.Control type="text" value={question.answers?.correct} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCorrectAnswerChanged(e.target.value, index)} />
        </Form.Group>
      </Row>
      {question.answers?.incorrect.map((_answer, answerIndex) => (
        <Row key={answerIndex}>
          <Form.Group as={Col} md={6}>
            <Form.Label>
              {t('reading-comprehension:admin.answers.incorrect-answer')}
            </Form.Label>
            <Form.Control type="text" value={question.answers?.incorrect[answerIndex]} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleIncorrectAnswerChanged(e.target.value, index, answerIndex)} />
          </Form.Group>
          <Col md={6} style={{ display: 'flex', alignItems: 'center' }}>
            <DangerButton
              className="reading-comprehension__delete--incorrect-answer-button"
              icon={EIcon.MINUS_CIRCLE}
              onClick={() => handleDeleteIncorrectAnswer(index, answerIndex)}
              modalText={t('reading-comprehension:admin.difficult-words.delete.text')}
              modalCancelLabel={t('common:keep')}
              modalConfirmLabel={t('common:delete')}
            >{t('reading-comprehension:admin.answers.delete-incorrect-answer')}</DangerButton>
          </Col>
        </Row>
      ))}
      <button className="reading-comprehension__add-word-button" onClick={() => handleAddIncorrectAnswer(index)}>
        + {t('reading-comprehension:admin.answers.add-incorrect-answer')}
      </button>
    </>
  );
};


export default ReadingComprehensionQuestionEditor;
