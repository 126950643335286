import React, { FormEvent, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Alert from 'react-bootstrap/esm/Alert';
import Form from 'react-bootstrap/esm/Form';
import { Api } from '../../common/api/api';
import { useNavigate } from 'react-router-dom';
import { EAdminRouterPath } from '../../router-path';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { ApiException } from '../../common/api/types';
import { PageTitle } from '../../common/utils/page-title.component';

const INPUT_MAX_LENGTH = 255;

export function CreateUser(): JSX.Element {
  const [error, setError] = useState<string|null>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleInputFirstName = (value: string): void => setFirstName(value.trimStart());
  const handleInputLastName = (value: string): void => setLastName(value.trimStart());
  const handleInputEmail = (value: string): void => setEmail(value.trim()); // Don't allow spaces anywhere

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    setSubmitting(true);
    Api.createUser({ firstName, lastName, email })
      .then(user => {
        navigate(EAdminRouterPath.USER_SHOW.fullPath(user.id));
      })
      .catch(err => {
        setSubmitting(false);
        if (err instanceof ApiException) {
          setError(err.message);
          return;
        }

        console.error('Failed to create user', err);
        setError('An unknown error occurred');
      });
  };

  return <>
    <PageTitle backLink={EAdminRouterPath.USER_LIST.fullPath}>Nieuwe gebruiker toevoegen</PageTitle>
    <Form onSubmit={handleSubmit}>
      <Row>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            required
            minLength={1}
            maxLength={INPUT_MAX_LENGTH}
            placeholder="e@mail.com"
            value={email}
            onChange={e => handleInputEmail(e.target.value)} />
        </Form.Group>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              required
              minLength={1}
              maxLength={INPUT_MAX_LENGTH}
              placeholder="First name"
              value={firstName}
              onChange={e => handleInputFirstName(e.target.value)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type="text"
              required
              minLength={1}
              maxLength={INPUT_MAX_LENGTH}
              placeholder="Last name"
              value={lastName}
              onChange={e => handleInputLastName(e.target.value)} />
          </Form.Group>
        </Col>
      </Row>

      { error && <Alert variant="danger">{error}</Alert>}
      <Button variant="primary" type="submit" disabled={submitting}>Create user</Button>
    </Form>
  </>;
}
