/* eslint-disable no-void */
import React, { useState, useEffect, PropsWithChildren, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Status } from './status.component';
import { EIcon, Icon } from './icon.component';
import './file-input.component.scss';
import { useDropzone } from 'react-dropzone';
import { Alert } from 'react-bootstrap';

interface IFileInputProps {
  className?: string;
  value?: File|{ name: string }|null;
  /** Override filename retrieved from value */
  filename?: string;
  processing?: boolean;
  error?: string|null;
  displayDelete?: boolean;
  onChange: (value: File|null) => void;
  onBulkUpload?: (value: File[]) => void;
  allowedMimeTypes: string[];
  allowedFileExtension: string[];
}

export interface FileList {
  [dir: string]: File[];
}

export function FileInput({ className, processing, error, allowedMimeTypes, allowedFileExtension, value, displayDelete, onChange, onBulkUpload, filename, children }: PropsWithChildren<IFileInputProps>): JSX.Element {
  const [fileUploadError, setFileUploadError] = useState<string|null>(null);

  const fileValidator = useCallback((file: File): { code: string, message: string }|null => {
    const isCorrectFileType = allowedMimeTypes.includes(file.type);
    if (!isCorrectFileType) {
      return {
        code: 'file-type-not-allowed',
        message: `Bestandstype '${file.type}' is niet toegestaan`,
      };
    }

    if (file.size === 0) {
      return {
        code: 'file-too-small',
        message: 'Bestand is corrupt',
      };
    }

    return null;
  }, [allowedMimeTypes]);

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      onChange(null);
    } else if (acceptedFiles.length === 1 || (acceptedFiles.length > 1 && !onBulkUpload)) {
      onChange(acceptedFiles[0]);
    } else if (acceptedFiles.length > 1 && onBulkUpload) {
      onBulkUpload(acceptedFiles);
    }
  }, [onBulkUpload, onChange]);

  const { getRootProps, getInputProps, isDragActive, fileRejections, open } = useDropzone({
    onDrop: handleDrop,
    validator: fileValidator,
    maxFiles: onBulkUpload ? undefined : 1 });

  const rejections = useMemo(() => {
    if (fileRejections.length === 0) {
      return null;
    }

    return <Alert variant="danger">
      { fileRejections.map((rejection, i) => <ul key={i}>
        <strong>{rejection.file.name}</strong>
        { rejection.errors.map((error, j) => {
          let { message } = error;
          switch (error.code) {
            case 'too-many-files': message = 'Te veel bestanden'; break;
          }
          return <div key={j}>{message}</div>;
        })}
      </ul>)}
    </Alert>;
  }, [fileRejections]);

  useEffect(() => {
    setFileUploadError(null);
  }, [value]);

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    onChange(null);
  };

  const handleClickUpload = (event: React.MouseEvent) => {
    event.stopPropagation();
    open();
  };

  const displayDeleteButton = displayDelete !== false && (filename || value || displayDelete);
  const dropZoneClassName = classNames('file-input__drop-zone', { 'file-input__drop-zone--hovering': isDragActive });
  return <div className={classNames('file-input', { 'file-input--processing': processing }, className)}>
    <div {...getRootProps({ className: dropZoneClassName })} >
      <div className="file-input__spinner-container"><Status loading={processing} /></div>
      <div className="file-input__buttons">
        <input {...getInputProps({ className: 'file-input__file-input', tabIndex: -1, type: 'file', name: 'filename', accept: allowedFileExtension.join(', ') })} />

        <button type="button" className="file-input__button" onClick={handleClickUpload}>
          <Icon>{EIcon.UPLOAD}</Icon>
          <span className="file-input__button-label">Uploaden</span>
        </button>

        { children }

        { (filename || value) && <div className="file-input__file-name">{filename || value?.name}</div> }

        { displayDeleteButton && <button type="button" className="file-input__button file-input__button--delete" onClick={handleDelete}>
          <Icon>{EIcon.X}</Icon>
          <span className="file-input__button-label">Verwijderen</span>
        </button> }
      </div>
    </div>
    <Status error={error || fileUploadError} />
    { rejections }
  </div>;
}
