/* eslint-disable no-warning-comments */
import { PropsWithChildren } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { EIcon, Icon } from '../utils/icon.component';
import { UserMenu } from '../utils/user-menu.component';
import './main.layout.scss';
import allLogo from './all_logo_white.png';
import { ISchool } from 'shared';
import classNames from 'classnames';

export interface IMainLayoutMenuItem {
  name: string;
  icon: EIcon;
  link: string;
  openExtern?: boolean;
}

export interface IMainLayoutProps {
  logoLink: string;
  menuItems: IMainLayoutMenuItem[];
  school?: ISchool;
}

export function MainLayout({ menuItems, logoLink, children }: PropsWithChildren<IMainLayoutProps>): JSX.Element {
  const renderNavLink = (menuItem: IMainLayoutMenuItem) => {
    if (menuItem.openExtern) {
      return <a href={menuItem.link} className="main-layout__menu-link" target="_blank">
        <Icon className="main-layout__menu-link-icon">{menuItem.icon}</Icon>{menuItem.name}
      </a>;
    }
    return <NavLink to={menuItem.link} end className={({ isActive }) => classNames('main-layout__menu-link', { 'main-layout__menu-link--active': isActive })}>
      <Icon className="main-layout__menu-link-icon">{menuItem.icon}</Icon>{menuItem.name}
    </NavLink>;
  };

  return <div className="main-layout">
    <nav className="main-layout__navigation">
      <Link to={logoLink} className="main-layout__site-logo"><img src={allLogo} alt="Actief Leren Lezen" /></Link>
      <ul className="main-layout__menu">
        { menuItems.map(menuItem => <li key={menuItem.link} className="main-layout__menu-item">
          { renderNavLink(menuItem) }
        </li>)}
      </ul>
    </nav>
    <div className="main-layout__main-container">
      <header className="main-layout__header">
        <UserMenu />
      </header>
      <main className="main-layout__content">
        {children}
      </main>
    </div>
  </div>;
}
