import React, { ChangeEvent, FormEvent, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { EPupilAvatar } from 'shared';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { Group, Pupil } from '../../common/api/types';
import { DangerButton } from '../../common/utils/danger-button.component';
import { EIcon, Icon } from '../../common/utils/icon.component';
import { useTranslation } from 'react-i18next';

const INPUT_MAX_LENGTH = 255;

interface EditPupilModalFormProps {
  visible: boolean;
  groups: Group[];
  firstName: string;
  onChangeFirstName: (value: string) => void;
  lastName: string;
  onChangeLastName: (value: string) => void;
  avatar: EPupilAvatar;
  onChangeAvatar?: (value: EPupilAvatar) => void;
  groupId: number|null;
  onChangeGroupId: (value: number|null) => void;
  title: string;
  onCancel: () => void;
  onSubmit: () => void;
}

function EditPupilModalForm({ visible, groups, title, firstName, onChangeFirstName, lastName, onChangeLastName, avatar, onChangeAvatar, groupId, onChangeGroupId, onCancel, onSubmit, children }: PropsWithChildren<EditPupilModalFormProps>) {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };
  const { t } = useTranslation();

  const handleChangeAvatar = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!onChangeAvatar) {
      return;
    }
    onChangeAvatar(e.target.value as EPupilAvatar);
  };

  return <Modal size="lg" show={visible} onHide={onCancel}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Voornaam</Form.Label>
            <Form.Control
              type="text"
              required
              minLength={1}
              maxLength={INPUT_MAX_LENGTH}
              value={firstName}
              onChange={e => onChangeFirstName(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Achternaam</Form.Label>
            <Form.Control
              type="text"
              required
              minLength={1}
              maxLength={INPUT_MAX_LENGTH}
              value={lastName}
              onChange={e => onChangeLastName(e.target.value)} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Avatar</Form.Label>
            <Form.Select value={avatar} disabled={!onChangeAvatar} onChange={handleChangeAvatar}>
              { Object.values(EPupilAvatar).map((value) => <option key={value} value={value}>
                {t(`avatar:${value}`)}
              </option>) }
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Groep</Form.Label>
            <Form.Select value={groupId || -1} onChange={({ target: { value } }) => onChangeGroupId(value === '-1' ? null : +value)}>
              <option value={-1}>Geen</option>
              { groups.map((group) => <option key={group.id} value={group.id}>
                {group.name}
              </option>) }
            </Form.Select>
          </Form.Group>
        </Row>
      </Modal.Body>

      <Modal.Footer>{children}</Modal.Footer>
    </Form>
  </Modal>;
}

interface EditPupilModalProps {
  value?: Pupil|null;
  groups: Group[];
  onChange: (pupil: Pupil) => void;
  onDelete: (pupil: Pupil) => void;
  onCancel: () => void;
}

function randomAvatar(selectFromAvatars?: EPupilAvatar[]): EPupilAvatar {
  const avatars = selectFromAvatars || Object.values(EPupilAvatar);
  const randomIndex = Math.floor(Math.random() * avatars.length);
  return avatars[randomIndex];
}

export function EditPupilModal({ value, groups, onChange, onDelete, onCancel }: EditPupilModalProps): JSX.Element {
  const [firstName, setFirstName] = useState(value?.firstName || '');
  const [lastName, setLastName] = useState(value?.lastName || '');
  const [avatar, setAvatar] = useState(value?.avatar || randomAvatar());
  const [groupId, setGroupId] = useState(value?.groupId || null);

  useEffect(() => {
    if (!value) return;
    setFirstName(value.firstName);
    setLastName(value.lastName);
    setAvatar(value.avatar);
    setGroupId(value.groupId || null);
  }, [value]);

  const handleSubmit = () => {
    if (!value) return;
    onChange({
      ...value,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      avatar,
      groupId,
    });
  };

  const handleDelete = () => {
    if (!value) return;
    onDelete(value);
  };

  return <EditPupilModalForm
    title="Leerling bewerken"
    groups={groups}
    visible={!!value}
    firstName={firstName}
    onChangeFirstName={setFirstName}
    lastName={lastName}
    onChangeLastName={setLastName}
    avatar={avatar}
    onChangeAvatar={setAvatar}
    groupId={groupId}
    onChangeGroupId={setGroupId}
    onCancel={onCancel}
    onSubmit={handleSubmit}
  >
    <DangerButton
      modalText={`Weet je zeker dat je ${value?.firstName || ''} ${value?.lastName || ''} wilt verwijderen?`}
      modalConfirmLabel="Verwijderen"
      modalCancelLabel="Behouden"
      onClick={() => handleDelete()}
    >Leerling verwijderen</DangerButton>
    <Button variant="primary" type="submit">
      <Icon>{EIcon.SAVE}</Icon>
      Leerling opslaan
    </Button>
  </EditPupilModalForm>;
}

interface CreatePupilModalProps {
  visible: boolean;
  groups: Group[];
  pupils: Pupil[];
  onCreate: (newPupil: Omit<Pupil, 'id'>) => void;
  onCancel: () => void;
}

export function CreatePupilModal({ visible, groups, pupils, onCreate, onCancel }: CreatePupilModalProps): JSX.Element {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [groupId, setGroupId] = useState<number|null>(null);
  const avatar = useMemo(() => {
    // We provide the pupil with a unique (when possible) avatar on creation. The avatar can later be changed to anything.
    const availableAvatars = Object.values(EPupilAvatar);
    pupils.forEach(pupil => {
      if (pupil.groupId !== groupId) {
        return;
      }
      const pupilAvatarIndex = availableAvatars.indexOf(pupil.avatar);
      if (pupilAvatarIndex === -1) {
        return;
      }
      availableAvatars.splice(pupilAvatarIndex, 1);
    });
    return randomAvatar(availableAvatars.length > 0 ? availableAvatars : undefined);
  }, [groupId, groups]);

  useEffect(() => {
    if (visible) {
      return;
    }

    // Reset the values every time the modal is hidden
    setFirstName('');
    setLastName('');
    setGroupId(null);
  }, [visible]);

  const handleSubmit = () => {
    onCreate({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      avatar,
      groupId,
    });
  };

  return <EditPupilModalForm
    title="Voeg een leerling toe"
    groups={groups}
    visible={visible}
    firstName={firstName}
    onChangeFirstName={setFirstName}
    lastName={lastName}
    onChangeLastName={setLastName}
    avatar={avatar}
    groupId={groupId}
    onChangeGroupId={setGroupId}
    onCancel={onCancel}
    onSubmit={handleSubmit}
  >
    <Button variant="primary" type="submit">
      <Icon>{EIcon.USER_PLUS}</Icon>
      Leerling aanmaken
    </Button>
  </EditPupilModalForm>;
}
