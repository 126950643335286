/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSession } from '../auth/session.context';
import { Status } from '../utils/status.component';
import { EAdminRouterPath, ESchoolRouterPath } from '../../router-path';
import allLogo from '../layouts/all_logo_color.png';
import './select-module.scss';
import { AuthService } from '../auth/auth.service';
import { useTranslation } from 'react-i18next';

export function SelectModule(): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const fromUrl = (location.state as { from?: Location }|undefined)?.from?.pathname;
  const { userOfSchools: schools, isAdmin: isContentManager, isLoading, isAuthorized } = useSession();
  const [hasAttemptedAuthLoadOnce, setHasAttemptedAuthLoadOnce] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    setHasAttemptedAuthLoadOnce(true);
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && hasAttemptedAuthLoadOnce && !isAuthorized) {
      const loginUrl = AuthService.getAuthInterfaceUrl({ returnPath: fromUrl });
      window.location.replace(loginUrl);
      return;
    }

    if (isLoading || !isAuthorized) {
      return;
    }

    if (schools.length === 1 && !isContentManager) {
      navigate(ESchoolRouterPath.HOME.fullPath(schools[0].id), { replace: true });
    } else if (schools.length === 0 && isContentManager) {
      navigate(EAdminRouterPath.HOME.fullPath, { replace: true });
    }
  }, [schools.length, isLoading, isAuthorized, fromUrl, hasAttemptedAuthLoadOnce]);

  const renderSelectModule = () => <>
    <p className="module-selector__info">{t('select-module:select-school')}</p>
    <ul className="module-selector__schools">
      { schools.map(school => <li key={school.id} className="module-selector__school">
        <Link className="module-selector__link module-selector__link--school" to={ESchoolRouterPath.HOME.fullPath(school.id)}>{school.name}</Link>
      </li>) }
    </ul>
    { isContentManager && <Link className="module-selector__link module-selector__link--admin" to={EAdminRouterPath.HOME.fullPath}>{t('select-module:continue-as-admin')}</Link> }
    <button className="module-selector__sign-out" onClick={() => AuthService.logout()}>{t('common:sign-out')}</button>
  </>;

  const renderLogin = () => <>
    <p className="module-selector__info">{t('select-module:login-to-continue')}</p>
    <a className="module-selector__link module-selector__link--login" href={AuthService.getAuthInterfaceUrl({ returnPath: fromUrl })}>{t('common:sign-in')}</a>
  </>;

  return (
    <div className="module-selector">
      <img className="module-selector__logo" src={allLogo} alt="Actief Leren Lezen" />
      <div className="module-selector__container">
        <div className="module-selector__body">
          <Status loading={isLoading} />
          { (!isLoading && isAuthorized) && renderSelectModule() }
          { (!isLoading && !isAuthorized) && renderLogin() }
        </div>
      </div>
    </div>
  );
}
