import { createContext, useContext } from 'react';
import { Pupil } from '../api/types';
import { DEFAULT_RECORDING_LANGUAGE, ERecordingLanguage } from 'shared';

export interface IPupilContext {
  pupil?: Pupil;
  language: ERecordingLanguage;
}

export const PupilContext = createContext<IPupilContext>({ language: DEFAULT_RECORDING_LANGUAGE });

export function usePupil(): IPupilContext {
  return useContext(PupilContext);
}
