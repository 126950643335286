
import React, { FormEvent, useEffect, useState } from 'react';
import { ELessonCategory, ELessonType, EExerciseType, Exercise, LessonDocument, LessonDocumentManager, LESSON_ALLOWED_EXERCISES, exerciseLimitReached } from 'shared';
import classNames from 'classnames';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import { ExerciseType } from '../../../common/utils/exercise-type.component';
import { Status } from '../../../common/utils/status.component';
import { ExerciseEditor } from './exercises/exercise-editor.component';
import './lesson-document-editor.scss';
import { EditorTitle } from '../../../common/utils/editor-title';

interface ILessonDocumentEditorProps {
  className?: string;
  category: ELessonCategory;
  type: ELessonType | null;
  value: string;
  onChange: (document: string) => void;
}

function useAllowedExercises(category: ELessonCategory, type: ELessonType | null): EExerciseType[] {
  const exerciseCategory = LESSON_ALLOWED_EXERCISES.get(category) || new Map<ELessonType, EExerciseType[]>();
  const allowedExercisesForType = exerciseCategory.get(type ?? 'null');

  return allowedExercisesForType ?? [];
}

function LessonDocumentRecoveryMode({ source, error, onAttemptRecovery }: { source: string, error: string, onAttemptRecovery: (updatedSource: string) => void }): JSX.Element {
  const [value, setValue] = useState(source);
  const [attemptingRecovery, setAttemptingRecovery] = useState(false);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setAttemptingRecovery(true);
    onAttemptRecovery(value);

    setTimeout(() => setAttemptingRecovery(false), 100); // Recovery happens instantly so we use a timeout for feedback.
  };

  if (attemptingRecovery) {
    return <div className="lesson-document-editor__recovery-mode">
      <Status loading={true} />
    </div>;
  }

  return <div className="lesson-document-editor__recovery-mode">
    <Form onSubmit={handleSubmit}>
      <Alert variant="danger">
        The current document is corrupt. You can change the original source here to attempt a recovery.
        <h3>Error:</h3>
        <code>{error}</code>
      </Alert>
      <Form.Group>
        <Form.Label>Original source</Form.Label>
        <Form.Control className="lesson-document-editor__recovery-input" as="textarea" value={value} rows={15} onChange={event => setValue(event.target.value)} />
      </Form.Group>
      <Button type="submit">Attempt recovery</Button>
    </Form>
  </div>;
}

export function LessonDocumentEditor({ className, category, type, value, onChange }: ILessonDocumentEditorProps): JSX.Element {
  const allowedExercises = useAllowedExercises(category, type);
  const [document, setDocument] = useState<LessonDocument>(LessonDocumentManager.getEmptyLessonDocument(category));
  const [invalidDocumentError, setInvalidDocumentError] = useState<string | null>(null);

  const saveChange = (document: LessonDocument): void => {
    setDocument(document);
    const newDocumentSource = LessonDocumentManager.lessonDocumentToString(document);
    onChange(newDocumentSource);
  };

  useEffect(() => {
    try {
      const parsedDocument = LessonDocumentManager.parseJsonToLessonDocument(value);
      setDocument(parsedDocument);
      setInvalidDocumentError(null);
    } catch (error) {
      console.error('Failed to parse document', error);
      if (error instanceof Error) {
        setInvalidDocumentError(error.message);
      } else {
        setInvalidDocumentError('Unknown error');
      }
    }
  }, [value]);

  const handleAddExercise = (type: EExerciseType): void => {
    const updatedDocument = LessonDocumentManager.addExercise(document, type);
    saveChange(updatedDocument);
  };

  const handleUpdateExercise = (exercise: Exercise): void => {
    const updatedDocument = LessonDocumentManager.updateExercise(document, exercise);
    saveChange(updatedDocument);
  };

  const handleDeleteExercise = (exercise: Exercise): void => {
    const updatedDocument = LessonDocumentManager.deleteExercise(document, exercise);
    saveChange(updatedDocument);
  };

  if (invalidDocumentError) {
    return <section className={classNames('lesson-document-editor', className)}>
      <LessonDocumentRecoveryMode source={value} error={invalidDocumentError} onAttemptRecovery={onChange} />
    </section>;
  }

  return <section className={classNames('lesson-document-editor', className)}>
    <EditorTitle>Inhoud van de les</EditorTitle>

    {document.exercises.map((exercise) => <ExerciseEditor key={exercise.id} value={exercise} onChange={handleUpdateExercise} onDelete={handleDeleteExercise} />)}

    <div className="lesson-document-editor__add-buttons">
      {allowedExercises.map(exercise => <Button
        disabled={exerciseLimitReached(document.exercises, exercise)}
        key={exercise}
        onClick={() => handleAddExercise(exercise)}
      >
        Voeg een <ExerciseType value={exercise} /> toe
      </Button>)}
    </div>
  </section>;
}
