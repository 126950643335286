import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import { Recording, TranscriptWord, TDifficultWord, DEFAULT_RECORDING_LANGUAGE } from 'shared';
import './transcript-display.component.scss';
import { pauseAudio, playAudio, resumeAudio, stopAudio } from './audio.helper';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { getStorageUrl } from './storage.helper';
import { resolveRecordingItem } from './recording-resolver';

type WordProps = {
  word: TranscriptWord;
  difficultExampleUrl?: string;
  highlight?: boolean;
  onShowingDifficultWord?: (showing: boolean) => void;
  onChange?: (value: TranscriptWord) => void;
  onBreak?: (breakIndex: number) => boolean;
  onRemoveBeforeBreak?: () => void;
  onCombineWithNextWord?: () => void;
}

export function Word({ word, highlight, difficultExampleUrl, onShowingDifficultWord, onChange, onBreak, onRemoveBeforeBreak, onCombineWithNextWord }: WordProps): JSX.Element {
  const wordRef = useRef<HTMLSpanElement>(null);
  const [showDifficultExample, setShowDifficultExample] = useState(false);
  const editable = !!onChange;
  const combinable = !!onCombineWithNextWord;
  const isDifficult = !!difficultExampleUrl;
  const allowDifficultModal = isDifficult && !editable && !combinable;

  const handleShowDifficultExample = () => {
    setShowDifficultExample(true);
    if (onShowingDifficultWord) {
      onShowingDifficultWord(true);
    }
  };

  const handleHideDifficultExample = () => {
    setShowDifficultExample(false);
    if (onShowingDifficultWord) {
      onShowingDifficultWord(false);
    }
  };

  useEffect(() => {
    if (!showDifficultExample) {
      return;
    }

    setTimeout(() => handleHideDifficultExample(), 3000);
  }, [showDifficultExample]);

  const getCaretPosition = (): number => {
    const selection = document.getSelection();
    if (!selection || selection.type !== 'Caret') {
      return 0;
    }

    if (selection.focusNode?.parentElement !== wordRef.current) {
      return 0;
    }

    return selection.anchorOffset;
  };

  const restoreCaretPosition = (position: number) => {
    const textNode = wordRef.current?.childNodes[0];
    if (!textNode) {
      return;
    }

    if (wordRef.current !== document.activeElement) {
      return;
    }

    const range = document.createRange();
    range.setStart(textNode, position);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const setWordInDom = (value: string) => {
    if (!wordRef.current) {
      return;
    }

    if (wordRef.current.innerText === value) {
      return;
    }

    const caretPosition = getCaretPosition();
    wordRef.current.innerHTML = value;
    restoreCaretPosition(caretPosition);
  };

  const handleChangeWord = (value: string) => {
    if (!onChange) {
      setWordInDom(value);
      return;
    }

    if (value === '') {
      value = '-';
    }

    if (value.includes('\n')) {
      // eslint-disable-next-line no-use-before-define
      handleBreakWord(value);
      return;
    }

    onChange({ ...word, word: value });
    setWordInDom(value);
  };

  const handleBreakWord = (value: string) => {
    const breakRegex = /[\n\r]/g;
    const breakAtIndex = breakRegex.exec(value)?.index ?? 0;
    const cleanValue = value.replaceAll(breakRegex, '');

    if (!onBreak) {
      handleChangeWord(cleanValue);
      return;
    }

    setWordInDom(cleanValue);
    const success = onBreak(breakAtIndex);
    if (!success) {
      restoreCaretPosition(breakAtIndex);
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    const caretPosition = getCaretPosition();
    if (event.key === 'Backspace' && onRemoveBeforeBreak && caretPosition === 0) {
      onRemoveBeforeBreak();
    }
  };

  const handleRemoveWhitespace = () => {
    if (!onCombineWithNextWord) {
      return;
    }

    onCombineWithNextWord();
  };

  useEffect(() => {
    setWordInDom(word.word);
  }, [word.word]);

  return <><span className={classNames('transcript-word', { 'transcript-word--highlight': highlight, 'transcript-word--difficult': isDifficult })}>
    <span className="transcript-word__word">
      <span
        ref={wordRef}
        contentEditable={editable}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: '' }}
        onInput={event => handleChangeWord(event.currentTarget.innerText)}
        onKeyUp={handleKeyUp}
      />
      { allowDifficultModal && <button className="transcript-word__difficult-modal-button" onClick={handleShowDifficultExample} aria-label="Wat is dit?"></button> }
    </span>
    {word.lineBreak ? <br className="transcript-word__line-break" /> : <span className={classNames('transcript-word__whitespace', { 'transcript-word__whitespace--combinable': combinable })} onClick={handleRemoveWhitespace}> </span>}
  </span>
  { allowDifficultModal && <Modal show={showDifficultExample} className="transcript-word__difficult-modal" centered onHide={handleHideDifficultExample}>
    <Modal.Body><img src={difficultExampleUrl} alt="" /></Modal.Body>
  </Modal> }
  </>;
}

type TTranscriptDisplayProps = {
  words: TranscriptWord[];
  difficultWords: TDifficultWord[];
  recording?: Recording;
  onChange?: (value: TranscriptWord[]) => void;
}

export interface ITranscriptDisplayFunctions {
  play: (onFinish?: () => void) => void;
  stop: () => void;
  isPlaying: () => boolean;
}

export const TranscriptDisplay = forwardRef<ITranscriptDisplayFunctions, TTranscriptDisplayProps>(({ words, difficultWords, recording, onChange }, ref): JSX.Element => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playingAudioId, setPlayingAudioId] = useState<number | null>(null);
  const [highlightWordIndex, setHighlightWordIndex] = useState<number | null>(null);
  const [enableCombineWords, setEnableCombineWords] = useState(false);
  const recordingItem = resolveRecordingItem(recording, DEFAULT_RECORDING_LANGUAGE); // Only supports one language
  const recordingFilename = recordingItem?.filename;
  const editMode = !!onChange;
  const difficultWordsToDisplay = [...difficultWords];

  useImperativeHandle(ref, () => ({
    play(callback) {
      // eslint-disable-next-line no-use-before-define
      handlePlay(callback);
    },
    stop() {
      // eslint-disable-next-line no-use-before-define
      handleStop();
    },
    isPlaying() {
      return isPlaying;
    }
  }));

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    return () => handleStop();
  }, [isPlaying]);

  const handleChangeWord = (word: TranscriptWord, index: number) => {
    if (!onChange) {
      return;
    }

    const copyWords = [...words];
    copyWords[index] = word;
    onChange(copyWords);
  };

  /**
   * @returns a boolean indicating if a break has occurred
   */
  const handleBreakWord = (wordIndex: number, breakIndex: number): boolean => {
    if (!onChange) {
      return false;
    }

    const word: TranscriptWord = words[wordIndex] || null;
    const previousSibling: TranscriptWord | null = words[wordIndex - 1] || null;

    if (!word) {
      return false;
    }

    if (breakIndex === 0) {
      // Place word on next line
      if (!previousSibling) {
        return false;
      }

      previousSibling.lineBreak = true;
      onChange([...words]);
      return true;
    }

    if (breakIndex >= word.word.length) {
      // Place next word on new line
      if (word.lineBreak) {
        return false;
      }

      word.lineBreak = true;
      onChange([...words]);
      return true;
    }

    // Split word into two lines where the last word is on a new line
    // Not doing this right now
    console.warn('Splitting words not possible right now');
    return false;
  };

  const handleRemoveBeforeBreak = (wordIndex: number) => {
    if (!onChange) {
      return;
    }

    const previousSibling: TranscriptWord | null = words[wordIndex - 1] || null;

    if (!previousSibling || !previousSibling.lineBreak) {
      return;
    }

    previousSibling.lineBreak = false;
    onChange([...words]);
  };

  const handleCombineWithNextWord = (wordIndex: number) => {
    if (!onChange) {
      return;
    }

    const word: TranscriptWord | null = words[wordIndex] || null;
    const nextWord: TranscriptWord | null = words[wordIndex + 1] || null;

    if (!word || !nextWord) {
      return;
    }

    nextWord.startTime = word.startTime;
    nextWord.word = `${word.word}${nextWord.word}`;
    words.splice(wordIndex, 1);

    onChange([...words]);
  };

  const handleToggleCombineWords = () => {
    setEnableCombineWords(!enableCombineWords);
  };

  const handleAudioPlayProgress = useCallback((time: number) => {
    const timeMs = time * 1000;

    if (time === 0) {
      // When the playback stop this will report 0 after setting setHighlightWordIndex to null
      // causing highlightWordIndex to be set to 0. So the display will get stuck showing the
      // first word highlighted.
      return;
    }

    let currentIndex = null;
    words.forEach((word, index) => {
      const compensatedStartTime = word.startTime - 100; // -100 ms feels better, else the word might have been said before the highlight starts
      if (compensatedStartTime <= timeMs && timeMs < word.endTime) {
        currentIndex = index;
      }
    });

    if (highlightWordIndex === currentIndex) {
      return;
    }
    setHighlightWordIndex(currentIndex);
  }, [words, highlightWordIndex]);

  const handleStop = () => {
    if (!isPlaying) {
      return;
    }

    setIsPlaying(false);
    setHighlightWordIndex(null);

    if (playingAudioId !== null) {
      stopAudio(playingAudioId);
    }
    setPlayingAudioId(null);
  };

  const handlePlay = (onFinish?: () => void) => {
    if (!recordingFilename) {
      console.error('Tried to play recording, but no recording was defined');
      return;
    }

    if (isPlaying) {
      handleStop();
    }

    setIsPlaying(true);
    playAudio(recordingFilename, setPlayingAudioId, handleAudioPlayProgress)
      .catch(err => console.error('Could not play audio', recordingFilename, err))
      .finally(() => {
        if (onFinish) {
          onFinish();
        }
        setIsPlaying(false);
        setPlayingAudioId(null);
        setHighlightWordIndex(null);
      });
  };

  const handlePause = () => {
    if (!isPlaying) {
      return;
    }

    if (playingAudioId !== null) {
      pauseAudio(playingAudioId);
    }
  };

  const handleResume = () => {
    if (!isPlaying) {
      return;
    }

    if (playingAudioId !== null) {
      resumeAudio(playingAudioId);
    }
  };

  const handleShowingDifficultWord = (showing: boolean) => {
    if (showing) {
      handlePause();
    } else {
      handleResume();
    }
  };

  const renderWord = (word: TranscriptWord, index: number) => {
    const difficultWordIndex = difficultWordsToDisplay.findIndex((difficultWord => difficultWord.word.toLowerCase() === word.word.toLowerCase()));
    const difficultWord = difficultWordIndex !== -1 ? difficultWordsToDisplay.splice(difficultWordIndex, 1)[0] : null;
    const difficultWordImageUrl = difficultWord?.image && getStorageUrl(difficultWord.image.largeFilename);

    if (editMode) {
      return <Word
        highlight={highlightWordIndex !== null && index <= highlightWordIndex}
        difficultExampleUrl={difficultWordImageUrl}
        key={index}
        word={word}
        onChange={enableCombineWords ? undefined : (value) => handleChangeWord(value, index)}
        onBreak={(breakIndex) => handleBreakWord(index, breakIndex)}
        onRemoveBeforeBreak={() => handleRemoveBeforeBreak(index)}
        onCombineWithNextWord={enableCombineWords ? () => handleCombineWithNextWord(index) : undefined}
      />;
    }

    return <Word
      highlight={highlightWordIndex !== null && index <= highlightWordIndex}
      difficultExampleUrl={difficultWordImageUrl}
      key={index}
      word={word}
      onShowingDifficultWord={handleShowingDifficultWord}
    />;
  };

  return <>
    <p className={classNames('transcript-display', { 'transcript-display--edit-mode': editMode })}>
      {words.map(renderWord)}
    </p>
    { editMode && <div className="transcript-display__controls">
      <Button onClick={handleToggleCombineWords}>{enableCombineWords ? 'Stop met samenvoegen' : 'Woorden samenvoegen'}</Button>
      { isPlaying ? <Button onClick={handleStop}>Stop</Button> : <Button onClick={() => handlePlay()} disabled={!recordingFilename}>Preview</Button> }
    </div> }
  </>;
});
