import { ELessonBook, ELessonCategory, ELessonLevel } from 'shared';
import { useLocation } from 'react-router-dom';
import { isEnumValue } from '../../common/utils/input.helper';

export function useLessonParameters(): { category?: ELessonCategory, level?: ELessonLevel, book?: ELessonBook } {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const category = params.get('category');
  const level = params.get('level');
  const book = params.get('book');

  let parsedCategory: ELessonCategory|undefined;
  if (isEnumValue(ELessonCategory, category)) {
    parsedCategory = category as ELessonCategory;
  }

  let parsedLevel: ELessonLevel|undefined;
  if (isEnumValue(ELessonLevel, level)) {
    parsedLevel = level as ELessonLevel;
  }

  let parsedBook: ELessonBook|undefined;
  if (isEnumValue(ELessonBook, book)) {
    parsedBook = book as ELessonBook;
  }

  return { category: parsedCategory, level: parsedLevel, book: parsedBook };
}
