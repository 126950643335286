import React, { useCallback, useMemo, useState } from 'react';
import Form from 'react-bootstrap/esm/Form';
import Modal from 'react-bootstrap/esm/Modal';
import { useTranslation } from 'react-i18next';
import { DEFAULT_RECORDING_LANGUAGE, IPupilDecodingQuestionResultItem, IPupilDecodingResult, IQuestion, ReadingComprehensionTranscriptExercise } from 'shared';
import { ReadingComprehensionLayout } from './reading-comprehension-layout';
import { EIcon } from '../../../common/utils/icon.component';
import { RoundButton } from '../../../common/utils/round-button.component';
import { Status } from '../../../common/utils/status.component';
import { shuffle } from '../../../common/utils/shuffel.helper';
import { TranscriptDisplay } from '../../../common/utils/transcript-display.component';
import { RecordingPlayer } from '../../../common/utils/recording-player.component';
import vlamSad from './vlam-sad.png';
import vlamThumbsUp from './vlam-thumbs-up.png';
import './reading-comprehension-questions.scss';
import { resolveRecordingItem } from '../../../common/utils/recording-resolver';

const ANSWER_KEY = 'answer';

type SelectAnswer = string|number|null;

type QuestionProps = {
  question: IQuestion;
  selectedAnswer: SelectAnswer;
  onSelect: (event: { correct: boolean, answer: SelectAnswer, answerString: string, correctAnswerString: string }) => void;
}

function questionIndexToChar(index: number): string {
  return String.fromCharCode(97 + index); // The char 'a' starts at 97
}

function Question({ question: questionData, selectedAnswer, onSelect }: QuestionProps): JSX.Element {
  const { answers, question } = questionData;
  const displayOrder = useMemo(() => {
    const indexes: (string|number)[] = answers.incorrect.map((_, index) => index);
    indexes.push(ANSWER_KEY);
    shuffle(indexes);
    return indexes;
  }, [questionData]);
  const correctAnswerIndex = displayOrder.findIndex((key) => key === ANSWER_KEY);

  const handleSelectAnswer = (key: string|number, text: string) => {
    onSelect({
      correct: key === ANSWER_KEY,
      answer: key,
      answerString: text,
      correctAnswerString: `${questionIndexToChar(correctAnswerIndex)}. ${answers.correct}`
    });
  };

  const renderQuestions = () => displayOrder.map((key, index) => {
    const isCorrect = key === ANSWER_KEY;
    const answer = isCorrect ? answers.correct : answers.incorrect[key as number];
    return <Form.Check
      key={index}
      type="radio"
      size={36}
      className="question-display__answer-radio"
    >
      <Form.Check.Label>
        <Form.Check.Input type="radio" name="answer" checked={selectedAnswer === key} onChange={() => handleSelectAnswer(key, answer)} />
        {questionIndexToChar(index)}. {answer}
      </Form.Check.Label>
    </Form.Check>;
  });

  return <div className="question-display">
    <h2 className="question-display__question"><RecordingPlayer recording={question.recording ?? undefined} language={DEFAULT_RECORDING_LANGUAGE} >{question.text}</RecordingPlayer></h2>
    {renderQuestions()}
  </div>;
}

function ResultModal({ title, subTitle, state, preview, onClose }: { title: string, subTitle: string, state: 'happy'|'sad', preview?: { lines: string[], highlight: number }, onClose: () => void }) {
  return <Modal className="question-result-modal" show={true} onHide={() => onClose()} size="xl">
    <div className="question-result-modal__container">
      <h2 className="question-result-modal__title">{title}</h2>
      <p className="question-result-modal__sub-title">{subTitle}</p>

      <div className="question-result-modal__main">
        <img className="question-result-modal__vlam" src={state === 'happy' ? vlamThumbsUp : vlamSad} alt="" />
        { preview && <div className="question-result-modal__answer-source">
          <p>
            { preview.lines.map((line, index) => <React.Fragment key={index}>
              <span className={preview.highlight === index ? 'question-result-modal__highlight' : undefined}>
                <span>{line}</span>
              </span>
              <br/>
            </React.Fragment>)}
          </p>
        </div> }
      </div>

      <RoundButton variant="success" className="question-result-modal__close-button" onClick={() => onClose()} icon={EIcon.CHEVRON_RIGHT}>Ga door</RoundButton>
    </div>
  </Modal>;
}

type TReadingComprehensionQuestionsProps = {
  exercise: ReadingComprehensionTranscriptExercise;
  onContinue: (result: IPupilDecodingResult) => void;
  onStep: () => void;
}

const SNIPPET_LENGTH = 3;

function questionToResultEntry(question: IQuestion): IPupilDecodingQuestionResultItem {
  return {
    question: question.question.text,
    correctOption: question.answers.correct,
    wrongOptions: [...question.answers.incorrect],
    attempts: [],
  };
}

export function ReadingComprehensionQuestions({ exercise, onContinue, onStep }: TReadingComprehensionQuestionsProps): JSX.Element {
  const { t } = useTranslation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [correctAnswerSelected, setCorrectAnswerSelected] = useState<boolean|null>(null);
  const [selectedAnswer, setSelectedAnswer] = useState<SelectAnswer>(null);
  const [selectedAnswerString, setSelectedAnswerString] = useState('');
  const [answerAttempts, setAnswerAttempts] = useState(0);
  const [correctAnswerString, setCorrectAnswerString] = useState('');
  const [showCorrectAnswerModal, setShowCorrectAnswerModal] = useState(false);
  const [showWrongAnswerModal, setShowWrongAnswerModal] = useState(false);
  const [showRevealAnswerModal, setShowRevealAnswerModal] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const [result, setResult] = useState<IPupilDecodingResult>({ version: 1, questions: [] });

  const questions = exercise.data?.questions || [];
  const currentQuestion: IQuestion|undefined = questions[currentQuestionIndex];
  const nextQuestion: IQuestion|undefined = questions[currentQuestionIndex + 1];

  const recordingItem = resolveRecordingItem(exercise.data?.recording, DEFAULT_RECORDING_LANGUAGE); // These only support one language

  const words = recordingItem?.transcript?.words || [];

  const disableContinue = selectedAnswer === null;


  const answerSnippet = useMemo(() => {
    if (!currentQuestion) {
      return undefined;
    }

    const lines: string[] = [];
    let currentLine: string[] = [];
    words.forEach((word, index) => {
      currentLine.push(word.word);
      const lastWord = index === words.length - 1;
      if (word.lineBreak || lastWord) {
        lines.push(currentLine.join(' '));
        currentLine = [];
      }
    });

    const matchText = currentQuestion.answers.correct.toLowerCase();
    const answerLineIndex = lines.findIndex(line => line.toLowerCase().includes(matchText));
    if (answerLineIndex === -1) {
      return undefined;
    }

    if (lines.length <= SNIPPET_LENGTH) {
      return { lines: lines, highlight: answerLineIndex };
    }

    if (answerLineIndex === 0) {
      return {
        lines: lines.slice(0, SNIPPET_LENGTH),
        highlight: answerLineIndex,
      };
    }

    if (answerLineIndex === lines.length - 1) {
      return {
        lines: lines.slice(lines.length - SNIPPET_LENGTH),
        highlight: SNIPPET_LENGTH - 1,
      };
    }

    const offset = Math.floor((SNIPPET_LENGTH - 1) / 2); // -1 to get the amount of lines to add
    return {
      lines: lines.slice(answerLineIndex - offset, answerLineIndex + 1 + offset), // + 1 to include the actual line in the total
      highlight: offset,
    };
  }, [currentQuestionIndex]);

  const handleGoToNext = useCallback(() => {
    if (!currentQuestion) {
      onContinue(result);
      return;
    }

    if (correctAnswerSelected === true) {
      setShowCorrectAnswerModal(true);
      return;
    } else if (correctAnswerSelected === false && answerAttempts === 0) {
      setShowWrongAnswerModal(true);
      return;
    } else if (correctAnswerSelected === false && answerAttempts >= 1) {
      setShowRevealAnswerModal(true);
      return;
    }
    console.error('Unexpected case reached', { correctAnswerSelected, answerAttempts });
  }, [currentQuestion, nextQuestion, correctAnswerSelected, answerAttempts]);

  const handleSelectAnswer: QuestionProps['onSelect'] = ({ correct, answer, answerString, correctAnswerString }) => {
    setCorrectAnswerSelected(correct);
    setSelectedAnswer(answer);
    setSelectedAnswerString(answerString);
    setCorrectAnswerString(correctAnswerString);
  };

  const saveQuestionResult = () => {
    if (!currentQuestion) {
      return;
    }
    const resultEntry = (result.questions || [])[currentQuestionIndex] || questionToResultEntry(currentQuestion);

    resultEntry.attempts.push(selectedAnswerString);

    if (!result.questions) {
      result.questions = [];
    }
    result.questions[currentQuestionIndex] = resultEntry;
    setResult({ ...result });
  };

  const resetAnswers = () => {
    setCorrectAnswerSelected(null);
    setSelectedAnswer(null);
    setSelectedAnswerString('');
    setCorrectAnswerString('');
  };

  const startNextQuestion = () => {
    if (!nextQuestion) {
      onContinue(result);
      return;
    }

    setCurrentQuestionIndex(currentQuestionIndex + 1);
    resetAnswers();
    setAnswerAttempts(0);
    onStep();
  };

  const handleClosedCorrectAnswerModal = () => {
    saveQuestionResult();
    setShowCorrectAnswerModal(false);

    startNextQuestion();
  };

  const handleClosedWrongAnswerModal = () => {
    saveQuestionResult();
    setShowWrongAnswerModal(false);

    resetAnswers();
    setAnswerAttempts(answerAttempts + 1);
  };

  const handleClosedRevealAnswerModal = () => {
    saveQuestionResult();
    setShowRevealAnswerModal(false);

    startNextQuestion();
  };

  const renderModal = () => {
    if (showCorrectAnswerModal) {
      return <ResultModal title={t('reading-comprehension:correct-answer-title')} subTitle={`${t('reading-comprehension:correct-answer-is')} ${correctAnswerString}`} state="happy" preview={answerSnippet} onClose={handleClosedCorrectAnswerModal} />;
    } else if (showWrongAnswerModal) {
      return <ResultModal title={t('reading-comprehension:wrong-answer-title')} subTitle={t('reading-comprehension:try-again')} state="sad" onClose={handleClosedWrongAnswerModal} />;
    } else if (showRevealAnswerModal) {
      return <ResultModal title={t('reading-comprehension:wrong-answer-title')} subTitle={`${t('reading-comprehension:correct-answer-is')} ${correctAnswerString}`} state="sad" preview={answerSnippet} onClose={handleClosedRevealAnswerModal} />;
    }

    return null;
  };

  if (!questions || questions.length === 0) {
    return <Status error="Cannot display questions: No questions defined"/>;
  }

  return <ReadingComprehensionLayout
    className="reading-comprehension-questions"
    onContinue={handleGoToNext}
    disableContinue={disableContinue}
    buttons={<RoundButton icon={showTranscript ? EIcon.EYE_OFF : EIcon.EYE} variant="info" onClick={() => setShowTranscript(!showTranscript)} />}
  >
    {renderModal()}
    { currentQuestion && <Question key={currentQuestionIndex} question={currentQuestion} selectedAnswer={selectedAnswer} onSelect={handleSelectAnswer} /> }
    { showTranscript && <aside className="reading-comprehension-questions__transcript">
      <TranscriptDisplay words={words} difficultWords={[]} />
    </aside> }
  </ReadingComprehensionLayout>;
}
