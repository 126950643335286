import { EPupilPasswordPart } from 'shared';
import { Api } from '../api/api';
import { UnauthorizedException } from '../api/types';
import { SESSION_STORAGE_PUPIL_TOKEN } from '../auth/auth.service';

const saveAccessToken = (accessToken: string): void => {
  sessionStorage.setItem(SESSION_STORAGE_PUPIL_TOKEN, accessToken);
};

export const getPupilAccessToken = (): string|null => sessionStorage.getItem(SESSION_STORAGE_PUPIL_TOKEN);

/**
 * @returns the token on success, null on wrong password and throws an error on an unexpected response.
 */
export const authorizePupil = async({ pupilId, password }: { pupilId: number, password: EPupilPasswordPart[] }): Promise<{ accessToken: string }|null> => {
  const passwordString = password.join('/');
  try {
    const token = await Api.authorizePupil(pupilId, passwordString);
    saveAccessToken(token.accessToken);
    return token;
  } catch (err) {
    if (err instanceof UnauthorizedException === false) {
      throw err;
    }
  }

  return null;
};
