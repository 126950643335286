import { useState } from 'react';
import { ILesson, IPupilDecodingResult, ReadingComprehensionTranscriptExercise } from 'shared';
import { IExerciseProps } from '..';
import { DecodingLayout } from '../decoding/decoding-layout';
import { Status } from '../../../common/utils/status.component';
import { ReadingComprehensionQuestions } from './reading-comprehension-questions';
import { ReadingComprehensionTranscript } from './reading-comprehension-transcript';
import { ReadingComprehensionFinished } from './reading-comprehension-finished';

interface IShowReadingComprehensionProps extends IExerciseProps<ReadingComprehensionTranscriptExercise> {
  progress: number;
  lesson: ILesson;
}

enum EContent {
  TRANSCRIPT,
  QUESTIONS,
  FINISHED,
}

const ShowReadingComprehension = ({ lesson, progress, onClose, exercise, onStepForward, onFinishExercise }: IShowReadingComprehensionProps): JSX.Element => {
  const [currentContent, setCurrentContent] = useState<EContent>(EContent.TRANSCRIPT);
  const [result, setResult] = useState<IPupilDecodingResult|undefined>(undefined);
  const questions = exercise?.data?.questions;

  const handleGoToNext = (result?: IPupilDecodingResult) => {
    setResult(result);
    if (currentContent === EContent.TRANSCRIPT && questions && questions.length > 0) {
      // There are questions
      setCurrentContent(EContent.QUESTIONS);
    } else if (currentContent === EContent.TRANSCRIPT) {
      // No questions
      setCurrentContent(EContent.FINISHED);
    } else if (currentContent === EContent.QUESTIONS) {
      setCurrentContent(EContent.FINISHED);
    }

    onStepForward();
  };

  const handleOnStep = () => {
    onStepForward();
  };

  const renderContent = () => {
    switch (currentContent) {
      case EContent.TRANSCRIPT:
        return <ReadingComprehensionTranscript exercise={exercise} onContinue={handleGoToNext} />;
      case EContent.QUESTIONS:
        return <ReadingComprehensionQuestions exercise={exercise} onContinue={handleGoToNext} onStep={handleOnStep} />;
      case EContent.FINISHED:
        return <ReadingComprehensionFinished onContinue={() => onFinishExercise(result && JSON.stringify(result))} />;
      default:
        return <Status error="Unexpected content type"/>;
    }
  };

  return <DecodingLayout onClose={onClose} lesson={lesson} progress={progress}>
    {renderContent()}
  </DecodingLayout>;
};

export default ShowReadingComprehension;
