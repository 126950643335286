/* eslint-disable no-use-before-define */
export type {
  ILesson as Lesson,
  IUser as User,
  ISchool as School,
  IImage as Image,
  IGroup as Group,
  IPupil as Pupil,
} from 'shared';

export class ApiException extends Error {
  readonly key: string;
  readonly message: string;

  constructor(key = 'UnknownError', message = 'An unexpected error has occurred') {
    super(key);

    this.key = key;
    this.message = message;
  }

  static from(errorKey: string): ApiException|null {
    switch (errorKey) {
      case FriendlySchoolIdAlreadyTakenException.name:
        return new FriendlySchoolIdAlreadyTakenException();
      case NotFoundException.name:
        return new NotFoundException();
      case SchoolSubscriptionExpiredException.name:
        return new SchoolSubscriptionExpiredException();
      case TooManyRequestsException.name:
        return new TooManyRequestsException();
      case CannotSetProgressBackwardsException.name:
        return new CannotSetProgressBackwardsException();
      case OnlySilenceRecordedException.name:
        return new OnlySilenceRecordedException();
      case 'Unauthorized':
        return new UnauthorizedException();
      default:
        return null;
    }
  }
}

export class FriendlySchoolIdAlreadyTakenException extends ApiException {
  constructor() {
    super('FriendlySchoolIdAlreadyTakenException', 'Friend ID is already in use by another school');
  }
}

export class NotFoundException extends ApiException {
  constructor() {
    super('NotFoundException', 'Resource cannot be found');
  }
}

export class UnauthorizedException extends ApiException {
  constructor() {
    super('UnauthorizedException', 'Unauthorized');
  }
}

export class SchoolSubscriptionExpiredException extends ApiException {
  constructor() {
    super('SchoolSubscriptionExpiredException', 'This school has an expired subscription');
  }
}

export class TooManyRequestsException extends ApiException {
  constructor() {
    super('TooManyRequestsException', 'Pleas wait before making more requests to this resource');
  }
}

export class CannotSetProgressBackwardsException extends ApiException {
  constructor() {
    super('CannotSetProgressBackwardsException', 'Pupil can only move progress forwards');
  }
}

export class OnlySilenceRecordedException extends ApiException {
  constructor() {
    super('OnlySilenceRecordedException', 'Only silence recorded');
  }
}
