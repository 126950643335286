import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ELessonCategory, IGroup } from 'shared';
import { useSession } from '../../common/auth/session.context';
import { useSchool } from '../../common/school-provider/school.context';
import { PageTitle } from '../../common/utils/page-title.component';
import { Status } from '../../common/utils/status.component';
import { PupilProgressFilters } from './filters';
import { PupilProgressTable } from './table';

export const PupilProgress = (): JSX.Element => {
  const { isLoading, teacherOfGroups } = useSession();
  const { school } = useSchool();
  const [selectedCategory, setSelectedCategory] = useState<ELessonCategory>(ELessonCategory.DECODING);
  const [selectedGroup, setSelectedGroup] = useState<IGroup|undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { t } = useTranslation();
  const groups = teacherOfGroups.filter(group => group.schoolId === school?.id);

  useEffect(() => {
    if (groups) {
      setSelectedGroup(groups[0]);
    }
  }, [teacherOfGroups, school]);

  const renderContent = () => {
    if (isLoading) {
      return <Status loading={isLoading} />;
    } else if (!isLoading && groups.length === 0) {
      return <Status info={t('pupil-progress:no-groups')} />;
    }

    return <>
      <PupilProgressFilters groups={groups} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} setSelectedGroup={setSelectedGroup} selectedGroup={selectedGroup} searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
      <PupilProgressTable searchQuery={searchQuery} selectedGroup={selectedGroup} selectedCategory={selectedCategory} />
    </>;
  };

  return (
    <>
      <PageTitle className="pupil-progress__page-title">
        {t('pupil-progress:page-title')}<br/>
      </PageTitle>
      {renderContent()}
    </>
  );
};
