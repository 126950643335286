import React, { useEffect, useState } from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars

export const useDeveloperByPassMode = (): boolean => {
  const [developerBypassMode, setDeveloperBypassMode] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey && event.shiftKey) {
        setDeveloperBypassMode(true);
      }
    };

    const handleKeyUp = () => {
      if (developerBypassMode) {
        setDeveloperBypassMode(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return developerBypassMode;
};
