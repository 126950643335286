import { PropsWithChildren } from 'react';
import { EIcon } from '../../../common/utils/icon.component';
import { RoundButton } from '../../../common/utils/round-button.component';
import { useTranslation } from 'react-i18next';
import './reading-comprehension-layout.scss';
import classNames from 'classnames';

type TReadingComprehensionLayoutProps = {
  className?: string;
  buttons?: JSX.Element;
  onContinue?: () => void;
  disableContinue?: boolean;
}

export function ReadingComprehensionLayout({ className, buttons, disableContinue, onContinue, children }: PropsWithChildren<TReadingComprehensionLayoutProps>): JSX.Element {
  const { t } = useTranslation();
  return <div className={classNames('reading-comprehension-layout', className)}>
    <div className="reading-comprehension-layout__content">{children}</div>
    <div className="reading-comprehension-layout__buttons">
      {buttons}
      {onContinue && <RoundButton
        className="reading-comprehension-layout__next-button"
        icon={EIcon.CHEVRON_RIGHT}
        variant="success"
        onClick={onContinue}
        disabled={disableContinue}
      >{t('common:continue')}</RoundButton>}
    </div>
  </div>;
}
