import React, { useCallback, useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import { ImageInput } from '../../../../common/utils/image-input.component';
import { HiddenObjectsGameExercise, Image, Recording, DEFAULT_HITBOX, HiddenObjectsGameData, HiddenObjectsGameTile, HiddenObjectsGameTileHitbox } from 'shared';
import { IExerciseEditorProps } from './exercise-editor.component';
import { AudioInput } from '../../../../common/utils/audio-input.component';
import { Box } from '../../../../common/utils/box.component';
import { DangerButton } from '../../../../common/utils/danger-button.component';
import { EIcon } from '../../../../common/utils/icon.component';
import { HiddenObjectsGameDisplay } from './hidden-objects-game-display.component';

function findAssetsInBulkUpload(set: { image: Image, source: File }[]): { picture?: Image, tiles: HiddenObjectsGameTile[] } {
  let picture: Image|undefined;
  const tiles: { [folder: string]: Partial<HiddenObjectsGameTile> } = {};
  set.forEach(({ image, source }) => {
    if (['woordplaat.png', 'woordplaat.jpg', 'woordplaat.jpeg'].includes(source.name.toLowerCase())) {
      picture = image;
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore // File.path does not exits assumes the type checker, but it does and should be used on Chrome (File.webkitRelativePath only exits on Firefox and Safari).
    const sourcePath = source.path as string || source.webkitRelativePath; // Use File.webkitRelativePath as backup for when File.path indeed does not exits
    const folder = sourcePath.replace(`${source.name}`, '').replace(/\/$/, '');
    if (!tiles[folder]) {
      const [folderName] = /[\w\s]+$/.exec(folder) || [];
      tiles[folder] = { word: folderName?.trim(), hitbox: [{ ...DEFAULT_HITBOX }] };
    }

    if (['echt.png', 'echt.jpg', 'echt.jpeg'].includes(source.name.toLowerCase())) {
      tiles[folder].realisticImage = image;
    } else {
      tiles[folder].image = image;
    }
  });

  return {
    picture,
    tiles: Object.keys(tiles)
      .map(key => tiles[key])
      .filter(tile => tile.image) as HiddenObjectsGameTile[], // After this filter .image it always set
  };
}

function TileEditor({ id, tile, onChange }: { id: number|string|null, tile: HiddenObjectsGameTile, onChange: (updatedTile: HiddenObjectsGameTile|null) => void }): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const wrongFeedbackRecordings = tile.wrongFeedbackRecording || [];

  useEffect(() => {
    ref.current?.scrollIntoView();
  }, [ref, id]); // If these values change we probably need to focus on the item again

  const handleChangeQuestionRecording = useCallback((recording: Recording|null) => {
    onChange({ ...tile, questionRecording: recording || undefined });
  }, [tile]);

  const handleChangeTileImage = useCallback((image: Image|null) => {
    if (!image) {
      return; // Do not allow deleting image
    }

    onChange({ ...tile, image: image });
  }, [tile]);

  const handleChangeWord = useCallback((value: string|null) => {
    const word = value ? value.trimStart() : undefined;
    onChange({ ...tile, word });
  }, [tile]);

  const handleChangeRecordingWord = useCallback((recording: Recording|null) => {
    onChange({ ...tile, recordingWord: recording || undefined });
  }, [tile]);

  const handleChangeRealisticImage = useCallback((image: Image|null) => {
    onChange({ ...tile, realisticImage: image || undefined });
  }, [tile]);

  const handleChangeCorrectFeedback = useCallback((value: string|null) => {
    const correctFeedback = value ? value.trimStart() : undefined;
    onChange({ ...tile, correctFeedback });
  }, [tile]);

  const handleChangeRecordingCorrectFeedback = useCallback((recording: Recording|null) => {
    onChange({ ...tile, correctFeedbackRecording: recording || undefined });
  }, [tile]);

  const handleDelete = () => {
    onChange(null);
  };

  const handleChangeRecordingWrongFeedback = useCallback((index: number, recording: Recording|null) => {
    const updated = [...wrongFeedbackRecordings];
    if (recording) {
      updated.splice(index, 1, recording);
    } else {
      updated.splice(index, 1);
    }
    onChange({ ...tile, wrongFeedbackRecording: updated });
  }, [tile, wrongFeedbackRecordings]);

  return <div ref={ref} className="hidden-objects-game-tile-editor">
    <Row>
      <Form.Group as={Col}>
        <Form.Label>Audio opname van de vraag</Form.Label>
        <AudioInput value={tile.questionRecording} onChange={handleChangeQuestionRecording} />
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label>Afbeelding voor tegel</Form.Label>
        <ImageInput value={tile.image} onChange={handleChangeTileImage} displayDelete={false} />
      </Form.Group>
    </Row>

    <Box title="Correct antwoord">
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Woord</Form.Label>
          <Form.Control
            type="text"
            value={tile.word || ''}
            onChange={e => handleChangeWord(e.target.value)} />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Audio opname van woord</Form.Label>
          <AudioInput value={tile.recordingWord} onChange={handleChangeRecordingWord} />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col}>
          <Form.Label>Realistisch beeld van woord</Form.Label>
          <ImageInput value={tile.realisticImage} onChange={handleChangeRealisticImage} />
        </Form.Group>

        <Col></Col>
      </Row>

      <Row>
        <Form.Group as={Col}>
          <Form.Label>Correct antwoord reactie</Form.Label>
          <Form.Control
            type="text"
            value={tile.correctFeedback || ''}
            onChange={e => handleChangeCorrectFeedback(e.target.value)} />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Audio opname van correct antwoord reactie</Form.Label>
          <AudioInput value={tile.correctFeedbackRecording} onChange={handleChangeRecordingCorrectFeedback} />
        </Form.Group>
      </Row>
    </Box>

    <Box title="Fout antwoord">
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Audio opname van fout antwoord - reactie 1</Form.Label>
          <AudioInput value={wrongFeedbackRecordings[0]} onChange={(recording) => handleChangeRecordingWrongFeedback(0, recording)} />
        </Form.Group>

        { wrongFeedbackRecordings.length >= 1 ?
          <Form.Group as={Col}>
            <Form.Label>Audio opname van fout antwoord - reactie 2</Form.Label>
            <AudioInput value={wrongFeedbackRecordings[1]} onChange={(recording) => handleChangeRecordingWrongFeedback(1, recording)} />
          </Form.Group> :
          <Col></Col>
        }
      </Row>
    </Box>

    <Row>
      <Col>
        <DangerButton
          icon={EIcon.MINUS_CIRCLE}
          onClick={handleDelete}
          modalText="Weet je het zeker dat je de tegel wilt verwijderen?"
          modalCancelLabel="Behouden"
          modalConfirmLabel="Verwijderen"
        >Verwijderen</DangerButton>
      </Col>
    </Row>
  </div>;
}

export function HiddenObjectsGameEditor({ value, onChange }: IExerciseEditorProps<HiddenObjectsGameExercise>): JSX.Element {
  const data: HiddenObjectsGameData = value.data ?? {};
  const image = data.image ?? null;
  const tiles = data.tiles ?? null;
  const introRecording = data.introRecording ?? null;
  const [editingTileIndex, setEditingTileIndex] = useState<number|null>(null);
  const editingTile = tiles && editingTileIndex !== null ? tiles[editingTileIndex] : null;

  const applyChanges = useCallback((changes: HiddenObjectsGameData) => {
    onChange({ ...value, data: {
      ...value.data,
      ...changes,
    } });
  }, [value, value.data, onChange]);

  const getUpdatedTiles = useCallback((newTiles: HiddenObjectsGameTile[]): HiddenObjectsGameTile[] => (tiles ? [...tiles, ...newTiles] : newTiles), [tiles]);

  const handleAddTile = (image: Image|null): void => {
    if (!image) {
      return;
    }

    applyChanges({
      tiles: getUpdatedTiles([{ image, hitbox: [{ ...DEFAULT_HITBOX }] }]),
    });
  };

  const handleBulkChange = (set: { image: Image, source: File }[]): void => {
    const changes = findAssetsInBulkUpload(set);
    const newTiles = getUpdatedTiles(changes.tiles);
    if (changes.picture) {
      applyChanges({
        image: changes.picture,
        tiles: newTiles,
      });
    } else {
      applyChanges({
        tiles: newTiles,
      });
    }
  };

  const handleChangeTile = useCallback((updatedTile: HiddenObjectsGameTile|null): void => {
    if (editingTileIndex === null || !tiles || !tiles[editingTileIndex]) {
      console.error('Tried to update tile that does not exits', { editingTileIndex, updatedTile });
      return;
    }

    const copyTiles = [...tiles];
    if (updatedTile) {
      copyTiles.splice(editingTileIndex, 1, updatedTile);
    } else {
      copyTiles.splice(editingTileIndex, 1);
      setEditingTileIndex(null);
    }

    applyChanges({
      tiles: copyTiles,
    });
  }, [tiles, editingTileIndex]);

  let tileInputName = 'Geen afbeelding';
  if (tiles && tiles.length > 0) {
    tileInputName = `${tiles.length} afbeelding${tiles.length > 1 ? 'en' : ''}`;
  }

  const handleChangeHitbox = useCallback((hitboxIndex: number|null, newHitbox: HiddenObjectsGameTileHitbox|null) => {
    if (editingTileIndex === null || !tiles || !tiles[editingTileIndex]) {
      console.error('Tried to update hitbox of tile that does not exits', { editingTileIndex, newHitbox });
      return;
    }

    const hitboxes = [...tiles[editingTileIndex].hitbox];
    if (newHitbox) {
      if (hitboxIndex === null) {
        hitboxes.push(newHitbox);
      } else {
        hitboxes[hitboxIndex] = { ...newHitbox };
      }
    } else if (hitboxIndex !== null) {
      hitboxes.splice(hitboxIndex, 1);
    } else {
      return; // Nothing to do
    }

    if (hitboxes.length === 0) {
      hitboxes.push({ ...DEFAULT_HITBOX }); // There must always be one hitbox
    }

    handleChangeTile({ ...tiles[editingTileIndex], hitbox: hitboxes });
  }, [tiles, editingTileIndex]);

  return <div className="hidden-objects-game-editor">
    <Row>
      <Form.Group as={Col}>
        <Form.Label>Upload de woordplaat</Form.Label>
        <ImageInput value={image} onChange={(value) => applyChanges({ image: value || undefined })} onBulkChange={handleBulkChange} displayDelete={false} />
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label>Upload de 20 tegels van de woordplaat</Form.Label>
        <ImageInput value={null} filename={tileInputName} onChange={handleAddTile} onBulkChange={handleBulkChange} displayDelete={false} />
      </Form.Group>
    </Row>

    <Row>
      <Form.Group as={Col}>
        <Form.Label>Audio opname van introductie woordplaat</Form.Label>
        <AudioInput value={introRecording} onChange={(value) => applyChanges({ introRecording: value || undefined })} />
      </Form.Group>

      <Col></Col>
    </Row>

    { image && <h3>Selecteer een van de tegels om een woord toe te voegen</h3> }
    <HiddenObjectsGameDisplay
      image={image} tiles={tiles || []}
      selectedIndex={editingTileIndex}
      onClick={(index) => setEditingTileIndex(index)}
      hitboxes={editingTile?.hitbox}
      onChangeHitbox={handleChangeHitbox} />

    { (tiles && editingTile) && <TileEditor id={editingTileIndex} tile={editingTile} onChange={handleChangeTile} /> }
  </div>;
}
