"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PupilDecodingResultSchema = exports.HiddenObjectsGameScoreBoard = exports.PupilHiddenObjectsGameResultSchema = exports.EPupilProgressStatus = void 0;
var Joi = __importStar(require("joi"));
var EPupilProgressStatus;
(function (EPupilProgressStatus) {
    EPupilProgressStatus["STARTED"] = "started";
    EPupilProgressStatus["FINISHED"] = "finished";
    EPupilProgressStatus["RETRY"] = "retry";
    EPupilProgressStatus["LOCKED"] = "locked";
    EPupilProgressStatus["NEW"] = "new";
    EPupilProgressStatus["DISABLED"] = "disabled";
})(EPupilProgressStatus = exports.EPupilProgressStatus || (exports.EPupilProgressStatus = {}));
exports.PupilHiddenObjectsGameResultSchema = Joi.object({
    version: Joi.number().integer().min(1).required(),
    items: Joi.array().items(Joi.object({
        word: Joi.string().min(1).required(),
        failedAttempts: Joi.number().integer().min(0).required(),
    })).required(),
});
var HiddenObjectsGameScoreBoard = /** @class */ (function () {
    function HiddenObjectsGameScoreBoard() {
        var _this = this;
        this.result = { version: 1, items: [] };
        this.getItem = function (word) {
            var tileResult = _this.result.items.find(function (item) { return item.word === word; });
            if (tileResult) {
                return tileResult;
            }
            var newLength = _this.result.items.push({
                word: word,
                failedAttempts: 0,
            });
            return _this.result.items[newLength - 1];
        };
        this.resetGameResult = function () {
            _this.result.items = [];
        };
        this.registerFailedClick = function (word) {
            var item = _this.getItem(word);
            item.failedAttempts++;
        };
        this.registerCorrectClick = function (word) {
            _this.getItem(word);
        };
        this.getResult = function () { return JSON.stringify(_this.result); };
    }
    return HiddenObjectsGameScoreBoard;
}());
exports.HiddenObjectsGameScoreBoard = HiddenObjectsGameScoreBoard;
exports.PupilDecodingResultSchema = Joi.object({
    version: Joi.number().integer().required(),
    questions: Joi.array().items(Joi.object({
        question: Joi.string().required(),
        correctOption: Joi.string().required(),
        wrongOptions: Joi.array().items(Joi.string()).required(),
        attempts: Joi.array().items(Joi.string()).required(),
    })).optional(),
});
