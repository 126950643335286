import { EInstructionLabel, ILesson } from 'shared';
import { EIcon } from '../../../common/utils/icon.component';
import { InstructionLabel } from '../../../common/utils/instruction-label.component';
import { RoundButton } from '../../../common/utils/round-button.component';
import { DecodingLayout } from '../decoding/decoding-layout';
import { Word } from '../../../common/utils/transcript-display.component';
import './reading-comprehension-overview.scss';

interface IReadingComprehensionOverviewProps { progress: number, lesson: ILesson, onClose: () => void, onStart: () => void }

const ReadingComprehensionOverview = (props: IReadingComprehensionOverviewProps): JSX.Element => (
  <DecodingLayout {...props}>
    <div className="reading-comprehension-overview">
      <h2 className="reading-comprehension-overview__info"><InstructionLabel instructionKey={EInstructionLabel.KLIK_OP_ONDERSTREEPT_WOORD_UITLEG} /></h2>
      <p className="reading-comprehension-overview__guide">
        <Word word={{ startTime: 0, endTime: 0, word: 'Vlam is een' }} /><Word word={{ startTime: 0, endTime: 0, word: 'draak' }} difficultExampleUrl="/assets/vlam-blij.png" />
      </p>
      <RoundButton icon={EIcon.CHEVRON_RIGHT} className="reading-comprehension-overview__start-button" variant="success" showLabel={true} onClick={props.onStart}>Start les</RoundButton>
    </div>
  </DecodingLayout>
);

export default ReadingComprehensionOverview;
