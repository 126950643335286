import React, { Dispatch, SetStateAction, useState } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { School } from '../../common/api/types';
import { DynamicTable, DynamicTableColumn, ESortDirection } from '../../common/utils/dynamic-table.component';
import { EIcon, Icon } from '../../common/utils/icon.component';
import { PageTitle } from '../../common/utils/page-title.component';
import { EAdminRouterPath } from '../../router-path';
import { CreateSchool } from './create';

interface ISchoolsTableData {
  id: number;
  name: string;
  friendlyId: string;
  subscriptionType: string;
  subscriptionEndAt: string;
  button: React.ReactNode;
}

const CreateSchoolModal = ({ modalOpen, handleCloseModal }: { modalOpen: boolean, handleCloseModal: Dispatch<SetStateAction<void>>}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal show={modalOpen} onHide={() => handleCloseModal()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t('school:add-school')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CreateSchool />
      </Modal.Body>
    </Modal>
  );
};

function SchoolTable({ schools }: { schools?: School[] }): JSX.Element|null {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const columns: DynamicTableColumn<ISchoolsTableData>[] = [
    { title: t('common:id'), objectKey: 'id' },
    { title: t('school:name'), objectKey: 'name' },
    { title: t('school:friendly-id'), objectKey: 'friendlyId' },
    { title: t('school:subscription-type'), objectKey: 'subscriptionType' },
    { title: t('school:subscription-end'), objectKey: 'subscriptionEndAt' },
    { title: '', objectKey: 'button', disableSort: true },
  ];

  if (!schools) {
    return <DynamicTable<ISchoolsTableData> columns={columns} data={[]} sortByDefault={columns[0]} sortDirectionDefault={ESortDirection.ASCENDING} onClickRow={school => navigate(EAdminRouterPath.SCHOOL_SHOW.fullPath(school.id))} />;
  }

  const data: ISchoolsTableData[] = schools.filter(school => school.name?.toLowerCase().includes(searchQuery.toLowerCase())).map(({ id, name, friendlyId, subscriptionType, invalidSubscription, subscriptionEndAt }) => ({
    id,
    name: name || '',
    friendlyId: friendlyId || '',
    subscriptionType: `${t(`school:subscription.${subscriptionType || ''}`) || t('school:no-subscription') || ''} (${t('school:subscription.expired', { expired: invalidSubscription ? t('common:yes') : t('common:no') })})`,
    subscriptionEndAt: subscriptionEndAt?.toLocaleDateString() || '-',
    button: <Button onClick={() => navigate(EAdminRouterPath.SCHOOL_SHOW.fullPath(id))}>{t('school:go-to')}</Button>
  }));


  return <>
    <div className="lesson-table__header">
      <PageTitle>{t('school:page-title')}</PageTitle>
      <div className="lesson-table__header-actions">
        <InputGroup className="input__search--user">
          <InputGroup.Text id="zoekIcoon">
            <Icon>{EIcon.SEARCH}</Icon>
          </InputGroup.Text>
          <Form.Control
            type="text"
            id="searchField"
            aria-describedby="zoekIcoon"
            placeholder={t('school:search')}
            autoComplete="off"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {
            searchQuery && (
              <InputGroup.Text id="clear-icon" onClick={() => setSearchQuery('')}>
                <Icon>{EIcon.X}</Icon>
              </InputGroup.Text>
            )
          }
        </InputGroup>
        <Button className="outer-shadow bigger-radius" onClick={() => setModalOpen(true)}><Icon>{EIcon.PLUS}</Icon>{t('school:add-new-school')}</Button>
      </div>
    </div>
    <DynamicTable<ISchoolsTableData> columns={columns} data={data} sortByDefault={columns[0]} sortDirectionDefault={ESortDirection.ASCENDING} onClickRow={school => navigate(EAdminRouterPath.SCHOOL_SHOW.fullPath(school.id))} />
    <CreateSchoolModal modalOpen={modalOpen} handleCloseModal={() => setModalOpen(false)}/>
  </>;
}

export default SchoolTable;
