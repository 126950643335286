import { ELessonLevel } from 'shared';
import React, { FormEvent, PropsWithChildren, useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { Group } from '../../common/api/types';
import { DangerButton } from '../../common/utils/danger-button.component';
import { useTranslation } from 'react-i18next';
import { AvatarInput } from '../../common/utils/avatar-input.component';

const INPUT_MAX_LENGTH = 255;

interface EditGroupModalFormProps {
  visible: boolean;
  name: string;
  onChangeName: (value: string) => void;
  level: string;
  onChangeLevel: (value: ELessonLevel) => void;
  avatar: string|null;
  onChangeAvatar: (value: string|null) => void;
  title: string;
  onCancel: () => void;
  onSubmit: () => void;
}

function EditGroupModalForm({ visible, title, name, onChangeName, level, onChangeLevel, avatar, onChangeAvatar, onCancel, onSubmit, children }: PropsWithChildren<EditGroupModalFormProps>) {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  const { t } = useTranslation();

  return <Modal size="lg" show={visible} onHide={onCancel}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Naam van de groep</Form.Label>
            <Form.Control
              type="text"
              required
              minLength={1}
              maxLength={INPUT_MAX_LENGTH}
              value={name}
              onChange={e => onChangeName(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Niveau van de groep</Form.Label>
            <Form.Select value={level} onChange={e => onChangeLevel(e.target.value as ELessonLevel)}>
              { Object.values(ELessonLevel).map((value) => <option key={value} value={value}>
                {t(`lesson-level:${value}`)}
              </option>) }
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Avatar van de groep</Form.Label>
            <AvatarInput value={avatar} onChange={onChangeAvatar} />
          </Form.Group>
          <Col />
        </Row>
      </Modal.Body>

      <Modal.Footer>{children}</Modal.Footer>
    </Form>
  </Modal>;
}

interface EditGroupModalProps {
  value?: Group|null;
  onChange: (group: Group) => void;
  onDelete: (group: Group) => void;
  onCancel: () => void;
}

export function EditGroupModal({ value, onChange, onDelete, onCancel }: EditGroupModalProps): JSX.Element {
  const [name, setName] = useState(value?.name || '');
  const [level, setLevel] = useState(value?.level || ELessonLevel.LEVEL_3);
  const [avatar, setAvatar] = useState(value?.avatar || null);

  useEffect(() => {
    if (!value) return;
    setName(value.name);
    setLevel(value.level);
    setAvatar(value.avatar || null);
  }, [value]);

  const handleSubmit = () => {
    if (!value) return;
    onChange({
      ...value,
      name: name.trim(),
      level,
      avatar: avatar || undefined,
    });
  };

  const handleDelete = () => {
    if (!value) return;
    onDelete(value);
  };

  return <EditGroupModalForm
    title="Groep bewerken"
    visible={!!value}
    name={name}
    onChangeName={setName}
    level={level}
    onChangeLevel={setLevel}
    avatar={avatar}
    onChangeAvatar={setAvatar}
    onCancel={onCancel}
    onSubmit={handleSubmit}
  >
    <DangerButton
      modalText={`Weet je zeker dat je ${value?.name || ''} wilt verwijderen?`}
      modalConfirmLabel="Verwijderen"
      modalCancelLabel="Behouden"
      onClick={() => handleDelete()}
    >Groep verwijderen</DangerButton>
    <Button variant="primary" type="submit">Groep opslaan</Button>
  </EditGroupModalForm>;
}

interface CreateGroupModalProps {
  visible: boolean;
  onCreate: (newGroup: Omit<Group, 'id'|'schoolId'>) => void;
  onCancel: () => void;
}

export function CreateGroupModal({ visible, onCreate, onCancel }: CreateGroupModalProps): JSX.Element {
  const [name, setName] = useState('');
  const [level, setLevel] = useState(ELessonLevel.LEVEL_3);
  const [avatar, setAvatar] = useState<string|null>(null);

  useEffect(() => {
    if (visible) {
      return;
    }

    // Reset the values every time the modal is hidden
    setName('');
    setLevel(ELessonLevel.LEVEL_3);
    setAvatar(null);
  }, [visible]);

  const handleSubmit = () => {
    onCreate({
      name: name.trim(),
      level,
      avatar: avatar || undefined,
    });
  };

  return <EditGroupModalForm
    title="Voeg een groep toe"
    visible={visible}
    name={name}
    onChangeName={setName}
    level={level}
    onChangeLevel={setLevel}
    avatar={avatar}
    onChangeAvatar={setAvatar}
    onCancel={onCancel}
    onSubmit={handleSubmit}
  >
    <Button variant="primary" type="submit">Groep aanmaken</Button>
  </EditGroupModalForm>;
}
