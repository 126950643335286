import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ELessonRouterPath, EMainRouterPath } from '../../router-path';
import { useHint } from '../hint-provider/hint.context';
import { useSchool } from '../school-provider/school.context';
import { EIcon } from '../utils/icon.component';
import { RoundButton } from '../utils/round-button.component';
import './pupil.layout.scss';

export function PupilLayout({ horizontalBackground, homeButton, switchButtons, children }: PropsWithChildren<{ horizontalBackground?: boolean, homeButton?: boolean, switchButtons?: boolean }>): JSX.Element {
  const { school } = useSchool();
  const navigate = useNavigate();
  const { setHintMessage } = useHint();
  const { t } = useTranslation();
  // Don't show the login button if the user is in app mode. Else the user might get stuck in the login screen
  const hideLoginButton = window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches;

  const handleHome = () => {
    setHintMessage('');
    navigate(ELessonRouterPath.HOME.fullPath);
  };

  return <div className={classNames('pupil-layout', { 'pupil-layout--horizontal-background': horizontalBackground })}>
    <main className="pupil-layout__main-container">
      <div className="pupil-layout__header">
        <h2 className="pupil-layout__school-name">{school?.name}</h2>
        { switchButtons && <div className="pupil-layout__switch-button-list">
          <Link className="pupil-layout__switch-button pupil-layout__switch-button--school" to={EMainRouterPath.SELECT_SCHOOL.fullPath({ noAutoRedirect: true })}>{t('pupil:select-other-school')}</Link>
          { !hideLoginButton && <Link className="pupil-layout__switch-button pupil-layout__switch-button--login" to={EMainRouterPath.AUTH.path}>{t('pupil:switch-to-login')}</Link> }
        </div> }
      </div>
      {children}
      {
        homeButton &&
        <div className="pupil-layout__home-button-container">
          <RoundButton className="pupil-layout__home-button" large={true} icon={EIcon.HOME} onClick={handleHome}>Home</RoundButton>
        </div>
      }
    </main>
  </div>;
}
