import { ILesson, IPupilDecodingResult, ReadingComprehensionTextExercise } from 'shared';
import { IExerciseProps } from '..';
import { DecodingLayout } from '../decoding/decoding-layout';
import { ReadingComprehensionText } from './reading-comprehension-text';
import { useState } from 'react';
import { ReadingComprehensionQuestions } from './reading-comprehension-questions';
import { Status } from '../../../common/utils/status.component';

interface IShowReadingComprehensionTextProps extends IExerciseProps<ReadingComprehensionTextExercise> {
  progress: number;
  lesson: ILesson;
}

enum EContent {
  TEXT,
  QUESTIONS,
}

export const ShowReadingComprehensionText = ({ lesson, progress, onClose, exercise, onStepForward, onFinishExercise }: IShowReadingComprehensionTextProps): JSX.Element => {
  const [currentContent, setCurrentContent] = useState<EContent>(EContent.TEXT);
  const questions = exercise?.data?.questions;

  const handleGoToNext = (result?: IPupilDecodingResult) => {
    if (currentContent === EContent.TEXT && questions && questions.length > 0) {
      // There are questions
      setCurrentContent(EContent.QUESTIONS);
    } else if (currentContent === EContent.TEXT) {
      // No questions
      onFinishExercise(result && JSON.stringify(result));
    } else if (currentContent === EContent.QUESTIONS) {
      onFinishExercise(result && JSON.stringify(result));
    }

    onStepForward();
  };

  const handleOnStep = () => {
    onStepForward();
  };

  const renderContent = () => {
    switch (currentContent) {
      case EContent.TEXT:
        return <ReadingComprehensionText exercise={exercise} onContinue={handleGoToNext} />;
      case EContent.QUESTIONS:
        return <ReadingComprehensionQuestions exercise={exercise} onContinue={handleGoToNext} onStep={handleOnStep} />;
      default:
        return <Status error="Unexpected content type"/>;
    }
  };

  return <DecodingLayout onClose={onClose} lesson={lesson} progress={progress}>
    {renderContent()}
  </DecodingLayout>;
};
