import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EMainRouterPath } from '../../router-path';
import { useHint } from '../hint-provider/hint.context';
import HintBalloon from './hint-balloon.component';
import './not-found.component.scss';

const NotFound = (): JSX.Element => {
  const { setHintOpen, setHintMessage } = useHint();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setHintMessage(t('errors:not-found.message'));
    setHintOpen(true);
  });

  return (
    <div className="not-found__page">
      <HintBalloon />
      <Button className="not-found__button" onClick={() => navigate(EMainRouterPath.SELECT_SCHOOL.fullPath({ noAutoRedirect: true }))}>{t('errors:not-found.button')}</Button>
    </div>
  );
};

export default NotFound;
