import { useRef, useState } from 'react';
import { ReadingComprehensionTextExercise } from 'shared';
import { EIcon } from '../../../common/utils/icon.component';
import { RoundButton } from '../../../common/utils/round-button.component';
import { ReadingComprehensionLayout } from './reading-comprehension-layout';
import { ITextDisplayFunctions, TextDisplay } from '../../../common/utils/text-display.component';

type TReadingComprehensionTextProps = {
  exercise: ReadingComprehensionTextExercise;
  onContinue: () => void;
}

export function ReadingComprehensionText({ exercise, onContinue }: TReadingComprehensionTextProps): JSX.Element {
  const [isPlaying, setIsPlaying] = useState(false);
  const textDisplayRef = useRef<ITextDisplayFunctions>(null);

  const text = exercise.data?.text || '';
  const recording = exercise.data?.recording;
  const difficultWords = exercise.data?.words || [];

  const handleGoToNext = () => {
    onContinue();
  };

  const handleClickPlay = () => {
    if (!textDisplayRef.current) {
      return;
    }

    if (isPlaying) {
      textDisplayRef.current.stop();
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
      textDisplayRef.current.play(() => setIsPlaying(false));
    }
  };

  return <ReadingComprehensionLayout
    onContinue={handleGoToNext}
    buttons={
      <RoundButton icon={isPlaying ? EIcon.SPEAKER_OFF : EIcon.SPEAKER} variant="info" onClick={handleClickPlay} />
    }
  >
    <TextDisplay ref={textDisplayRef} text={text} difficultWords={difficultWords || []} recording={recording} />
  </ReadingComprehensionLayout>;
}
