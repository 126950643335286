import { ELessonBook, ELessonCategory, ELessonLevel } from 'shared';
import { EShowLessonState } from './admin-module/lesson/show';

function toQueryParameters(map: { [key: string]: string|boolean }): string {
  const parameters = Object.keys(map)
    .filter(key => {
      const value = map[key];
      return value !== undefined;
    })
    .map(key => {
      let value = map[key];
      if (typeof value === 'boolean') {
        value = value ? 'true' : 'false';
      }
      return `${key}=${value}`;
    })
    .join('&');

  if (parameters) {
    return `?${parameters}`;
  }
  return '';
}

export const EMainRouterPath = {
  ADMIN_MODULE: { path: '/admin' },
  SCHOOL_MODULE: { path: '/school/:schoolId', fullPath: (schoolId: number|string): string => `/school/${schoolId}` },
  PUPIL_MODULE: { path: '/pupil/:schoolId', fullPath: (schoolId: number|string): string => `/pupil/${schoolId}` },
  PUPIL_SCHOOL_SHORT_LINK: { path: '/:schoolId', fullPath: (schoolId: number|string): string => `/${schoolId}` },
  LESSON_MODULE: { path: '/lesson' },
  AUTH: { path: '/auth' },
  AUTH_CALLBACK: { path: '/auth/callback' },
  NOT_FOUND: { path: '/not-found' },
  SELECT_SCHOOL: { path: '/pupil', fullPath: (params: { noAutoRedirect?: boolean } = {}): string => `/pupil${toQueryParameters(params)}` }
};

export const EAdminRouterPath = {
  HOME: { path: '', fullPath: EMainRouterPath.ADMIN_MODULE.path },

  SCHOOL_LIST: { path: 'school', fullPath: `${EMainRouterPath.ADMIN_MODULE.path}/school` },
  SCHOOL_CREATE: { path: 'school/new', fullPath: `${EMainRouterPath.ADMIN_MODULE.path}/school/new` },
  SCHOOL_SHOW: { path: 'school/:id', fullPath: (id: number): string => `${EMainRouterPath.ADMIN_MODULE.path}/school/${id}` },

  LESSON_LIST: { path: 'lesson', fullPath: (params: { category?: ELessonCategory, level?: ELessonLevel, book?: ELessonBook } = {}): string => `${EMainRouterPath.ADMIN_MODULE.path}/lesson${toQueryParameters(params)}` },
  LESSON_CREATE: { path: 'lesson/new', fullPath: (params: { category?: ELessonCategory, level?: ELessonLevel, book?: ELessonBook } = {}): string => `${EMainRouterPath.ADMIN_MODULE.path}/lesson/new${toQueryParameters(params)}` },
  LESSON_SHOW: { path: 'lesson/:id', fullPath: (id: number, params: { state?: EShowLessonState } = {}): string => `${EMainRouterPath.ADMIN_MODULE.path}/lesson/${id}${toQueryParameters(params)}` },

  USER_LIST: { path: 'user', fullPath: `${EMainRouterPath.ADMIN_MODULE.path}/user` },
  USER_CREATE: { path: 'user/new', fullPath: `${EMainRouterPath.ADMIN_MODULE.path}/user/new` },
  USER_SHOW: { path: 'user/:id', fullPath: (id: number): string => `${EMainRouterPath.ADMIN_MODULE.path}/user/${id}` },

  USAGE_LIST: { path: 'usage', fullPath: `${EMainRouterPath.ADMIN_MODULE.path}/usage` },
  USAGE_SHOW: { path: 'usage/:runId', fullPath: (runId: string, params: { compareToRunId?: string}): string => `${EMainRouterPath.ADMIN_MODULE.path}/usage/${runId}${toQueryParameters(params)}` },

  INSTRUCTION_LABEL_LIST: { path: 'label', fullPath: `${EMainRouterPath.ADMIN_MODULE.path}/label` },
};

export const ESchoolRouterPath = {
  HOME: { path: '', fullPath: (schoolId: number|string): string => EMainRouterPath.SCHOOL_MODULE.fullPath(schoolId) },

  USER_LIST: { path: 'user', fullPath: (schoolId: number|string): string => `${EMainRouterPath.SCHOOL_MODULE.fullPath(schoolId)}/user` },

  PROGRESS: { path: 'progress', fullPath: (schoolId: number|string): string => `${EMainRouterPath.SCHOOL_MODULE.fullPath(schoolId)}/progress` },
  PROGRESS_DETAIL: { path: 'progress/:pupilId', fullPath: (schoolId: number|string, pupilId: number|string, params: { category?: ELessonCategory } = {}): string => `${EMainRouterPath.SCHOOL_MODULE.fullPath(schoolId)}/progress/${pupilId}${toQueryParameters(params)}` },
};

export const EPupilRouterPath = {
  HOME: { path: '', fullPath: (schoolId: number|string): string => EMainRouterPath.PUPIL_MODULE.fullPath(schoolId) },

  AUTH: { path: 'auth/:pupilId', fullPath: (schoolId: number|string, groupId: number, pupilId: number): string => `${EMainRouterPath.PUPIL_MODULE.fullPath(schoolId)}/auth/${pupilId}?group=${groupId}` },

  GROUP_LIST: { path: 'group', fullPath: (schoolId: number|string): string => `${EMainRouterPath.PUPIL_MODULE.fullPath(schoolId)}/group` },

  PUPIL_LIST: { path: 'group/:groupId', fullPath: (schoolId: number|string, groupId: number): string => `${EMainRouterPath.PUPIL_MODULE.fullPath(schoolId)}/group/${groupId}` },
};

export const ELessonRouterPath = {
  HOME: { path: '', fullPath: EMainRouterPath.LESSON_MODULE.path },

  LIST_LESSONS: { path: 'list/:lessonCategory', fullPath: (lessonCategory: ELessonCategory, lessonBook?: ELessonBook | null): string => `${EMainRouterPath.LESSON_MODULE.path}/list/${lessonCategory}${lessonBook ? `?book=${lessonBook}` : ''}` },
  SHOW_LESSON: { path: ':lessonId', fullPath: (lessonId: number): string => `${EMainRouterPath.LESSON_MODULE.path}/${lessonId}` },
};
