/**
 * Preform in place Fisher-Yates shuffle on provided array.
 * @param array array to shuffle.
 */
export function shuffle<T>(array: T[]): void {
  // Fisher–Yates shuffle
  let currentIndex = array.length;
  let randomIndex: number;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore array[currentIndex] and array[randomIndex] will not be undefined
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
}
