import React, { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { Image, HiddenObjectsGameTile, hiddenObjectsGameTileCompleteSchema, HiddenObjectsGameTileHitbox, DEFAULT_HITBOX } from 'shared';
import './hidden-objects-game-display.component.scss';
import classNames from 'classnames';
import { EIcon, Icon } from '../../../../common/utils/icon.component';
import { Button } from 'react-bootstrap';
import { getStorageUrl } from '../../../../common/utils/storage.helper';

const CLICK_AREA_PADDING = 5.748 + 2.4; // 5.748% = tile size, 2.4% = margin between tile and edge. See .scss for these values
const MIN_HITBOX_SIZE = 3; // % of image width/height

const clamp = (min: number, value: number, max: number) => Math.min(Math.max(value, min), max);

interface MousePos {
  mouseX: number;
  mouseY: number;
}

interface HitboxProps {
  hitbox: HiddenObjectsGameTileHitbox;
  imageRef: HTMLImageElement|null;
  hasFocus: boolean;
  disableDelete: boolean;
  onClickHitbox: () => void;
  onChangeHitbox?: (hitbox: HiddenObjectsGameTileHitbox|null) => void;
}

function Hitbox({ imageRef, hasFocus, disableDelete, onClickHitbox, onChangeHitbox, ...props }: HitboxProps): JSX.Element {
  const [hitbox, setHitbox] = useState({ ...props.hitbox });
  const [initialHitbox, setInitialHitbox] = useState({ ...props.hitbox });
  const [mouseDownHitbox, setMouseDownHitbox] = useState(false);
  const [mouseDownSizeHandle, setMouseDownSizeHandle] = useState(false);
  const [mouseDownRoundnessHandle, setMouseDownRoundnessHandle] = useState(false);
  const [lastClickPos, setLastClickPos] = useState<MousePos>({ mouseX: 0, mouseY: 0 });
  const hitboxRef = useRef<HTMLDivElement>(null);
  const resizeButtonRef = useRef<HTMLButtonElement>(null);
  const canEdit = !!onChangeHitbox;
  const showEditHandles = canEdit && hasFocus;

  useEffect(() => { // onChangeProps
    setHitbox({ ...props.hitbox });
    setInitialHitbox({ ...props.hitbox });
  }, [props.hitbox]);

  const handleDragSizeHandle = ({ mouseX, mouseY }: MousePos) => {
    if (!imageRef || !hitboxRef.current || !resizeButtonRef.current || !hitbox) {
      return;
    }

    const { x: imageX, y: imageY, width: imageWidth, height: imageHeight } = imageRef.getBoundingClientRect(); // X,Y values relative to viewport

    // Get the current position of the hitbox on the image
    const { x: hitboxX, y: hitboxY } = hitboxRef.current.getBoundingClientRect(); // X,Y values relative to viewport
    const hitboxImageX = hitboxX - imageX;
    const hitboxImageY = hitboxY - imageY;

    // Get the point where the user clicked on the image
    let imageClickX = mouseX - imageX;
    let imageClickY = mouseY - imageY;

    // Center the handle on the user click point
    const { width: buttonWidth, height: buttonHeight } = resizeButtonRef.current.getBoundingClientRect();
    imageClickX = imageClickX - (buttonWidth / 2);
    imageClickY = imageClickY - (buttonHeight / 2);

    // Calculate new size
    let newWidth = imageClickX - hitboxImageX;
    let newHeight = imageClickY - hitboxImageY;

    // Convert pixels to values relative to image size
    newWidth = (100 / imageWidth) * newWidth;
    newHeight = (100 / imageHeight) * newHeight;

    if (newWidth < MIN_HITBOX_SIZE) {
      newWidth = MIN_HITBOX_SIZE;
    } else if (hitbox.x + newWidth > (100 - CLICK_AREA_PADDING)) { // 100 as in 100% of image width
      newWidth = (100 - CLICK_AREA_PADDING) - hitbox.x;
    }

    if (newHeight < MIN_HITBOX_SIZE) {
      newHeight = MIN_HITBOX_SIZE;
    } else if (hitbox.y + newHeight > 100) { // 100 as in 100% of image height
      newHeight = 100 - hitbox.y;
    }

    setHitbox({
      ...hitbox,
      // Convert pixels to relative values
      width: newWidth,
      height: newHeight,
    });
  };

  const handleDragRoundnessHandle = ({ mouseY }: MousePos) => {
    if (!hitbox) {
      return;
    }

    const mouseDistanceDraggedY = lastClickPos.mouseY - mouseY;
    const newRoundness = clamp(0, initialHitbox.roundness + mouseDistanceDraggedY, 100);

    setHitbox({
      ...hitbox,
      roundness: newRoundness,
    });
  };

  const handleDragHitbox = ({ mouseX, mouseY }: MousePos) => {
    if (!imageRef || !hitboxRef.current || !hitbox) {
      return;
    }

    if (mouseDownSizeHandle || mouseDownRoundnessHandle) {
      // Don't drag while using the handlers
      return;
    }

    const { x: imageX, y: imageY, width: imageWidth, height: imageHeight } = imageRef.getBoundingClientRect(); // X,Y values relative to viewport

    // Get the point where the mouse is on the image
    const imageClickX = mouseX - imageX;
    const imageClickY = mouseY - imageY;

    // Get the point where the mouse fist clicked on the image
    const initialImageClickX = lastClickPos.mouseX - imageX;
    const initialImageClickY = lastClickPos.mouseY - imageY;

    // Get initial hitbox position relative to image
    const initialHitboxImageX = (initialHitbox.x / 100) * imageWidth;
    const initialHitboxImageY = (initialHitbox.y / 100) * imageHeight;

    // Get the amount the user has dragged from when they started
    const draggedX = imageClickX - initialImageClickX;
    const draggedY = imageClickY - initialImageClickY;

    // Move hitbox by amount dragged
    const newHitboxX = initialHitboxImageX + draggedX;
    const newHitboxY = initialHitboxImageY + draggedY;

    // Get relative position of hitbox
    let newRelativeHitboxX = (100 / imageWidth) * newHitboxX;
    let newRelativeHitboxY = (100 / imageHeight) * newHitboxY;

    const minPosX = CLICK_AREA_PADDING;
    const maxPosX = (100 - CLICK_AREA_PADDING) - hitbox.width; // 100 as in 100% of image width
    const minPosY = 0; // 0 as in 0% of image height
    const maxPosY = 100 - hitbox.height; // 100 as in 100% of image height

    // Constrain hitbox within image - space for the tiles
    newRelativeHitboxX = clamp(minPosX, newRelativeHitboxX, maxPosX);
    newRelativeHitboxY = clamp(minPosY, newRelativeHitboxY, maxPosY);

    setHitbox({
      ...hitbox,
      x: newRelativeHitboxX,
      y: newRelativeHitboxY,
    });
  };

  const handleMouseDownHitbox = (event: React.MouseEvent) => {
    setLastClickPos({ mouseX: event.clientX, mouseY: event.clientY });
    onClickHitbox();
    setMouseDownHitbox(true);
  };

  const handleMouseUpHitbox = () => {
    setMouseDownHitbox(false);
  };

  const handleMouseDownSizeHandle = (event: React.MouseEvent) => {
    setLastClickPos({ mouseX: event.clientX, mouseY: event.clientY });
    setMouseDownSizeHandle(true);
  };

  const handleMouseUpSizeHandle = () => {
    setMouseDownSizeHandle(false);
  };

  const handleMouseDownRoundnessHandle = (event: React.MouseEvent) => {
    setLastClickPos({ mouseX: event.clientX, mouseY: event.clientY });
    setMouseDownRoundnessHandle(true);
  };

  const handleMouseUpRoundnessHandle = () => {
    setMouseDownRoundnessHandle(false);
  };

  useEffect(() => {
    const handleMouseUp = () => {
      // React batching causes part of this update to be applied while others are run
      // later. This causes onMouseDownOrUp to be called assuming an older state.
      flushSync(() => { // Update state immediately
        handleMouseUpHitbox();
        handleMouseUpSizeHandle();
        handleMouseUpRoundnessHandle();
      });
    };

    document.addEventListener('mouseup', handleMouseUp);
    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => { // onMouseDownOrUp
    if (!mouseDownHitbox && !mouseDownSizeHandle && !mouseDownRoundnessHandle) {
      // No interactions with image or editHandlers
      return;
    }

    if (!hasFocus) {
      // Only edit hitbox if there are changes
      return;
    }

    const handleMouseDrag = (event: MouseEvent|MousePos) => {
      const mousePos = event instanceof MouseEvent ? { mouseX: event.clientX, mouseY: event.clientY } : event;

      if (mouseDownHitbox) {
        handleDragHitbox(mousePos);
      }

      if (mouseDownSizeHandle) {
        handleDragSizeHandle(mousePos);
      }

      if (mouseDownRoundnessHandle) {
        handleDragRoundnessHandle(mousePos);
      }
    };

    handleMouseDrag(lastClickPos);
    document.body.addEventListener('mousemove', handleMouseDrag);
    // eslint-disable-next-line consistent-return
    return () => {
      document.body.removeEventListener('mousemove', handleMouseDrag);
    };
  }, [mouseDownHitbox, mouseDownSizeHandle, mouseDownRoundnessHandle]);

  useEffect(() => {
    // If we instantly report the hitbox change the entire object might get updated a lot of times per second.
    // Therefor we have a private hitbox copy to edit and once the user is done we will report the changes,
    // if any, to the parent component. If we don't do this the app will be slow.
    if (mouseDownSizeHandle || mouseDownRoundnessHandle || mouseDownHitbox) {
      return;
    }

    if (onChangeHitbox && hitbox && initialHitbox && (
      initialHitbox.x !== hitbox.x ||
      initialHitbox.y !== hitbox.y ||
      initialHitbox.width !== hitbox.width ||
      initialHitbox.height !== hitbox.height ||
      initialHitbox.roundness !== hitbox.roundness
    )) {
      setInitialHitbox({ ...hitbox });
      onChangeHitbox({ ...hitbox });
    }
  }, [hitbox, initialHitbox, mouseDownSizeHandle, mouseDownRoundnessHandle, mouseDownHitbox, onChangeHitbox]);

  const handleDeleteHitbox = () => {
    if (!onChangeHitbox) {
      return;
    }
    onChangeHitbox(null);
  };

  return <div
    ref={hitboxRef}
    className={classNames('hidden-objects-game-display__hitbox', { 'hidden-objects-game-display__hitbox--edit': canEdit, 'hidden-objects-game-display__hitbox--focus': hasFocus })}
    onMouseDown={handleMouseDownHitbox}
    onMouseUp={handleMouseUpHitbox}
    style={{ top: `${hitbox.y}%`, left: `${hitbox.x}%`, width: `${hitbox.width}%`, height: `${hitbox.height}%`, borderRadius: `${hitbox.roundness}%` }}
  >
    { showEditHandles && <div className="hidden-objects-game-display__hitbox-handles">
      <button ref={resizeButtonRef} className="hidden-objects-game-display__hitbox-handle hidden-objects-game-display__hitbox-handle--size" onMouseDown={handleMouseDownSizeHandle} onMouseUp={handleMouseUpSizeHandle}>
        <Icon>{EIcon.ENLARGE}</Icon>
        <span className="hidden-objects-game-display__hitbox-handle-label">Vergroot/verklein</span>
      </button>
      <button className="hidden-objects-game-display__hitbox-handle hidden-objects-game-display__hitbox-handle--roundness" onMouseDown={handleMouseDownRoundnessHandle} onMouseUp={handleMouseUpRoundnessHandle}>
        <Icon>{EIcon.CIRCLE}</Icon>
        <span className="hidden-objects-game-display__hitbox-handle-label">Rond/vierkant</span>
      </button>
      { !disableDelete && <button className="hidden-objects-game-display__hitbox-handle hidden-objects-game-display__hitbox-handle--delete" onClick={handleDeleteHitbox}>
        <Icon>{EIcon.TRASH}</Icon>
        <span className="hidden-objects-game-display__hitbox-handle-label">Verwijderen</span>
      </button> }
    </div>}
  </div>;
}

export interface HiddenObjectsGameDisplayProps {
  image: Image|null;
  tiles: HiddenObjectsGameTile[];
  hitboxes?: HiddenObjectsGameTileHitbox[];
  randomizeTiles?: boolean;
  onChangeHitbox?: (hitboxIndex: number|null, hitbox: HiddenObjectsGameTileHitbox|null) => void;
  selectedIndex: number|null;
  validTileIndexes?: number[];
  onClick: (index: number) => void;
  onClickImage?: (clickedHitbox: boolean) => void;
}

function generateRandomDisplayOrder(tilesCount: number): number[] {
  const order: number[] = [];
  while (order.length < tilesCount) {
    const randomInt = Math.floor(Math.random() * (tilesCount - 0));
    order.push(randomInt);
  }
  return order;
}

export function HiddenObjectsGameDisplay({ hitboxes, image, tiles, selectedIndex, validTileIndexes, randomizeTiles, onChangeHitbox, onClick, onClickImage }: HiddenObjectsGameDisplayProps): JSX.Element {
  const [focusedHitboxIndex, setFocusedHitboxIndex] = useState<number|null>(null);
  const [tilesDisplayOrder, setTilesDisplayOrder] = useState<number[]>([]);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => { // handleChangeTiles
    if (!randomizeTiles) {
      setTilesDisplayOrder([]);
      return;
    }

    setTilesDisplayOrder(generateRandomDisplayOrder(tiles.length));
  }, [tiles]);

  useEffect(() => { // handleChangeTile
    setFocusedHitboxIndex(null);
  }, [selectedIndex]);

  const handleMouseDownImage = (event: React.MouseEvent) => {
    event.preventDefault(); // Don't drag the image
  };

  const handleClickHitbox = (index: number): void => {
    setFocusedHitboxIndex(index);

    if (!onClickImage) {
      return;
    }
    onClickImage(true);
  };

  const handleClickImage = (): void => {
    setFocusedHitboxIndex(null);

    if (!onClickImage) {
      return;
    }
    onClickImage(false);
  };

  const handleAddHitbox = (): void => {
    if (!onChangeHitbox) {
      return;
    }
    onChangeHitbox(null, { ...DEFAULT_HITBOX });
    setFocusedHitboxIndex(hitboxes?.length || null);
  };

  const renderTile = (tile: HiddenObjectsGameTile, index: number): JSX.Element => {
    let isValid = false;
    if (validTileIndexes) {
      isValid = validTileIndexes.includes(index);
    } else {
      const { error } = hiddenObjectsGameTileCompleteSchema.validate(tile);
      isValid = !error;
    }

    const className = classNames(
      'hidden-objects-game-display__tile',
      {
        'hidden-objects-game-display__tile--selected': selectedIndex === index,
        'hidden-objects-game-display__tile--valid': isValid,
      }
    );

    return <li key={index} className={className} style={{ order: tilesDisplayOrder[index] }}>
      <button className="hidden-objects-game-display__tile-button" onClick={() => onClick(index)}>
        <img className="hidden-objects-game-display__tile-image" src={getStorageUrl(tile.image.smallFilename)} alt="" />
        { isValid && <Icon className="hidden-objects-game-display__tile-valid-check">{EIcon.CHECK_CIRCLE}</Icon> }
      </button>
    </li>;
  };

  const showPlaceholderImage = !image && tiles.length > 0;
  return <div className="hidden-objects-game-display">
    <div className="hidden-objects-game-display__container">
      { showPlaceholderImage && <div className="hidden-objects-game-display__full-image hidden-objects-game-display__full-image--placeholder" /> }
      { image && <img
        ref={imageRef}
        className="hidden-objects-game-display__full-image"
        src={getStorageUrl(image.largeFilename)}
        alt=""
        onMouseDown={handleMouseDownImage}
        onClick={handleClickImage}
      /> }
      <ul className={classNames('hidden-objects-game-display__tiles', { 'hidden-objects-game-display__tiles--selected-mode': selectedIndex !== null })}>
        { tiles.map(renderTile)}
      </ul>
      <div className="hidden-objects-game-display__hitbox-container">
        { (hitboxes && imageRef.current) && hitboxes.map((hitbox, index) => <Hitbox
          key={index}
          hitbox={hitbox}
          imageRef={imageRef.current}
          hasFocus={index === focusedHitboxIndex}
          disableDelete={hitboxes.length === 1}
          onClickHitbox={() => handleClickHitbox(index)}
          onChangeHitbox={onChangeHitbox ? (newHitbox) => onChangeHitbox(index, newHitbox) : undefined}
        />) }
      </div>
    </div>
    { (onChangeHitbox && selectedIndex !== null) && <div className="hidden-objects-game-display__controls">
      <Button className="hidden-objects-game-display__controls-add-hitbox" onClick={handleAddHitbox}>
        <Icon>{EIcon.PLUS}</Icon>
        <span className="hidden-objects-game-display__controls-label">Klikgebied toevoegen</span>
      </Button>
      <div className="hidden-objects-game-display__tip">Definieer het klikgebied van het woord door de cirkel aan te passen</div>
    </div> }
  </div>;
}
