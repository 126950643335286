import React, { ReactElement, useMemo, useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ELessonCategory, IGroup, ILesson, IPupil, IPupilHiddenObjectsGameResult, PupilHiddenObjectsGameResultSchema } from 'shared';
import { Api } from '../../common/api/api';
import { useSchool } from '../../common/school-provider/school.context';
import { getAvatarPath } from '../../common/utils/avatar-path';
import { DateTime } from '../../common/utils/date-time.component';
import { DynamicTable, DynamicTableColumn, ESortDirection } from '../../common/utils/dynamic-table.component';
import { EIcon, Icon } from '../../common/utils/icon.component';
import { Status } from '../../common/utils/status.component';
import { ListDecodingLessonsTile } from '../../lesson-module/list-lesson-items';
import './table.scss';
import './styles/index.scss';
import { ESchoolRouterPath } from '../../router-path';
import { useNavigate } from 'react-router-dom';

const FETCH_KEY = 'pupil-progress';

export enum EPupilProgressStatus {
  STARTED = 'started',
  FINISHED = 'finished',
  RETRY = 'retry',
  LOCKED = 'locked',
  NEW = 'new',
  DISABLED = 'disabled',
}

export interface IPupilProgress {
  pupilId: number;
  lesson: ILesson;
  status: EPupilProgressStatus;
  result: string;
  createdAt: Date;
  finishedAt: Date|null;
  updatedAt: Date;
}

class MissingSchoolError extends Error {
  constructor() {
    super('Missing school');
  }
}

interface IPupilProgressTable {
  selectedGroup: IGroup|undefined;
  selectedCategory: ELessonCategory;
  searchQuery: string;
}

interface IDecodingProgressTableData {
  name: ReactElement<any, any>;
  date: ReactElement<any, any>;
  currentLesson: JSX.Element|null;
  buttons: ReactElement<any, any>;
}

interface IStrongReaderProgressTableData {
  name: ReactElement<any, any>;
  date: ReactElement<any, any>;
  currentLesson: JSX.Element|null;
  buttons: ReactElement<any, any>;
}

interface IVocabularyProgressTableData {
  name: JSX.Element;
  date: JSX.Element;
  currentLesson: JSX.Element|null;
  wordCount: JSX.Element;
  wrongClickCount: JSX.Element;
}

interface IPupilWithProgress {
  pupil: IPupil,
  lessons: {
    lesson: ILesson,
    progress: IPupilProgress|undefined
  }[],
  lastLesson?: {
    lesson: ILesson,
    progress: IPupilProgress|undefined
  },
}

const PupilProgressEditModal = ({ onClose, selectedCategory, pupil }: { onClose: () => void, selectedCategory: ELessonCategory, pupil?: IPupil|null }): JSX.Element => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: pupilLessons, isLoading, error: errorFetch, refetch } = useQuery([pupil, 'pupil-lessons'], () => {
    if (!pupil) {
      return Promise.resolve([]);
    }
    return Api.listPupilLessonsAsTeacher(pupil.id, selectedCategory);
  });

  const refetchAll = async() => {
    await refetch();
    await queryClient.invalidateQueries({ queryKey: [FETCH_KEY] });
  };

  const { mutate: updateProgress, isLoading: isLoadingUpdateProgress, error: errorSingel } = useMutation(({ lessonId, status }: { lessonId: number, status: EPupilProgressStatus }) => {
    if (!pupil) {
      return Promise.reject(new Error('No pupil set'));
    }
    return Api.updatePupilProgressAsTeacher(pupil.id, { lessonId, status, result: '{}' });
  }, {
    onSuccess: () => refetchAll(),
  });

  const { mutate: updateProgressBulk, isLoading: isLoadingUpdatingProgressBulk, error: errorBulk } = useMutation(({ lessonIds, status }: { lessonIds: number[], status: EPupilProgressStatus }) => {
    if (!pupil) {
      return Promise.reject(new Error('No pupil set'));
    }
    return Api.updateManyPupilProgressAsTeacher(pupil.id, { lessonIds, status });
  }, {
    onSuccess: () => refetchAll(),
  });

  const handleUpdateProgressUntil = (idx: number, status: EPupilProgressStatus) => {
    updateProgressBulk({
      lessonIds: (pupilLessons || []).slice(0, ++idx).map((pupilLesson) => pupilLesson.lesson.id),
      status
    });
  };

  const handleUpdateProgressFrom = (idx: number, status: EPupilProgressStatus) => {
    const updateLessonIds = (pupilLessons || [])
      .slice(idx)
      .map((pupilLesson) => pupilLesson.lesson.id);

    updateProgressBulk({
      lessonIds: updateLessonIds,
      status
    });
  };

  const isLoadingMutation = isLoadingUpdateProgress || isLoadingUpdatingProgressBulk;
  const error = errorFetch || errorSingel || errorBulk;

  const handleClose = () => {
    onClose();
  };

  const renderModalBody = () => {
    if (isLoading || !pupilLessons) {
      return null;
    }

    return <ol className="decoding-lesson-list">
      {pupilLessons.map((pupilLesson, idx) => (
        <li key={idx}>
          <Dropdown align="end">
            <Dropdown.Toggle
              title="Pas deze les aan"
              className={`${pupilLesson.state} pupil-progress__dropdown-toggle`}
              disabled={pupilLesson.state === EPupilProgressStatus.DISABLED}
            >
              <ListDecodingLessonsTile renderPlain pupilLesson={pupilLesson}/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {
                pupilLesson.state !== EPupilProgressStatus.LOCKED && <>
                  <Dropdown.Item onClick={() => updateProgress({ lessonId: pupilLesson.lesson.id, status: EPupilProgressStatus.FINISHED })}>
                    {t('pupil-progress:edit-modal.mark-as-done')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => updateProgress({ lessonId: pupilLesson.lesson.id, status: EPupilProgressStatus.RETRY })}>
                    {t('pupil-progress:edit-modal.mark-as-retry')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => updateProgress({ lessonId: pupilLesson.lesson.id, status: EPupilProgressStatus.LOCKED })}>
                    {t('pupil-progress:edit-modal.mark-as-locked')}
                  </Dropdown.Item>
                </>
              }
              <div className="pupil-progress__dropdown-bulk-items">
                <Dropdown.Item onClick={() => handleUpdateProgressUntil(idx, EPupilProgressStatus.FINISHED)}>
                  {t('pupil-progress:edit-modal.mark-as-done-until')}
                </Dropdown.Item>
                <Dropdown.Item className="pupil-progress__dropdown-danger-item" onClick={() => handleUpdateProgressFrom(idx, EPupilProgressStatus.LOCKED)}>
                  {t('pupil-progress:edit-modal.mark-as-locked-from-here')}
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      ))}
    </ol>;
  };

  return (
    <Modal show={!!pupil} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {t('pupil-progress:edit-modal.title', { name: `${pupil?.firstName || ''} ${pupil?.lastName || ''}` })}
          <Status className="pupil-progress-table-status" loading={isLoading || isLoadingMutation} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Status error={error} />
        {renderModalBody()}
      </Modal.Body>
    </Modal>
  );
};

const PupilProgressCurrentLesson = ({
  progress,
  onEditProgress,
}: {
  progress?: IPupilProgress;
  onEditProgress?: () => void;
}): JSX.Element | null => <div className="pupil-progress__current-lesson" onClick={onEditProgress}>
  { progress ? <>Les: {progress.lesson.number} | {progress.lesson.name || ''}</> : '-' }
  &nbsp;&nbsp;
  { onEditProgress && <Icon>{EIcon.EDIT}</Icon> }
</div>;

const usePupilsWithProgress = (selectedCategory: ELessonCategory, selectedGroup?: IGroup): { data: IPupilWithProgress[], isLoading: boolean } => {
  const { school } = useSchool();

  const { data: pupilProgress, isLoading: isLoadingPupilProgress } = useQuery(['pupil-progress', selectedCategory, selectedGroup, FETCH_KEY], () => {
    if (!selectedCategory || !selectedGroup) return null;
    return Api.listPupilProgress({ category: selectedCategory, groupId: selectedGroup?.id });
  });

  const { data: pupils, isLoading: isLoadingPupils } = useQuery(['group-pupils', school, selectedGroup], () => {
    if (!school) return Promise.reject(new MissingSchoolError());
    if (!selectedGroup) return [];
    return Api.listSchoolGroupPupils(school.id, selectedGroup.id);
  });

  const { data: lessons, isLoading: isLoadingLessons } = useQuery(['lessons', selectedCategory, selectedGroup], () => {
    if (!selectedGroup) return Promise.resolve([]);
    return Api.listLessons({ category: selectedCategory, level: selectedGroup.level, published: true });
  });

  const isLoading = isLoadingPupilProgress || isLoadingPupils || isLoadingLessons;

  const data = useMemo<IPupilWithProgress[]>(() => {
    if (!pupilProgress || !pupils || !lessons) {
      return [];
    }

    const pupilsWithProgress: IPupilWithProgress[] = [];
    pupils.forEach((pupil: IPupil) => {
      const pupilLessons = (lessons || []).map((lesson) => ({
        lesson,
        progress: pupilProgress.find((progress) => progress.lesson.id === lesson.id && progress.pupilId === pupil.id),
      }));

      let lastLesson: { lesson: ILesson; progress: IPupilProgress | undefined; }|undefined;
      for (let i = pupilLessons.length - 1; i >= 0; i--) { // Traverse down from highest lesson number to lowest
        const lesson = pupilLessons[i];
        if (!lesson.progress) {
          continue;
        }
        lastLesson = lesson;
        break;
      }

      pupilsWithProgress.push({
        pupil,
        lessons: pupilLessons,
        lastLesson,
      });
    });

    return pupilsWithProgress;
  }, [pupils, lessons, pupilProgress, selectedCategory]);

  return { data, isLoading };
};

const PupilNameWithAvatar = ({ pupil }: { pupil: IPupil }) => {
  const pupilName = `${pupil.firstName} ${pupil.lastName}`;
  return <div>
    <img
      alt={`Profielfoto van ${pupilName}`}
      className="pupil-table__row--image"
      src={getAvatarPath(pupil.avatar)}
    />
    {pupilName}
  </div>;
};

const DecodingPupilProgressTable = React.memo(({ pupilsWithProgress, onEditProgress }: { pupilsWithProgress: IPupilWithProgress[], onEditProgress: (pupil: IPupil) => void }): JSX.Element | null => {
  const navigate = useNavigate();
  const { school } = useSchool();

  if (!school) {
    return <Status loading={true} />;
  }

  const columns: DynamicTableColumn<IDecodingProgressTableData>[] = [
    { title: 'Leerling', objectKey: 'name' },
    { title: 'Datum', objectKey: 'date' },
    { title: 'Laatste les', objectKey: 'currentLesson' },
    { title: '', objectKey: 'buttons', disableSort: true },
  ];

  const data: IDecodingProgressTableData[] = [];
  for (const progress of pupilsWithProgress) {
    const { pupil, lastLesson } = progress;

    data.push({
      name: <PupilNameWithAvatar pupil={pupil} />,
      date: lastLesson?.progress?.updatedAt ? <DateTime date={lastLesson.progress.updatedAt} /> : <>-</>,
      currentLesson: <PupilProgressCurrentLesson progress={lastLesson?.progress} onEditProgress={() => onEditProgress(pupil)} />,
      buttons: <Button onClick={() => navigate(ESchoolRouterPath.PROGRESS_DETAIL.fullPath(school.id, pupil.id, { category: ELessonCategory.DECODING }))}>Bekijken</Button>,
    });
  }

  return <DynamicTable<IDecodingProgressTableData> columns={columns} data={data} sortByDefault={columns[0]} sortDirectionDefault={ESortDirection.ASCENDING} />;
});

const ReadingComprehensionProgressTable = React.memo(({ pupilsWithProgress, onEditProgress }: { pupilsWithProgress: IPupilWithProgress[], onEditProgress: (pupil: IPupil) => void }): JSX.Element | null => {
  const navigate = useNavigate();
  const { school } = useSchool();

  if (!school) {
    return <Status loading={true} />;
  }

  const columns: DynamicTableColumn<IDecodingProgressTableData>[] = [
    { title: 'Leerling', objectKey: 'name' },
    { title: 'Datum', objectKey: 'date' },
    { title: 'Laatste les', objectKey: 'currentLesson' },
    { title: '', objectKey: 'buttons', disableSort: true },
  ];

  const data: IDecodingProgressTableData[] = [];
  for (const progress of pupilsWithProgress) {
    const { pupil, lastLesson } = progress;

    data.push({
      name: <PupilNameWithAvatar pupil={pupil} />,
      date: lastLesson?.progress?.updatedAt ? <DateTime date={lastLesson.progress.updatedAt} /> : <>-</>,
      currentLesson: <PupilProgressCurrentLesson progress={lastLesson?.progress} onEditProgress={() => onEditProgress(pupil)} />,
      buttons: <Button onClick={() => navigate(ESchoolRouterPath.PROGRESS_DETAIL.fullPath(school.id, pupil.id, { category: ELessonCategory.READING_COMPREHENSION }))}>Bekijken</Button>,
    });
  }

  return <DynamicTable<IDecodingProgressTableData> columns={columns} data={data} sortByDefault={columns[0]} sortDirectionDefault={ESortDirection.ASCENDING} />;
});

const StrongReaderPupilProgressTable = React.memo(({ pupilsWithProgress }: { pupilsWithProgress: IPupilWithProgress[] }): JSX.Element | null => {
  const { school } = useSchool();

  if (!school) {
    return <Status loading={true} />;
  }

  const columns: DynamicTableColumn<IStrongReaderProgressTableData>[] = [
    { title: 'Leerling', objectKey: 'name' },
    { title: 'Datum', objectKey: 'date' },
    { title: 'Laatste les', objectKey: 'currentLesson' },
    { title: '', objectKey: 'buttons', disableSort: true },
  ];

  const data: IStrongReaderProgressTableData[] = [];
  for (const progress of pupilsWithProgress) {
    const { pupil, lastLesson } = progress;

    data.push({
      name: <PupilNameWithAvatar pupil={pupil} />,
      date: lastLesson?.progress?.updatedAt ? <DateTime date={lastLesson.progress.updatedAt} /> : <>-</>,
      currentLesson: <PupilProgressCurrentLesson progress={lastLesson?.progress} />,
      buttons: <></>,
    });
  }

  return <DynamicTable<IStrongReaderProgressTableData> columns={columns} data={data} sortByDefault={columns[0]} sortDirectionDefault={ESortDirection.ASCENDING} />;
});

const VocabularyPupilProgressTable = React.memo(({ pupilsWithProgress }: { pupilsWithProgress: IPupilWithProgress[] }): JSX.Element | null => {
  const getLessonResult = (pupilProgress: IPupilProgress): { wordCount: number, wrongClickCount: number, result: IPupilHiddenObjectsGameResult, noMistakes: boolean } | null => {
    const { result } = pupilProgress;

    try {
      const parsedJson = JSON.parse(result) as IPupilHiddenObjectsGameResult;
      const { error } = PupilHiddenObjectsGameResultSchema.validate(parsedJson, { convert: false });
      if (error) {
        throw new Error('Got invalid lesson result object', error);
      }

      const wordCount = parsedJson.items.length;
      const wrongClickCount = parsedJson.items.reduce((accumulator, item) => accumulator + item.failedAttempts, 0);
      const noMistakes = wrongClickCount === 0;

      return {
        wordCount,
        wrongClickCount,
        noMistakes,
        result: parsedJson,
      };
    } catch (e) {
      console.warn('Failed to parse JSON result of PupilProgress', pupilProgress, e);
      return null;
    }
  };

  const columns: DynamicTableColumn<IVocabularyProgressTableData>[] = [
    { title: 'Naam', objectKey: 'name' },
    { title: 'Datum', objectKey: 'date' },
    { title: 'Laatste woordplaat', objectKey: 'currentLesson' },
    { title: 'Aantal woorden', objectKey: 'wordCount' },
    { title: 'Fout geklikt', objectKey: 'wrongClickCount' },
  ];

  const data: IVocabularyProgressTableData[] = [];
  for (const progress of pupilsWithProgress) {
    const { pupil, lastLesson } = progress;
    const result = lastLesson?.progress && getLessonResult(lastLesson.progress);

    data.push({
      name: <PupilNameWithAvatar pupil={pupil} />,
      date: lastLesson?.progress?.updatedAt ? <DateTime date={lastLesson.progress.updatedAt} /> : <>-</>,
      currentLesson: lastLesson ? <>{lastLesson.lesson.number} - {lastLesson.lesson.name}</> : <>-</>,
      wordCount: result ? <>{result.wordCount} woorden</> : <>-</>,
      wrongClickCount: result ? <span className={`status-message--${result.noMistakes ? 'finished' : 'retry'}`}><Icon>{result.noMistakes ? EIcon.CHECK_CIRCLE : EIcon.X_CIRCLE}</Icon>&nbsp;{result.wrongClickCount} keer fout geklikt</span> : <>-</>,
    });
  }

  return <DynamicTable<IVocabularyProgressTableData> columns={columns} data={data} sortByDefault={columns[0]} sortDirectionDefault={ESortDirection.ASCENDING} />;
});

export const PupilProgressTable = React.memo(({
  selectedGroup,
  selectedCategory,
  searchQuery,
}: IPupilProgressTable): JSX.Element => {
  const { data, isLoading } = usePupilsWithProgress(selectedCategory, selectedGroup);
  const [editProgress, setEditProgress] = useState<IPupil|null>(null);

  let pupilsWithProgress = data;
  if (searchQuery) {
    pupilsWithProgress = data.filter(({ pupil }) => {
      const pupilName = `${pupil.firstName} ${pupil.lastName}`;
      return pupilName.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  if (isLoading) {
    return <Status loading={isLoading} />;
  }

  const renderTable = () => {
    switch (selectedCategory) {
      case ELessonCategory.DECODING:
        return <DecodingPupilProgressTable pupilsWithProgress={pupilsWithProgress} onEditProgress={setEditProgress} />;
      case ELessonCategory.READING_COMPREHENSION:
        return <ReadingComprehensionProgressTable pupilsWithProgress={pupilsWithProgress} onEditProgress={setEditProgress} />;
      case ELessonCategory.VOCABULARY:
        return <VocabularyPupilProgressTable pupilsWithProgress={pupilsWithProgress} />;
      case ELessonCategory.STRONG_READER:
        return <StrongReaderPupilProgressTable pupilsWithProgress={pupilsWithProgress} />;
      default:
        return <Status error="Unsupported ELessonCategory" />;
    }
  };

  return <>
    { renderTable() }
    <PupilProgressEditModal
      pupil={editProgress}
      selectedCategory={selectedCategory}
      onClose={() => setEditProgress(null)}
    />
  </>;
});
