import React, { PropsWithChildren } from 'react';
import * as FeatherIcon from 'react-feather';
import './icon.component.scss';
import { ReactComponent as HalfCircle } from './assets/half-circle.svg';

interface IIconProps {
  label?: string;
  className?: string|string[];
}

export enum EIcon {
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  BOOK = 'book',
  CHART = 'bar-chart-2',
  CHECK_CIRCLE = 'check-circle',
  CHEVRON_DOWN = 'chevron_down',
  CHEVRON_LEFT = 'chevron_left',
  CHEVRON_RIGHT = 'chevron_right',
  CHEVRON_UP = 'chevron_up',
  CIRCLE = 'circle',
  EDIT = 'edit',
  EYE = 'eye',
  EYE_OFF = 'eye_off',
  ENLARGE = 'enlarge',
  FOLDER = 'folder',
  GLOBE = 'globe',
  HOME = 'home',
  INFO = 'info',
  LIST = 'list',
  LOCK = 'lock',
  LOG_OUT = 'log-out',
  LOGOUT = 'log-out',
  MAIL = 'mail',
  MIC = 'mice',
  MIC_OFF = 'mice_off',
  MINUS_CIRCLE = 'minus-circle',
  NEW = 'new',
  OPEN_BOOK = 'open_book',
  PENCIL = 'pencil',
  PHONE = 'phone',
  PLAY_CIRCLE = 'play_circle',
  PLUS = 'plust',
  PRINT = 'print',
  PROGRESS = 'progress',
  REFRESH = 'refresh-ccw',
  SAVE = 'save',
  SEARCH = 'search',
  SLASH = 'slash',
  SMILE = 'smile',
  SPEAKER = 'speaker',
  SPEAKER_OFF = 'speaker-x',
  STOP_CIRCLE = 'stop_circle',
  TRASH = 'trash',
  TRENDING_UP = 'trending_up',
  UPLOAD = 'upload',
  USER = 'user',
  USER_PLUS = 'user-plus',
  USERS = 'users',
  X = 'x',
  X_CIRCLE = 'x-circle',
}

export function Icon({ label, className, children }: PropsWithChildren<IIconProps>): JSX.Element|null {
  let iconName = '';
  React.Children.forEach(children, (child) => {
    if (typeof child === 'string') {
      iconName += child;
    }
  });

  const fullClassName = [
    'icon',
    `icon--${iconName}`,
    ...(Array.isArray(className) ? className : [className])
  ].join(' ');

  const renderIcon = (): JSX.Element => {
    switch (iconName) {
      case EIcon.ARROW_LEFT: return <FeatherIcon.ArrowLeft />;
      case EIcon.ARROW_RIGHT: return <FeatherIcon.ArrowRight />;
      case EIcon.BOOK: return <FeatherIcon.Book />;
      case EIcon.CHART: return <FeatherIcon.BarChart2 />;
      case EIcon.CHECK_CIRCLE: return <FeatherIcon.CheckCircle />;
      case EIcon.CHEVRON_DOWN: return <FeatherIcon.ChevronDown />;
      case EIcon.CHEVRON_LEFT: return <FeatherIcon.ChevronLeft />;
      case EIcon.CHEVRON_RIGHT: return <FeatherIcon.ChevronRight />;
      case EIcon.CHEVRON_UP: return <FeatherIcon.ChevronUp />;
      case EIcon.CIRCLE: return <FeatherIcon.Circle />;
      case EIcon.EDIT: return <FeatherIcon.Edit />;
      case EIcon.EYE: return <FeatherIcon.Eye />;
      case EIcon.EYE_OFF: return <FeatherIcon.EyeOff />;
      case EIcon.ENLARGE: return <FeatherIcon.Maximize2 />;
      case EIcon.FOLDER: return <FeatherIcon.Folder />;
      case EIcon.GLOBE: return <FeatherIcon.Globe />;
      case EIcon.HOME: return <FeatherIcon.Home />;
      case EIcon.INFO: return <FeatherIcon.Info />;
      case EIcon.LIST: return <FeatherIcon.List />;
      case EIcon.LOCK: return <FeatherIcon.Lock />;
      case EIcon.LOG_OUT: return <FeatherIcon.LogOut />;
      case EIcon.LOGOUT: return <FeatherIcon.LogOut />;
      case EIcon.MAIL: return <FeatherIcon.Mail />;
      case EIcon.MIC_OFF: return <FeatherIcon.MicOff />;
      case EIcon.MIC: return <FeatherIcon.Mic />;
      case EIcon.MINUS_CIRCLE: return <FeatherIcon.MinusCircle />;
      case EIcon.NEW: return <FeatherIcon.PlayCircle />;
      case EIcon.OPEN_BOOK: return <FeatherIcon.BookOpen />;
      case EIcon.PENCIL: return <FeatherIcon.Edit3 />;
      case EIcon.PHONE: return <FeatherIcon.Phone />;
      case EIcon.PLAY_CIRCLE: return <FeatherIcon.PlayCircle />;
      case EIcon.PLUS: return <FeatherIcon.Plus />;
      case EIcon.PRINT: return <FeatherIcon.Printer />;
      case EIcon.PROGRESS: return <HalfCircle />;
      case EIcon.REFRESH: return <FeatherIcon.RefreshCcw />;
      case EIcon.SAVE: return <FeatherIcon.Save />;
      case EIcon.SEARCH: return <FeatherIcon.Search />;
      case EIcon.SLASH: return <FeatherIcon.Slash />;
      case EIcon.SMILE: return <FeatherIcon.Smile />;
      case EIcon.SPEAKER: return <FeatherIcon.Volume2 />;
      case EIcon.SPEAKER_OFF: return <FeatherIcon.VolumeX />;
      case EIcon.STOP_CIRCLE: return <FeatherIcon.StopCircle />;
      case EIcon.TRASH: return <FeatherIcon.Trash2 />;
      case EIcon.TRENDING_UP: return <FeatherIcon.TrendingUp />;
      case EIcon.UPLOAD: return <FeatherIcon.Upload />;
      case EIcon.USER_PLUS: return <FeatherIcon.UserPlus />;
      case EIcon.USER: return <FeatherIcon.User />;
      case EIcon.USERS: return <FeatherIcon.Users />;
      case EIcon.X_CIRCLE: return <FeatherIcon.XCircle />;
      case EIcon.X: return <FeatherIcon.X />;
      default:
        console.warn(iconName, 'is not a known icon');
        return <>{iconName}</>;
    }
  };

  return <span className={fullClassName} aria-hidden={!label} aria-label={label}>
    { renderIcon() }
  </span>;
}
