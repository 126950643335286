import React from 'react';
import { ILesson, EInstructionLabel } from 'shared';
import { EIcon } from '../../../common/utils/icon.component';
import { InstructionLabel } from '../../../common/utils/instruction-label.component';
import { RoundButton } from '../../../common/utils/round-button.component';
import { useLessonMeta } from '../../../common/utils/use-lesson-meta';
import { GoalBlocks } from '../goal-blocks';
import { DecodingLayout } from './decoding-layout';
import './decoding-overview.scss';

export function DecodingOverview({ progress, lesson, onClose, onStart }: { progress: number, lesson: ILesson, onClose: () => void, onStart: () => void }): JSX.Element {
  const { goal } = useLessonMeta(lesson);

  return <DecodingLayout lesson={lesson} progress={progress} onClose={onClose}>
    <div className="decoding-overview">
      <h2 className="decoding-overview__info"><InstructionLabel instructionKey={EInstructionLabel.DOEL_VAN_DE_LES} /></h2>
      { !!goal?.letters.length && <>
        <h3 className="decoding-overview__small-info"><InstructionLabel instructionKey={EInstructionLabel.LETTERS} /></h3>
        <GoalBlocks parts={goal.letters} />
      </> }
      { !!goal?.words.length && <>
        <h3 className="decoding-overview__small-info"><InstructionLabel instructionKey={EInstructionLabel.WOORDEN} /></h3>
        <GoalBlocks parts={goal.words} />
      </> }
      { !!goal?.punctuations.length && <>
        <h3 className="decoding-overview__small-info"><InstructionLabel instructionKey={EInstructionLabel.LEESTEKENS} /></h3>
        <GoalBlocks parts={goal.punctuations} />
      </> }
      <RoundButton icon={EIcon.CHEVRON_RIGHT} className="decoding-overview__start-button" variant="success" showLabel={true} onClick={onStart}>Start les</RoundButton>
    </div>
  </DecodingLayout>;
}
