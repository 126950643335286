import { useCallback, useEffect, useRef, useState } from 'react';
import BsTable from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { v1 as uuid } from 'uuid';
import classNames from 'classnames';
import './dynamic-table.component.scss';
import { EIcon, Icon } from './icon.component';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';

export enum ESortDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export interface DynamicTableColumn<T> {
  title: string;
  objectKey: keyof T;
  disableSort?: boolean;
  customSortFunction?: (indexA: number, indexB: number, direction: ESortDirection, objectKey: keyof T) => number;
}

function serveXlsxDownload(tableEl: HTMLTableElement, filename: string): void {
  const workbook = XLSX.utils.table_to_book(tableEl);
  XLSX.writeFile(workbook, `${filename}.xlsx`);
}

interface DynamicTableProps<T> {
  className?: string;
  columns: DynamicTableColumn<T>[];
  data: T[];
  sortByDefault?: DynamicTableColumn<T>;
  sortDirectionDefault?: ESortDirection;
  onSortChange?: (direction: ESortDirection, by?: DynamicTableColumn<T>) => void;
  onDisplayDataChange?: (data: T[]) => void;
  filterFunction?: (item: T) => boolean;
  onClickRow?: (item: T) => void;
  showDownloadButton?: boolean;
  downloadFileName?: string;
  /**
   * Display variant without shadows
   */
  simplified?: boolean;
  /**
   * CSS value including `rem/px`
   */
  rowHeight?: string;
}

export function DynamicTable<T>({ className, columns, data, sortByDefault, sortDirectionDefault, showDownloadButton, downloadFileName, simplified, rowHeight, onSortChange, onDisplayDataChange, filterFunction, onClickRow }: DynamicTableProps<T>): JSX.Element {
  const [keyMap] = useState<WeakMap<any, string>>(new WeakMap());
  const [sortedBy, setSortedBy] = useState<DynamicTableColumn<T>|undefined>(sortByDefault);
  const [sortDirection, setSortDirection] = useState<ESortDirection>(sortDirectionDefault ?? ESortDirection.DESCENDING);
  const [sortedData, setSortedData] = useState<T[]>([...data]);
  const tableRef = useRef<HTMLTableElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (onSortChange) {
      onSortChange(sortDirection, sortedBy);
    }
  }, [sortDirection, sortedBy, onSortChange]);

  useEffect(() => {
    if (onDisplayDataChange) {
      onDisplayDataChange([...sortedData]);
    }
  }, [sortedData, onDisplayDataChange]);

  const sortList = useCallback((a: T, b: T, dataToSort: T[]): number => {
    if (!sortedBy) {
      return 0; // Nothing to sort, why are we even here?
    }
    const { objectKey, customSortFunction } = sortedBy;
    const sortDesc = sortDirection === ESortDirection.DESCENDING;
    const valueA = a[objectKey];
    const valueB = b[objectKey];
    if (customSortFunction) {
      const indexOfA = dataToSort.indexOf(a);
      const indexOfB = dataToSort.indexOf(b);
      return customSortFunction(indexOfA, indexOfB, sortDirection, objectKey);
    } else if (typeof valueA === 'string' && typeof valueB === 'string') {
      return sortDesc ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
      return sortDesc ? valueB - valueA : valueA - valueB;
    } else {
      return 0; // No idea how to sort you...
    }
  }, [sortDirection, sortedBy]);

  useEffect(() => {
    const dataCopy = [...data].filter(item => (filterFunction ? filterFunction(item) : true));
    if (!sortedBy) {
      // nothing to sort, all items are in order
      setSortedData(dataCopy);
    } else {
      dataCopy.sort((a, b) => sortList(a, b, dataCopy));
      setSortedData(dataCopy);
    }
  }, [data, sortedBy, sortDirection, sortList, filterFunction]);

  const getObjectId = (object: DynamicTableColumn<T>|T): string => {
    const hasId = keyMap.get(object);
    if (hasId !== undefined) {
      return hasId;
    } else {
      const newId = uuid();
      keyMap.set(object, newId);
      return newId;
    }
  };

  const renderHeaderSortIcon = (columnLayout: DynamicTableColumn<T>): JSX.Element|null => {
    const columnIsSorted = sortedBy?.objectKey === columnLayout.objectKey;
    const icons = <><Icon>{EIcon.CHEVRON_UP}</Icon><Icon>{EIcon.CHEVRON_DOWN}</Icon></>;
    if (columnIsSorted && sortDirection === ESortDirection.DESCENDING) {
      return <span className="header-item-sort__icon header-item-sort__icon--desc" aria-label="Aflopend gesorteerd, klik om te veranderen">{icons}</span>;
    } else if (columnIsSorted && sortDirection === ESortDirection.ASCENDING) {
      return <span className="header-item-sort__icon header-item-sort__icon--asc" aria-label="Oplopend gesorteerd, klik om te veranderen">{icons}</span>;
    } else {
      return <span className="header-item-sort__icon header-item-sort__icon--none" aria-label="Klik om te sorteren">{icons}</span>;
    }
  };

  const setSortOrder = (columnLayout: DynamicTableColumn<T>): void => {
    // On first click: start sorting as descending
    // On second click: switch sorting to ascending
    // On third click: reset sorting on this column
    if (sortedBy?.objectKey === columnLayout.objectKey) {
      switch (sortDirection) {
        case ESortDirection.DESCENDING:
          setSortDirection(ESortDirection.ASCENDING);
          break;
        case ESortDirection.ASCENDING:
        default:
          setSortDirection(ESortDirection.DESCENDING);
          break;
      }
    } else {
      setSortedBy(columnLayout);
      setSortDirection(ESortDirection.DESCENDING);
    }
  };

  const handleDownload = () => {
    if (!tableRef.current) {
      return;
    }

    const filename = downloadFileName || t('dynamic-table:default-filename') || 'file';
    serveXlsxDownload(tableRef.current, filename);
  };

  return <>
    <BsTable ref={tableRef} className={classNames('dynamic-table', className, { 'dynamic-table--simplified': simplified })}>
      <thead className="dynamic-table__head">
        <tr>
          {
            columns.map(column => {
              const { title } = column;
              return <th key={getObjectId(column)} className="dynamic-table__head-item dynamic-table-column">
                { column.disableSort ? title : <button className="dynamic-table__head-item-sort-button" onClick={() => setSortOrder(column)}>
                  <span className="head-item-sort__title">{title}</span>{renderHeaderSortIcon(column)}
                </button> }
              </th>;
            })
          }
        </tr>
      </thead>
      <div className="dynamic-table__divider" />
      <tbody className="dynamic-table__body">
        { sortedData.map(rowData => (
          <tr className="dynamic-table__row" key={getObjectId(rowData)} tabIndex={onClickRow ? 0 : -1 } onClick={() => onClickRow && onClickRow(rowData)}>
            { columns.map(column => <td key={`${getObjectId(column)}${getObjectId(rowData)}`} className="dynamic-table-column" style={{ height: rowHeight }}>
              {rowData[column.objectKey] as unknown as string}
            </td>) }
          </tr>))
        }
      </tbody>
    </BsTable>
    { showDownloadButton && <Button className="dynamic-table__xlsx-download" onClick={handleDownload}>{t('dynamic-table:download-xlsx')}</Button> }
  </>;
}
