import React, { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Api } from '../api/api';
import { useQuery } from 'react-query';
import { Status } from '../utils/status.component';
import { getPupilAccessToken } from '../utils/pupil-auth.helper';
import { PupilContext } from './pupil.context';
import { DEFAULT_RECORDING_LANGUAGE } from 'shared';

export function PupilProvider({ children, unauthorizedRedirectPath }: PropsWithChildren<{ unauthorizedRedirectPath: string }>): JSX.Element {
  const navigate = useNavigate();
  const token = getPupilAccessToken();
  const { data: pupil, error, isLoading } = useQuery(['pupil', token], () => {
    if (!token) {
      return Promise.reject(new Error('Missing token'));
    }
    return Api.pupilMe();
  }, { retry: false });

  useEffect(() => {
    if (!error) {
      return;
    }

    console.error('Failed to load pupil from token', error);
    navigate(unauthorizedRedirectPath, { replace: true });
  }, [error]);

  if (isLoading || error) {
    return <Status loading={true} error={error} />;
  }

  return (
    <PupilContext.Provider value={{ pupil, language: pupil?.language ?? DEFAULT_RECORDING_LANGUAGE }}>
      {children}
    </PupilContext.Provider>
  );
}
