import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import './modal-arrow.component.scss';

interface ModalArrow extends PropsWithChildren {
  className?: string;
  type: 'left' | 'left-top' | 'left-bottom';
}

export function ModalArrow({ className, type, children }: ModalArrow): JSX.Element {
  return (
    <div className={classNames('modal-arrow ', className)}>
      <div className={classNames('modal-arrow__arrow ', `modal-arrow__arrow--${type}`)}></div>
      <span className="modal-arrow__text">{children}</span>
    </div>
  );
}
