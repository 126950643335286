"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LessonDocumentManager = void 0;
var _1 = require(".");
var __1 = require("..");
var uuid_1 = require("uuid");
var exercise_1 = require("./exercises/exercise");
var LessonDocumentManager = /** @class */ (function () {
    function LessonDocumentManager() {
    }
    LessonDocumentManager.lessonDocumentToString = function (document) {
        return JSON.stringify(document, null, 2);
    };
    LessonDocumentManager.parseJsonToLessonDocument = function (json, options) {
        if (options === void 0) { options = {}; }
        var parsedJson = JSON.parse(json);
        if (options.skipValidation) {
            return parsedJson;
        }
        var _a = _1.lessonDocumentSchema.validate(parsedJson, { convert: false }), error = _a.error, value = _a.value;
        if (error) {
            throw error;
        }
        return value;
    };
    LessonDocumentManager.addExercise = function (document, type) {
        document = LessonDocumentManager.copyDocument(document);
        if (!document.exercises) {
            document.exercises = [];
        }
        if ((0, __1.exerciseLimitReached)(document.exercises, type)) {
            return document;
        }
        document.exercises.push({
            id: (0, uuid_1.v1)(),
            type: type,
        });
        return document;
    };
    LessonDocumentManager.updateExercise = function (document, exercise) {
        document = LessonDocumentManager.copyDocument(document);
        if (!document.exercises) {
            throw new Error('Cannot update exercise when document.exercises is undefined');
        }
        var currentExerciseIndex = document.exercises.findIndex(function (e) { return e.id === exercise.id; });
        if (currentExerciseIndex === -1) {
            throw new Error("Exercise ".concat(exercise.id, " cannot be updated since it does not exist in document"));
        }
        document.exercises.splice(currentExerciseIndex, 1, exercise);
        return document;
    };
    LessonDocumentManager.deleteExercise = function (document, exercise) {
        document = LessonDocumentManager.copyDocument(document);
        if (!document.exercises) {
            throw new Error('Cannot update exercise when document.exercises is undefined');
        }
        var currentExerciseIndex = document.exercises.findIndex(function (e) { return e.id === exercise.id; });
        if (currentExerciseIndex === -1) {
            throw new Error("Exercise ".concat(exercise.id, " cannot be deleted since it does not exist in document"));
        }
        document.exercises.splice(currentExerciseIndex, 1);
        return document;
    };
    LessonDocumentManager.getEmptyLessonDocument = function (category) {
        var document = { version: _1.LATEST_LESSON_DOCUMENT_VERSION, exercises: [] };
        if (category === __1.ELessonCategory.VOCABULARY) {
            document = LessonDocumentManager.addExercise(document, exercise_1.EExerciseType.HIDDEN_OBJECTS_GAME);
        }
        return document;
    };
    LessonDocumentManager.getEmptyLessonDocumentString = function (category) {
        return JSON.stringify(LessonDocumentManager.getEmptyLessonDocument(category));
    };
    LessonDocumentManager.shouldShowInitialLessonOverview = function (lesson) {
        return lesson.category === __1.ELessonCategory.DECODING;
    };
    LessonDocumentManager.getLessonStepsCount = function (lesson, lessonDocument) {
        var count = 0;
        for (var _i = 0, _a = lessonDocument.exercises; _i < _a.length; _i++) {
            var exercise = _a[_i];
            count += (0, exercise_1.getExerciseStepsCount)(exercise);
        }
        // Add steps for each exercise, user should click a button to go to the next step
        count += Math.max(0, lessonDocument.exercises.length - 1);
        if (LessonDocumentManager.shouldShowInitialLessonOverview(lesson)) {
            count += 1;
        }
        return count;
    };
    LessonDocumentManager.copyDocument = function (document) { return (__assign({}, document)); };
    return LessonDocumentManager;
}());
exports.LessonDocumentManager = LessonDocumentManager;
