import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Table from 'react-bootstrap/esm/Table';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../common/api/api';
import { User } from '../../common/api/types';
import { PageTitle } from '../../common/utils/page-title.component';
import { Status } from '../../common/utils/status.component';
import { EAdminRouterPath } from '../../router-path';

function UserTable({ users }: { users?: User[] }): JSX.Element|null {
  const navigate = useNavigate();

  if (!users) {
    return null;
  }

  return <Table striped bordered hover>
    <thead>
      <tr>
        <th>Firstname</th>
        <th>Lastname</th>
        <th>Capablities</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      { users.map(user => <tr key={user.id}>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.capabilities.join(', ')}</td>
        <td><Button onClick={() => navigate(EAdminRouterPath.USER_SHOW.fullPath(user.id))}>Go to</Button></td>
      </tr>)}
    </tbody>
  </Table>;
}

export function ListUsers(): JSX.Element {
  const { data: users, error, isLoading } = useQuery('users', Api.listUsers);
  const navigate = useNavigate();

  return <>
    <PageTitle>Gebruikers</PageTitle>
    <Button variant="success" onClick={() => navigate(EAdminRouterPath.USER_CREATE.fullPath)}>Add user</Button>
    <Status loading={isLoading} error={error} />
    <UserTable users={users} />
  </>;
}
