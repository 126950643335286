"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hiddenObjectsGameExerciseDataSchema = exports.hiddenObjectsGameTileCompleteSchema = exports.hiddenObjectsGameTileSchema = exports.hiddenObjectsGameTileHitboxSchema = exports.DEFAULT_HITBOX = void 0;
var Joi = __importStar(require("joi"));
var exercise_elements_1 = require("./exercise-elements");
exports.DEFAULT_HITBOX = Object.freeze({
    x: 45,
    y: 45,
    width: 10,
    height: 10,
    roundness: 100,
});
exports.hiddenObjectsGameTileHitboxSchema = Joi.object({
    x: Joi.number().min(0).max(100)
        .required(),
    y: Joi.number().min(0).max(100)
        .required(),
    width: Joi.number().min(0).max(100)
        .required(),
    height: Joi.number().min(0).max(100)
        .required(),
    roundness: Joi.number().min(0).max(100)
        .required(),
});
exports.hiddenObjectsGameTileSchema = Joi.object({
    image: exercise_elements_1.imageSchema.required(),
    questionRecording: exercise_elements_1.recordingSchema.optional(),
    word: Joi.string().optional(),
    recordingWord: exercise_elements_1.recordingSchema.optional(),
    realisticImage: exercise_elements_1.imageSchema.optional(),
    correctFeedback: Joi.string().optional(),
    correctFeedbackRecording: exercise_elements_1.recordingSchema.optional(),
    wrongFeedbackRecording: Joi.array().items(exercise_elements_1.recordingSchema).optional(),
    hitbox: Joi.array().items(exports.hiddenObjectsGameTileHitboxSchema).min(1).required(),
});
exports.hiddenObjectsGameTileCompleteSchema = Joi.object({
    image: exercise_elements_1.imageSchema.required(),
    questionRecording: exercise_elements_1.recordingSchema.required(),
    word: Joi.string().required(),
    recordingWord: exercise_elements_1.recordingSchema.required(),
    realisticImage: exercise_elements_1.imageSchema.required(),
    correctFeedback: Joi.string().required(),
    correctFeedbackRecording: exercise_elements_1.recordingSchema.required(),
    wrongFeedbackRecording: Joi.array().items(exercise_elements_1.recordingSchema).min(1)
        .required(),
    hitbox: Joi.array().items(exports.hiddenObjectsGameTileHitboxSchema).min(1).required(),
});
exports.hiddenObjectsGameExerciseDataSchema = Joi.object({
    introRecording: exercise_elements_1.recordingSchema.optional(),
    image: exercise_elements_1.imageSchema.optional(),
    tiles: Joi.array().items(exports.hiddenObjectsGameTileSchema).optional(),
});
