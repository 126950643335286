import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { EIcon, Icon } from './icon.component';
import classNames from 'classnames';
import './page-title.component.scss';

interface IPageTitleProps {
  className?: string;
  backLink?: string;
  subTitle?: string|JSX.Element;
}

export function PageTitle({ className, subTitle, backLink, children }: PropsWithChildren<IPageTitleProps>): JSX.Element {
  return <div className={classNames('page-title', { 'page-title--with-link': backLink }, className)}>
    { backLink && <Link className="page-title__link" to={backLink}><Icon label="Terug">{EIcon.CHEVRON_LEFT}</Icon></Link>}
    <h2 className="page-title__title">
      <span className="page-title__main-title">{children}</span>
      { subTitle && <span className="page-title__sub-title">{subTitle}</span> }
    </h2>
  </div>;
}
