import './modal-wordblock.component.scss';
import { ModalLesson } from './modal-lesson.component';
import volume from '../lesson-modals-images/volume.svg';
import info1 from '../lesson-modals-images/info1.svg';
import info2 from '../lesson-modals-images/info2.svg';
import { ModalArrow } from './modal-arrow.component';
import { ModalComponent } from './modal.component';

export function ModalWordblock(): JSX.Element {
  return (
    <ModalComponent title="Woordblok info">
      <ModalLesson />
      <div className="modal-info__text">
        Herhaal het woord <img src={volume} alt="volume" />
      </div>
      <div className="modal-info__rules">
        <div className="modal-info__rule modal-info__rule-1">
          <img src={info1} alt={info1} />
          <ModalArrow className="modal-info__rule-1-arrow-1" type="left">
            Woorddeel 1
          </ModalArrow>
          <ModalArrow className="modal-info__rule-1-arrow-2" type="left">
            Woorddeel 2
          </ModalArrow>
          <ModalArrow className="modal-info__rule-1-arrow-3" type="left">
            Woorddeel 3
          </ModalArrow>
        </div>
        <div className="modal-info__rule modal-info__rule-2">
          <img src={info2} alt={info2} />
          <ModalArrow className="modal-info__rule-2-arrow-1" type="left">
            Afbeelding van woord
          </ModalArrow>
          <ModalArrow className="modal-info__rule-2-arrow-2" type="left-bottom">
            Audio opname <br /> van het eindwoord
          </ModalArrow>
          <ModalArrow className="modal-info__rule-2-arrow-3" type="left-top">
            Eindwoord
          </ModalArrow>
        </div>
      </div>
    </ModalComponent>
  );
}
