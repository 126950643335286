import { useRef, useState } from 'react';
import { DEFAULT_RECORDING_LANGUAGE, ReadingComprehensionTranscriptExercise } from 'shared';
import { EIcon } from '../../../common/utils/icon.component';
import { RoundButton } from '../../../common/utils/round-button.component';
import { ITranscriptDisplayFunctions, TranscriptDisplay } from '../../../common/utils/transcript-display.component';
import { Status } from '../../../common/utils/status.component';
import { ReadingComprehensionLayout } from './reading-comprehension-layout';
import { resolveRecordingItem } from '../../../common/utils/recording-resolver';

type TReadingComprehensionTranscriptProps = {
  exercise: ReadingComprehensionTranscriptExercise;
  onContinue: () => void;
}

export function ReadingComprehensionTranscript({ exercise, onContinue }: TReadingComprehensionTranscriptProps): JSX.Element {
  const [isPlaying, setIsPlaying] = useState(false);
  const transcriptDisplayRef = useRef<ITranscriptDisplayFunctions>(null);

  const recordingItem = resolveRecordingItem(exercise.data?.recording, DEFAULT_RECORDING_LANGUAGE); // Only supports one language
  const words = recordingItem?.transcript?.words || [];
  const recording = exercise.data?.recording;
  const difficultWords = exercise.data?.words || [];

  const handleGoToNext = () => {
    onContinue();
  };

  const handleClickPlay = () => {
    if (!transcriptDisplayRef.current) {
      return;
    }

    if (isPlaying) {
      transcriptDisplayRef.current.stop();
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
      transcriptDisplayRef.current.play(() => setIsPlaying(false));
    }
  };

  if (!recording) {
    return <Status error="Cannot display lesson: Missing recording"/>;
  }

  return <ReadingComprehensionLayout
    onContinue={handleGoToNext}
    buttons={
      <RoundButton icon={isPlaying ? EIcon.SPEAKER_OFF : EIcon.SPEAKER} variant="info" onClick={handleClickPlay} />
    }
  >
    <TranscriptDisplay ref={transcriptDisplayRef} words={words} difficultWords={difficultWords || []} recording={recording} />
  </ReadingComprehensionLayout>;
}
