import React, { useEffect, useState } from 'react';
import { HintContext } from './hint.context';

export function HintProvider({ children }: {children: React.ReactNode}): JSX.Element {
  const [hintOpen, setHintOpen] = useState<boolean>(false);
  const [hintMessage, setHintMessage] = useState<string|React.ReactNode>('');

  useEffect(() => {
    if (!hintMessage) setHintOpen(false);
  }, [hintMessage]);

  return (
    <HintContext.Provider value={{ hintMessage, hintOpen, setHintOpen, setHintMessage }}>
      {children}
    </HintContext.Provider>
  );
}
