/* eslint-disable no-case-declarations */
import React from 'react';
import { useQuery } from 'react-query';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Api } from '../../common/api/api';
import { PageTitle } from '../../common/utils/page-title.component';
import { Status } from '../../common/utils/status.component';
import { EAdminRouterPath } from '../../router-path';
import { IUsage } from 'shared';
import { DateTime } from '../../common/utils/date-time.component';
import { ESortDirection, DynamicTableColumn, DynamicTable } from '../../common/utils/dynamic-table.component';
import { useTranslation } from 'react-i18next';

interface IUsageTableData {
  school: JSX.Element;
  contactName: string;
  contactEmail: string;
  pupils: number;
  pupilsDelta: number;
  teachers: number;
  teachersDelta: number;
}

function UsageTable({ run, compareRun }: { run?: IUsage[], compareRun?: IUsage[] }): JSX.Element|null {
  const { t } = useTranslation();

  if (!run) {
    return null;
  }

  const sort = (indexA: number, indexB: number, direction: ESortDirection, objectKey: keyof IUsageTableData): number => {
    const a = run[indexA];
    const b = run[indexB];
    if (!a || !b) {
      console.warn(`Sort in UsageTable error, sort item a: ${a ? 'defined' : 'undefined'} b: ${b ? 'defined' : 'undefined'}`);
      return 0;
    }

    const sortDesc = direction === ESortDirection.DESCENDING;
    switch (objectKey) {
      case 'school':
        const nameA = a.school.name;
        const nameB = b.school.name;
        return sortDesc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      default:
        return 0;
    }
  };

  const columns: DynamicTableColumn<IUsageTableData>[] = [
    { title: t('usage:school'), objectKey: 'school', customSortFunction: sort },
    { title: t('usage:contact-name'), objectKey: 'contactName' },
    { title: t('usage:contact-email'), objectKey: 'contactEmail' },
    { title: t('usage:total-teachers'), objectKey: 'teachers' },
    { title: t('usage:new-teachers'), objectKey: 'teachersDelta' },
    { title: t('usage:total-pupils'), objectKey: 'pupils' },
    { title: t('usage:new-pupils'), objectKey: 'pupilsDelta' },
  ];

  const data: IUsageTableData[] = run.map((item) => {
    const compareItem = compareRun?.find((compare) => compare.school.id === item.school.id);

    return {
      school: <Link to={EAdminRouterPath.SCHOOL_SHOW.fullPath(item.school.id)} >{item.school.name}</Link>,
      contactName: item.contactName,
      contactEmail: item.contactEmail,
      pupils: item.pupilCount,
      pupilsDelta: item.pupilCount - (compareItem?.pupilCount ?? 0),
      teachers: item.teacherCount,
      teachersDelta: item.teacherCount - (compareItem?.teacherCount ?? 0)
    };
  });

  const runDateString = run[0]?.createdAt.toISOString().replaceAll(':', '-').split('.')[0] || 'null';
  const compareRunDateString = compareRun && compareRun[0]?.createdAt.toISOString().replaceAll(':', '-').split('.')[0] || 'null';
  const downloadTitle = `${runDateString}_${compareRunDateString}`;

  return <DynamicTable<IUsageTableData>
    columns={columns}
    data={data}
    sortByDefault={columns[0]}
    sortDirectionDefault={ESortDirection.ASCENDING}
    showDownloadButton={true}
    downloadFileName={downloadTitle}
  />;
}

export function ShowUsage(): JSX.Element {
  const { t } = useTranslation();
  const { runId } = useParams();
  const [searchParams] = useSearchParams();
  const compareToRunId = searchParams.get('compareToRunId');

  const { data: run, error: runError, isLoading: runIsLoading } = useQuery(['usage', runId], () => {
    if (!runId) {
      return Promise.reject(new Error('Missing run id'));
    }
    return Api.getUsageRun(runId);
  });

  const { data: compareRun, error: compareRunError, isLoading: compareRunIsLoading } = useQuery(['usage', compareToRunId], () => {
    if (!compareToRunId) {
      return Promise.resolve(undefined);
    }
    return Api.getUsageRun(compareToRunId);
  });

  const isLoading = runIsLoading || compareRunIsLoading;
  const error = runError || compareRunError;

  const title = run && run[0] ? <DateTime date={run[0].createdAt} /> : t('usage:title');

  return <>
    <PageTitle backLink={EAdminRouterPath.USAGE_LIST.fullPath}>{title}</PageTitle>
    <Status loading={isLoading} error={error} />
    <UsageTable run={run} compareRun={compareRun} />
  </>;
}
