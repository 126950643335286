import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Lesson } from '../../common/api/types';
import { PageTitle } from '../../common/utils/page-title.component';
import { Status } from '../../common/utils/status.component';
import { buildLessonTitle } from '../../common/utils/strings.helper';
import { EAdminRouterPath } from '../../router-path';
import { EditLesson } from './edit-lesson.component';
import { EShowLessonState } from './show';
import { useLessonParameters } from './use-lesson-parameters.hook';

export function CreateLesson(): JSX.Element {
  const navigate = useNavigate();
  const { category, level, book } = useLessonParameters();
  const [newLesson, setNewLesson] = useState<Lesson|null>(null);

  useEffect(() => {
    if (!newLesson) {
      return;
    }

    navigate(EAdminRouterPath.LESSON_SHOW.fullPath(newLesson.id, { state: EShowLessonState.NEW }));
  }, [newLesson]);

  if (!category || !level) {
    return <Status error={'Missing query parameters'} />;
  }

  return <>
    <PageTitle backLink={EAdminRouterPath.LESSON_LIST.fullPath({ category, level, book })}>{buildLessonTitle('Nieuwe ', category, ' aanmaken')}</PageTitle>
    <EditLesson createLesson={{ category, level }} onSubmit={setNewLesson} />
  </>;
}
