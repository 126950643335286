import React, { PropsWithChildren, useEffect } from 'react';
import { SchoolContext } from './school.context';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from '../api/api';
import { useQuery } from 'react-query';
import { Status } from '../utils/status.component';

export function SchoolProvider({ children, unknownSchoolPath }: PropsWithChildren<{ unknownSchoolPath: string }>): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();
  const { schoolId } = params;
  const { data: school, error, isLoading } = useQuery(['school', schoolId], () => {
    if (!schoolId) {
      return Promise.resolve(undefined);
    }
    return Api.getSchool(schoolId);
  }, { retry: false });

  useEffect(() => {
    if (!error) {
      return;
    }

    console.error('Failed to load school from params', { schoolId }, error);
    navigate(unknownSchoolPath, { replace: true });
  }, [error]);

  if (isLoading || error) {
    return <Status loading={true} error={error} />;
  }

  return (
    <SchoolContext.Provider value={{ school }}>
      {children}
    </SchoolContext.Provider>
  );
}
