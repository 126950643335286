import { EInstructionLabel, ILesson, WordBlockExercise } from 'shared';
import { GenericShowWordTower, TWordTowerRenderer } from './show-word-tower';
import { IExerciseProps } from '..';
import classNames from 'classnames';
import './show-word-block.scss';

const WordTowerRenderer: TWordTowerRenderer = (parts, partIndex): JSX.Element => (
  <div className="word-block-display">
    { parts.map((part, rowIndex) => <div key={rowIndex} className={classNames('word-block-display__row', { 'word-block-display__row--active': rowIndex <= partIndex })}>
      <div className="word-block-display__group">
        <span className="word-block-display__part">{part.letterBlocks.map((block) => block.content).join(' ')}</span>
      </div>
    </div>)}
  </div>
);

interface IShowWordBlockProps extends IExerciseProps<WordBlockExercise> {
  progress: number;
  lesson: ILesson;
  exerciseIndex: number;
}

export function ShowWordBlock(props: IShowWordBlockProps): JSX.Element {
  return <GenericShowWordTower wordTowerRenderer={WordTowerRenderer} instructionLabel={EInstructionLabel.HERHAAL_DIT_WOORD} {...props} />;
}
