"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.migrationToV4 = void 0;
var exercises_1 = require("../exercises");
function convertRecording(recording) {
    var _a;
    if (!recording) {
        return recording;
    }
    var recordingItem = __assign({}, recording);
    return _a = {},
        _a[exercises_1.DEFAULT_RECORDING_LANGUAGE] = recordingItem,
        _a;
}
function convertHiddenObjectsGameData(data) {
    data.introRecording = convertRecording(data.introRecording);
    for (var _i = 0, _a = data.tiles || []; _i < _a.length; _i++) {
        var tile = _a[_i];
        tile.questionRecording = convertRecording(tile.questionRecording);
        tile.recordingWord = convertRecording(tile.recordingWord);
        tile.correctFeedbackRecording = convertRecording(tile.correctFeedbackRecording);
        if (tile.wrongFeedbackRecording) {
            tile.wrongFeedbackRecording = tile.wrongFeedbackRecording.map(function (recording) { return convertRecording(recording); });
        }
    }
}
function convertWordTowerExerciseData(data) {
    data.recording = convertRecording(data.recording);
    data.fullRecording = convertRecording(data.fullRecording);
    if (data.wordParts) {
        data.wordParts = data.wordParts.map(function (wordPart) { return (__assign(__assign({}, wordPart), { recording: convertRecording(wordPart.recording) })); });
    }
}
function convertReadingComprehensionTextData(data) {
    data.recording = convertRecording(data.recording);
}
function convertReadingComprehensionTranscriptData(data) {
    var _a;
    data.recording = convertRecording(data.recording);
    (_a = data.questions) === null || _a === void 0 ? void 0 : _a.forEach(function (question) {
        var _a;
        if (!question.question.recording) {
            return;
        }
        question.question.recording = (_a = convertRecording(question.question.recording)) !== null && _a !== void 0 ? _a : null;
    });
}
exports.migrationToV4 = {
    migratingFromVersion: 3,
    migratingToVersion: 4,
    migration: function (lessonDocument) {
        for (var _i = 0, _a = lessonDocument.exercises; _i < _a.length; _i++) {
            var exercise = _a[_i];
            if (exercise.type === exercises_1.EExerciseType.HIDDEN_OBJECTS_GAME && exercise.data) {
                convertHiddenObjectsGameData(exercise.data);
                continue;
            }
            if (exercise.type === exercises_1.EExerciseType.WORD_TOWER && exercise.data) {
                convertWordTowerExerciseData(exercise.data);
                continue;
            }
            if (exercise.type === exercises_1.EExerciseType.WORD_BLOCK && exercise.data) {
                convertWordTowerExerciseData(exercise.data); // Structure is the same as WordTower
                continue;
            }
            if (exercise.type === exercises_1.EExerciseType.READING_COMPREHENSION_TEXT && exercise.data) {
                convertReadingComprehensionTextData(exercise.data);
                continue;
            }
            if (exercise.type === exercises_1.EExerciseType.READING_COMPREHENSION_TRANSCRIPT && exercise.data) {
                convertReadingComprehensionTranscriptData(exercise.data);
                continue;
            }
        }
        return lessonDocument;
    },
};
