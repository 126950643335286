import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSchool } from '../common/school-provider/school.context';
import { EIcon, Icon } from '../common/utils/icon.component';
import { PageTitle } from '../common/utils/page-title.component';
import { Button } from 'react-bootstrap';
import { useSession } from '../common/auth/session.context';
import { EMainRouterPath } from '../router-path';
import { useNavigate } from 'react-router-dom';
import appExample from './app-example.png';
import './home.scss';

export const SchoolHome = (): JSX.Element => {
  const { t } = useTranslation();
  const { school } = useSchool();
  const { userOfSchools, isAdmin } = useSession();
  const navigate = useNavigate();

  const friendlyId = school?.friendlyId || '';
  const pupilUrl = `${location.origin}/${friendlyId}`;
  const pupilUrlPretty = `${location.host}/${friendlyId}`;

  const showSwitchButton = (userOfSchools.length > 1 || isAdmin);
  const switchButtonText = isAdmin ? t('common:switch-module') : t('common:select-school');

  const handleSwitchEnvironment = () => navigate(EMainRouterPath.AUTH.path);

  return <div className="school-home">
    <article className="school-home__article">
      <PageTitle>{t('school-home:title')}</PageTitle>

      <div className="school-home__canvas" dangerouslySetInnerHTML={{
        __html: t('school-home:canvas',
          {
            friendlyId,
            link: pupilUrl,
            linkText: pupilUrlPretty,
          }
        )
      }}/>
    </article>
    <aside className="school-home__aside">
      <h2 className="school-home__school-name">{school?.name}</h2>
      <h2 className="school-home__questions">{t('school-home:got-questions')}</h2>
      <address className="school-home__contact-info">
        <div>{t('common:company')}</div>
        <div><Icon>{EIcon.MAIL}</Icon><a href={`mailto:${t('common:contact-email')}`}>{t('common:contact-email')}</a></div>
        <div><Icon>{EIcon.PHONE}</Icon>{t('common:contact-phone')}</div>
      </address>
      { showSwitchButton && <Button onClick={handleSwitchEnvironment} className="school-home__switch-button" variant="secondary">{switchButtonText} <Icon>arrow-right</Icon></Button> }
      <h2 className="school-home__app-info">Actief Leren Lezen app</h2>
      <img className="school-home__app-example" src={appExample} alt="" />
      <div className="school-home__school-code">
        Schoolcode: <strong>{friendlyId}</strong>
      </div>
      <Button href={pupilUrl} className="school-home__open-app" variant="secondary">Actief Leren Lezen <Icon>arrow-right</Icon></Button>
    </aside>
  </div>;
};
