import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { EInstructionLabel, IPupil } from 'shared';
import { Api } from '../../common/api/api';
import { SelectLayout } from '../../common/layouts/select.layout';
import { useSchool } from '../../common/school-provider/school.context';
import { getAvatarPath } from '../../common/utils/avatar-path';
import { Status } from '../../common/utils/status.component';
import { EPupilRouterPath } from '../../router-path';
import { Pagination } from 'swiper';
import paginateData from '../../common/utils/paginate-data';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './list.scss';

const PupilTile = ({ schoolId, groupId, pupil }: {schoolId: number, groupId: number, pupil: IPupil}) => (
  <div key={pupil.id} className="school-pupil-list__group">
    <Link to={EPupilRouterPath.AUTH.fullPath(schoolId, groupId, pupil.id)} className="school-pupil-list__link">
      <img className="school-pupil-list__pupil-avatar" src={getAvatarPath(pupil.avatar)} alt="" />
      <span className="school-pupil-list__pupil-name">{pupil.firstName}</span>
    </Link>
  </div>
);

function PupilList({ schoolId, groupId }: { schoolId: number, groupId: number }): JSX.Element {
  const { data: pupils, error, isLoading } = useQuery(['group', schoolId, groupId], () => Api.listSchoolGroupPupils(schoolId, groupId));
  const PUPILS_PER_SLIDE = 12;
  const slides: IPupil[][] | undefined = pupils && paginateData(pupils, PUPILS_PER_SLIDE);
  if (!pupils || isLoading || error) {
    return <Status loading={isLoading} error={error} />;
  }

  return <Swiper
    modules={[Pagination]}
    spaceBetween={0}
    slidesPerView={1}
    navigation
    pagination={{ clickable: true }}
    scrollbar={{ draggable: true }}
  >
    {slides?.map((slide, idx) => (
      <SwiperSlide key={idx}>
        { slide.map(pupil => <PupilTile key={(pupil).id} schoolId={schoolId} groupId={groupId} pupil={pupil} />)}
      </SwiperSlide>
    ))}
  </Swiper>;
}

export function ListGroupPupils(): JSX.Element {
  const { school } = useSchool();
  const params = useParams();
  const groupId = +(params.groupId || '');

  if (!school) {
    return <Status loading={true} />;
  }

  if (!groupId) {
    return <Status error="Groep kon niet worden geladen zonder ID" />;
  }

  if (Number.isNaN(groupId)) {
    return <Status error="Groep ID is ongeldig" />;
  }

  return <SelectLayout actionInstructionLabel={EInstructionLabel.WIE_BEN_JIJ} backPath={EPupilRouterPath.GROUP_LIST.fullPath(school.id)}>
    <PupilList schoolId={school.id} groupId={groupId} />
  </SelectLayout>;
}
