import './modal-goal.component.scss';
import { ModalArrow } from './modal-arrow.component';
import { ModalLesson } from './modal-lesson.component';
import transcription1 from '../lesson-modals-images/transcription1.svg';
import transcription2 from '../lesson-modals-images/transcription2.svg';
import transcription3 from '../lesson-modals-images/transcription3.svg';
import volume from '../lesson-modals-images/volume.svg';
import { ModalComponent } from './modal.component';

export function ModalGoal(): JSX.Element {
  return (
    <ModalComponent title="Doel van de les">
      <ModalLesson />
      <div className="modal-goal__text">Doel van de les. <img src={volume} alt="volume" /></div>
      <div className="modal-goal__tip-list">
        <div className="modal-goal__tip">
          <div className="modal-goal__tip-title">Letter(s) of lettersamenstellingen <img src={volume} alt="volume" /></div>
          <div className="modal-goal__tip-transcription">
            <img src={transcription1} alt={transcription1} />
            <ModalArrow
              className="modal-goal__arrow modal-goal__arrow-1"
              type="left"
            >
                      Letter(s) of lettersamenstellingen
            </ModalArrow>
          </div>
        </div>
        <div className="modal-goal__tip">
          <div className="modal-goal__tip-title">Leesteken(s) <img src={volume} alt="volume" /></div>
          <div className="modal-goal__tip-transcription">
            <img src={transcription2} alt={transcription2} />
            <ModalArrow
              className="modal-goal__arrow modal-goal__arrow-2"
              type="left"
            >
                   Leesteken(s)
            </ModalArrow>
          </div>
        </div>
        <div className="modal-goal__tip">
          <div className="modal-goal__tip-title">Woord(en) <img src={volume} alt="volume" /></div>
          <div className="modal-goal__tip-transcription">
            <img src={transcription3} alt={transcription3} />
            <ModalArrow
              className="modal-goal__arrow modal-goal__arrow-3"
              type="left"
            >
                      Woord(en)
            </ModalArrow>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
}
