/* eslint-disable no-case-declarations */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../common/utils/page-title.component';
import { useQuery } from 'react-query';
import { Api } from '../../../common/api/api';
import { Status } from '../../../common/utils/status.component';
import { useParams, useSearchParams } from 'react-router-dom';
import { ELessonCategory, EPupilProgressStatus } from 'shared';
import { isEnumValue } from '../../../common/utils/input.helper';
import { ProgressDetailTable } from './progress-detail-table';
import { ESchoolRouterPath } from '../../../router-path';

export const ProgressDetail = (): JSX.Element => {
  const { t } = useTranslation();
  const params = useParams();
  const schoolId = params.schoolId ? +params.schoolId : null;
  const pupilId = params.pupilId ? +params.pupilId : null;
  const [searchParams] = useSearchParams();
  let category = (searchParams.get('category') ?? undefined) as ELessonCategory|undefined;
  if (!isEnumValue(ELessonCategory, category)) {
    category = undefined;
  }

  const { data: pupil, isLoading: isLoadingPupil } = useQuery(['pupil', schoolId, pupilId], () => {
    if (!schoolId || !pupilId) {
      return null;
    }
    return Api.getSchoolPupil(schoolId, pupilId);
  }, { refetchOnWindowFocus: false });

  const { data: progress, isLoading: isLoadingProgress } = useQuery(['pupil-progress', pupilId, category], () => {
    if (!pupilId) {
      return [];
    }
    return Api.listSinglePupilProgress(pupilId, { category })
      .then(result => result.filter(item => [EPupilProgressStatus.FINISHED, EPupilProgressStatus.STARTED].includes(item.status)));
  }, { refetchOnWindowFocus: false });

  const isLoading = isLoadingPupil || isLoadingProgress;
  if (isLoading) {
    return <Status loading={isLoading} />;
  }

  return <>
    <PageTitle className="progress-detail__page-title" backLink={schoolId ? ESchoolRouterPath.PROGRESS.fullPath(schoolId) : undefined}>
      {t('progress-detail:page-title', { name: `${pupil?.firstName || '...'} ${pupil?.lastName || ''}` })}
    </PageTitle>
    { progress && <ProgressDetailTable progress={progress} /> }
  </>;
};
