import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { EIcon, Icon } from './icon.component';
import './info.component.scss';

export function Info({ className, children }: PropsWithChildren<{ className?: string }>): JSX.Element {
  return <div className={classNames('info', className)} tabIndex={1}>
    <span className="info__label">Wat is dit?</span>
    <Icon className="info__icon" aria-hidden={true}>{EIcon.INFO}</Icon>
    <div className="info__content">{children}</div>
  </div>;
}
