import { EPupilPasswordPart } from 'shared';
import ape from '../assets/password/ape.png';
import bear from '../assets/password/bear.png';
import bus from '../assets/password/bus.png';
import chicken from '../assets/password/chicken.png';
import giant from '../assets/password/giant.png';
import horse from '../assets/password/horse.png';
import swan from '../assets/password/swan.png';
import tent from '../assets/password/tent.png';

const passwordPathMap: { [avatar: string]: string } = {
  [EPupilPasswordPart.APE]: ape,
  [EPupilPasswordPart.BEAR]: bear,
  [EPupilPasswordPart.BUS]: bus,
  [EPupilPasswordPart.CHICKEN]: chicken,
  [EPupilPasswordPart.GIANT]: giant,
  [EPupilPasswordPart.HORSE]: horse,
  [EPupilPasswordPart.SWAN]: swan,
  [EPupilPasswordPart.TENT]: tent,
};

export function getPasswordImagePath(passwordPart: EPupilPasswordPart): string {
  const image = passwordPathMap[passwordPart];
  if (!image) {
    throw Error('Password image requested that does not exists');
  }
  return image;
}
