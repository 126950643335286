import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { EInstructionLabel } from 'shared';
import { Api } from '../../common/api/api';
import { SelectLayout } from '../../common/layouts/select.layout';
import { useSchool } from '../../common/school-provider/school.context';
import { EIcon, Icon } from '../../common/utils/icon.component';
import { Status } from '../../common/utils/status.component';
import { getStorageUrl } from '../../common/utils/storage.helper';
import { EPupilRouterPath } from '../../router-path';
import './list.scss';

function GroupList({ schoolId }: { schoolId: number }): JSX.Element {
  const { data: groups, error, isLoading } = useQuery(['school', schoolId], () => Api.listSchoolGroups(schoolId));

  if (!groups || isLoading || error) {
    return <Status loading={isLoading} error={error} />;
  }

  return <ul className="school-group-list">
    { groups.map(group => <li key={group.id} className="school-group-list__group">
      <Link to={EPupilRouterPath.PUPIL_LIST.fullPath(schoolId, group.id)} className="school-group-list__link">
        { group.avatar ?
          <img className="school-group-list__group-avatar" src={getStorageUrl(group.avatar)} alt="" /> :
          <Icon className="school-group-list__group-avatar-placeholder">{EIcon.USERS}</Icon>
        }
        <span className="school-group-list__group-name">{group.name}</span>
      </Link>
    </li>)}
  </ul>;
}

export function ListSchoolGroups(): JSX.Element {
  const { school } = useSchool();

  if (!school) {
    return <Status loading={true} />;
  }

  return <SelectLayout actionInstructionLabel={EInstructionLabel.KIES_JOUW_KLAS}>
    <GroupList schoolId={school.id} />
  </SelectLayout>;
}
