import Alert from 'react-bootstrap/esm/Alert';
import { EExerciseType, Exercise } from 'shared';
import { HiddenObjectsGameEditor } from './hidden-objects-game-editor.component';
import { VideoEditor } from './video-editor.component';
import { WordTowerEditor } from './word-tower-editor.component';
import ReadingComprehensionTranscriptEditor from './reading-comprehension-transcript-editor.component';
import { WordBlockEditor } from './word-block-editor.component';
import ReadingComprehensionTextEditor from './reading-comprehension-text-editor.component';
export interface IExerciseEditorProps<T = Exercise> {
  value: T;
  onChange: (exercise: T) => void;
  onDelete: (exercise: T) => void;
}


export function ExerciseEditor(props: IExerciseEditorProps): JSX.Element {
  switch (props.value.type) {
    case EExerciseType.WORD_TOWER:
      return <WordTowerEditor { ...props } />;
    case EExerciseType.WORD_BLOCK:
      return <WordBlockEditor { ...props } />;
    case EExerciseType.HIDDEN_OBJECTS_GAME:
      return <HiddenObjectsGameEditor { ...props } />;
    case EExerciseType.VIDEO:
      return <VideoEditor { ...props } />;
    case EExerciseType.READING_COMPREHENSION_TRANSCRIPT:
      return <ReadingComprehensionTranscriptEditor { ...props } />;
    case EExerciseType.READING_COMPREHENSION_TEXT:
      return <ReadingComprehensionTextEditor { ...props } />;
    default:
      return <Alert variant="danger">Oefeningstype {props.value.type} niet ondersteund</Alert>;
  }
}
