// file deepcode ignore RegExpBadCharRange: Regex pattern seems to be used in many places & thus should be very carefully replaced at another time.
import React, { FormEvent, useCallback, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Alert from 'react-bootstrap/esm/Alert';
import Form from 'react-bootstrap/esm/Form';
import { Api } from '../../common/api/api';
import { useNavigate } from 'react-router-dom';
import { EAdminRouterPath } from '../../router-path';
import { ApiException } from '../../common/api/types';
import { useTranslation } from 'react-i18next';
import { DEFAULT_RECORDING_LANGUAGE, ERecordingLanguage, ESchoolSubscription, ISchool, IUser } from 'shared';
import { BoxDivider } from '../../common/utils/box.component';
import { toast } from 'react-toastify';
import { CreateAdminFields } from './create-admin-fields.component';
import { Col, Row } from 'react-bootstrap';

const INPUT_MAX_LENGTH = 255;
const FRIENDLY_ID_MAX_LENGTH = 32;
const FRIENDLY_REGEX = /^[a-z-0-9-]+$/;
const FRIENDLY_INVALID_CHARS_REGEX = /[^a-z-0-9-]+/g;

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

export interface EditSchoolInputsProps {
  schoolName: string;
  setSchoolName: (value: string) => void;
  friendlyId: string;
  setFriendlyId: (value: string) => void;
  contactName: string;
  setContactName: (value: string) => void;
  contactEmail: string;
  setContactEmail: (value: string) => void;
  manualFriendlyId: boolean;
  setManualFriendlyId: (value: boolean) => void;
  subscriptionType: ESchoolSubscription;
  setSubscriptionType: (value: ESchoolSubscription) => void;
  subscriptionEndAt: string;
  setSubscriptionEndAt: (value: string) => void;
  customSubscription: boolean;
  setCustomSubscription: (value: boolean) => void;
  language: ERecordingLanguage;
  setLanguage: (value: ERecordingLanguage) => void;
}

export function EditSchoolInputs(props: EditSchoolInputsProps): JSX.Element {
  const {
    schoolName,
    setSchoolName,
    friendlyId,
    setFriendlyId,
    contactName,
    setContactName,
    contactEmail,
    setContactEmail,
    manualFriendlyId,
    setManualFriendlyId,
    subscriptionType,
    setSubscriptionType,
    subscriptionEndAt,
    setSubscriptionEndAt,
    customSubscription,
    setCustomSubscription,
    language,
    setLanguage,
  } = props;
  const { t } = useTranslation();

  const makeFriendlyId = (value: string, replacement: string): string => value
    .trim()
    .toLowerCase()
    .replace(FRIENDLY_INVALID_CHARS_REGEX, replacement)
    .substring(0, FRIENDLY_ID_MAX_LENGTH);

  const handleInputName = useCallback((value: string): void => {
    setSchoolName(value.trimStart());

    if (!manualFriendlyId) {
      setFriendlyId(makeFriendlyId(value.trim(), '-'));
    }
  }, [manualFriendlyId]);

  const handleInputFriendlyId = (value: string): void => {
    setManualFriendlyId(true);

    const hasTailingSpace = value.endsWith(' '); // Restore the tailing space else the user is not able to type spaces
    setFriendlyId(makeFriendlyId(value, '') + (hasTailingSpace ? '-' : ''));
  };

  const handleInputContactName = (value: string): void => setContactName(value.trimStart());
  const handleInputContactEmail = (value: string): void => setContactEmail(value.trim());

  return <>
    <fieldset>
      <legend>School info</legend>
      <Form.Group className="mb-3">
        <Form.Label>{t('school:name')}</Form.Label>
        <Form.Control
          type="text"
          required
          minLength={1}
          maxLength={255}
          placeholder={t('school:name')}
          value={schoolName}
          onChange={e => handleInputName(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('school:friendly-id')}</Form.Label>
        <Form.Control
          type="text"
          required
          minLength={1}
          maxLength={FRIENDLY_ID_MAX_LENGTH}
          pattern={FRIENDLY_REGEX.source}
          placeholder={t('school:friendly-id')}
          value={friendlyId}
          onChange={e => handleInputFriendlyId(e.target.value)} />
      </Form.Group>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{t('school:contact-name')}</Form.Label>
            <Form.Control
              type="text"
              required
              minLength={1}
              maxLength={INPUT_MAX_LENGTH}
              placeholder={t('school:contact-name')}
              value={contactName}
              onChange={e => handleInputContactName(e.target.value)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{t('school:contact-email')}</Form.Label>
            <Form.Control
              type="email"
              required
              minLength={1}
              maxLength={INPUT_MAX_LENGTH}
              placeholder="e@mail.com"
              value={contactEmail}
              onChange={e => handleInputContactEmail(e.target.value)} />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group>
        <Form.Label>{t('school:language')}</Form.Label>
        <Form.Select value={language} onChange={e => setLanguage(e.target.value as ERecordingLanguage)}>
          {Object.values(ERecordingLanguage).map(lang => <option key={lang} value={lang}>{t(`common:language:${lang}`)}</option>)}
        </Form.Select>
      </Form.Group>

      <Form.Group>
        <Form.Label>{t('school:subscription.title')}</Form.Label>
        <Form.Select value={subscriptionType} onChange={e => setSubscriptionType(e.target.value as ESchoolSubscription)}>
          <option value={ESchoolSubscription.SUBSCRIPTION}>{t(`school:subscription.${ESchoolSubscription.SUBSCRIPTION}`)}</option>
          <option value={ESchoolSubscription.TRIAL}>{t(`school:subscription.${ESchoolSubscription.TRIAL}`)}</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Label>{t(`school:subscription:expiration-default:${subscriptionType}`)}</Form.Label>
        <Form.Check
          type="switch"
          label={t('school:subscription:expiration-custom')}
          checked={customSubscription}
          onChange={(event => setCustomSubscription(event.target.checked))}
        />
      </Form.Group>

      { customSubscription && <Form.Group className="mb-3">
        <Form.Label>{t('school:subscription.expiration')}</Form.Label>
        <Form.Control
          type="text"
          minLength={10}
          maxLength={10}
          pattern={DATE_REGEX.source}
          placeholder="YYYY-MM-DD"
          value={subscriptionEndAt}
          onChange={e => setSubscriptionEndAt(e.target.value)} />
      </Form.Group> }
    </fieldset>
  </>;
}

export function CreateSchool(): JSX.Element {
  const [error, setError] = useState<string|null>(null);

  const [schoolName, setSchoolName] = useState<string>('');
  const [friendlyId, setFriendlyId] = useState<string>('');
  const [contactName, setContactName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [manualFriendlyId, setManualFriendlyId] = useState<boolean>(false);
  const [subscriptionType, setSubscriptionType] = useState<ESchoolSubscription>(ESchoolSubscription.TRIAL);
  const [subscriptionEndAt, setSubscriptionEndAt] = useState<string>('');
  const [customSubscription, setCustomSubscription] = useState<boolean>(false);
  const [language, setLanguage] = useState<ERecordingLanguage>(DEFAULT_RECORDING_LANGUAGE);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [adminFirstName, setAdminFirstName] = useState<string>('');
  const [adminLastName, setAdminLastName] = useState<string>('');
  const [adminEmail, setAdminEmail] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleError = (err: any): void => {
    if (err instanceof ApiException) {
      setError(err.message);
      return;
    }

    console.error('Unexpected CreateSchool error', err);
    setError('An unknown error occurred');
  };

  const handleSubmit = async(event: FormEvent): Promise<void> => {
    event.preventDefault();

    setSubmitting(true);
    let school: ISchool|undefined;
    let admin: IUser|undefined;
    try {
      const subscriptionEndAtValue = customSubscription && subscriptionEndAt ? new Date(subscriptionEndAt) : undefined;
      school = await Api.createSchool({ name: schoolName.trim(), friendlyId, contactName: contactName.trim(), contactEmail: contactEmail, subscriptionType, subscriptionEndAt: subscriptionEndAtValue, language });
      admin = await Api.addSchoolAdmin(school.id, { firstName: adminFirstName.trim(), lastName: adminLastName.trim(), email: adminEmail.trim() });
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);

      if (school && !admin) {
        toast.warning(t('school:notifications:admin-failed'), { autoClose: false });
      }

      if (school) {
        navigate(EAdminRouterPath.SCHOOL_SHOW.fullPath(school.id));
      }
    }
  };

  return <>
    <Form onSubmit={(e) => {
      handleSubmit(e).catch(console.error);
    }}>
      <EditSchoolInputs
        schoolName={schoolName}
        setSchoolName={setSchoolName}
        friendlyId={friendlyId}
        setFriendlyId={setFriendlyId}
        manualFriendlyId={manualFriendlyId}
        setManualFriendlyId={setManualFriendlyId}
        contactName={contactName}
        setContactName={setContactName}
        contactEmail={contactEmail}
        setContactEmail={setContactEmail}
        subscriptionType={subscriptionType}
        setSubscriptionType={setSubscriptionType}
        subscriptionEndAt={subscriptionEndAt}
        setSubscriptionEndAt={setSubscriptionEndAt}
        customSubscription={customSubscription}
        setCustomSubscription={setCustomSubscription}
        language={language}
        setLanguage={setLanguage}
      />
      <BoxDivider />
      <fieldset>
        <legend>{t('school:admin.user.title')}</legend>

        <CreateAdminFields
          firstName={adminFirstName}
          onChangeFirstName={setAdminFirstName}
          lastName={adminLastName}
          onChangeLastName={setAdminLastName}
          email={adminEmail}
          onChangeEmail={setAdminEmail}
        />
      </fieldset>

      <BoxDivider />

      { error && <Alert variant="danger">{error}</Alert>}
      <Button variant="primary" type="submit" disabled={submitting}>{t('common:add')}</Button>
    </Form>
  </>;
}
