import { useQuery } from 'react-query';
import { Api } from '../../common/api/api';
import { Status } from '../../common/utils/status.component';
import SchoolTable from './table';

export function ListSchools(): JSX.Element {
  const { data: schools, error, isLoading } = useQuery('schools', Api.listSchools);

  return <>
    <Status loading={isLoading} error={error} />
    <SchoolTable schools={schools} />
  </>;
}
