import { ModalLesson } from './modal-lesson.component';
import { ModalComponent } from './modal.component';

export function ModalInfo(): JSX.Element {
  return (
    <ModalComponent title="Kop van de les info">
      <ModalLesson withArrows/>
    </ModalComponent>
  );
}
