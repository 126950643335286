"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.migrationToV2 = void 0;
var exercises_1 = require("../exercises");
exports.migrationToV2 = {
    migratingFromVersion: 1,
    migratingToVersion: 2,
    migration: function (lessonDocument) {
        for (var _i = 0, _a = lessonDocument.exercises; _i < _a.length; _i++) {
            var exercise = _a[_i];
            if (exercise.type !== exercises_1.EExerciseType.HIDDEN_OBJECTS_GAME) {
                continue;
            }
            var data = exercise.data;
            if (!data) {
                continue;
            }
            var tiles = data.tiles || [];
            for (var _b = 0, tiles_1 = tiles; _b < tiles_1.length; _b++) {
                var tile = tiles_1[_b];
                // In v1 a tile had one hitbox instead of an array of hitboxes
                var hitbox = tile.hitbox;
                // Add missing roundness value to hitbox
                hitbox.roundness = 100;
                // Migrate single hitbox to array of hitboxes
                tile.hitbox = [hitbox];
            }
        }
        return lessonDocument;
    },
};
