interface IDateTimeProps {
  className?: string|string[];
  date: Date;
}

function convertMonth(month: number): string {
  switch (month) {
    case 0: return 'januari';
    case 1: return 'februari';
    case 2: return 'maart';
    case 3: return 'april';
    case 4: return 'mei';
    case 5: return 'juni';
    case 6: return 'juli';
    case 7: return 'augustus';
    case 8: return 'september';
    case 9: return 'oktober';
    case 10: return 'november';
    case 11: return 'december';
    default: return '???';
  }
}

export function DateTime({ className, date }: IDateTimeProps): JSX.Element|null {
  const fullClassName = ['date-time', ...(Array.isArray(className) ? className : [className])].join(' ');
  date = new Date(date);
  const leadingZeroHours = date.getHours() < 10;
  const leadingZeroMinutes = date.getMinutes() < 10;
  const prettyDate = `${date.getDate()} ${convertMonth(date.getMonth())} ${date.getFullYear()} om ${leadingZeroHours ? '0' : ''}${date.getHours()}:${leadingZeroMinutes ? '0' : ''}${date.getMinutes()}`;

  return <time className={fullClassName} dateTime={date.toISOString()}>{prettyDate}</time>;
}
