/* eslint-disable no-void */
import React, { useState } from 'react';
import { Api } from '../api/api';
import { FileInput } from './file-input.component';
import classNames from 'classnames';
import './avatar-input.component.scss';
import { ALLOWED_IMAGE_MIME_TYPES, ALLOWED_IMAGE_FILE_EXTENSIONS } from './image-input.component';
import { getStorageUrl } from './storage.helper';

interface IAvatarInputProps {
  className?: string;
  value?: string|null;
  onChange: (value: string|null) => void;
}

export function AvatarInput({ className, value, onChange }: IAvatarInputProps): JSX.Element {
  const [isProcessingImage, setIsProcessingImage] = useState<boolean>(false);

  const processImage = (file: File): Promise<string> => {
    const filename = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
    return Api.uploadAvatar(file, filename)
      .then(result => result.filename);
  };

  const handleFileChange = (file: File|null) => {
    if (!file) {
      onChange(null);
      return;
    }

    setIsProcessingImage(true);
    processImage(file)
      .then(onChange)
      .catch(err => {
        console.error('Failed to upload image', err);
      })
      .finally(() => {
        setIsProcessingImage(false);
      });
  };

  return <FileInput
    className={classNames('avatar-input', className)}
    processing={isProcessingImage}
    displayDelete={!!value}
    allowedMimeTypes={ALLOWED_IMAGE_MIME_TYPES}
    allowedFileExtension={ALLOWED_IMAGE_FILE_EXTENSIONS}
    onChange={handleFileChange}
  >
    { value && <img className="avatar-input__preview" src={getStorageUrl(value)} alt=""/> }
  </FileInput>;
}
