import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PupilProvider } from '../common/pupil-provider/pupil.provider';
import { ELessonRouterPath, EMainRouterPath } from '../router-path';
import { ListLessonItems } from './list-lesson-items';
import { SelectLesson } from './select-lesson';
import { ShowLesson } from './lesson/show';
import { InstructionLabelsProvider } from '../common/instruction-labels-provider/instruction-labels.provider';

export function LessonModule(): JSX.Element {
  return (
    <>
      <PupilProvider unauthorizedRedirectPath={EMainRouterPath.NOT_FOUND.path}>
        <InstructionLabelsProvider>
          <Routes>
            <Route path={ELessonRouterPath.HOME.path} element={<SelectLesson />} />
            <Route path={ELessonRouterPath.LIST_LESSONS.path} element={<ListLessonItems />} />
            <Route path={ELessonRouterPath.SHOW_LESSON.path} element={<ShowLesson />} />
          </Routes>
        </InstructionLabelsProvider>
      </PupilProvider>
    </>
  );
}
