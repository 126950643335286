import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Api } from '../../common/api/api';
import { PageTitle } from '../../common/utils/page-title.component';
import { Status } from '../../common/utils/status.component';
import { EAdminRouterPath } from '../../router-path';

export function ShowUser(): JSX.Element {
  const { id } = useParams();
  const { data: user, error, isLoading } = useQuery(['user', id], () => {
    if (!id) {
      return Promise.reject(new Error('Missing user id'));
    }
    return Api.getUser(+id);
  });

  return <>
    <PageTitle backLink={EAdminRouterPath.USER_LIST.fullPath}>Gebruiker</PageTitle>
    <Status loading={isLoading} error={error} />
    <div>
      <h4>{user?.firstName} {user?.lastName}</h4>
      <p>{user?.capabilities.join(', ')}</p>
    </div>
  </>;
}
