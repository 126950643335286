import superagent from 'superagent';
import { AccessTokenExpiredError, AuthService, NoTokenError, RefreshTokenExpiredError } from '../auth/auth.service';
import { getPupilAccessToken } from '../utils/pupil-auth.helper';
import { ApiException } from './types';

const base = process.env.REACT_APP_API_BASE_PATH || '';

const AUTH_HEADER_NAME = 'x-access-token';
const AUTH_PUPIL_HEADER_NAME = 'x-pupil-access-token';

//  These two come from the type definitions for SuperAgent 4.1 since they are not exported
type MultipartValueSingle = Blob | Buffer | string | boolean | number;
type MultipartValue = MultipartValueSingle | MultipartValueSingle[];

interface RequestOptions {
  optionalUserAuth?: boolean;
}

function getAccessToken({ optionalUserAuth }: Pick<RequestOptions, 'optionalUserAuth'> = {}): Promise<string|''> {
  return AuthService.getAccessToken()
    .catch(err => {
      if (optionalUserAuth && (
        err instanceof AccessTokenExpiredError ||
        err instanceof RefreshTokenExpiredError ||
        err instanceof NoTokenError
      )) {
        return '';
      }
      throw err;
    });
}

function parseToObject<T>(json: string): T {
  const datesToObjects = (_key: string, value: any): any => {
    if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)) { // is ISO datetime string
      return new Date(value);
    }
    return value;
  };

  return JSON.parse(json, datesToObjects) as T;
}

export abstract class Request {
  static async post<T>(path: string, body?: string|Blob|Record<string, unknown>, options: RequestOptions = {}): Promise<T> {
    const token = await getAccessToken(options);
    const pupilToken = getPupilAccessToken() || '';
    return superagent.post(`${base}${path}`)
      .set(AUTH_HEADER_NAME, token)
      .set(AUTH_PUPIL_HEADER_NAME, pupilToken)
      .send(body)
      .set('accept', 'json')
      .then(result => parseToObject<T>(result.text))
      .catch(err => {
        throw Request.translateError(err);
      });
  }

  static async postFile<T>(path: string, filename: string, blob: Blob, data?: Record<string, MultipartValue>): Promise<T> {
    const token = await AuthService.getAccessToken();
    const pupilToken = getPupilAccessToken() || '';
    const request = superagent.post(`${base}${path}`)
      .set(AUTH_HEADER_NAME, token)
      .set(AUTH_PUPIL_HEADER_NAME, pupilToken)
      .set('accept', 'json')
      .attach('file', new File([blob], filename));

    if (data) {
      Object.keys(data).forEach(key => {
        const value = data[key];
        // eslint-disable-next-line no-void
        void request.field(key, JSON.stringify(value));
      });
    }

    return request
      .then(result => parseToObject<T>(result.text))
      .catch(err => {
        throw Request.translateError(err);
      });
  }

  static async delete<T>(path: string, body?: string|Record<string, unknown>): Promise<T> {
    const token = await AuthService.getAccessToken();
    const pupilToken = getPupilAccessToken() || '';
    return superagent.delete(`${base}${path}`)
      .set(AUTH_HEADER_NAME, token)
      .set(AUTH_PUPIL_HEADER_NAME, pupilToken)
      .send(body)
      .set('accept', 'json')
      .then(result => parseToObject<T>(result.text))
      .catch(err => {
        throw Request.translateError(err);
      });
  }

  static async put<T>(path: string, body?: string|Record<string, unknown>, options: RequestOptions = {}): Promise<T> {
    const token = await getAccessToken(options);
    const pupilToken = getPupilAccessToken() || '';
    return superagent.put(`${base}${path}`)
      .set(AUTH_HEADER_NAME, token)
      .set(AUTH_PUPIL_HEADER_NAME, pupilToken)
      .send(body)
      .set('accept', 'json')
      .then(result => parseToObject<T>(result.text))
      .catch(err => {
        throw Request.translateError(err);
      });
  }

  static async get<T>(path: string, options: RequestOptions = {}): Promise<T> {
    const token = await getAccessToken(options);
    const pupilToken = getPupilAccessToken() || '';
    return superagent.get(`${base}${path}`)
      .set(AUTH_HEADER_NAME, token)
      .set(AUTH_PUPIL_HEADER_NAME, pupilToken)
      .set('accept', 'json')
      .then(result => parseToObject<T>(result.text))
      .catch(err => {
        throw Request.translateError(err);
      });
  }

  private static translateError(error: any): ApiException {
    if (typeof error?.response?.text !== 'string') { // eslint-disable-line @typescript-eslint/no-unsafe-member-access
      console.error('Unexpected error type:', error);
      return new ApiException();
    }

    const response = JSON.parse(error.response.text as string); // eslint-disable-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    if (typeof response?.message !== 'string') { // eslint-disable-line @typescript-eslint/no-unsafe-member-access
      console.error('Unexpected error response:', error);
      return new ApiException();
    }

    const translatedError = ApiException.from(response.message); // eslint-disable-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    if (!translatedError) {
      console.error('Unknown error from api:', response.message, response); // eslint-disable-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      return new ApiException(response.message); // eslint-disable-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    }

    return translatedError;
  }
}
