import { AppRouter } from './app-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App(): JSX.Element {
  return (
    <>
      <ToastContainer />
      <AppRouter />
    </>
  );
}

export default App;
