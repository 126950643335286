import React, { useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import Button from 'react-bootstrap/esm/Button';
import Table from 'react-bootstrap/esm/Table';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import Form from 'react-bootstrap/esm/Form';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from '../../common/api/api';
import { Group, Pupil } from '../../common/api/types';
import { PageTitle } from '../../common/utils/page-title.component';
import { Status } from '../../common/utils/status.component';
import { useSchool } from '../../common/school-provider/school.context';
import { EditGroupModal, CreateGroupModal } from './group-modal.component';
import { CreatePupilModal, EditPupilModal } from './pupil-modal.component';
import { EIcon, Icon } from '../../common/utils/icon.component';
import { getAvatarPath } from '../../common/utils/avatar-path';
import { EPupilPasswordPart, ITeacher } from 'shared';
import { ESortDirection } from '../../common/utils/dynamic-table.component';
import { getPasswordImagePath } from '../../common/utils/password-image-path';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { CreateTeacherModal, EditTeacherModal } from './teacher-modal.component';
import { getStorageUrl } from '../../common/utils/storage.helper';
import { useSession } from '../../common/auth/session.context';

class MissingSchoolError extends Error {
  constructor() {
    super('Missing school');
  }
}

export enum ESortBy {
  NAME = 'name',
  TYPE= 'type',
  CODE = 'code',
}

interface UserTableProps {
  groups?: Group[];
  pupils?: Pupil[];
  teachers?: ITeacher[];
  onEditGroup: (group: Group) => void;
  onEditUser: (pupil: Pupil) => void;
  onEditTeacher: (teacher: ITeacher) => void;
  searchQuery?: string;
}

type THeader = {displayName: string, key: string};

type TGroupsWithUsers = { group: Group, users: Pupil[], teachers: ITeacher[] };

function UserTable({ groups, pupils, teachers, onEditGroup, onEditUser, onEditTeacher, searchQuery }: UserTableProps): JSX.Element|null {
  const session = useSession();
  const { school } = useSchool();
  const [openGroupId, setOpenGroupId] = useState<number|null>(null);
  const [sortedBy, setSortedBy] = useState<string|null>('name');
  const [sortDirection, setSortDirection] = useState<ESortDirection>(ESortDirection.ASCENDING);
  const isAdminOfSchool = session.adminOfSchools.find(adminOfSchool => adminOfSchool.id === school?.id);

  const sortPupilsByName = (a: Pupil, b: Pupil): number => (sortDirection === ESortDirection.ASCENDING ? `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`) : `${b.firstName} ${b.lastName}`.localeCompare(`${a.firstName} ${a.lastName}`));
  const sortGroupsByName = (a: TGroupsWithUsers, b: TGroupsWithUsers): number => (sortDirection === ESortDirection.ASCENDING ? a.group.name.localeCompare(b.group.name) : b.group.name.localeCompare(a.group.name));

  const sortByType = (a: TGroupsWithUsers, b: TGroupsWithUsers): number => (sortDirection === ESortDirection.ASCENDING ? a.group.level.localeCompare(b.group.level) : b.group.level.localeCompare(a.group.level));

  const sortByCode = (a: Pupil, b: Pupil): number => (sortDirection === ESortDirection.ASCENDING ? (a.password as string).localeCompare((b.password as string)) : (b.password as string).localeCompare((a.password as string)));

  const items = useMemo(() => {
    const orphanUsers: Pupil[] = [];
    const orphanTeachers: ITeacher[] = [];
    let groupsWithUsers: { [groupId: number]: TGroupsWithUsers } = {};

    (groups || []).forEach(group => {
      groupsWithUsers[group.id] = { group, users: [], teachers: [] };
    });

    (pupils || []).forEach(user => {
      if (user.groupId && groupsWithUsers[user.groupId]) {
        groupsWithUsers[user.groupId].users.push(user);
      } else {
        orphanUsers.push(user);
      }
    });

    (teachers || []).forEach(teacher => {
      let inGroups = false;
      teacher.groups.forEach(group => {
        if (!groupsWithUsers[group.id]) {
          return;
        }
        groupsWithUsers[group.id].teachers.push(teacher);
        inGroups = true;
      });

      if (!inGroups) {
        orphanTeachers.push(teacher);
      }
    });

    if (sortedBy === ESortBy.NAME) {
      orphanUsers.sort(sortPupilsByName);

      groupsWithUsers = Object.values(groupsWithUsers).sort(sortGroupsByName);
      Object.values(groupsWithUsers).forEach((group: TGroupsWithUsers) => {
        group.users.sort(sortPupilsByName);
      });
    } else if (sortedBy === ESortBy.TYPE) {
      Object.values(groupsWithUsers).sort(sortByType);
    } else if (sortedBy === ESortBy.CODE) {
      Object.values(groupsWithUsers).forEach((group: TGroupsWithUsers) => {
        group.users.sort(sortByCode);
      });
    }

    if (searchQuery) {
      Object.values(groupsWithUsers).forEach((group: TGroupsWithUsers) => {
        group.users = group.users.filter((user: Pupil) => user.firstName.toLowerCase().includes(searchQuery.toLowerCase()));
      });

      Object.values(groupsWithUsers).forEach((group: TGroupsWithUsers, index: number) => {
        if (group.users.length === 0) {
          delete groupsWithUsers[index];
        }
      });
    }

    return { orphanUsers, orphanTeachers, groupedUsers: Object.values(groupsWithUsers) };
  }, [groups, pupils, teachers, sortedBy, sortDirection, searchQuery]);

  const handleToggleGroup = (group: Group) => {
    if (group.id === openGroupId) {
      setOpenGroupId(null);
    } else {
      setOpenGroupId(group.id);
    }
  };

  const headers: THeader[] = [
    {
      displayName: t(`sort-by:${ESortBy.NAME}`),
      key: 'name',
    },
    {
      displayName: t(`sort-by:${ESortBy.TYPE}`),
      key: 'type',
    },
    {
      displayName: t(`sort-by:${ESortBy.CODE}`),
      key: 'code',
    }
  ];

  const renderUserPassword = (user: Pupil) => (user.password as EPupilPasswordPart).split('/').map(
    (char, idx) => <img alt={char} className="user-table__row--user-password-image" style={{ height: '50px', width: '50px' }} key={idx} src={getPasswordImagePath((char as EPupilPasswordPart))} />
  );

  const renderHeaderSortIcon = (columnLayout: THeader): JSX.Element|null => {
    const columnIsSorted = sortedBy === columnLayout.key;
    const icons = <><Icon>{EIcon.CHEVRON_UP}</Icon><Icon>{EIcon.CHEVRON_DOWN}</Icon></>;
    if (columnIsSorted && sortDirection === ESortDirection.DESCENDING) {
      return <span className="header-item-sort__icon header-item-sort__icon--desc" aria-label="Aflopend gesorteerd, klik om te veranderen">{icons}</span>;
    } else if (columnIsSorted && sortDirection === ESortDirection.ASCENDING) {
      return <span className="header-item-sort__icon header-item-sort__icon--asc" aria-label="Oplopend gesorteerd, klik om te veranderen">{icons}</span>;
    } else {
      return <span className="header-item-sort__icon header-item-sort__icon--none" aria-label="Klik om te sorteren">{icons}</span>;
    }
  };

  const renderUser = (user: Pupil) => (
    <tr className="user-table__row user-table__row--user" key={`user_${user.id}`}>
      <td className="dynamic-table-column">
        <div className="user-table__row--user-info">
          <img alt={`Profielfoto van ${user.firstName} ${user.lastName}`} className="user-table__row--image" src={getAvatarPath(user.avatar)}/>
          <span aria-label="Volledige naam van de gebruiker">{user.firstName} {user.lastName}</span>
        </div>
      </td>
      <td className="dynamic-table-column">
        {t('users:pupil')}
      </td>
      <td className="dynamic-table-column">
        <div className="user-table__row--user-password">
          {renderUserPassword(user)}
        </div>
      </td>
      { isAdminOfSchool && <td className="dynamic-table-column">
        <Button variant="link" className="user-table__row--edit-button" aria-label="Klik om de gebruiker aan te passen" onClick={(e) => {
          e.stopPropagation();
          onEditUser(user);
        }}>
          <Icon>{ EIcon.PENCIL }</Icon> <span>Aanpassen</span>
        </Button>
      </td> }
    </tr>
  );

  const renderTeacher = (teacher: ITeacher) => (
    <tr className="user-table__row user-table__row--user" key={`teacher_${teacher.id}`}>
      <td className="dynamic-table-column">
        <div className="user-table__row--user-info">
          {
            // eslint-disable-next-line no-constant-condition, no-warning-comments
            teacher.avatar ?
              <img alt={`Profielfoto van ${teacher.user.firstName} ${teacher.user.lastName}`} className="user-table__row--image" src={getStorageUrl(teacher.avatar)} /> :
              <div className="user-table__row--image" />
          }
          <span aria-label="Volledige naam van de leraar">{teacher.user.firstName} {teacher.user.lastName}</span>
        </div>
      </td>
      <td className="dynamic-table-column">
        {t('users:teacher')}
      </td>
      <td className="dynamic-table-column">
      </td>
      { isAdminOfSchool && <td className="dynamic-table-column">
        <Button variant="link" className="user-table__row--edit-button" aria-label="Klik om de leraar aan te passen" onClick={(e) => {
          e.stopPropagation();
          onEditTeacher(teacher);
        }}>
          <Icon>{EIcon.PENCIL}</Icon> <span>Aanpassen</span>
        </Button>
      </td> }
    </tr>
  );

  const renderPrintRows = (user: Pupil) => (
    <tr key={user.id}>
      <td>
        <img className="avatar" src={getAvatarPath(user.avatar)} />
        <span>{user.firstName} {user.lastName}</span>
      </td>
      <td style={{ textAlign: 'center' }}>
        {renderUserPassword(user)}
      </td>
    </tr>
  );

  const handlePrint = (userRows: JSX.Element[]) => {
    const rows = ReactDOMServer.renderToString(<div>{userRows}</div>);
    const doc = `
      <style>
        * {
          border-color: lightgray;
        }

        table {
          font-family: Verdana, sans-serif;
          border-collapse: collapse;
          max-width: 840px;
          margin: 0 auto;
        }

        td {
          padding: 0 26px;
        }

        .avatar {
          width: 40px;
          height: 40px;
          border: solid 1px lightgray;
          border-radius: 100%;
          vertical-align: middle;
          margin-right: 8px;
        }

        .user-table__row--user-password-image {
          border: solid 1px lightgray;
          margin: 8px 4px;
        }
      </style>
      <table width="100%" border="1">
        <thead>
        <tr>
          <th style="text-align: center">Naam</th>
          <th style="text-align: center">Code</th>
        </tr>
        </thead>
        <tbody>
          ${rows}
        </tbody>
      </table>
    `;

    if (typeof window !== 'undefined') {
      const newWin = window.open('');
      newWin?.document.write(doc);
      setTimeout(() => {
        newWin?.print();
        newWin?.close();
      }, 500);
    }
  };

  const handleEditGroup = (e: React.MouseEvent<HTMLElement>, item: {group: Group, users: Pupil[]}) => {
    e.stopPropagation();
    onEditGroup(item.group);
  };

  const onPrint = (e: React.MouseEvent<HTMLElement>, item: {group: Group, users: Pupil[]}) => {
    e.stopPropagation();
    handlePrint(item.users.map(renderPrintRows));
  };

  const renderGroupWithUsers = (item: { group: Group, users: Pupil[], teachers: ITeacher[] }) => [
    <tr key={`group_${item.group.id}`} className="user-table__row user-table__row--group" onClick={(e) => {
      e.stopPropagation();
      handleToggleGroup(item.group);
    }}>
      <td className="dynamic-table-column">
        <div className="user-table__row--user-info">
          <Icon>
            { openGroupId === item.group.id ? EIcon.CHEVRON_DOWN : EIcon.CHEVRON_RIGHT }
          </Icon>
          { item.group.avatar ?
            <img alt={`Avatar van ${item.group.name}`} className="user-table__row--image" src={getStorageUrl(item.group.avatar)}/> :
            <Icon>{ EIcon.FOLDER }</Icon>
          }
          <span>{item.group.name}</span>
        </div>
      </td>
      <td className="dynamic-table-column">{t(`lesson-level:${item.group.level}`)}</td>
      <td className="dynamic-table-column">
        <div>
          <Button variant="link" onClick={(e) => onPrint(e, item)}
            className="user-table__row--print-button">
            <Icon>{EIcon.PRINT}</Icon> <span>{t('school:print-group-codes')}</span>
          </Button>
        </div>
      </td>
      { isAdminOfSchool && <td className="dynamic-table-column">
        <Button variant="link" onClick={(e) => handleEditGroup(e, item)} className="user-table__row--edit-button">
          <Icon>{ EIcon.PENCIL }</Icon> <span>{t('school:edit-group')}</span>
        </Button>
      </td> }
    </tr>,
    item.group.id === openGroupId ? item.teachers.map(renderTeacher) : null,
    item.group.id === openGroupId ? item.users.map(renderUser) : null,
  ];

  const setSortOrder = (columnLayout: THeader): void => {
    // On first click: start sorting as descending
    // On second click: switch sorting to ascending
    // On third click: reset sorting on this column
    if (sortedBy === columnLayout.key) {
      switch (sortDirection) {
        case ESortDirection.DESCENDING:
          setSortDirection(ESortDirection.ASCENDING);
          break;
        case ESortDirection.ASCENDING:
        default:
          setSortDirection(ESortDirection.DESCENDING);
          break;
      }
    } else {
      setSortedBy(columnLayout.key);
      setSortDirection(ESortDirection.DESCENDING);
    }
  };


  return <Table className="dynamic-table">
    <thead className="dynamic-table__head">
      <tr>
        {headers.map((header) => <th className="dynamic-table__head-item dynamic-table-column" key={header.key}><button className="dynamic-table__head-item-sort-button" onClick={() => setSortOrder(header)}>
          <span className="head-item-sort__title">{header.displayName}</span>{renderHeaderSortIcon(header)}
        </button> </th>)}
        { isAdminOfSchool && <th className="dynamic-table__head-item dynamic-table-column"><span className="head-item-sort__title">Acties</span></th> }
      </tr>
    </thead>
    <div className="dynamic-table__divider" />
    <tbody className="dynamic-table__body">
      { items.orphanTeachers.map(renderTeacher)}
      { items.orphanUsers.map(renderUser)}
      { items.groupedUsers.map(renderGroupWithUsers)}
    </tbody>
  </Table>;
}

export function ListSchoolUsers(): JSX.Element {
  const session = useSession();
  const queryClient = useQueryClient();
  const [createGroup, setCreateGroup] = useState(false);
  const [createPupil, setCreatePupil] = useState(false);
  const [createTeacher, setCreateTeacher] = useState(false);
  const [editGroup, setEditGroup] = useState<Group|null>(null);
  const [editPupil, setEditPupil] = useState<Pupil|null>(null);
  const [editTeacher, setEditTeacher] = useState<ITeacher|null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { t } = useTranslation();
  const { school } = useSchool();
  const isAdminOfSchool = session.adminOfSchools.find(adminOfSchool => adminOfSchool.id === school?.id);

  const { data: groups, error: errorOnGroups, isLoading: isLoadingGroups } = useQuery(['groups', school], () => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.listSchoolGroups(school.id);
  });

  const { data: pupils, error: errorOnPupils, isLoading: isLoadingPupils } = useQuery(['pupils', school], () => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.listSchoolPupils(school.id);
  });

  const { data: teachers, error: errorOnTeachers, isLoading: isLoadingTeachers } = useQuery(['teachers', school], () => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.listSchoolTeachers(school.id);
  });

  // Group - mutations

  const createGroupMutation = useMutation((newGroup: Omit<Group, 'id'|'schoolId'>) => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.createSchoolGroup(school.id, newGroup);
  }, { onSuccess: () => queryClient.invalidateQueries('groups') });

  const updateGroupMutation = useMutation((group: Group) => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.updateSchoolGroup(school.id, group.id, group);
  }, { onSuccess: () => queryClient.invalidateQueries('groups') });

  const deleteGroupMutation = useMutation((group: Group) => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.deleteSchoolGroup(school.id, group.id);
  }, { onSuccess: () => queryClient.invalidateQueries('groups') });

  // Group - handlers

  const handleCreateGroup = (newGroup: Omit<Group, 'id'|'schoolId'>) => {
    createGroupMutation.mutate(newGroup);
    setCreateGroup(false);
  };

  const handleUpdateGroup = (group: Group) => {
    updateGroupMutation.mutate(group);
    setEditGroup(null);
  };

  const handleDeleteGroup = (group: Group) => {
    deleteGroupMutation.mutate(group);
    setEditGroup(null);
  };

  const handleEditGroup = (group: Group) => {
    setEditGroup(group);
  };

  const handleCancelEditGroup = () => {
    setCreateGroup(false);
    setEditGroup(null);
  };

  // Pupil - mutations

  const createPupilMutation = useMutation((newPupil: Omit<Pupil, 'id'>) => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.createSchoolPupil(school.id, newPupil);
  }, { onSuccess: () => queryClient.invalidateQueries('pupils') });

  const updatePupilMutation = useMutation((pupil: Pupil) => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.updateSchoolPupil(school.id, pupil.id, pupil);
  }, { onSuccess: () => queryClient.invalidateQueries('pupils') });

  const deletePupilMutation = useMutation((pupil: Pupil) => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.deleteSchoolPupil(school.id, pupil.id);
  }, { onSuccess: () => queryClient.invalidateQueries('pupils') });

  // Pupil - handlers

  const handleCreatePupil = (newPupil: Omit<Pupil, 'id'>) => {
    createPupilMutation.mutate(newPupil);
    setCreatePupil(false);
  };

  const handleUpdatePupil = (pupil: Pupil) => {
    updatePupilMutation.mutate(pupil);
    setEditPupil(null);
  };

  const handleDeletePupil = (pupil: Pupil) => {
    deletePupilMutation.mutate(pupil);
    setEditPupil(null);
  };

  const handleEditPupil = (pupil: Pupil) => {
    setEditPupil(pupil);
  };

  const handleCancelEditPupil = () => {
    setCreatePupil(false);
    setEditPupil(null);
  };

  // Teacher - mutations
  const invalidateTeachers = async(changedTeacher: ITeacher) => {
    await queryClient.invalidateQueries('teachers');
    if (changedTeacher.user.id === session.user?.id) {
      session.invalidateUserInfo();
    }
  };

  const createTeacherMutation = useMutation((newTeacher: { email: string, firstName: string, lastName: string, groups: Group[], avatar?: string }) => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.createSchoolTeacher(school.id, { ...newTeacher, groupIds: newTeacher.groups.map(g => g.id) });
  }, { onSuccess: invalidateTeachers });

  const updateTeacherMutation = useMutation((teacher: ITeacher) => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.updateSchoolTeacher(school.id, teacher.user.id, { groupIds: teacher.groups.map(g => g.id), avatar: teacher.avatar });
  }, { onSuccess: invalidateTeachers });

  const deleteTeacherMutation = useMutation((teacher: ITeacher) => {
    if (!school) return Promise.reject(new MissingSchoolError());
    return Api.deleteSchoolTeacher(school.id, teacher.user.id)
      .then(() => teacher); // Return the teacher so we can check in invalidateTeachers if our user changed
  }, { onSuccess: invalidateTeachers });

  // Teacher - handlers

  const handleCreateTeacher = (newTeacher: { email: string, firstName: string, lastName: string, groups: Group[], avatar?: string }) => {
    createTeacherMutation.mutate(newTeacher);
    setCreateTeacher(false);
  };

  const handleUpdateTeacher = (teacher: ITeacher) => {
    updateTeacherMutation.mutate(teacher);
    setEditTeacher(null);
  };

  const handleDeleteTeacher = (teacher: ITeacher) => {
    deleteTeacherMutation.mutate(teacher);
    setEditTeacher(null);
  };

  const handleEditTeacher = (teacher: ITeacher) => {
    setEditTeacher(teacher);
  };

  const handleCancelEditTeacher = () => {
    setCreateTeacher(false);
    setEditTeacher(null);
  };

  // Other

  const isLoading =
    isLoadingGroups ||
    isLoadingPupils ||
    isLoadingTeachers ||
    createGroupMutation.isLoading ||
    updateGroupMutation.isLoading ||
    deleteGroupMutation.isLoading ||
    createPupilMutation.isLoading ||
    updatePupilMutation.isLoading ||
    deletePupilMutation.isLoading ||
    createTeacherMutation.isLoading ||
    updateTeacherMutation.isLoading ||
    deleteTeacherMutation.isLoading;

  const error =
    errorOnGroups ||
    errorOnPupils ||
    errorOnTeachers ||
    createGroupMutation.error ||
    updateGroupMutation.error ||
    deleteGroupMutation.error ||
    createPupilMutation.error ||
    updatePupilMutation.error ||
    deletePupilMutation.error ||
    createTeacherMutation.error ||
    updateTeacherMutation.error ||
    deleteTeacherMutation.error;

  return <>
    <Row>
      <Col md="4">
        <PageTitle>{t('school:users')}</PageTitle>
      </Col>
      <Col style={{ textAlign: 'right' }}>
        <Row>
          { isAdminOfSchool && <Col md="4">
            <Dropdown align="end">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <Icon>{EIcon.PLUS}</Icon>
                {t('common:add')}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setCreateGroup(true)}>Voeg een groep toe</Dropdown.Item>
                <Dropdown.Item onClick={() => setCreatePupil(true)}>Voeg een leerling toe</Dropdown.Item>
                <Dropdown.Item onClick={() => setCreateTeacher(true)}>Voeg een leraar toe</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col> }
          <Col>
            <InputGroup className="input__search--user">
              <InputGroup.Text id="zoekIcoon">
                <Icon>{EIcon.SEARCH}</Icon>
              </InputGroup.Text>
              <Form.Control
                type="text"
                id="searchField"
                aria-describedby="zoekIcoon"
                placeholder={t('school:search-user')}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {
                searchQuery && (
                  <InputGroup.Text id="clearIcon" onClick={() => setSearchQuery('')}>
                    <Icon>{EIcon.X}</Icon>
                  </InputGroup.Text>
                )
              }
            </InputGroup>
          </Col>
        </Row>
      </Col>
    </Row>
    <Status loading={isLoading} error={error} />
    <UserTable groups={groups} pupils={pupils} teachers={teachers} onEditGroup={handleEditGroup} onEditUser={handleEditPupil} onEditTeacher={handleEditTeacher} searchQuery={searchQuery}/>
    <EditGroupModal
      value={editGroup}
      onChange={handleUpdateGroup}
      onDelete={handleDeleteGroup}
      onCancel={handleCancelEditGroup}
    />
    <CreateGroupModal
      visible={createGroup}
      onCreate={handleCreateGroup}
      onCancel={handleCancelEditGroup}
    />
    <EditPupilModal
      value={editPupil}
      groups={groups || []}
      onChange={handleUpdatePupil}
      onDelete={handleDeletePupil}
      onCancel={handleCancelEditPupil}
    />
    <CreatePupilModal
      visible={createPupil}
      groups={groups || []}
      pupils={pupils || []}
      onCreate={handleCreatePupil}
      onCancel={handleCancelEditPupil}
    />
    <EditTeacherModal
      value={editTeacher}
      allGroups={groups || []}
      onChange={handleUpdateTeacher}
      onDelete={handleDeleteTeacher}
      onCancel={handleCancelEditTeacher}
    />
    <CreateTeacherModal
      visible={createTeacher}
      allGroups={groups || []}
      onCreate={handleCreateTeacher}
      onCancel={handleCancelEditTeacher}
    />
  </>;
}
