import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdminLayout } from '../common/layouts/admin.layout';
import { EAdminRouterPath } from '../router-path';
import { ListInstructionLabels } from './instruction-labels/list';
import { CreateLesson } from './lesson/create';
import { ListLessons } from './lesson/list';
import { ShowLesson } from './lesson/show';
import { CreateSchool } from './school/create';
import { ListSchools } from './school/list';
import { ShowSchool } from './school/show';
import { CreateUser } from './user/create';
import { ListUsers } from './user/list';
import { ShowUser } from './user/show';
import { ListUsage } from './usage/list';
import { ShowUsage } from './usage/show';

export function AdminModule(): JSX.Element {
  return (
    <AdminLayout>
      <Routes>
        <Route path={EAdminRouterPath.HOME.path} element={<h3>Home.</h3>} />

        <Route path={EAdminRouterPath.SCHOOL_LIST.path} element={<ListSchools />} />
        <Route path={EAdminRouterPath.SCHOOL_CREATE.path} element={<CreateSchool />} />
        <Route path={EAdminRouterPath.SCHOOL_SHOW.path} element={<ShowSchool />} />

        { /* This endpoint is hidden for Kluitman */}
        <Route path={EAdminRouterPath.USER_LIST.path} element={<ListUsers />} />
        <Route path={EAdminRouterPath.USER_CREATE.path} element={<CreateUser />} />
        <Route path={EAdminRouterPath.USER_SHOW.path} element={<ShowUser />} />

        <Route path={EAdminRouterPath.LESSON_LIST.path} element={<ListLessons />} />
        <Route path={EAdminRouterPath.LESSON_CREATE.path} element={<CreateLesson />} />
        <Route path={EAdminRouterPath.LESSON_SHOW.path} element={<ShowLesson />} />

        <Route path={EAdminRouterPath.USAGE_LIST.path} element={<ListUsage />} />
        <Route path={EAdminRouterPath.USAGE_SHOW.path} element={<ShowUsage />} />

        <Route path={EAdminRouterPath.INSTRUCTION_LABEL_LIST.path} element={<ListInstructionLabels />} />
      </Routes>
    </AdminLayout>
  );
}
