"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.migrationToV3 = void 0;
var exercises_1 = require("../exercises");
function getFilenameFromUrl(url) {
    return url.replace(/^http(s){0,1}:\/\/.*\/storage\//, '');
}
function convertImage(image) {
    if (!image) {
        return image;
    }
    return {
        // @ts-ignore
        smallFilename: getFilenameFromUrl(image.smallUrl),
        // @ts-ignore
        mediumFilename: getFilenameFromUrl(image.mediumUrl),
        // @ts-ignore
        largeFilename: getFilenameFromUrl(image.largeUrl),
        // @ts-ignore
        xlargeFilename: getFilenameFromUrl(image.xlargeUrl),
        // @ts-ignore
        sourceFilename: getFilenameFromUrl(image.sourceUrl),
    };
}
function convertRecording(recording) {
    if (!recording) {
        return recording;
    }
    return {
        // @ts-ignore
        filename: getFilenameFromUrl(recording.url),
        // @ts-ignore
        sourceFilename: getFilenameFromUrl(recording.sourceUrl),
        // @ts-ignore
        name: recording.name,
        // @ts-ignore
        transcript: recording.transcript,
    };
}
function convertHiddenObjectsGameData(data) {
    data.introRecording = convertRecording(data.introRecording);
    data.image = convertImage(data.image);
    for (var _i = 0, _a = data.tiles || []; _i < _a.length; _i++) {
        var tile = _a[_i];
        tile.image = convertImage(tile.image);
        tile.questionRecording = convertRecording(tile.questionRecording);
        tile.recordingWord = convertRecording(tile.recordingWord);
        tile.realisticImage = convertImage(tile.realisticImage);
        tile.correctFeedbackRecording = convertRecording(tile.correctFeedbackRecording);
        if (tile.wrongFeedbackRecording) {
            tile.wrongFeedbackRecording = tile.wrongFeedbackRecording.map(function (recording) { return convertRecording(recording); });
        }
    }
}
function convertWordTowerExerciseData(data) {
    data.recording = convertRecording(data.recording);
    data.fullRecording = convertRecording(data.fullRecording);
    data.image = convertImage(data.image);
    if (data.wordParts) {
        data.wordParts = data.wordParts.map(function (wordPart) { return ({
            letterBlocks: wordPart.letterBlocks,
            recording: convertRecording(wordPart.recording),
        }); });
    }
}
exports.migrationToV3 = {
    migratingFromVersion: 2,
    migratingToVersion: 3,
    migration: function (lessonDocument) {
        for (var _i = 0, _a = lessonDocument.exercises; _i < _a.length; _i++) {
            var exercise = _a[_i];
            if (exercise.type === exercises_1.EExerciseType.HIDDEN_OBJECTS_GAME && exercise.data) {
                convertHiddenObjectsGameData(exercise.data);
                continue;
            }
            if (exercise.type === exercises_1.EExerciseType.WORD_TOWER && exercise.data) {
                convertWordTowerExerciseData(exercise.data);
                continue;
            }
        }
        return lessonDocument;
    },
};
