import React, { FormEvent, PropsWithChildren, useEffect, useState } from 'react';
import { ITeacher } from 'shared';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { Group } from '../../common/api/types';
import { DangerButton } from '../../common/utils/danger-button.component';
import { AvatarInput } from '../../common/utils/avatar-input.component';

const INPUT_MAX_LENGTH = 255;

interface EditTeacherModalFormProps {
  visible: boolean;
  allGroups: Group[];
  email?: string;
  onChangeEmail?: (value: string) => void;
  firstName?: string;
  onChangeFirstName?: (value: string) => void;
  lastName?: string;
  onChangeLastName?: (value: string) => void;
  groups: Group[];
  onChangeGroups: (value: Group[]) => void;
  avatar: string|null;
  onChangeAvatar: (value: string|null) => void;
  title: string;
  onCancel: () => void;
  onSubmit: () => void;
}

function EditTeacherModalForm({ visible, allGroups, title, email, onChangeEmail, firstName, onChangeFirstName, lastName, onChangeLastName, groups, onChangeGroups, avatar, onChangeAvatar, onCancel, onSubmit, children }: PropsWithChildren<EditTeacherModalFormProps>) {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  const handleChangeGroup = (group: Group, checked: boolean) => {
    const groupsContainsGroup = groups.some(g => g.id === group.id);
    if (checked && !groupsContainsGroup) {
      onChangeGroups([...groups, group]);
    } else if ((checked && groupsContainsGroup) || !checked && !groupsContainsGroup) {
      onChangeGroups([...groups]);
    } else if (!checked && groupsContainsGroup) {
      onChangeGroups(groups.filter(g => g.id !== group.id));
    }
  };

  return <Modal show={visible} onHide={onCancel}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        { (onChangeEmail) && <Row>
          <Form.Group as={Col}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              required
              minLength={1}
              maxLength={INPUT_MAX_LENGTH}
              value={email}
              onChange={e => onChangeEmail(e.target.value)} />
          </Form.Group>
        </Row> }
        { (onChangeFirstName || onChangeLastName) && <Row>
          { onChangeFirstName && <Form.Group as={Col}>
            <Form.Label>Voornaam</Form.Label>
            <Form.Control
              type="text"
              required
              minLength={1}
              maxLength={INPUT_MAX_LENGTH}
              value={firstName}
              onChange={e => onChangeFirstName(e.target.value)} />
          </Form.Group> }
          { onChangeLastName && <Form.Group as={Col}>
            <Form.Label>Achternaam</Form.Label>
            <Form.Control
              type="text"
              required
              minLength={1}
              maxLength={INPUT_MAX_LENGTH}
              value={lastName}
              onChange={e => onChangeLastName(e.target.value)} />
          </Form.Group> }
        </Row> }
        <Row>
          <Form.Group>
            <Form.Label>Avatar van de leerkracht</Form.Label>
            <AvatarInput value={avatar} onChange={onChangeAvatar} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Geeft les aan groepen</Form.Label>
            { allGroups.map(group => <Form.Check key={group.id}
              type="checkbox"
              label={group.name}
              checked={groups.some(g => g.id === group.id)}
              onChange={e => handleChangeGroup(group, e.target.checked)}
            />) }
          </Form.Group>
        </Row>
      </Modal.Body>

      <Modal.Footer>{children}</Modal.Footer>
    </Form>
  </Modal>;
}

interface EditTeacherModalProps {
  value?: ITeacher|null;
  allGroups: Group[];
  onChange: (teacher: ITeacher) => void;
  onDelete: (teacher: ITeacher) => void;
  onCancel: () => void;
}

export function EditTeacherModal({ value, allGroups, onChange, onDelete, onCancel }: EditTeacherModalProps): JSX.Element {
  const [groups, setGroups] = useState<Group[]>(value?.groups || []);
  const [avatar, setAvatar] = useState<string|null>(value?.avatar || null);

  useEffect(() => {
    if (!value) return;
    setGroups(value.groups || []);
    setAvatar(value.avatar || null);
  }, [value]);

  const handleSubmit = () => {
    if (!value) return;
    onChange({
      ...value,
      groups,
      avatar: avatar || undefined,
    });
  };

  const handleDelete = () => {
    if (!value) return;
    onDelete(value);
  };

  return <EditTeacherModalForm
    title="Leerkracht bewerken"
    allGroups={allGroups}
    visible={!!value}
    groups={groups}
    avatar={avatar}
    onChangeGroups={setGroups}
    onChangeAvatar={setAvatar}
    onCancel={onCancel}
    onSubmit={handleSubmit}
  >
    <DangerButton
      modalText={`Weet je zeker dat je ${value && value?.user.firstName || ''} ${value && value?.user.lastName || ''} wilt verwijderen?`}
      modalConfirmLabel="Verwijderen"
      modalCancelLabel="Behouden"
      onClick={() => handleDelete()}
    >Leerkracht verwijderen</DangerButton>
    <Button variant="primary" type="submit">Leerkracht opslaan</Button>
  </EditTeacherModalForm>;
}

interface CreateTeacherModalProps {
  visible: boolean;
  allGroups: Group[];
  onCreate: (newTeacher: { email: string, firstName: string, lastName: string, groups: Group[], avatar?: string }) => void;
  onCancel: () => void;
}

export function CreateTeacherModal({ visible, allGroups, onCreate, onCancel }: CreateTeacherModalProps): JSX.Element {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [groups, setGroups] = useState<Group[]>([]);
  const [avatar, setAvatar] = useState<string|null>(null);

  useEffect(() => {
    if (visible) {
      return;
    }

    // Reset the values every time the modal is hidden
    setEmail('');
    setFirstName('');
    setLastName('');
    setGroups([]);
    setAvatar(null);
  }, [visible]);

  const handleSubmit = () => {
    onCreate({
      email: email.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      groups,
      avatar: avatar || undefined,
    });
  };

  return <EditTeacherModalForm
    title="Voeg een leerkracht toe"
    allGroups={allGroups}
    visible={visible}
    email={email}
    onChangeEmail={setEmail}
    firstName={firstName}
    onChangeFirstName={setFirstName}
    lastName={lastName}
    onChangeLastName={setLastName}
    groups={groups}
    onChangeGroups={setGroups}
    avatar={avatar}
    onChangeAvatar={setAvatar}
    onCancel={onCancel}
    onSubmit={handleSubmit}
  >
    <Button variant="primary" type="submit">Leerkracht aanmaken</Button>
  </EditTeacherModalForm>;
}
