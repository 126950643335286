import React, { PropsWithChildren } from 'react';
import { ILesson } from 'shared';
import './video-layout.scss';
import icon from '../../icon-pjf.png';
import { RoundButton } from '../../../common/utils/round-button.component';
import { EIcon } from '../../../common/utils/icon.component';

export function VideoLayout({ lesson, onClose, children }: PropsWithChildren<{ lesson: ILesson, progress: number, onClose: () => void }>): JSX.Element {
  return <div className="video-layout">
    <div className="video-layout__header">
      <RoundButton icon={EIcon.X} onClick={onClose} variant="danger">Sluiten</RoundButton>
    </div>
    <div className="video-layout__title">
      <img className="video-layout__title-image" src={icon} alt="" />
      <h1 className="video-layout__title-text">Les {lesson.number} • {lesson.name}</h1>
    </div>
    { children }
  </div>;
}
