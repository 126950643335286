import { Col, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';

export const VideoDisplay = ({ url }: { url: string }): JSX.Element => (
  <Row>
    <Col>
      <ReactPlayer
        loop={false}
        config={{
          vimeo: {
            playerOptions: {
              pip: false,
              portrait: false,
              title: false,
              byline: false,
              color: '#850e2c'
            }
          }
        }}
        autoplay={false}
        showTitle={false}
        pip={false}
        stopOnUnmount={false}
        showPortrait={false}
        url={url}
        width={getComputedStyle(document.documentElement).getPropertyValue('--video-player-width')}
        height={getComputedStyle(document.documentElement).getPropertyValue('--video-player-height')}
        controls />
    </Col>
  </Row>
);
