import { useTranslation } from 'react-i18next';
import { ELessonType, IPupilDecodingResult, IPupilProgress, PupilDecodingResultSchema } from 'shared';
import { ReactElement, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import { DynamicTableColumn, DynamicTable } from '../../../common/utils/dynamic-table.component';
import { ProgressResult, ProgressResultItem } from './progress-summary';
import { EIcon } from '../../../common/utils/icon.component';

type TReadingComprehensionTableData = {
  question: string;
  chosenAnswer: string;
  result: ReactElement<any, any>;
  attempts: number;
}


function ReadingComprehensionDetails({ result }: { result: IPupilDecodingResult }): JSX.Element {
  const { t } = useTranslation();

  if (!result.questions) {
    return <></>;
  }

  const columns: DynamicTableColumn<TReadingComprehensionTableData>[] = [
    { title: 'Vraag', objectKey: 'question' },
    { title: 'Gekozen antwoord', objectKey: 'chosenAnswer' },
    { title: 'Resultaat', objectKey: 'result', disableSort: true },
    { title: 'Pogingen', objectKey: 'attempts' },
  ];

  const data: TReadingComprehensionTableData[] = [];
  for (const question of result.questions) {
    const correct = question.attempts.includes(question.correctOption);
    const chosenAnswer: string|undefined = question.attempts[question.attempts.length - 1];

    data.push({
      question: question.question,
      chosenAnswer: chosenAnswer,
      result: <ProgressResult>
        <ProgressResultItem icon={correct ? EIcon.CHECK_CIRCLE : EIcon.X_CIRCLE} aria-label={t(correct ? 'progress-summary:correct' : 'progress-summary:wrong')} />
      </ProgressResult>,
      attempts: question.attempts.length,
    });
  }

  return <DynamicTable<TReadingComprehensionTableData> columns={columns} data={data} simplified={true} />;
}

export function ProgressOverviewModal({ progress, title }: { progress: IPupilProgress, title: string }): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (progress.lesson.type !== ELessonType.READING_COMPREHENSION) {
    return <></>;
  }

  let data: any = {};
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data = JSON.parse(progress.result);
  } catch (err) {
    console.error('Failed to parse lesson progress result', progress, err);
  }

  const validatedData = PupilDecodingResultSchema.validate(data);
  if (validatedData.error) {
    return <></>;
  }

  const renderContent = () => {
    if (!open) {
      return null;
    }

    return <ReadingComprehensionDetails result={validatedData.value} />;
  };

  return <>
    <Modal show={open} onHide={() => setOpen(false)} size="lg">
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
    </Modal>
    <Button type="button" onClick={() => setOpen(!open)}>{t('progress-summary:details')}</Button>
  </>;
}
