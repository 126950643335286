import { createContext, useContext } from 'react';
import { School } from '../api/types';

export interface ISchoolContext {
  school?: School;
}

export const SchoolContext = createContext<ISchoolContext>({});

export function useSchool(): ISchoolContext {
  return useContext(SchoolContext);
}
