import React, { PropsWithChildren } from 'react';
import { ILesson } from 'shared';
import './vocabulary-layout.scss';
import icon from '../../icon-chest.png';
import { RoundButton } from '../../../common/utils/round-button.component';
import { EIcon } from '../../../common/utils/icon.component';
import classNames from 'classnames';

export function VocabularyLayout({ lesson, onClose, children, className }: PropsWithChildren<{ lesson: ILesson, onClose: () => void, className?: string }>): JSX.Element {
  return <div className={classNames('vocabulary-layout', className)}>
    <div className="vocabulary-layout__header">
      <div className="vocabulary-layout__title">
        <img className="vocabulary-layout__title-image" src={icon} alt="" />
        <h1 className="vocabulary-layout__title-text">{lesson.number}. {lesson.name}</h1>
      </div>
      <RoundButton icon={EIcon.X} onClick={onClose} variant="danger">Sluiten</RoundButton>
    </div>
    <div className="vocabulary-layout__content">
      { children }
    </div>
  </div>;
}
