"use strict";
// Migrate database if you change the avatar or password enum
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPupilPasswordPart = exports.EPupilAvatar = void 0;
var EPupilAvatar;
(function (EPupilAvatar) {
    EPupilAvatar["BELL"] = "bell";
    EPupilAvatar["BOOT"] = "boot";
    EPupilAvatar["BUNCH"] = "bunch";
    EPupilAvatar["CHOIR"] = "choir";
    EPupilAvatar["CUP"] = "cup";
    EPupilAvatar["DEER"] = "deer";
    EPupilAvatar["DENT"] = "dent";
    EPupilAvatar["DOOR"] = "door";
    EPupilAvatar["FENCE"] = "fence";
    EPupilAvatar["FIN"] = "fin";
    EPupilAvatar["FIRE"] = "fire";
    EPupilAvatar["FLAME"] = "flame";
    EPupilAvatar["FLUTE"] = "flute";
    EPupilAvatar["FOOT"] = "foot";
    EPupilAvatar["FOX"] = "fox";
    EPupilAvatar["FRUIT"] = "fruit";
    EPupilAvatar["GHOST"] = "ghost";
    EPupilAvatar["GLASS"] = "glass";
    EPupilAvatar["GOAT"] = "goat";
    EPupilAvatar["HOP"] = "hop";
    EPupilAvatar["HOUR"] = "hour";
    EPupilAvatar["HOUSE"] = "house";
    EPupilAvatar["HUT"] = "hut";
    EPupilAvatar["NEST"] = "nest";
    EPupilAvatar["PANTS"] = "pants";
    EPupilAvatar["PAW"] = "paw";
    EPupilAvatar["PEACOCK"] = "peacock";
    EPupilAvatar["PIG"] = "pig";
    EPupilAvatar["ROAD"] = "road";
    EPupilAvatar["SEA"] = "sea";
    EPupilAvatar["SEESAW"] = "seesaw";
    EPupilAvatar["STOOL"] = "stool";
    EPupilAvatar["SUN"] = "sun";
    EPupilAvatar["SWEEP"] = "sweep";
    EPupilAvatar["UNCLE"] = "uncle";
    EPupilAvatar["WALL"] = "wall";
    EPupilAvatar["WASP"] = "wasp";
    EPupilAvatar["WELL"] = "well";
    EPupilAvatar["WIG"] = "wig";
    EPupilAvatar["WITCH"] = "witch";
})(EPupilAvatar = exports.EPupilAvatar || (exports.EPupilAvatar = {}));
var EPupilPasswordPart;
(function (EPupilPasswordPart) {
    EPupilPasswordPart["APE"] = "ape";
    EPupilPasswordPart["BEAR"] = "bear";
    EPupilPasswordPart["BUS"] = "bus";
    EPupilPasswordPart["CHICKEN"] = "chicken";
    EPupilPasswordPart["GIANT"] = "giant";
    EPupilPasswordPart["HORSE"] = "horse";
    EPupilPasswordPart["SWAN"] = "swan";
    EPupilPasswordPart["TENT"] = "tent";
})(EPupilPasswordPart = exports.EPupilPasswordPart || (exports.EPupilPasswordPart = {}));
